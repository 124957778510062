import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";

// Redux actions
import { getCheapEssayPage } from "../../../redux/slices/ServicePagesSlice";

// Components
import PageBanner from "../../components/layout/pages/PageBanner";
import AssignmentWriters from "../../components/layout/pages/AssignmentWriters";
import Help from "../../components/layout/pages/Help";
import HelpFeatures from "../../components/layout/pages/HelpFeatures";
import FAQ2 from "../../components/layout/pages/FAQ2";

const CheapEssay = () => {
  const dispatch = useAppDispatch();

  // Redux stores
  const { cheapEssayStatus, cheapEssayData } = useAppSelector(
    (state) => state.servicePages
  );

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    if (cheapEssayStatus !== "success") {
      dispatch(getCheapEssayPage());
    }
  }, []);

  return (
    <>
      {/* page banner area start */}
      <PageBanner
        className="about-inner-banner"
        title={cheapEssayData.sec_1_i_banner[0].heading}
        description={cheapEssayData.sec_1_i_banner[0].subheading}
        reviewSites={cheapEssayData.sec_1_i_banner[0].inner_banner_review}
      />
      {/* page banner area end */}

      {/* wrapper area start */}
      <div className="wrapper">
        {/* Assignment Writers */}
        <section className="assignment-writers-area">
          <div className="container">
            <AssignmentWriters
              title1="Meet Our Professional Paper Writers"
              title2="Book qualified writers to guarantee an A+ in MBA papers."
              // data={cheapEssayData.sec_2_assignment_writer}
            />
          </div>
        </section>

        {/* Get Help */}
        <Help
          headingOneText={cheapEssayData.sec_main.sec_3_h1}
          headingTwoText={cheapEssayData.sec_main.sec_3_h2}
          data={cheapEssayData.sec_main.sec_3_it_1}
        />

        {/* help features */}
        <HelpFeatures
          headingOneText={cheapEssayData.sec_main.sec_4_h1}
          headingTwoText={cheapEssayData.sec_main.sec_4_h2}
          data={cheapEssayData.sec_main.sec_4_it_1}
          helpButton={false}
        />

        {/* faq area 2 */}
        <FAQ2 dataType="write_my_paper" data={cheapEssayData.sec_main.sec_5_at} />
      </div>
      {/* wrapper area end */}
    </>
  );
};

export default CheapEssay;
