import { useEffect, useState } from "react";
// import useAuth from "../hooks/useAuth";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";

// Helpers
import qs from "qs";

// Redux actions
import { getUserOrdersList } from "../../../redux/slices/OrdersSlice";
import { getProfilePage } from "../../../redux/slices/UserSlice";

// Models
import { OrderDataModel } from "../../../models/constants/data/dataModels";

// Components
import OrdersTable from "../../components/layout/pages/OrdersTable";
import OrderDetails from "../../components/layout/pages/OrderDetails";
let userEmail = localStorage.getItem("user_email");
const initialQuery = qs.stringify(
  {
    filters: {
      email: {
        $eq: `${userEmail}`,
      },
    },
    sort: ["id:desc"],
    pagination: {
      page: 1,
      pageSize: 5,
    },
  },
  {
    encodeValuesOnly: true,
  }
);
const Index = () => {
  const dispatch = useAppDispatch();
  const [ready, setReady] = useState(false);
  const [orderDetails, setorderDetails] = useState<OrderDataModel | undefined>(undefined);

  // Redux stores
  const { ordersData } = useAppSelector((state) => state.orders);
  const { profilePageStatus, profilePageData } = useAppSelector((state) => state.userData);

  function fetchOrdersForUser() {
    // const query = qs.stringify(
    //   {
    //     filters: {
    //       email: {
    //         $eq: `${userEmail}`,
    //       },
    //     },
    //   },
    //   {
    //     encodeValuesOnly: true, // prettify URL
    //   }
    // );
    // if (ordersData.data.length === 0) {
    //   dispatch(getUserOrdersList(initialQuery));
    // }
  }

  // useAuth();
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    dispatch(getUserOrdersList(initialQuery));
    if (profilePageStatus !== "success") {
      dispatch(getProfilePage());
    }
  }, []);

  function fetchorder() {
    fetchOrdersForUser();
    if (profilePageStatus !== "success") {
      dispatch(getProfilePage());
    }
  }
  useEffect(() => {
    if (ordersData.data.length > 0) {
      setorderDetails(ordersData.data[0]);
    }
  }, [ordersData]);
  console.log("orderdetails=====> folo", ordersData);

  const handleupdateorder = (data: any) => {
    console.log(`handleupdateorder=====>`, data);
  };
  console.log("orderdetails=====> neeew", !orderDetails ? ordersData.data[0] : orderDetails, ordersData);
  return (
    <>
      <section className="all-orders-area dashboard-all-orders-area pb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-9">
              {ordersData.data.length > 0 && (
                <div className="posd-area">
                  <h4 className="prev-order mb-0">Previous Order</h4>
                  <a href="/payment" className="custombtn btn-1 hover-filled-slide-down">
                    <span>pay now</span>
                  </a>
                </div>
              )}
              {ordersData.data.length === 0 && (
                <div className="main-hdng main-hdng-newsd">
                  <h2>Hello, {profilePageData.name}</h2>
                  <p>Experts are available 24x7 to help you to improve your grade. Upload your assignment and enjoy the best academic service in the world.</p>
                </div>
              )}
              <OrdersTable ordersData={ordersData} setorderDetails={setorderDetails} />
            </div>
            <div className="col-lg-3">
              <div className="all-orders-right-area">
                <Link to="/say-no-to-ai" className="custombtn btn-1 hover-filled-slide-down">
                  <span>
                    SAY NO TO AI <i className="fa-solid fa-arrow-right-long" />
                  </span>
                </Link>
                <Link to="/submit-new-assignment" className="custombtn btn-4 hover-filled-slide-down">
                  <span>
                    Order Now <i className="fa-solid fa-arrow-right-long" />
                  </span>
                </Link>
              </div>
            </div>
          </div>

          <div className="row">
            {ordersData.data.length > 0 && <OrderDetails orderDetails={orderDetails || ordersData.data[0]} handleupdateorder={handleupdateorder} />}
            {/* {ordersData.data.length > 0 && <OrderDetails orderDetails={!orderDetails ? ordersData.data[0] : orderDetails} />} */}

            {ordersData.data.length === 0 && (
              <div className="col-lg-12">
                <div className="preorder-bx when-no-order">
                  <div className="row">
                    <div className="col-md-8 col-lg-9 mobile-order-2">
                      <div className="preorder-bx-left">
                        <h2>Save money with prebook order</h2>
                        <p>Get flat 15 % off on your next assignment on prebooking it.</p>
                        <a href="#!" className="custombtn btn-1 hover-filled-slide-down">
                          <span>Pre-book order</span>
                        </a>
                      </div>
                    </div>
                    <div className="col-md-4 col-lg-3">
                      <div className="preorder-bx-right">
                        <img src="/assets/images/prebook-img.svg" alt="prebook-img" className="prebook-img" />
                        <div className="preorder-bx-right-circle"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default Index;
