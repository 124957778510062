import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";

// Redux actions
import { getWriteMyPaperPage } from "../../../redux/slices/ServicePagesSlice";

// Components
import PageBanner from "../../components/layout/pages/PageBanner";
import AssignmentWriters from "../../components/layout/pages/AssignmentWriters";
import Help from "../../components/layout/pages/Help";
import HelpFeatures from "../../components/layout/pages/HelpFeatures";
import FAQ2 from "../../components/layout/pages/FAQ2";

const WriteMyPaper = () => {
  const dispatch = useAppDispatch();

  // Redux stores
  const { writeMyPaperPageStatus, writeMyPaperPageData } = useAppSelector(
    (state) => state.servicePages
  );

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    if (writeMyPaperPageStatus !== "success") {
      dispatch(getWriteMyPaperPage());
    }
  }, []);

  return (
    <>
      {/* page banner area start */}
      <PageBanner
        className="about-inner-banner"
        title={writeMyPaperPageData.sec_1_i_banner[0].heading}
        description={writeMyPaperPageData.sec_1_i_banner[0].subheading}
        // reviewSites={writeMyPaperPageData.sec_1_i_banner[0].inner_banner_review}
      />
      {/* page banner area end */}

      {/* wrapper area start */}
      <div className="wrapper">
        {/* Assignment Writers */}
        <section className="assignment-writers-area">
          <div className="container">
            <AssignmentWriters
              title1={writeMyPaperPageData.sec_2_h1}
              title2={writeMyPaperPageData.sec_2_h2}
              data={writeMyPaperPageData.sec_2_assignment_writer}
            />
          </div>
        </section>

        {/* Get Help */}
        <Help
          headingOneText={writeMyPaperPageData.sec_3_h1}
          headingTwoText={writeMyPaperPageData.sec_3_h2}
          data={writeMyPaperPageData.sec_3_it_1}
        />
        {/* <section className="gethelp-area">
          <div className="container">
            <div className="main-hdng text-center">
              <h2>How To Get My Paper Written?</h2>
              <p>Finding Reliable Resources for Assignment Writing</p>
            </div>
            <div className="gethelp-bx-area">
              <div className="gethelp-line">
                <img src="assets/images/gethelp-line.svg" alt="gethelp-line" />
              </div>
              <div className="gethelp-bx">
                <div className="gethelp-bx-number">01</div>
                <div className="gethelp-bx-img">
                  <img
                    src="assets/images/gethelp-bx-img1.svg"
                    alt="gethelp-bx-img"
                  />
                </div>
                <div className="gethelp-bx-text">
                  <h5>Send Us Instructions</h5>
                  <p>
                    Provide the details of your task, such as the writing style
                    you want us to follow and the length of the task.
                  </p>
                </div>
              </div>
              <div className="gethelp-bx">
                <div className="gethelp-bx-number">02</div>
                <div className="gethelp-bx-img">
                  <img
                    src="assets/images/gethelp-bx-img2.svg"
                    alt="gethelp-bx-img"
                  />
                </div>
                <div className="gethelp-bx-text">
                  <h5>Choose a Paper Writer</h5>
                  <p>
                    All our writers have graduated from top universities. Check
                    their availability before sending a request.
                  </p>
                </div>
              </div>
              <div className="gethelp-bx">
                <div className="gethelp-bx-number">03</div>
                <div className="gethelp-bx-img">
                  <img
                    src="assets/images/gethelp-bx-img3.svg"
                    alt="gethelp-bx-img"
                  />
                </div>
                <div className="gethelp-bx-text">
                  <h5>Complete the Payment</h5>
                  <p>
                    Guarantee a high-quality paper once you pay for your order.
                    We use multiple payment methods for convenience.
                  </p>
                </div>
              </div>
            </div>
            <div className="btn-group">
              <a href="#!" className="custombtn btn-1 hover-filled-slide-down">
                <span>Book a Solution</span>
              </a>
            </div>
          </div>
        </section> */}

        {/* help features */}
        <HelpFeatures
          headingOneText={writeMyPaperPageData.sec_4_h1}
          headingTwoText={writeMyPaperPageData.sec_4_h2}
          data={writeMyPaperPageData.sec_4_it_1}
          helpButton={false}
        />
        {/* <section className="help-features-area">
          <div className="circle-blur-img">
            <img
              src="assets/images/circle-blur-img.svg"
              alt="circle-blur-img"
            />
          </div>
          <div className="circle-blur-img-2">
            <img
              src="assets/images/circle-blur-img-2.svg"
              alt="circle-blur-img-2"
            />
          </div>
          <div className="container">
            <div className="main-hdng text-center">
              <h2>Key Benefits Of Our “MBA Essay Writing” Service</h2>
              <p>
                Discover why students trust our professional paper writing
                service.
              </p>
            </div>
            <div className="row g-5">
              <div className="col-lg-4 col-md-6">
                <div className="help-features-bx">
                  <span>
                    <img
                      src="assets/images/help-features-ico13.svg"
                      alt="icon"
                    />
                  </span>
                  <h5>Quality Writing</h5>
                  <p>
                    Discover top experts from all academic fields ready to
                    tackle any type of assignment you want.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="help-features-bx">
                  <span>
                    <img
                      src="assets/images/help-features-ico3.svg"
                      alt="icon"
                    />
                  </span>
                  <h5>Authentic Paper</h5>
                  <p>
                    Our decade-long experience in this industry allows us to
                    solve students’ problems within seconds.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="help-features-bx">
                  <span>
                    <img
                      src="assets/images/help-features-ico14.svg"
                      alt="icon"
                    />
                  </span>
                  <h5>Timely Delivery</h5>
                  <p>
                    We cover all types of subjects, from English to programming.
                    Master all subjects under our SMEs.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="help-features-bx">
                  <span>
                    <img
                      src="assets/images/help-features-ico6.svg"
                      alt="icon"
                    />
                  </span>
                  <h5>Reputed Scholars</h5>
                  <p>
                    We have a 99% success rate in delivering essays, research
                    papers, and all types of assignments.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="help-features-bx">
                  <span>
                    <img
                      src="assets/images/help-features-ico5.svg"
                      alt="icon"
                    />
                  </span>
                  <h5>Affordable Prices</h5>
                  <p>
                    Enjoy free access to plagiarism checkers and more tools you
                    need to submit a perfect assignment.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="help-features-bx">
                  <span>
                    <img
                      src="assets/images/help-features-ico15.svg"
                      alt="icon"
                    />
                  </span>
                  <h5>100% Confidentiality</h5>
                  <p>
                    Our client-centric approach allows us to offer personalized
                    services for complete satisfaction.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        {/* faq area 2 */}
        <FAQ2 dataType="write_my_paper" dataProvided={false} />
      </div>
      {/* wrapper area end */}
    </>
  );
};

export default WriteMyPaper;
