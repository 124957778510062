import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

// Helpers
import { ApiHelperFunction } from "../../helpers/api_helpers";
import _ from "lodash";

// Typeguards
import { isApiErrorResponse } from "../../helpers/typeguards";

// Models
import { ApiFuncArgProps, ApiErrorResponse } from "../../models/apiFuncHelpers";
import { SamplePageDataModel } from "../../models/views/pages";
import {
  SampleSubCategoryListDataModel,
  SampleAnswerListDataModel,
} from "../../models/constants/data/dataModels";
import { AxiosResponse } from "axios";

interface SamplePageInitialState {
  samplePageStatus: string;
  samplePageData: SamplePageDataModel;
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  sampleSubCategoryStatus: string;
  sampleSubCategoryData: SampleSubCategoryListDataModel;
  sampleSubCategoryIsLoading: boolean;
  sampleSubCategoryIsSuccess: boolean;
  sampleSubCategoryIsError: boolean;
  sampleAnswerListStatus: string;
  sampleAnswerListData: SampleAnswerListDataModel;
  sampleAnswerListIsLoading: boolean;
  sampleAnswerListIsSuccess: boolean;
  sampleAnswerListIsError: boolean;
}

const initialState: SamplePageInitialState = {
  samplePageStatus: "idle",
  samplePageData: {
    sec_2_h1: "",
    createdAt: "",
    updatedAt: "",
    publishedAt: "",
    sec_3_h1: "",
    sec_1_i_banner: [
      {
        id: 0,
        heading: "",
        subheading: "",
        inner_banner_review: [],
      },
    ],
    sec_3_it_1: [],
  },
  isLoading: false,
  isSuccess: false,
  isError: false,
  sampleSubCategoryStatus: "idle",
  sampleSubCategoryData: {
    data: [],
    meta: {
      pagination: {
        page: 0,
        pageCount: 0,
        pageSize: 0,
        total: 0,
      },
    },
  },
  sampleSubCategoryIsLoading: false,
  sampleSubCategoryIsSuccess: false,
  sampleSubCategoryIsError: false,
  sampleAnswerListStatus: "idle",
  sampleAnswerListData: {
    data: [],
    meta: {
      pagination: {
        page: 0,
        pageSize: 0,
        pageCount: 0,
        total: 0,
      },
    },
  },
  sampleAnswerListIsLoading: false,
  sampleAnswerListIsSuccess: false,
  sampleAnswerListIsError: false,
};

export const getSamplePage = createAsyncThunk("get_sample_page", async () => {
  try {
    let response = await ApiHelperFunction({
      urlPath: "/sample-page?populate=deep",
      method: "GET",
    } as ApiFuncArgProps);

    if (isApiErrorResponse(response)) {
      console.log("Error: ", response.error.message);
    } else {
      return response.data;
    }
  } catch (error: any) {
    console.log("Failed to fetch sample page due to ", error.message);
  }
});

export const getSampleSubcategories = createAsyncThunk(
  "get_sample_subcategories",
  async () => {
    try {
      let response = await ApiHelperFunction({
        urlPath: "/sample-sub-categories?populate=deep",
        method: "GET",
      } as ApiFuncArgProps);

      if (isApiErrorResponse(response)) {
        console.log("Error: ", response.error.message);
      } else {
        return response.data;
      }
    } catch (error: any) {
      console.log(
        "Failed to fetch sample subcategories due to ",
        error.message
      );
    }
  }
);

export const getSampleAnswerList = createAsyncThunk(
  "get_sample_answer_list",
  async (query?:string) => {
    let response:AxiosResponse | ApiErrorResponse;

    try {
      if (query) {
        response = await ApiHelperFunction({
          urlPath: `/sample-sections?populate=deep&${query}`,
          method: "GET",
        } as ApiFuncArgProps);
      } else {
        response = await ApiHelperFunction({
          urlPath: "/sample-sections?populate=deep",
          method: "GET",
        } as ApiFuncArgProps);
      }

      if (isApiErrorResponse(response)) {
        console.log("Error: ", response.error.message);
      } else {
        return response.data;
      }
    } catch (error: any) {
      console.log(
        "Failed to fetch sample subcategories due to ",
        error.message
      );
    }
  }
);

export const samplePageSlice = createSlice({
  name: "sample_page",
  initialState,
  reducers: {
    clearSamplePageState: (state) => {
      state.samplePageStatus = "idle";
      state.samplePageData = {
        sec_2_h1: "",
        createdAt: "",
        updatedAt: "",
        publishedAt: "",
        sec_3_h1: "",
        sec_1_i_banner: [
          {
            id: 0,
            heading: "",
            subheading: "",
            inner_banner_review: [],
          },
        ],
        sec_3_it_1: [],
      };
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.samplePageStatus = "idle";
      state.sampleSubCategoryData = {
        data: [],
        meta: {
          pagination: {
            page: 0,
            pageCount: 0,
            pageSize: 0,
            total: 0,
          },
        },
      };
      state.sampleSubCategoryIsLoading = false;
      state.sampleSubCategoryIsSuccess = false;
      state.sampleSubCategoryIsError = false;
      state.sampleAnswerListStatus = "idle";
      state.sampleAnswerListData = {
        data: [],
        meta: {
          pagination: {
            page: 0,
            pageCount: 0,
            pageSize: 0,
            total: 0,
          },
        },
      };
      state.sampleAnswerListIsLoading = false;
      state.sampleAnswerListIsSuccess = false;
      state.sampleAnswerListIsError = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSamplePage.pending, (state) => {
        state.samplePageStatus = "loading";
        state.isLoading = true;
      })
      .addCase(getSamplePage.fulfilled, (state, { payload }) => {
        state.samplePageStatus = "success";
        state.isSuccess = true;
        state.samplePageData = payload?.data?.attributes;
      })
      .addCase(getSamplePage.rejected, (state) => {
        state.samplePageStatus = "failed";
        state.isError = true;
      })
      .addCase(getSampleSubcategories.pending, (state) => {
        state.sampleSubCategoryStatus = "loading";
        state.sampleSubCategoryIsLoading = true;
      })
      .addCase(getSampleSubcategories.fulfilled, (state, { payload }) => {
        state.sampleSubCategoryStatus = "success";
        let sampleCategories = _.groupBy(
          payload?.data,
          (item) =>
            item?.attributes?.sample_category?.data?.attributes?.category_name
        );
        console.log("sample categories", sampleCategories);
        state.sampleSubCategoryData = payload;
        state.sampleSubCategoryIsSuccess = true;
      })
      .addCase(getSampleSubcategories.rejected, (state) => {
        state.sampleSubCategoryStatus = "failed";
        state.sampleSubCategoryIsError = true;
      })
      .addCase(getSampleAnswerList.pending, (state) => {
        state.sampleAnswerListStatus = "loading";
        state.sampleAnswerListIsLoading = true;
      })
      .addCase(getSampleAnswerList.fulfilled, (state, { payload }) => {
        state.sampleAnswerListStatus = "success";
        state.sampleAnswerListData = payload;
        state.sampleAnswerListIsSuccess = true;
      })
      .addCase(getSampleAnswerList.rejected, (state) => {
        state.sampleAnswerListStatus = "failed";
        state.sampleAnswerListIsError = true;
      });
  },
});

export const { clearSamplePageState } = samplePageSlice.actions;
