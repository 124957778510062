import { useState, useEffect } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";

// Redux actions
import { clearHomepageState } from "../../../../redux/slices/HomePageSlice";
import { clearBlogState } from "../../../../redux/slices/BlogSlice";
import { clearAboutUsPageState } from "../../../../redux/slices/AboutUsPageSlice";
import { clearUserState } from "../../../../redux/slices/UserSlice";
import { clearOrdersListState } from "../../../../redux/slices/OrdersSlice";
import { clearWriteMyPaperPageState } from "../../../../redux/slices/ServicePagesSlice";
import { clearSamplePageState } from "../../../../redux/slices/SamplePageSlice";

let imgBaseUrl = process.env.REACT_APP_API_IMG_URL;

const DashboardHeader = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [selectTab, setselectTab] = useState("");
  // const [serviceItemId, setserviceItemId] = useState("");
  const [userDetails, setuserDetails] = useState({
    name: "",
    role: "",
  });

  // Redux stores
  const { profilePageData } = useAppSelector((state) => state.userData);

  useEffect(() => {
    // console.log(location.pathname.split("/").slice(-1)[0]);
    let path = location.pathname.split("/").slice(-1)[0];

    let tabItem;
    switch (path) {
      case "dashboard":
        tabItem = document.getElementById("header_item_1") as HTMLElement;
        tabItem.classList.add("active");
        setselectTab("header_item_1");
        break;
      case "all-orders":
        tabItem = document.getElementById("header_item_2") as HTMLElement;
        tabItem.classList.add("active");
        setselectTab("header_item_2");
        break;
      case "grammar-checker":
        tabItem = document.getElementById("header_item_3") as HTMLElement;
        tabItem.classList.add("active");
        setselectTab("header_item_3");
        break;
      default:
        break;
    }

    setuserDetails((prev) => {
      let update = JSON.parse(JSON.stringify(prev));
      update.name = localStorage.getItem("user_name");
      update.role = localStorage.getItem("user_role");
      return update;
    });
  }, []);

  const setActiveTab = (id: string) => {
    let oldSelectedItem = document.getElementById(selectTab) as HTMLElement;
    let newSelectedItem = document.getElementById(id) as HTMLElement;

    if (id !== selectTab) {
      oldSelectedItem.classList.remove("active");
      newSelectedItem.classList.add("active");
      setselectTab(id);
    }
  };

  const handleLogout = () => {
    localStorage.clear();
    dispatch(clearHomepageState());
    dispatch(clearBlogState());
    dispatch(clearAboutUsPageState());
    dispatch(clearUserState());
    dispatch(clearOrdersListState());
    dispatch(clearWriteMyPaperPageState());
    dispatch(clearSamplePageState());

    navigate("/");
  };

  return (
    <div className="dashboard-header-area">
      <div className="container">
        <div className="dashboard-header">
          <div className="dashboard-header-left-area">
            <ul>
              <li id="header_item_1" className={selectTab === "header_item_1" ? "active" : ""}>
                <Link to="/dashboard" onClick={() => setActiveTab("header_item_1")}>
                  <img src="/assets/images/dashboard-menu-ico.svg" alt="dashboard-menu-ico" />
                  <span>Dashboard</span>
                </Link>
              </li>
              <li id="header_item_2" className={selectTab === "header_item_2" ? "active" : ""}>
                <Link to="/all-orders" onClick={() => setActiveTab("header_item_2")}>
                  <img src="/assets/images/allorder-menu-ico.svg" alt="allorder-menu-ico" />
                  <span>All Order</span>
                </Link>
              </li>
              <li id="header_item_3" className="hasChild">
                <a href="javascript:void(0)">
                  <img src="/assets/images/moreservices-menu-ico.svg" alt="moreservices-menu-ico" />
                  <span>More services</span>
                </a>
                <ul>
                  <li id="service_item_1">
                    <Link
                      to="/coming-soon"
                      // onClick={() => {
                      //   setActiveTab("header_item_3");
                      //   setServiceItem("service_item_1");
                      // }}
                    >
                      Grammar Checker
                    </Link>
                  </li>
                  <li id="service_item_2">
                    <Link
                      to="/coming-soon"
                      // onClick={() => {
                      //   setActiveTab("header_item_3");
                      //   setServiceItem("service_item_2");
                      // }}
                    >
                      Factoring Calculator
                    </Link>
                  </li>
                  <li id="service_item_3">
                    <Link
                      to="/coming-soon"
                      // onClick={() => {
                      //   setActiveTab("header_item_3");
                      //   setServiceItem("service_item_3");
                      // }}
                    >
                      Equation Solver
                    </Link>
                  </li>
                  <li id="service_item_4">
                    <Link
                      to="/coming-soon"
                      // onClick={() => {
                      //   setActiveTab("header_item_3");
                      //   setServiceItem("service_item_4");
                      // }}
                    >
                      Paraphrasing Tool
                    </Link>
                  </li>
                  <li id="service_item_5">
                    <Link
                      to="/coming-soon"
                      // onClick={() => {
                      //   setActiveTab("header_item_3");
                      //   setServiceItem("service_item_5");
                      // }}
                    >
                      Plagiarism Checker
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div className="dashboard-header-right-area">
            <div className="dashboard-header-right">
              <a href="#!" className="notification-ico">
                <img src="/assets/images/notification-ico.svg" alt="notification-ico" />
              </a>
              <div className="dashboard-header-right-right">
                <div className="dp-dashboard-txt">
                  <h6>{userDetails.name}</h6>
                  <p>{userDetails.role}</p>
                </div>
                <div className="dp-dashboard-img">
                  <img src={profilePageData?.profile_pic === null ? "/assets/images/no-user.png" : imgBaseUrl ? imgBaseUrl + profilePageData.profile_pic?.url : "/assets/images/no-user.png"} alt="dp-dashboard" />
                </div>
                <div className="dropdown-btn-sd">
                  <a href="#! dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false" className="three-dot-ico">
                    <img src="/assets/images/three-dot-ico.svg" alt="three-dot-ico" />
                  </a>
                  <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                    <li>
                      <Link className="dropdown-item" to="/user-profile">
                        My Profile
                      </Link>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        // href="#"
                        onClick={() => handleLogout()}
                      >
                        Logout
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardHeader;
