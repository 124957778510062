import { useNavigate } from "react-router-dom";

// Models
import { SectionWithCardsProp } from "../../../../models/views/components/pages";

let imgBaseUrl = process.env.REACT_APP_API_IMG_URL;

const HelpFeatures = ({ className, headingOne = true, headingOneText, headingTwo = true, headingTwoText, data = [], helpButton = true, helpButtonText, helpButtonUrl }: SectionWithCardsProp) => {
  const navigate = useNavigate();
  return (
    <section className="help-features-area">
      <div className="circle-blur-img">
        <img src="/assets/images/circle-blur-img.svg" alt="circle-blur-img" />
      </div>
      <div className="circle-blur-img-2">
        <img src="/assets/images/circle-blur-img-2.svg" alt="circle-blur-img-2" />
      </div>
      <div className="container">
        <div className="main-hdng text-center">
          {headingOne && <h2>{headingOneText ? headingOneText : "Top Assignment Help Features"}</h2>}
          {headingTwo && <p>{headingTwoText ? headingTwoText : "Unlock All the Perks of Our Customized Assignment Help Service!"}</p>}
        </div>
        {data && (
          <div className="row g-5">
            {data.map((item, idx) => (
              <div key={idx} className="col-lg-4 col-md-6">
                <div className="help-features-bx">
                  <span>
                    <img src={imgBaseUrl ? imgBaseUrl + item.icon?.data?.attributes?.url : ""} alt="icon" />
                  </span>
                  <h5>{item.heading ? item.heading : "Quality Writing, No AI"}</h5>
                  <p>{item.description ? item.description : "Get quality writing with zero AI, crafted by real experts holding scholarly degrees and extensive academic insights"}</p>
                </div>
              </div>
            ))}
          </div>
        )}
        {helpButton && (
          <div className="btn-group">
            <a href={helpButtonUrl ? helpButtonUrl : "#!"} className="custombtn btn-2 hover-filled-slide-down">
              <span onClick={() => navigate("/submit-new-assignment")}>{helpButtonText ? helpButtonText : "Order Now"}</span>
            </a>
          </div>
        )}
      </div>
    </section>
  );
};

export default HelpFeatures;
