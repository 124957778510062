import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

// Helpers
import { ApiHelperFunction } from "../../helpers/api_helpers";

// Models
import { ApiFuncArgProps } from "../../models/apiFuncHelpers";
import { ExpertsPageDataModel } from "../../models/views/pages";

interface ExpertsInitialState {
  expertsPageStatus: string;
  expertsPageData: ExpertsPageDataModel;
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
}

const initialState: ExpertsInitialState = {
  expertsPageStatus: "idle",
  expertsPageData: {
    sec_2_h1: "",
    sec_2_h2: "",
    sec_3_h1: "",
    sec_4_h1: "",
    sec_4_h2: "",
    createdAt: "",
    updatedAt: "",
    publishedAt: "",
    sec_1_i_banner: [
      {
        id: 0,
        heading: "",
        subheading: "",
        inner_banner_review: [],
      },
    ],
    sec_2_assignment_writer: [],
    sec_3_it_1: [],
    sec_4_it_1: [],
  },
  isLoading: false,
  isSuccess: false,
  isError: false,
};

export const getExpertsPageData = createAsyncThunk("get_experts_page", async () => {
  try {
    let response = await ApiHelperFunction({
      urlPath: "/expert-page?populate=deep",
      method: "GET",
    } as ApiFuncArgProps);

    if (response.data) {
        return response.data;
    }
  } catch (error: any) {
    console.log("Failed to fetch experts page data due to ", error.message);
  }
});

export const expertsPageSlice = createSlice({
  name: "experts_page",
  initialState,
  reducers: {
    clearExpertsPageState: (state) => {
      state.expertsPageStatus = "idle";
      state.expertsPageData = {
        sec_2_h1: "",
        sec_2_h2: "",
        sec_3_h1: "",
        sec_4_h1: "",
        sec_4_h2: "",
        createdAt: "",
        updatedAt: "",
        publishedAt: "",
        sec_1_i_banner: [
          {
            id: 0,
            heading: "",
            subheading: "",
            inner_banner_review: [],
          },
        ],
        sec_2_assignment_writer: [],
        sec_3_it_1: [],
        sec_4_it_1: [],
      };
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getExpertsPageData.pending, (state) => {
        state.expertsPageStatus = "loading";
        state.isLoading = true;
      })
      .addCase(getExpertsPageData.fulfilled, (state, { payload }) => {
        state.expertsPageStatus = "success";
        state.isSuccess = true;
        state.expertsPageData = payload?.data?.attributes;
      })
      .addCase(getExpertsPageData.rejected, (state) => {
        state.expertsPageStatus = "failed";
        state.isError = true;
      });
  },
});

export const { clearExpertsPageState } = expertsPageSlice.actions;
