import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

// Helpers
import { ApiHelperFunction } from "../../helpers/api_helpers";

// Typeguards
import { isApiErrorResponse } from "../../helpers/typeguards";

// Models
import { ApiFuncArgProps } from "../../models/apiFuncHelpers";
import { HomepageDataModel } from "../../models/views/pages";

interface HomepageInitialState {
  status: string;
  attributes: HomepageDataModel;
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
}

const initialState: HomepageInitialState = {
  status: "idle",
  attributes: {
    createdAt: "",
    updatedAt: "",
    publishedAt: "",
    sec_1_h1: "",
    sec_1_h2: "",
    sec_1_h3: "",
    sec_1_h4: "",
    sec_1_h5: "",
    sec_1_h6: "",
    sec_1_rit: [],
    sec_2_h1: "",
    sec_2_h2: "",
    sec_2_assignment_writer: [],
    sec_3_h1: "",
    sec_3_h2: "",
    sec_4_h1: "",
    sec_4_h2: "",
    sec_5_h1: "",
    sec_5_h2: "",
    sec_5_it_1: [],
    sec_6_h1: "",
    sec_h2_1: "",
    sec_6_btn_1_text: "",
    sec_6_it_1: [],
    sec_7_h1: "",
    sec_7_h2: "",
    sec_8_h1: "",
    sec_8_h2: "",
    sec_8_faq: [],
    sec_9: [
      {
        id: 0,
        h1: "",
        h2: "",
        review_t2: [],
      },
    ],
  },
  isLoading: false,
  isSuccess: false,
  isError: false,
};

export const getHomepage = createAsyncThunk("get_homepage", async () => {
  try {
    let response = await ApiHelperFunction({
      urlPath: "/home-page?populate=deep",
      method: "GET",
    } as ApiFuncArgProps);
    console.log("getHomepage====>", response);
    if (isApiErrorResponse(response)) {
      console.log("Error: ", response.error.message);
    } else {
      return response.data;
    }
  } catch (error: any) {
    console.log("Failed to fetch home page due to ", error.message);
  }
});

export const homePageSlice = createSlice({
  name: "homepage",
  initialState,
  reducers: {
    clearHomepageState: (state) => {
      state.status = "idle";
      state.attributes = {
        createdAt: "",
        updatedAt: "",
        publishedAt: "",
        sec_1_h1: "",
        sec_1_h2: "",
        sec_1_h3: "",
        sec_1_h4: "",
        sec_1_h5: "",
        sec_1_h6: "",
        sec_1_rit: [],
        sec_2_h1: "",
        sec_2_h2: "",
        sec_2_assignment_writer: [],
        sec_3_h1: "",
        sec_3_h2: "",
        sec_4_h1: "",
        sec_4_h2: "",
        sec_5_h1: "",
        sec_5_h2: "",
        sec_5_it_1: [],
        sec_6_h1: "",
        sec_h2_1: "",
        sec_6_btn_1_text: "",
        sec_6_it_1: [],
        sec_7_h1: "",
        sec_7_h2: "",
        sec_8_h1: "",
        sec_8_h2: "",
        sec_8_faq: [],
        sec_9: [
          {
            id: 0,
            h1: "",
            h2: "",
            review_t2: [],
          },
        ],
      };
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getHomepage.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(getHomepage.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.isSuccess = true;
        state.attributes = payload?.data?.attributes;
      })
      .addCase(getHomepage.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
      });
  },
});

export const { clearHomepageState } = homePageSlice.actions;
