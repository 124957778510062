import React from "react";
import { Navigate } from "react-router-dom";

interface ProtectionComponentArgs {
  children: React.ReactNode;
}

export const LoginProtected = ({ children }: ProtectionComponentArgs) => {
  const isLoggedIn = localStorage.getItem("logged_in");
  const token = localStorage.getItem("Authorization");

  return isLoggedIn === "yes" && token ? (
    <>{children}</>
  ) : (
    <Navigate to="/" replace />
  );
};
