import React, { useState, useEffect, useRef } from "react";

// Utils
import { toast } from "react-hot-toast";
import Swal from "sweetalert2";
import * as Yup from "yup";
// Constants
import { digits, allowedKeys } from "../../../constants/data/data-constants";
import parsePhoneNumberFromString, { isValidPhoneNumber } from "libphonenumber-js";
// Models
import { ApiFuncArgProps, ApiErrorResponse } from "../../../models/apiFuncHelpers";

// API
import { ApiHelperFunction } from "../../../helpers/api_helpers";

// Typeguards
import { isApiErrorResponse } from "../../../helpers/typeguards";

// Components
// import OtpInput from "react-otp-input";
import PhoneInput from "react-phone-number-input";

import "react-phone-number-input/style.css";

let signInInitVal = {
  email: "",
  password: "",
};

let signInErrorsInit = {
  email: "",
  password: "",
};

let signUpInitVal = {
  name: "",
  username: "",
  phone_number_code: "+44",
  phone_number: "",
  email: "",
  profile_pic: "",
  password: "",
  confirmPassword: "",
};

let signUpErrorsInit = {
  name: "",
  username: "",
  phone_number: "",
  phone_number_code: "",
  email: "",
  profile_pic: "",
  password: "",
  confirmPassword: "",
};

var emailReg = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

const LoginModal = () => {
  type SignUpData = {
    name: string;
    username: string;
    phone_number: string;
    email: string;
    profile_pic: string;
    password: string;
    confirmPassword: string;
  };
  type SignUpErrors = Partial<Record<keyof SignUpData, string>>;
  const [signInData, setsignInData] = useState(signInInitVal);
  const [signInErrors, setsignInErrors] = useState(signInErrorsInit);
  const [pwdType, setpwdType] = useState("password");
  const [signUpSteps, setsignUpSteps] = useState(1);
  const [signUpData, setsignUpData] = useState(signUpInitVal);
  const [signUpErrors, setSignUpErrors] = useState<SignUpErrors>(signUpErrorsInit);
  const profileImgRef = useRef<HTMLInputElement>(null);
  const [profileImg, setprofileImg] = useState<File | undefined>(undefined);
  const [upProfilePic, setupProfilePic] = useState("");
  // const [otp, setotp] = useState("");
  const [pwdType2, setpwdType2] = useState("password");
  const [cnfPwdType, setcnfPwdType] = useState("password");
  const [check_validate, setCheckValidate] = useState("");
  const userSchema = Yup.object().shape({
    email: Yup.string()
      .email("Please enter a valid email address")
      .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, "Email must contain a valid domain (e.g., example.com).")
      .required("Email is required"),
    phone_number: Yup.string()
      .matches(/^\d+$/, "Phone number must contain only digits")

      .required("Phone number is required"),
    // .test("len", "Phone number must be 10 digits atleast", (val) => val && val.length >= 10),
    name: Yup.string().required("Name is required"),
    username: Yup.string().required("Username is required"),
    password: Yup.string().min(8, "Password must be at least 8 characters").required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), undefined], "Passwords must match")
      .required("Confirm Password is required"),
  });
  const validateForm = async (): Promise<boolean> => {
    try {
      await userSchema.validate(signUpData, { abortEarly: false });
      setCheckValidate("");
      setSignUpErrors({});
      return true;
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errorMessages: SignUpErrors = error.inner.reduce((acc, err) => {
          if (err.path) {
            acc[err.path as keyof SignUpData] = err.message;
          }
          return acc;
        }, {} as SignUpErrors);
        console.log("validation=====>", errorMessages);
        setSignUpErrors(errorMessages);
        setCheckValidate("");
      }
      return false;
    }
  };
  useEffect(() => {
    setTimeout(() => {
      setCheckValidate("");
      setsignInData(signInInitVal);
      setsignInErrors(signInErrorsInit);
      setpwdType("password");
      setsignUpData(signUpInitVal);
      setSignUpErrors(signUpErrorsInit);
      setsignUpSteps(1);
      // setotp("");
      setpwdType2("password");
      setcnfPwdType("password");
    }, 900000);
    setCheckValidate("");
  }, []);

  const handleSignInChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setsignInData({ ...signInData, [event.target.name]: event.target.value });
  };

  const handleLoginValidation = () => {
    const { email, password } = signInData;

    if (email === "") {
      setsignInErrors({ ...signInErrorsInit, email: "Email cannot be empty." });
      return false;
    }

    if (password === "") {
      setsignInErrors({
        ...signInErrorsInit,
        password: "Password cannot be empty.",
      });
      return false;
    }
    return true;
  };

  const handleSignInSubmit: React.MouseEventHandler<HTMLDivElement> = async (event) => {
    event.preventDefault();

    if (handleLoginValidation()) {
      try {
        let response = await ApiHelperFunction({
          urlPath: "/auth/local",
          method: "POST",
          data: {
            identifier: signInData.email,
            password: signInData.password,
          },
        } as ApiFuncArgProps);

        if (isApiErrorResponse(response)) {
          toast.error(response.error.message, { duration: 10000 });
        } else if (response.data?.user) {
          localStorage.setItem("Authorization", `${response.data?.jwt}`);
          localStorage.setItem("logged_in", "yes");
          localStorage.setItem("user_name", response.data?.user?.name);
          localStorage.setItem("user_email", response.data?.user?.email);
          window.location.href = "/dashboard";
          // toast.success("Logged in successfully", { duration: 10000 });
          // Swal.fire("Success", "Logged in successfully", "success").then(
          //   (result) => {
          //     if (result.isConfirmed) {
          //       window.location.href = "/dashboard";
          //     }
          //   }
          // );
        } else {
          console.log("Unexpected response:", response);
        }
      } catch (error: any) {
        console.log(error);
        toast.error(error.message, { duration: 10000 });
      }
    }
  };

  const handleSignUpChange: React.ChangeEventHandler<HTMLInputElement> = async (event) => {
    setCheckValidate("");
    setsignUpData({ ...signUpData, [event.target.name]: event.target.value });
  };

  const handleProfileImage: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    if (!event.target.files) return;

    if (event.target.files.length === 0) return;

    let file = event.target.files[0];

    if (file.size > 500000000) {
      setSignUpErrors({
        ...signUpErrorsInit,
        profile_pic: "Uploaded picture can't be more than 5mb",
      });
    } else {
      setprofileImg(file);
      setupProfilePic(URL.createObjectURL(file));
      setSignUpErrors({ ...signUpErrorsInit, profile_pic: "" });
    }
  };

  const handleRegisterValidation = () => {
    const { name, username, phone_number, email } = signUpData;

    /** ----- Strategy for validation ----- */
    // 1. if condition for wrong data format fulfilled
    // 2. set the appropriate error message variable
    // 3. set the step in which the wrong data format given
    // 4. return a false for this function
    /**------------------------------------ */

    /** --- Validation checks for step 1 start --- */
    if (name === "") {
      setSignUpErrors({
        ...signUpErrorsInit,
        name: "Name cannot be empty.",
      });
      // setsignUpSteps(1);
      return false;
    }
    if (username === "") {
      setSignUpErrors({
        ...signUpErrorsInit,
        username: "Username cannot be empty.",
      });
      // setsignUpSteps(1);
      return false;
    }
    if (phone_number === "") {
      setSignUpErrors({
        ...signUpErrorsInit,
        phone_number: "Phone number cannot be empty.",
      });
      // setsignUpSteps(1);
      return false;
    }

    if (email === "") {
      setSignUpErrors({ ...signUpErrorsInit, email: "Email cannot be empty." });
      // setsignUpSteps(1);
      return false;
    }
    if (!emailReg.test(email)) {
      setSignUpErrors({
        ...signUpErrorsInit,
        email: "Please put email in correct format. E.g., abc@xxx.com",
      });
      // setsignUpSteps(1);
      return false;
    }
    /** ---- Validation checks for step 1 end ---- */
    setCheckValidate("");
    return true;
  };

  const handleRegisterValidationStepTwo = () => {
    const { password, confirmPassword } = signUpData;
    console.log("valid===> 0", signUpErrors);
    /** --- Validation checks for step 2 start --- */
    // if (signUpErrors.profile_pic !== "") {
    //   setCheckValidate("Please Try again to upload profile image");
    //   return false;
    // }
    // if (!signUpErrors.profile_pic ) {
    //       setCheckValidate("Please Try again to upload profile image");
    //       return false;
    //     }
    if (password === "") {
      setSignUpErrors({
        ...signUpErrorsInit,
        password: "Password cannot be empty.",
      });
      // setsignUpSteps(2);
      return false;
    }
    if (password.length < 8) {
      setSignUpErrors({
        ...signUpErrorsInit,
        password: "Password cannot be less than 8 characters.",
      });
      // setsignUpSteps(2);
      return false;
    }
    if (confirmPassword === "") {
      setSignUpErrors({
        ...signUpErrorsInit,
        confirmPassword: "Confirm password cannot be empty.",
      });
      // setsignUpSteps(2);
      return false;
    }
    if (confirmPassword !== password) {
      setSignUpErrors({
        ...signUpErrorsInit,
        confirmPassword: "Password and confirm password is not same.",
      });
      // setsignUpSteps(2);
      return false;
    }
    setCheckValidate("");
    /** ---- Validation checks for step 2 end ---- */
    return true;
  };

  const handleSignUpSubmit: React.MouseEventHandler<HTMLDivElement> = async (event) => {
    event.preventDefault();
    const isValid = await validateForm();
    console.log("valid===>", isValid);

    if (!isValid) {
      setCheckValidate("Please fill All the fields accordingly");
      return;
    }
    console.log("valid===> step2", isValid, handleRegisterValidationStepTwo());

    if (handleRegisterValidationStepTwo()) {
      try {
        setCheckValidate("");
        let imageFiles = [];

        if (profileImg !== undefined) {
          const DATA = new FormData();

          // DATA.append("ref", "plugin::users-permissions.user");
          // DATA.append("refId", "9");
          // DATA.append("field", "profile_pic");
          DATA.append("files", profileImg);

          const imgUpRes = await ApiHelperFunction({
            urlPath: "/upload",
            method: "POST",
            data: DATA,
            contentType: "form-data",
          } as ApiFuncArgProps);

          if (isApiErrorResponse(imgUpRes)) {
            // console.log("Error:", response.error);
            // toast.error(imgUpRes.error.message);
            Swal.fire("Error", imgUpRes.error.message, "error");
            return;
          } else if (imgUpRes.data) {
            imageFiles = imgUpRes.data;
          } else {
            console.log("Unexpected response:", imgUpRes);
          }
        }

        let bodyData = {
          ...signUpData,
          phone_number_code: "c_" + signUpData.phone_number_code,
        };
        if (imageFiles?.[0] !== null || typeof imageFiles?.[0] === "undefined") {
          bodyData.profile_pic = imageFiles[0];
        }
        console.log("valid===> 0 1", bodyData);
        // return;
        const response = await ApiHelperFunction({
          urlPath: "/auth/local/register",
          method: "POST",
          data: bodyData,
        } as ApiFuncArgProps);
        console.log("valid===> step3", response);

        if (isApiErrorResponse(response)) {
          // "error" in response
          // Handle error response
          // console.log("Error:", response.error);
          toast.error(response.error.message, { duration: 10000 });
          return;
        } else if (response.data?.user) {
          // Successful registration logic
          if (profileImgRef.current) {
            profileImgRef.current.value = "";
          }
          localStorage.setItem("Authorization", `${response.data?.jwt}`);
          localStorage.setItem("logged_in", "yes");
          localStorage.setItem("user_name", response.data?.user?.name);
          localStorage.setItem("user_email", response.data?.user?.email);
          // toast.success("Registered successfully", { duration: 10000 });
          Swal.fire("Success", "Registered successfully", "success").then((result) => {
            if (result.isConfirmed) {
              window.location.href = "/dashboard";
            }
          });
        } else {
          console.log("Unexpected response:", response);
        }
      } catch (error: any) {
        toast.error(error.message, { duration: 10000 });
      }
    }
  };
  const handleClose = () => {
    setsignInData(signInInitVal);
    setsignUpData(signUpInitVal);
    setsignUpSteps(1);
  };

  const validatePhoneNumber = (phone: string, countryCode: any) => {
    let phoneNumber = parsePhoneNumberFromString(`${countryCode}${phone}`);

    return isValidPhoneNumber(`${countryCode}${phone}`, phoneNumber?.country);
  };
  console.log("signUpData>>>>>", signUpData);
  return (
    <div className="modal login-signup-modal fade" id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered modal-xl">
        <div className="modal-content">
          <div className="modal-header modal-header-login-close">
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}></button>
          </div>
          <div className="modal-body">
            {/* -- Login / Sign Up for Desktop start -- */}
            <div className="login-signup-area">
              <div className="login-signup-logo">
                <img src="/assets/images/login-signup-logo.svg" alt="login-signup-logo" />
              </div>

              <div className="login-area">
                <div className="login-signup-hdng">
                  <h2>Log In</h2>
                  <p>Enter your credentials to log in.</p>
                </div>
                <div className="login-signup-form">
                  <form>
                    <div className="form-grp">
                      <input
                        type="email"
                        name="email"
                        value={signInData.email}
                        placeholder="Email"
                        onChange={(e) => {
                          handleSignInChange(e);

                          if (e.target.value.length > 0) {
                            setsignInErrors((prev) => {
                              prev.email = "";
                              return prev;
                            });
                          }
                        }}
                      />
                      <div className="form-grp-icon">
                        <img src="/assets/images/form-email-ico.svg" alt="form-email-ico" />
                      </div>
                      <span className="text-danger text-errr">{signInErrors.email}</span>
                    </div>

                    <div className="form-grp">
                      <input
                        type={pwdType}
                        name="password"
                        value={signInData.password}
                        placeholder="Password"
                        onChange={(e) => {
                          handleSignInChange(e);

                          if (e.target.value.length > 0) {
                            setsignInErrors((prev) => {
                              prev.password = "";
                              return prev;
                            });
                          }
                        }}
                      />
                      <div className="form-grp-icon">
                        {/* <img
                          src="/assets/images/form-password-ico.svg"
                          alt="form-password-ico"
                        /> */}
                        {pwdType === "password" ? <i className="fa-regular fa-eye-slash password-show" onClick={() => setpwdType("text")}></i> : <i className="fa-regular fa-eye password-show" onClick={() => setpwdType("password")}></i>}
                      </div>
                      <span className="text-danger text-errr">{signInErrors.password}</span>
                    </div>

                    {/* <div className="frgt-psswrd">
                      <a href="#!" className="frgt-psswrd-btn">
                        Forgot Password?
                      </a>
                    </div> */}
                    <div className="btn-group" onClick={handleSignInSubmit}>
                      <a href="#!" className="custombtn btn-1 hover-filled-slide-down">
                        <span>Submit</span>
                      </a>
                    </div>
                  </form>
                </div>
              </div>

              <div className="signup-area">
                {signUpSteps === 1 && (
                  <div className="signup-screen">
                    <div className="login-signup-hdng">
                      <h2>Sign Up</h2>
                      <p>Create your account in just a few steps.</p>
                    </div>
                    <div className="login-signup-form">
                      <form>
                        <div className="form-grp">
                          <input
                            type="text"
                            name="name"
                            value={signUpData.name}
                            placeholder="Full Name"
                            onChange={(e) => {
                              handleSignUpChange(e);

                              if (e.target.value.length > 0) {
                                setSignUpErrors((prev) => {
                                  prev.name = "";
                                  return prev;
                                });
                              }
                            }}
                          />
                          <div className="form-grp-icon">
                            <img src="/assets/images/login-ico.svg" alt="form-user-ico" />
                          </div>
                          <span className="text-danger text-errr">{signUpErrors.name}</span>
                        </div>
                        <div className="form-grp">
                          <input
                            type="text"
                            name="username"
                            value={signUpData.username}
                            placeholder="User Name"
                            onChange={(e) => {
                              handleSignUpChange(e);

                              if (e.target.value.length > 0) {
                                setSignUpErrors((prev) => {
                                  prev.username = "";
                                  return prev;
                                });
                              }
                            }}
                          />
                          <div className="form-grp-icon">
                            <img src="/assets/images/form-user-ico.svg" alt="form-user-ico" />
                          </div>
                          <span className="text-danger text-errr">{signUpErrors.username}</span>
                        </div>

                        <div className="form-grp form-grp-newsd">
                          <PhoneInput
                            international
                            className="phninpt-sd"
                            defaultCountry="GB"
                            value={signUpData.phone_number_code}
                            onChange={(val) =>
                              setsignUpData((prev) => {
                                prev.phone_number_code = val as string;
                                console.log("prev.phone_number_code>>>>>", prev.phone_number_code);
                                if (validatePhoneNumber(signUpData.phone_number, val)) {
                                  setSignUpErrors((prev) => ({
                                    ...prev,
                                    phone_number: "",
                                  }));
                                } else {
                                  setSignUpErrors((prev) => ({
                                    ...prev,
                                    phone_number: "Phone number must have digits only.",
                                  }));
                                }
                                return prev;
                              })
                            }
                          />
                          <input
                            className="phone_number-sd"
                            type="text"
                            name="phone_number"
                            value={signUpData.phone_number}
                            placeholder="Phone No"
                            onKeyDown={(e) => {
                              // Allow digits only if Shift key is not pressed
                              if (digits.includes(e.key) && !e.shiftKey) {
                                return;
                              }

                              // Allow specific control keys
                              if (allowedKeys.includes(e.key)) {
                                return;
                              }

                              // If none of the above conditions are met, prevent the default action
                              e.preventDefault();
                            }}
                            onChange={(e) => {
                              handleSignUpChange(e);
                              if (validatePhoneNumber(e.target.value, signUpData.phone_number_code)) {
                                setSignUpErrors((prev) => ({
                                  ...prev,
                                  phone_number: "",
                                }));
                              } else {
                                setSignUpErrors((prev) => ({
                                  ...prev,
                                  phone_number: "Phone number must have digits only.",
                                }));
                              }
                              // if (e.target.value.length > 0) {
                              //   setSignUpErrors((prev) => {
                              //     prev.phone_number = "";
                              //     return prev;
                              //   });
                              // }
                            }}
                          />
                          <div className="form-grp-icon">
                            <img src="/assets/images/form-phone-ico.svg" alt="form-phone-ico" />
                          </div>
                          <span className="text-danger text-errr">{signUpErrors.phone_number}</span>
                        </div>

                        <div className="form-grp">
                          <input
                            type="email"
                            name="email"
                            value={signUpData.email}
                            placeholder="Email"
                            onChange={(e) => {
                              handleSignUpChange(e);

                              if (e.target.value.length > 0) {
                                setSignUpErrors((prev) => {
                                  prev.email = "";
                                  return prev;
                                });
                              }
                            }}
                          />
                          <div className="form-grp-icon">
                            <img src="/assets/images/form-email-ico.svg" alt="form-email-ico" />
                          </div>
                          <span className="text-danger text-errr">{signUpErrors.email}</span>
                        </div>

                        <div
                          className="btn-group"
                          onClick={() => {
                            if (handleRegisterValidation()) {
                              setsignUpSteps(2);
                            }
                          }}
                        >
                          <a
                            // href="#!"
                            className="custombtn btn-1 hover-filled-slide-down"
                          >
                            <span>Submit</span>
                          </a>
                        </div>
                      </form>
                    </div>
                  </div>
                )}

                {/* {signUpSteps === 2 && (
                  <div className="otp-screen">
                    <div className="login-signup-hdng">
                      <h2>Confirmation</h2>
                      <p>
                        Please enter the code we sent to the number ********78
                      </p>
                    </div>
                    <div className="login-signup-form">
                      <form>
                        <div className="otp-form-group">
                          <input type="number" />
                          <input type="number" />
                          <input type="number" />
                          <input type="number" />
                          <OtpInput
                            value={otp !== undefined ? otp : undefined}
                            onChange={setotp}
                            inputType="number"
                            numInputs={4}
                            placeholder="0024"
                            renderInput={(props) => (
                              <input
                                {...props}
                                className="login_otp_field"
                                type="number"
                              />
                            )}
                            containerStyle={{ justifyContent: "center" }}
                          />
                        </div>
                        <div className="frgt-psswrd">
                          <a href="#!" className="frgt-psswrd-btn">
                            Resend Code
                          </a>
                        </div>
                        <div
                          className="btn-group"
                          onClick={() => setsignUpSteps(3)}
                        >
                          <a
                            // href="#!"
                            className="custombtn btn-1 hover-filled-slide-down"
                          >
                            <span>confirm</span>
                          </a>
                        </div>
                      </form>
                    </div>
                  </div>
                )} */}

                {signUpSteps === 2 && (
                  <div className="set-password-screen">
                    <div className="login-signup-hdng">
                      <h2>Profile</h2>
                      <p>Keep your information up to date.</p>
                    </div>
                    <div className="login-signup-form">
                      <form>
                        <div className="dp-update">
                          <label className="dp-update-label" htmlFor="profile_pic">
                            <img src={upProfilePic || "/assets/images/dp.svg"} alt="dp" className="dp-update-dp" />
                            <a
                              // href="#!"
                              className="dp-upload-ico"
                            >
                              <img src="/assets/images/dp-upload-ico.svg" alt="dp-upload-ico" />
                            </a>
                          </label>

                          <input type="file" className="d-none" id="profile_pic" name="profile_pic" accept=".jpg,.jpeg,.png" ref={profileImgRef} onChange={handleProfileImage} />
                        </div>
                        <div className="form-grp">
                          <input
                            type={pwdType2}
                            name="password"
                            value={signUpData.password}
                            placeholder="Password"
                            onChange={(e) => {
                              handleSignUpChange(e);

                              if (e.target.value.length > 0) {
                                setSignUpErrors((prev) => {
                                  prev.password = "";
                                  return prev;
                                });
                              }
                            }}
                          />
                          <div className="form-grp-icon">
                            {/* {pwdType2 === "password" && (
                              <img
                                src="/assets/images/form-password-ico.svg"
                                alt="form-password-ico"
                              />
                            )} */}
                            {pwdType2 === "password" ? <i className="fa-regular fa-eye-slash password-show" onClick={() => setpwdType2("text")}></i> : <i className="fa-regular fa-eye password-show" onClick={() => setpwdType2("password")}></i>}
                          </div>
                          <span className="text-danger text-errr">{signUpErrors.password}</span>
                        </div>

                        <div className="form-grp">
                          <input
                            type={cnfPwdType}
                            name="confirmPassword"
                            value={signUpData.confirmPassword}
                            placeholder="Confirm Password"
                            onChange={(e) => {
                              handleSignUpChange(e);

                              if (e.target.value.length > 0) {
                                setSignUpErrors((prev) => {
                                  prev.confirmPassword = "";
                                  return prev;
                                });
                              }
                            }}
                          />
                          <div className="form-grp-icon">
                            {/* {cnfPwdType === "password" && (
                              <img
                                src="/assets/images/form-password-ico.svg"
                                alt="form-password-ico"
                              />
                            )} */}
                            {cnfPwdType === "password" ? <i className="fa-regular fa-eye-slash password-show" onClick={() => setcnfPwdType("text")}></i> : <i className="fa-regular fa-eye password-show" onClick={() => setcnfPwdType("password")}></i>}
                          </div>
                          <span className="text-danger text-errr">{signUpErrors.confirmPassword}</span>
                          <span className="text-danger text-errr">{check_validate}</span>
                        </div>

                        <div className="btn-group" onClick={handleSignUpSubmit}>
                          <a
                            // href="#!"
                            className="custombtn btn-1 hover-filled-slide-down"
                          >
                            <span>Submit</span>
                          </a>
                        </div>
                      </form>
                    </div>
                  </div>
                )}

                <div className="signup-progress">
                  <div className={`signup-progress-step ${signUpSteps === 1 ? "active" : ""}`}>
                    <div className="signup-progress-circle" style={{ cursor: "pointer" }} onClick={() => setsignUpSteps(1)}></div>
                    <div className="signup-progress-count">1</div>
                  </div>
                  <div className={`signup-progress-step ${signUpSteps === 2 ? "active" : ""}`}>
                    <div className="signup-progress-circle" style={{ cursor: "pointer" }} onClick={() => setsignUpSteps(2)}></div>
                    <div className="signup-progress-count"> 2</div>
                  </div>
                  {/* <div
                    className={`signup-progress-step ${
                      signUpSteps === 3 ? "active" : ""
                    }`}
                  >
                    <div
                      className="signup-progress-circle"
                      // onClick={() => setsignUpSteps(3)}
                    ></div>
                    <div className="signup-progress-count">Step 3</div>
                  </div> */}
                </div>
              </div>
            </div>
            {/* -- Login / Sign Up for Desktop end -- */}

            {/* -- Login / Sign Up for tab & mobile start -- */}
            <div className="login-signup-area-responsive">
              <ul className="nav nav-pills" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">
                    Log In
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">
                    Sign Up
                  </button>
                </li>
              </ul>
              <div className="tab-content" id="pills-tabContent">
                <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                  <div className="login-area">
                    <div className="login-signup-hdng">
                      <h2>Log In</h2>
                      <p>Enter your credentials to log in.</p>
                    </div>
                    <div className="login-signup-form">
                      <form>
                        <div className="form-grp">
                          <input
                            type="email"
                            name="email"
                            value={signInData.email}
                            placeholder="Email"
                            onChange={(e) => {
                              handleSignInChange(e);

                              if (e.target.value.length > 0) {
                                setsignInErrors((prev) => {
                                  prev.email = "";
                                  return prev;
                                });
                              }
                            }}
                          />
                          <div className="form-grp-icon">
                            <img src="/assets/images/form-email-ico.svg" alt="form-email-ico" />
                          </div>
                          <span className="text-danger text-errr">{signInErrors.email}</span>
                        </div>

                        <div className="form-grp">
                          <input
                            type={pwdType}
                            name="password"
                            value={signInData.password}
                            placeholder="Password"
                            onChange={(e) => {
                              handleSignInChange(e);

                              if (e.target.value.length > 0) {
                                setsignInErrors((prev) => {
                                  prev.password = "";
                                  return prev;
                                });
                              }
                            }}
                          />
                          <div className="form-grp-icon">
                            {/* <img
                              src="/assets/images/form-password-ico.svg"
                              alt="form-password-ico"
                            /> */}
                            {pwdType === "password" ? <i className="fa-regular fa-eye-slash password-show" onClick={() => setpwdType("text")}></i> : <i className="fa-regular fa-eye password-show" onClick={() => setpwdType("password")}></i>}
                          </div>
                          <span className="text-danger text-errr">{signInErrors.password}</span>
                        </div>

                        {/* <div className="frgt-psswrd">
                          <a href="#!" className="frgt-psswrd-btn">
                            Forgot Password?
                          </a>
                        </div> */}
                        <div className="btn-group" onClick={handleSignInSubmit}>
                          <a href="#!" className="custombtn btn-1 hover-filled-slide-down">
                            <span>Submit</span>
                          </a>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                  <div className="signup-area">
                    {signUpSteps === 1 && (
                      <div className="signup-screen">
                        <div className="login-signup-hdng">
                          <h2>Sign Ups</h2>
                          <p>Create your account in just a few steps.</p>
                        </div>
                        <div className="login-signup-form">
                          <form>
                            <div className="form-grp">
                              <input
                                type="text"
                                name="name"
                                value={signUpData.name}
                                placeholder="Full Name"
                                onChange={(e) => {
                                  handleSignUpChange(e);

                                  if (e.target.value.length > 0) {
                                    setSignUpErrors((prev) => {
                                      prev.name = "";
                                      return prev;
                                    });
                                  }
                                }}
                              />
                              <div className="form-grp-icon">
                                <img src="/assets/images/login-ico.svg" alt="form-user-ico" />
                              </div>
                              <span className="text-danger text-errr">{signUpErrors.name}</span>
                            </div>
                            <div className="form-grp">
                              <input
                                type="text"
                                name="username"
                                value={signUpData.username}
                                placeholder="User Name"
                                onChange={(e) => {
                                  handleSignUpChange(e);

                                  if (e.target.value.length > 0) {
                                    setSignUpErrors((prev) => {
                                      prev.username = "";
                                      return prev;
                                    });
                                  }
                                }}
                              />
                              <div className="form-grp-icon">
                                <img src="/assets/images/form-user-ico.svg" alt="form-user-ico" />
                              </div>
                              <span className="text-danger text-errr">{signUpErrors.username}</span>
                            </div>

                            <div className="form-grp form-grp-newsd">
                              <PhoneInput
                                international
                                className="phninpt-sd"
                                defaultCountry="IN"
                                value={signUpData.phone_number_code}
                                onChange={(val) => {
                                  console.log("phone_number_code>>>>>", val);
                                  setsignUpData((prev) => {
                                    prev.phone_number_code = val as string;
                                    if (validatePhoneNumber(signUpData.phone_number, val)) {
                                      setSignUpErrors((prev) => ({
                                        ...prev,
                                        phone_number: "",
                                      }));
                                    } else {
                                      setSignUpErrors((prev) => ({
                                        ...prev,
                                        phone_number: "Phone number must have digits only.",
                                      }));
                                    }
                                    return prev;
                                  });
                                }}
                              />
                              <input
                                className="phone_number-sd"
                                type="text"
                                name="phone_number"
                                value={signUpData.phone_number}
                                placeholder="Phone No"
                                onKeyDown={(e) => {
                                  // Allow digits only if Shift key is not pressed
                                  if (digits.includes(e.key) && !e.shiftKey) {
                                    return;
                                  }

                                  // Allow specific control keys
                                  if (allowedKeys.includes(e.key)) {
                                    return;
                                  }

                                  // If none of the above conditions are met, prevent the default action
                                  e.preventDefault();
                                }}
                                onChange={(e) => {
                                  handleSignUpChange(e);
                                  if (validatePhoneNumber(e.target.value, signUpData.phone_number_code)) {
                                    setSignUpErrors((prev) => ({
                                      ...prev,
                                      phone_number: "",
                                    }));
                                  } else {
                                    setSignUpErrors((prev) => ({
                                      ...prev,
                                      phone_number: "Phone number must have digits only.",
                                    }));
                                  }
                                  // if (e.target.value.length > 0) {
                                  //   setSignUpErrors((prev) => {
                                  //     prev.phone_number = "";
                                  //     return prev;
                                  //   });
                                  // }
                                }}
                              />
                              <div className="form-grp-icon">
                                <img src="/assets/images/form-phone-ico.svg" alt="form-phone-ico" />
                              </div>
                              <span className="text-danger text-errr">{signUpErrors.phone_number}</span>
                            </div>

                            <div className="form-grp">
                              <input
                                type="email"
                                name="email"
                                value={signUpData.email}
                                placeholder="Email"
                                onChange={(e) => {
                                  handleSignUpChange(e);

                                  if (e.target.value.length > 0) {
                                    setSignUpErrors((prev) => {
                                      prev.email = "";
                                      return prev;
                                    });
                                  }
                                }}
                              />
                              <div className="form-grp-icon">
                                <img src="/assets/images/form-email-ico.svg" alt="form-email-ico" />
                              </div>
                              <span className="text-danger text-errr">{signUpErrors.email}</span>
                            </div>

                            <div
                              className="btn-group"
                              onClick={() => {
                                if (handleRegisterValidation()) {
                                  setsignUpSteps(2);
                                }
                              }}
                            >
                              <a
                                // href="#!"
                                className="custombtn btn-1 hover-filled-slide-down"
                              >
                                <span>Submit</span>
                              </a>
                            </div>
                          </form>
                        </div>
                      </div>
                    )}

                    {/* {signUpSteps === 2 && (
                      <div className="otp-screen">
                        <div className="login-signup-hdng">
                          <h2>Confirmation</h2>
                          <p>
                            Please enter the code we sent to the number
                            ********78
                          </p>
                        </div>
                        <div className="login-signup-form">
                          <form>
                            <div className="otp-form-group">
                              <input type="number" />
                              <input type="number" />
                              <input type="number" />
                              <input type="number" />
                              <OtpInput
                                value={otp !== undefined ? otp : undefined}
                                onChange={setotp}
                                inputType="number"
                                numInputs={4}
                                placeholder="0024"
                                renderInput={(props) => (
                                  <input
                                    {...props}
                                    className="login_otp_field"
                                    type="number"
                                  />
                                )}
                                containerStyle={{ justifyContent: "center" }}
                              />
                            </div>
                            <div className="frgt-psswrd">
                              <a href="#!" className="frgt-psswrd-btn">
                                Resend Code
                              </a>
                            </div>
                            <div
                              className="btn-group"
                              onClick={() => setsignUpSteps(3)}
                            >
                              <a
                                href="#!"
                                className="custombtn btn-1 hover-filled-slide-down"
                              >
                                <span>confirm</span>
                              </a>
                            </div>
                          </form>
                        </div>
                      </div>
                    )} */}

                    {signUpSteps === 2 && (
                      <div className="set-password-screen">
                        <div className="login-signup-hdng">
                          <h2>Profile</h2>
                          <p>Keep your information up to date.</p>
                        </div>
                        <div className="login-signup-form">
                          <form>
                            <div className="dp-update">
                              <label className="dp-update-label" htmlFor="profile_pic">
                                <img src={upProfilePic || "/assets/images/dp.svg"} alt="dp" className="dp-update-dp" />
                                <a
                                  // href="#!"
                                  className="dp-upload-ico"
                                >
                                  <img src="/assets/images/dp-upload-ico.svg" alt="dp-upload-ico" />
                                </a>
                              </label>

                              <input type="file" className="d-none" id="profile_pic" name="profile_pic" accept=".jpg,.jpeg,.png" ref={profileImgRef} onChange={handleProfileImage} />
                            </div>
                            <div className="form-grp">
                              <input
                                type={pwdType2}
                                name="password"
                                value={signUpData.password}
                                placeholder="Password"
                                onChange={(e) => {
                                  handleSignUpChange(e);

                                  if (e.target.value.length > 0) {
                                    setSignUpErrors((prev) => {
                                      prev.password = "";
                                      return prev;
                                    });
                                  }
                                }}
                              />
                              <div className="form-grp-icon">
                                {/* {pwdType2 === "password" && (
                                  <img
                                    src="/assets/images/form-password-ico.svg"
                                    alt="form-password-ico"
                                  />
                                )} */}
                                {pwdType2 === "password" ? <i className="fa-regular fa-eye-slash password-show" onClick={() => setpwdType2("text")}></i> : <i className="fa-regular fa-eye password-show" onClick={() => setpwdType2("password")}></i>}
                              </div>
                              <span className="text-danger text-errr">{signUpErrors.password}</span>
                            </div>

                            <div className="form-grp">
                              <input
                                type={cnfPwdType}
                                name="confirmPassword"
                                value={signUpData.confirmPassword}
                                placeholder="Confirm Password"
                                onChange={(e) => {
                                  handleSignUpChange(e);

                                  if (e.target.value.length > 0) {
                                    setSignUpErrors((prev) => {
                                      prev.confirmPassword = "";
                                      return prev;
                                    });
                                  }
                                }}
                              />
                              <div className="form-grp-icon">
                                {/* {cnfPwdType === "password" && (
                                  <img
                                    src="/assets/images/form-password-ico.svg"
                                    alt="form-password-ico"
                                  />
                                )} */}
                                {cnfPwdType === "password" ? <i className="fa-regular fa-eye-slash password-show" onClick={() => setcnfPwdType("text")}></i> : <i className="fa-regular fa-eye password-show" onClick={() => setcnfPwdType("password")}></i>}
                              </div>
                              <span className="text-danger text-errr">{signUpErrors.confirmPassword}</span>
                              <span className="text-danger text-errr">{check_validate}</span>
                            </div>

                            <div className="btn-group" onClick={handleSignUpSubmit}>
                              <a href="#!" className="custombtn btn-1 hover-filled-slide-down">
                                <span>Submit</span>
                              </a>
                            </div>
                          </form>
                        </div>
                      </div>
                    )}

                    <div className="signup-progress">
                      <div className={`signup-progress-step ${signUpSteps === 1 ? "active" : ""}`}>
                        <div className="signup-progress-circle" style={{ cursor: "pointer" }} onClick={() => setsignUpSteps(1)}></div>
                        <div className="signup-progress-count">1</div>
                      </div>
                      <div className={`signup-progress-step ${signUpSteps === 2 ? "active" : ""}`}>
                        <div className="signup-progress-circle" style={{ cursor: "pointer" }} onClick={() => setsignUpSteps(2)}></div>
                        <div className="signup-progress-count">2</div>
                      </div>
                      {/* <div
                        className={`signup-progress-step ${
                          signUpSteps === 3 ? "active" : ""
                        }`}
                      >
                        <div
                          className="signup-progress-circle"
                          // onClick={() => setsignUpSteps(3)}
                        ></div>
                        <div className="signup-progress-count">Step 3</div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* -- Login / Sign Up for tab & mobile end -- */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginModal;
