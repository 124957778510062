import {
  useCallback,
  useEffect,
  useState,
  // useState
} from "react";
import { FetchPrivacyPolicy } from "../../helpers/api_helpers";
// Redux actions

// Components
import PageBanner from "../components/layout/pages/PageBanner";

let imgBaseUrl = process.env.REACT_APP_API_IMG_URL;

const FairPolicy = () => {
  const [privacyPolicy, setPrivacyPolicy] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    fetchPrivacyPolicy();
  }, []);
  const fetchPrivacyPolicy = async () => {
    try {
      setIsLoading(true);
      let response = await FetchPrivacyPolicy({
        urlPath: "fair-use-policy?populate=deep",
        method: "GET",
      });

      if (!response) {
        throw new Error("Failed to fetch privacy policy");
      }

      setPrivacyPolicy(response);
    } catch (err: any) {
      setError(err.message);
      console.error("Error fetching privacy policy:", err);
    } finally {
      setIsLoading(false);
    }
  };
  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!privacyPolicy?.data?.attributes) return <div>No data available</div>;
  return (
    <>
      {/* page banner area start */}
      <PageBanner title={privacyPolicy.data.attributes.sec_2_h1} description={privacyPolicy.data.attributes.sec_1_i_banner.subheading} reviewSites={privacyPolicy.data.attributes.sec_1_i_banner.inner_banner_review} />
      {/* page banner area end */}

      {/* wrapper area start */}
      <div className="wrapper privacypage-container">
        <div className="container">
          {/* Assignment Writers */} <p>{privacyPolicy.data.attributes.sec_2_description}</p>
        </div>
      </div>
      {/* wrapper area end */}
    </>
  );
};

export default FairPolicy;
