import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";

// Redux actions
import { getSubmitAssignmentPage } from "../../../redux/slices/SubmitAssignment";
// import { useAppDispatch, useAppSelector } from "../../../../redux/hooks.ts";
// Components
import PageBanner from "../../components/layout/pages/PageBanner";
import SubmitAssignmentForm from "../../components/layout/pages/SubmitAssignmentForm";
import FrequentFAQs from "../../components/layout/pages/FrequentFAQs";
let imgBaseUrl = process.env.REACT_APP_API_IMG_URL;

const SubmitNewAssigment = () => {
  const dispatch = useAppDispatch();

  // Redux stores
  const { submitAssignmentStatus, submitAssignmentData } = useAppSelector((state) => state.submitAssignment);
  const { status, attributes } = useAppSelector((state) => state.homepage);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    if (submitAssignmentStatus !== "success") {
      dispatch(getSubmitAssignmentPage());
    }
  }, []);

  return (
    <>
      {/* page banner area start */}
      <PageBanner className="sna-inner-banner" title={submitAssignmentData.sec_1_i_banner[0].heading} description={submitAssignmentData.sec_1_i_banner[0].subheading} reviewSites={submitAssignmentData.sec_1_i_banner[0].inner_banner_review} />
      {/* page banner area end */}

      {/* wrapper area start */}
      <div className="wrapper">
        <section className="real-experts-area">
          <div className="container">
            <div className="real-experts-bx">
              <div className="home-banner-form">
                <div className="main-hdng text-center">
                  <h2>{submitAssignmentData.sec_2_h1}</h2>
                  <p>{submitAssignmentData.sec_2_h1}</p>
                </div>
                <div className="row justify-content-center">
                  <div className="col-lg-7">
                    <SubmitAssignmentForm />
                  </div>
                </div>
                <FrequentFAQs headingOneText={attributes.sec_8_h1} headingTwo={true} headingTwoText={attributes.sec_8_h2} data={attributes.sec_8_faq} />
              </div>
            </div>
          </div>
        </section>

        <section className="why-prefer-area">
          <div className="container">
            <div className="main-hdng text-center">
              <h2>{submitAssignmentData.sec_3_h1}</h2>
            </div>
            <div className="why-prefer-bx-area">
              {submitAssignmentData.sec_3_it_1.map((item, index) => (
                <div className="why-prefer-bx">
                  <div className="why-prefer-bx-img">
                    <img
                      // src="/assets/images/why-prefer-bx-img1.svg"
                      src={imgBaseUrl ? imgBaseUrl + item.icon?.data?.attributes?.url : "/assets/images/why-prefer-bx-img1.svg"}
                      alt="why-prefer-bx-img"
                    />
                  </div>
                  <h5>{item.heading}</h5>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
      {/* wrapper area end */}
    </>
  );
};

export default SubmitNewAssigment;
