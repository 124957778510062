import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

// Helpers
import { ApiHelperFunction } from "../../helpers/api_helpers";

// Typeguards
import { isApiErrorResponse } from "../../helpers/typeguards";

// Models
import { ApiFuncArgProps } from "../../models/apiFuncHelpers";
import { ProfilePageDataModel } from "../../models/views/pages";

interface ProfilePageInitialState {
  profilePageStatus: string;
  profilePageData: ProfilePageDataModel;
  profilePageLoading: boolean;
  profilePageSuccess: boolean;
  profilePageError: boolean;
}

const initialState = {
  profilePageStatus: "idle",
  profilePageData: {
    username: "",
    email: "",
    name: "",
    phone_number: "",
    alternate_phone_number: "",
    phone_number_code: "",
    alternate_phone_code: "",
    university_name: "",
    course_enrolled_in: "",
    years_of_study_remaining: "",
    present_address: "",
    country_of_origin: "",
    profile_pic: {
      url: "",
    },
  },
  profilePageLoading: false,
  profilePageSuccess: false,
  profilePageError: false,
};

export const getProfilePage = createAsyncThunk("get_user_profile", async () => {
  try {
    let response = await ApiHelperFunction({
      urlPath: "users/me?populate=deep",
      method: "GET",
      role: "privileged",
    } as ApiFuncArgProps);

    if (isApiErrorResponse(response)) {
      console.log("Error: ", response.error.message);
    } else {
      return response.data;
    }
  } catch (error: any) {
    console.log("Failed to fetch profile page due to ", error.message);
  }
});

export const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    clearUserState: (state) => {
      state.profilePageStatus = "idle";
      state.profilePageData = {
        username: "",
        email: "",
        name: "",
        phone_number: "",
        alternate_phone_number: "",
        phone_number_code: "",
        alternate_phone_code: "",
        university_name: "",
        course_enrolled_in: "",
        years_of_study_remaining: "",
        present_address: "",
        country_of_origin: "",
        profile_pic: {
            url: "",
        },
      };
      state.profilePageLoading = false;
      state.profilePageSuccess = false;
      state.profilePageError = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProfilePage.pending, (state) => {
        state.profilePageStatus = "loading";
        state.profilePageError = true;
      })
      .addCase(getProfilePage.fulfilled, (state, { payload }) => {
        state.profilePageStatus = "success";
        state.profilePageSuccess = true;
        state.profilePageData = payload;
      })
      .addCase(getProfilePage.rejected, (state) => {
        state.profilePageStatus = "failed";
        state.profilePageError = true;
      });
  },
});

export const { clearUserState } = userSlice.actions;
