import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

// Helpers
import { ApiHelperFunction } from "../../helpers/api_helpers";

// Models
import { ApiFuncArgProps } from "../../models/apiFuncHelpers";
import { FAQpageDataModel } from "../../models/views/pages";

interface FAQPageInitialState {
  faqPageStatus: string;
  faqPageData: FAQpageDataModel;
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
}

const initialState: FAQPageInitialState = {
  faqPageStatus: "idle",
  faqPageData: {
    sec_2_h1: "",
    createdAt: "",
    updatedAt: "",
    publishedAt: "",
    sec_1_i_banner: [
      {
        id: 0,
        heading: "",
        subheading: "",
        inner_banner_review: [],
      },
    ],
    sec_2_faq: [],
  },
  isLoading: false,
  isSuccess: false,
  isError: false,
};

export const getFAQPage = createAsyncThunk(
  "get_faq_page",
  async () => {
    try {
      let response = await ApiHelperFunction({
        urlPath: "/faq-page?populate=deep",
        method: "GET",
      } as ApiFuncArgProps);

      if (response.data) {
        return response.data;
      }
    } catch (error: any) {
      console.log("Failed to fetch faq page due to ", error.message);
    }
  }
);

export const faqPageSlice = createSlice({
  name: "faq_page",
  initialState,
  reducers: {
    clearFAQPageState: (state) => {
      state.faqPageStatus = "idle";
      state.faqPageData = {
        sec_2_h1: "",
        createdAt: "",
        updatedAt: "",
        publishedAt: "",
        sec_1_i_banner: [
          {
            id: 0,
            heading: "",
            subheading: "",
            inner_banner_review: [],
          },
        ],
        sec_2_faq: [],
      };
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFAQPage.pending, (state) => {
        state.faqPageStatus = "loading";
        state.isLoading = true;
      })
      .addCase(getFAQPage.fulfilled, (state, { payload }) => {
        state.faqPageStatus = "success";
        state.isSuccess = true;
        state.faqPageData = payload?.data?.attributes;
      })
      .addCase(getFAQPage.rejected, (state) => {
        state.faqPageStatus = "failed";
        state.isError = true;
      });
  },
});

export const { clearFAQPageState } = faqPageSlice.actions;
