import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

// Helpers
import { ApiHelperFunction } from "../../helpers/api_helpers";

// Models
import { ApiFuncArgProps } from "../../models/apiFuncHelpers";
import { AboutUspageDataModel } from "../../models/views/pages";

interface AboutUsPageInitialState {
  aboutUsPageStatus: string;
  aboutUsPageData: AboutUspageDataModel;
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
}

const initialState: AboutUsPageInitialState = {
  aboutUsPageStatus: "idle",
  aboutUsPageData: {
    createdAt: "",
    updatedAt: "",
    publishedAt: "",
    sec_2_h1: "",
    sec_2_description: "",
    sec_2_btn_text: "",
    sec_2_btn_link: "",
    sec_3_h1: "",
    sec_3_description: "",
    sec_4_h1: "",
    sec_4_description: "",
    sec_5_h1: "",
    sec_5_description: "",
    sec_6_h1: "",
    sec_6_description: "",
    sec_1_i_banner: [
      {
        id: 0,
        heading: "",
        subheading: "",
        inner_banner_review: [],
        // {
        //     id: 0,
        //     text: "",
        //     review_count: "",
        //     rating: 0,
        //     icon: {
        //       data: "",
        //     },
        // },
      },
    ],
    sec_2_image: {
      data: "",
    },
    sec_3_it_1: [],
    sec_4_it_1: [],
    sec_5_it_1: [],
    sec_6_it_1: [],
  },
  isLoading: false,
  isSuccess: false,
  isError: false,
};

export const getAboutUsPage = createAsyncThunk("get_about_us_page", async () => {
  try {
    let response = await ApiHelperFunction({
      urlPath: "/about-us?populate=deep",
      method: "GET",
    } as ApiFuncArgProps);

    if (response.data) {
      return response.data;
    }
  } catch (error: any) {
    console.log("Failed to fetch about us page due to ", error.message);
  }
});

export const aboutUsPageSlice = createSlice({
  name: "about_us_page",
  initialState,
  reducers: {
    clearAboutUsPageState: (state) => {
      state.aboutUsPageStatus = "idle";
      state.aboutUsPageData = {
        createdAt: "",
        updatedAt: "",
        publishedAt: "",
        sec_2_h1: "",
        sec_2_description: "",
        sec_2_btn_text: "",
        sec_2_btn_link: "",
        sec_3_h1: "",
        sec_3_description: "",
        sec_4_h1: "",
        sec_4_description: "",
        sec_5_h1: "",
        sec_5_description: "",
        sec_6_h1: "",
        sec_6_description: "",
        sec_1_i_banner: [
          {
            id: 0,
            heading: "",
            subheading: "",
            inner_banner_review: [],
          },
        ],
        sec_2_image: {
          data: "",
        },
        sec_3_it_1: [],
        sec_4_it_1: [],
        sec_5_it_1: [],
        sec_6_it_1: [],
      };
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAboutUsPage.pending, (state) => {
        state.aboutUsPageStatus = "loading";
        state.isLoading = true;
      })
      .addCase(getAboutUsPage.fulfilled, (state, { payload }) => {
        state.aboutUsPageStatus = "success";
        state.isSuccess = true;
        state.aboutUsPageData = payload?.data?.attributes;
      })
      .addCase(getAboutUsPage.rejected, (state) => {
        state.aboutUsPageStatus = "failed";
        state.isError = true;
      });
  },
});

export const { clearAboutUsPageState } = aboutUsPageSlice.actions;
