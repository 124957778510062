import { useEffect, useState } from "react";
// import useAuth from "../hooks/useAuth";
import {
  Link,
  // useNavigate,
} from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";

// Helpers
import qs from "qs";

// Redux actions
import { getUserOrdersList } from "../../../redux/slices/OrdersSlice";

// Models
import { OrderDataModel } from "../../../models/constants/data/dataModels";

// Components
import OrdersTable from "../../components/layout/pages/OrdersTable";
import OrderDetails from "../../components/layout/pages/OrderDetails";

const AllOrders = () => {
  // const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [orderDetails, setorderDetails] = useState<OrderDataModel | undefined>(undefined);

  // Redux stores
  const { ordersData } = useAppSelector((state) => state.orders);

  let userEmail = localStorage.getItem("user_email");

  function fetchOrdersForUser() {
    const query = qs.stringify(
      {
        filters: {
          email: {
            $eq: `${userEmail}`,
          },
        },
      },
      {
        encodeValuesOnly: true, // prettify URL
      }
    );

    if (ordersData.data.length === 0) {
      dispatch(getUserOrdersList(query));
    }
    console.log("orderdetails=====> orrder", ordersData);
  }

  // useAuth();
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    fetchOrdersForUser();
  }, []);

  return (
    <section className="all-orders-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-9">
            <OrdersTable parentComponentName="AllOrders" ordersData={ordersData} setorderDetails={setorderDetails} />
          </div>
          <div className="col-lg-3">
            <div className="all-orders-right-area">
              <Link to="/coming-soon" className="custombtn btn-1 hover-filled-slide-down">
                <span>
                  Essay Typer <i className="fa-solid fa-arrow-right-long" />
                </span>
              </Link>
              <Link to="/referencing" className="custombtn btn-1 hover-filled-slide-down">
                <span>
                  Referencing <i className="fa-solid fa-arrow-right-long" />
                </span>
              </Link>
            </div>
          </div>
        </div>

        <div className="row">{ordersData.data.length > 0 && <OrderDetails orderDetails={orderDetails} />}</div>
      </div>
    </section>
  );
};

export default AllOrders;
