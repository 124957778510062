import React, { useEffect, useState, useRef } from "react";
import { useAppDispatch } from "../../../redux/hooks";

// Constants
import { digits, allowedKeys, countriesList } from "../../../constants/data/data-constants";

// Helper
import { toast } from "react-hot-toast";
import Swal from "sweetalert2";

// Utils
import { scrollToErrorElement } from "../../../utils";

// Models
import { ProfilePageDataModel } from "../../../models/views/pages";
import { ApiFuncArgProps } from "../../../models/apiFuncHelpers";

// API
import { ApiHelperFunction } from "../../../helpers/api_helpers";

// Redux actions
import { getProfilePage } from "../../../redux/slices/UserSlice";

// Typeguards
import { isApiErrorResponse } from "../../../helpers/typeguards";
import { ColorRing } from "react-loader-spinner";
// Components
import PageBanner from "../../components/layout/pages/PageBanner";
import Select from "react-select";

const customStyles = {
  menu: (provided: any) => ({
    ...provided,
    maxHeight: 200, // Set max height to enable scrolling when options exceed this height
    overflowY: "auto", // Add vertical scrolling
  }),
  menuList: (provided: any) => ({
    ...provided,
    maxHeight: 200, // Same max height as menu for consistency
    overflowY: "auto",
  }),
};

let imgBaseUrl = process.env.REACT_APP_API_IMG_URL;

let initialValues: ProfilePageDataModel = {
  username: "",
  email: "",
  name: "",
  phone_number: "",
  alternate_phone_number: "",
  phone_number_code: "",
  alternate_phone_code: "",
  university_name: "",
  course_enrolled_in: "",
  years_of_study_remaining: 0,
  present_address: "",
  country_of_origin: "",
};

let createErrors = {
  profile_pic: "",
  name: "",
  phone_number_code: "",
  phone_number: "",
};

const Profile = () => {
  const dispatch = useAppDispatch();
  const [edit, setedit] = useState(false);
  const [userId, setuserId] = useState(0);
  const [profileInfo, setprofileInfo] = useState(initialValues);
  const [errors, seterrors] = useState(createErrors);
  const profileImgRef = useRef<HTMLInputElement>(null);
  const [profileImg, setprofileImg] = useState<File | undefined>(undefined);
  const [upProfilePic, setupProfilePic] = useState("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [coursesSelected, setcoursesSelected] = useState<Array<string>>([]);
  const [yearsOfStudyRemaining, setyearsOfStudyRemaining] = useState<number>(0);

  const fetchProfile = async () => {
    try {
      let res = await ApiHelperFunction({
        urlPath: "users/me?populate=deep",
        method: "GET",
        role: "privileged",
      } as ApiFuncArgProps);

      if (isApiErrorResponse(res)) {
        toast.error("Failed to fetch profile information due to " + res.error.message, { duration: 10000 });
      } else if (res.data) {
        const { id, confirmed, blocked, createdAt, updatedAt, provider, course_enrolled_in, years_of_study_remaining, ...rest } = res.data;

        setuserId(id);
        setprofileInfo((prev) => {
          let update = JSON.parse(JSON.stringify(prev));
          return { ...update, ...rest };
        });
        setupProfilePic(res.data?.profile_pic === null ? "/assets/images/no-user.png" : imgBaseUrl ? imgBaseUrl + res.data.profile_pic?.url : "/assets/images/no-user.png");
        course_enrolled_in !== null && setcoursesSelected(course_enrolled_in.split(","));
        years_of_study_remaining !== null ? setyearsOfStudyRemaining(years_of_study_remaining) : setyearsOfStudyRemaining(0);
      } else {
        console.log("Unexpected response:", res);
      }
    } catch (error: any) {
      console.log(error);
      toast.error(error.message, { duration: 10000 });
    }
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    fetchProfile();
  }, []);

  const handleChange: React.ChangeEventHandler<HTMLInputElement | HTMLSelectElement> = (event) => {
    setprofileInfo({ ...profileInfo, [event.target.name]: event.target.value });
  };

  const handleProfileImage: React.ChangeEventHandler<HTMLInputElement> = async (event) => {
    const file = event.target.files?.[0];
    if (file) {
      try {
        const DATA = new FormData();
        DATA.append("files", file);
        // await new Promise((resolve) => setTimeout(resolve, 500));

        setIsLoading(true);
        const imgUpRes = await ApiHelperFunction({
          urlPath: "upload",
          method: "POST",
          data: DATA,
          role: "privileged",
          contentType: "form-data",
        } as ApiFuncArgProps);

        if (isApiErrorResponse(imgUpRes)) {
          throw new Error(imgUpRes.error.message);
        }

        const uploadedImage = imgUpRes.data?.[0];
        if (uploadedImage) {
          const bodyData = {
            ...profileInfo,
            profile_pic: uploadedImage,
          };

          const response = await ApiHelperFunction({
            urlPath: `/users/${userId}`,
            method: "PUT",
            data: bodyData,
            role: "privileged",
          } as ApiFuncArgProps);

          if (isApiErrorResponse(response)) {
            throw new Error(response.error.message);
          }
          if (response.data) {
            // Swal.fire("Success", "Profile Image updated successfully", "success");
            // setprofileImg(undefined);
            // setupProfilePic("");
            // setcoursesSelected([]);
            fetchProfile();
            dispatch(getProfilePage());
          }
        }
      } catch (error: any) {
        console.error(error);
        Swal.fire("Error", error.message, "error");
      } finally {
        setIsLoading(false);
      }
    }
  };

  // const handleProfileImage: React.ChangeEventHandler<HTMLInputElement> = (event) => {
  //   if (!event.target.files) return;

  //   if (event.target.files.length === 0) return;

  //   let file = event.target.files[0];

  //   if (file.size > 500000000) {
  //     seterrors({
  //       ...createErrors,
  //       profile_pic: "Uploaded picture can't be more than 5mb",
  //     });
  //   } else {
  //     uploadtheimage(file);
  //     setprofileImg(file);
  //     setupProfilePic(URL.createObjectURL(file));
  //     seterrors({ ...createErrors, profile_pic: "" });
  //   }
  // };

  // async function uploadtheimage(image: File) {
  //   try {
  //     setIsLoading(true);
  //     let imageFiles = [];
  //     console.log("profileImg=====>", profileImg, image);
  //     if (image !== undefined) {
  //       const DATA = new FormData();

  //       DATA.append("files", image);

  //       const imgUpRes = await ApiHelperFunction({
  //         urlPath: "/upload",
  //         method: "POST",
  //         data: DATA,
  //         role: "privileged",
  //         contentType: "form-data",
  //       } as ApiFuncArgProps);

  //       if (isApiErrorResponse(imgUpRes)) {
  //         // console.log("Error:", response.error);
  //         // toast.error(imgUpRes.error.message);
  //         Swal.fire("Error", imgUpRes.error.message, "error");
  //       } else {
  //         setprofileImg(undefined);
  //         setupProfilePic("");
  //         setcoursesSelected([]);
  //         fetchProfile();
  //         dispatch(getProfilePage());
  //       }
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // }

  const handleCourseBtnClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    let selections = [...coursesSelected];
    let existIndex = selections.findIndex((item) => item === event.currentTarget.value);

    // If an element with the same target value exists, remove it
    if (existIndex !== -1) {
      selections.splice(existIndex, 1);
    } else {
      selections.push(event.currentTarget.value);
    }

    setcoursesSelected(selections);
  };

  const handleRemainingYearOfStudySelect: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    // setprofileInfo((prev) => {
    //   let update = JSON.parse(JSON.stringify(prev));
    //   update.years_of_study_remaining = event.currentTarget.value;
    //   return update;
    // });    // Don't know why setting value like this is causing issue
    setyearsOfStudyRemaining(Number(event.currentTarget.value));
  };

  const handleValidation = () => {
    const { name, phone_number_code, phone_number } = profileInfo;

    if (errors.profile_pic !== "") {
      scrollToErrorElement("profile_pic");
      return false;
    }
    if (name === "") {
      seterrors({ ...createErrors, name: "Name is required." });
      scrollToErrorElement("name");
      return false;
    }
    if (phone_number_code === "") {
      seterrors({
        ...createErrors,
        phone_number_code: "Phone code is required.",
      });
      return false;
    }
    if (phone_number === "") {
      seterrors({
        ...createErrors,
        phone_number: "Phone number cannot be empty.",
      });
      return false;
    }
    if (phone_number.length < 10 || phone_number.length > 15) {
      seterrors({
        ...createErrors,
        phone_number: "Phone number must be between 10 to 15 integers.",
      });
      return false;
    }

    return true;
  };

  const handleSubmit: React.MouseEventHandler<HTMLDivElement> = async (event) => {
    event.preventDefault();
    // setIsLoading(true);
    if (handleValidation()) {
      try {
        let imageFiles = [];
        setIsLoading(true);
        if (profileImg !== undefined) {
          const DATA = new FormData();

          DATA.append("files", profileImg);

          const imgUpRes = await ApiHelperFunction({
            urlPath: "/upload",
            method: "POST",
            data: DATA,
            role: "privileged",
            contentType: "form-data",
          } as ApiFuncArgProps);

          if (isApiErrorResponse(imgUpRes)) {
            // console.log("Error:", response.error);
            // toast.error(imgUpRes.error.message);
            Swal.fire("Error", imgUpRes.error.message, "error");
          } else if (imgUpRes.data) {
            imageFiles = imgUpRes.data;
          } else {
            console.log("Unexpected response:", imgUpRes);
          }
        }

        // delete profileInfo.alternate_phone_code;
        profileInfo.course_enrolled_in = coursesSelected.join(",");
        profileInfo.years_of_study_remaining = yearsOfStudyRemaining;

        let bodyData = {
          ...profileInfo,
          profile_pic: imageFiles?.[0],
        };
        const response = await ApiHelperFunction({
          urlPath: `/users/${userId}`,
          method: "PUT",
          data: imageFiles.length > 0 ? bodyData : profileInfo,
          role: "privileged",
        } as ApiFuncArgProps);

        if (isApiErrorResponse(response)) {
          // toast.error(response.error.message);
          Swal.fire("Error", response.error.message, "error");
        } else if (response.data) {
          setuserId(0);
          setprofileInfo(initialValues);
          seterrors(createErrors);
          if (profileImgRef.current) {
            profileImgRef.current.value = "";
          }
          setprofileImg(undefined);
          setupProfilePic("");
          setcoursesSelected([]);
          fetchProfile();
          dispatch(getProfilePage());
          // toast.success("Profile updated successfully");
          Swal.fire("Success", "Profile updated successfully", "success");
        } else {
          console.log("Unexpected response:", response);
        }
      } catch (error: any) {
        // toast.error(error.message);
        Swal.fire("Error", error.message, "error");
      } finally {
        setIsLoading(false);
      }
    }
  };
  // if (ready) {
  //   return (
  //     <div className="loader-wrapper">
  //       <ColorRing visible={true} height="80" width="80" ariaLabel="color-ring-loading" wrapperStyle={{}} wrapperClass="color-ring-wrapper" colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]} />)
  //     </div>
  //   );
  // }

  console.log("profileInfo=====>", profileInfo);
  return (
    <>
      {/* page banner area start */}
      <PageBanner title="Profile" description="Get your hands on more such brilliant deals and discounts when you opt for our services." reviewInfo={false} className="profile-inner-banner" />
      {/* page banner area end */}

      {/* wrapper area start */}
      <div className="wrapper">
        <div className="who-i-am-area">
          <div className="container">
            <div className="who-i-am profile-who-i-am">
              <div className="row align-items-end">
                <div className="col-md-5 col-lg-4">
                  <div className="who-i-am-img">
                    {!isLoading ? (
                      <label htmlFor="profile_pic" className="profile_pic_sdnew">
                        <img src={upProfilePic || "/assets/images/no-user.png"} alt="who-i-am-img" className="who-i-am-img-lg" />
                        <a className="dp-upload-ico dp-upload-ico-profilePg">
                          <img src="/assets/images/dp-upload-ico.svg" alt="dp-upload-ico" className="who-i-am-img-sm" />
                        </a>
                      </label>
                    ) : (
                      <div className="loader-wrapper">
                        <ColorRing visible={true} height="80" width="80" ariaLabel="color-ring-loading" wrapperStyle={{}} wrapperClass="color-ring-wrapper" colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]} />
                      </div>
                    )}

                    <input type="file" name="profile_pic" id="profile_pic" className="d-none" accept=".jpg,.jpeg,.png" ref={profileImgRef} onChange={handleProfileImage} />
                  </div>
                  <span className="text-danger text-err">{errors.profile_pic}</span>
                </div>
                <div className="col-md-5 col-lg-4">
                  <div className="profile-hdng">
                    <h2>Who am I?</h2>
                  </div>
                  <div className="profile-details">
                    <div className="form-group">
                      <label>Your name</label>
                      <input
                        type="text"
                        // disabled={!edit}
                        id="name"
                        name="name"
                        value={profileInfo.name}
                        placeholder="Enter Your Full Name"
                        onChange={(e) => {
                          handleChange(e);

                          if (e.target.value.length > 0) {
                            seterrors((prev) => {
                              prev.name = "";
                              return prev;
                            });
                          }
                        }}
                      />
                      <span className="text-danger text-errr">{errors.name}</span>
                    </div>
                  </div>
                </div>
                <div className="col-md-2 col-lg-4" onClick={() => (!edit ? setedit(true) : setedit(false))}>
                  <a className="edit-profile-btn">
                    <img src="/assets/images/edit-profile-btn.svg" alt="edit-profile-btn" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="who-i-live-area">
          <div className="container">
            <div className="who-i-live">
              <div className="profile-hdng">
                <h2>Where do I live?</h2>
              </div>
              <div className="row align-items-end">
                <div className="col-md-5 col-lg-4">
                  <div className="profile-details">
                    <div className="form-group">
                      <label>Present residence</label>
                      <input
                        type="text"
                        // defaultValue="Olathe"
                        name="present_address"
                        value={profileInfo.present_address}
                        placeholder="Present Address"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-5 col-lg-4">
                  <div className="profile-details">
                    <div className="form-group">
                      <label>Country of origin</label>
                      {/* <select
                        className="form-select"
                        aria-label="Default select example"
                        name="country_of_origin"
                        value={profileInfo.country_of_origin}
                        onChange={handleChange}
                      >
                        <option key={0} value="select">
                          Select A Country
                        </option>
                        {countriesList.map((item, index) => (
                          <option key={index + 1} value={item.label}>
                            {item.label}
                          </option>
                        ))}
                      </select> */}
                      <Select
                        name="country_of_origin"
                        id="country_of_origin"
                        classNamePrefix="select"
                        options={countriesList.map((item) => {
                          return { value: item.label, label: item.label };
                        })}
                        placeholder={profileInfo.country_of_origin === null && "Select A Country"}
                        value={
                          profileInfo.country_of_origin !== null && {
                            value: profileInfo.country_of_origin,
                            label: profileInfo.country_of_origin,
                          }
                        }
                        onChange={(val) => {
                          setprofileInfo((prev) => {
                            let update = JSON.parse(JSON.stringify(prev));
                            if (val) {
                              update.country_of_origin = val.value;
                            }
                            return update;
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-2 col-lg-4">
                  <a href="#!" className="edit-profile-btn">
                    <img src="/assets/images/edit-profile-btn.svg" alt="edit-profile-btn" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="who-i-do-area">
          <div className="container">
            <div className="who-i-do">
              <div className="profile-hdng">
                <h2>What do I do?</h2>
              </div>
              <div className="row gy-5">
                <div className="col-md-6 col-lg-4">
                  <div className="profile-details">
                    <div className="form-group">
                      <label>
                        University name
                        <span>
                          <img src="/assets/images/info-ico-2.svg" alt="info-ico" />
                        </span>
                      </label>
                      <input type="text" name="university_name" value={profileInfo.university_name} onChange={handleChange} />
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="profile-details">
                    <div className="form-group">
                      <label>Course enrolled in</label>
                    </div>
                  </div>
                  <div className="course-bx-area">
                    <button type="button" className={`course-bx ${coursesSelected.includes("management") ? "selected" : ""}`} value="management" onClick={handleCourseBtnClick}>
                      <div className="course-bx-img">
                        <img src="/assets/images/Management-ico.svg" alt="Management-ico" />
                      </div>
                      <h6>Management</h6>
                    </button>
                    <button type="button" value="chemistry" className={`course-bx ${coursesSelected.includes("chemistry") ? "selected" : ""}`} onClick={handleCourseBtnClick}>
                      <div className="course-bx-img">
                        <img src="/assets/images/Chemistry-ico.svg" alt="Chemistry-ico" />
                      </div>
                      <h6>Chemistry</h6>
                    </button>
                    <button type="button" value="engineering" className={`course-bx ${coursesSelected.includes("engineering") ? "selected" : ""}`} onClick={handleCourseBtnClick}>
                      <div className="course-bx-img">
                        <img src="/assets/images/Engineering-ico.svg" alt="Engineering-ico" />
                      </div>
                      <h6>Engineering</h6>
                    </button>
                    <button type="button" value="commerce" className={`course-bx ${coursesSelected.includes("commerce") ? "selected" : ""}`} onClick={handleCourseBtnClick}>
                      <div className="course-bx-img">
                        <img src="/assets/images/Commerce-ico.svg" alt="Commerce-ico" />
                      </div>
                      <h6>Commerce</h6>
                    </button>
                    <button type="button" value="law" className={`course-bx ${coursesSelected.includes("law") ? "selected" : ""}`} onClick={handleCourseBtnClick}>
                      <div className="course-bx-img">
                        <img src="/assets/images/Law-ico.svg" alt="Law-ico" />
                      </div>
                      <h6>Law</h6>
                    </button>
                    <button type="button" value="medical" className={`course-bx ${coursesSelected.includes("medical") ? "selected" : ""}`} onClick={handleCourseBtnClick}>
                      <div className="course-bx-img">
                        <img src="/assets/images/Medical-ico.svg" alt="Medical-ico" />
                      </div>
                      <h6>Medical</h6>
                    </button>
                    <button type="button" value="computer" className={`course-bx ${coursesSelected.includes("computer") ? "selected" : ""}`} onClick={handleCourseBtnClick}>
                      <div className="course-bx-img">
                        <img src="/assets/images/Computer-ico.svg" alt="Computer-ico" />
                      </div>
                      <h6>Computer</h6>
                    </button>
                    <button type="button" value="mathematics" className={`course-bx ${coursesSelected.includes("mathematics") ? "selected" : ""}`} onClick={handleCourseBtnClick}>
                      <div className="course-bx-img">
                        <img src="/assets/images/Mathematics-ico.svg" alt="Mathematics-ico" />
                      </div>
                      <h6>Mathematics</h6>
                    </button>
                    <button type="button" value="english" className={`course-bx ${coursesSelected.includes("english") ? "selected" : ""}`} onClick={handleCourseBtnClick}>
                      <div className="course-bx-img">
                        <img src="/assets/images/English-ico-2.svg" alt="English-ico" />
                      </div>
                      <h6>English</h6>
                    </button>
                    <button type="button" value="web_design" className={`course-bx ${coursesSelected.includes("web_design") ? "selected" : ""}`} onClick={handleCourseBtnClick}>
                      <div className="course-bx-img">
                        <img src="/assets/images/Webdesign-ico.svg" alt="Webdesign-ico" />
                      </div>
                      <h6>Web design</h6>
                    </button>
                    <button type="button" value="finance" className={`course-bx ${coursesSelected.includes("finance") ? "selected" : ""}`} onClick={handleCourseBtnClick}>
                      <div className="course-bx-img">
                        <img src="/assets/images/Finance-ico.svg" alt="Finance-ico" />
                      </div>
                      <h6>Finance</h6>
                    </button>
                    <button type="button" value="physics" className={`course-bx ${coursesSelected.includes("physics") ? "selected" : ""}`} onClick={handleCourseBtnClick}>
                      <div className="course-bx-img">
                        <img src="/assets/images/Physics-ico.svg" alt="Physics-ico" />
                      </div>
                      <h6>Physics</h6>
                    </button>
                    <button type="button" value="biology" className={`course-bx ${coursesSelected.includes("biology") ? "selected" : ""}`} onClick={handleCourseBtnClick}>
                      <div className="course-bx-img">
                        <img src="/assets/images/Biology-ico.svg" alt="Biology-ico" />
                      </div>
                      <h6>Biology</h6>
                    </button>
                    <button type="button" value="nursing" className={`course-bx ${coursesSelected.includes("nursing") ? "selected" : ""}`} onClick={handleCourseBtnClick}>
                      <div className="course-bx-img">
                        <img src="/assets/images/Nursing-ico.svg" alt="Nursing-ico" />
                      </div>
                      <h6>Nursing</h6>
                    </button>
                    <button type="button" value="geography" className={`course-bx ${coursesSelected.includes("geography") ? "selected" : ""}`} onClick={handleCourseBtnClick}>
                      <div className="course-bx-img">
                        <img src="/assets/images/Geography-ico.svg" alt="Geography-ico" />
                      </div>
                      <h6>Geography</h6>
                    </button>
                    <button type="button" value="psychology" className={`course-bx ${coursesSelected.includes("psychology") ? "selected" : ""}`} onClick={handleCourseBtnClick}>
                      <div className="course-bx-img">
                        <img src="/assets/images/Psychology-ico.svg" alt="Psychology-ico" />
                      </div>
                      <h6>Psychology</h6>
                    </button>
                    <button type="button" value="history" className={`course-bx ${coursesSelected.includes("history") ? "selected" : ""}`} onClick={handleCourseBtnClick}>
                      <div className="course-bx-img">
                        <img src="/assets/images/History-ico.svg" alt="History-ico" />
                      </div>
                      <h6>History</h6>
                    </button>
                    <button type="button" value="sociology" className={`course-bx ${coursesSelected.includes("sociology") ? "selected" : ""}`} onClick={handleCourseBtnClick}>
                      <div className="course-bx-img">
                        <img src="/assets/images/Sociology-ico.svg" alt="Sociology-ico" />
                      </div>
                      <h6>Sociology</h6>
                    </button>
                    <button type="button" value="economics" className={`course-bx ${coursesSelected.includes("economics") ? "selected" : ""}`} onClick={handleCourseBtnClick}>
                      <div className="course-bx-img">
                        <img src="/assets/images/Economics-ico.svg" alt="Economics-ico" />
                      </div>
                      <h6>Economics</h6>
                    </button>
                    <button type="button" value="statistics" className={`course-bx ${coursesSelected.includes("statistics") ? "selected" : ""}`} onClick={handleCourseBtnClick}>
                      <div className="course-bx-img">
                        <img src="/assets/images/Statistics-ico.svg" alt="Statistics-ico" />
                      </div>
                      <h6>Statistics</h6>
                    </button>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="profile-details">
                    <div className="form-group">
                      <label>Years of study remaining</label>
                    </div>
                  </div>
                  <div className="range-slider">
                    <div className="range-slider-line"></div>
                    <div className="range-slider-circle-area">
                      <div className={`range-slider-circle-bx ${yearsOfStudyRemaining === 1 ? "selected" : ""}`}>
                        <div className="range-slider-circle-cnt">1 Year</div>
                        <button type="button" value={1} className="range-slider-circle" onClick={handleRemainingYearOfStudySelect}></button>
                      </div>
                      <div className={`range-slider-circle-bx ${yearsOfStudyRemaining === 2 ? "selected" : ""}`}>
                        <div className="range-slider-circle-cnt">2 Years</div>
                        <button type="button" value={2} className="range-slider-circle" onClick={handleRemainingYearOfStudySelect}></button>
                      </div>
                      <div className={`range-slider-circle-bx ${yearsOfStudyRemaining === 3 ? "selected" : ""}`}>
                        <div className="range-slider-circle-cnt">3 Years</div>
                        <button type="button" value={3} className="range-slider-circle" onClick={handleRemainingYearOfStudySelect}></button>
                      </div>
                      <div className={`range-slider-circle-bx ${yearsOfStudyRemaining === 4 ? "selected" : ""}`}>
                        <div className="range-slider-circle-cnt">4 Years</div>
                        <button type="button" value={4} className="range-slider-circle" onClick={handleRemainingYearOfStudySelect}></button>
                      </div>
                      <div className={`range-slider-circle-bx ${yearsOfStudyRemaining === 5 ? "selected" : ""}`}>
                        <div className="range-slider-circle-cnt">5 Years</div>
                        <button type="button" value={5} className="range-slider-circle" onClick={handleRemainingYearOfStudySelect}></button>
                      </div>
                      <div className={`range-slider-circle-bx ${yearsOfStudyRemaining === 6 ? "selected" : ""}`}>
                        <div className="range-slider-circle-cnt">6 Years</div>
                        <button type="button" value={6} className="range-slider-circle" onClick={handleRemainingYearOfStudySelect}></button>
                      </div>
                      <div className={`range-slider-circle-bx ${yearsOfStudyRemaining === 7 ? "selected" : ""}`}>
                        <div className="range-slider-circle-cnt">7 Years</div>
                        <button type="button" value={7} className="range-slider-circle" onClick={handleRemainingYearOfStudySelect}></button>
                      </div>
                      <div className={`range-slider-circle-bx ${yearsOfStudyRemaining === 8 ? "selected" : ""}`}>
                        <div className="range-slider-circle-cnt">8 Years</div>
                        <button type="button" value={8} className="range-slider-circle" onClick={handleRemainingYearOfStudySelect}></button>
                      </div>
                      <div className={`range-slider-circle-bx ${yearsOfStudyRemaining === 9 ? "selected" : ""}`}>
                        <div className="range-slider-circle-cnt">9 Years</div>
                        <button type="button" value={9} className="range-slider-circle" onClick={handleRemainingYearOfStudySelect}></button>
                      </div>
                      {/* <div
                        className={`range-slider-circle-bx ${
                          yearsOfStudyRemaining === "more" ? "selected" : ""
                        }`}
                      >
                        <div className="range-slider-circle-cnt">More</div>
                        <button
                          type="button"
                          value="more"
                          className="range-slider-circle"
                          onClick={handleRemainingYearOfStudySelect}
                        ></button>
                      </div> */}
                    </div>
                    {/* <img
                      src="/assets/images/range-slider-img.png"
                      alt="range-slider-img"
                    /> */}
                  </div>
                  <div className="range-slider-mobile">
                    <div>
                      <input type="radio" id="test1" name="radio-group" defaultChecked />
                      <label htmlFor="test1">0.5 Year</label>
                    </div>
                    <div>
                      <input type="radio" id="test2" name="radio-group" />
                      <label htmlFor="test2">1 Year</label>
                    </div>
                    <div>
                      <input type="radio" id="test3" name="radio-group" />
                      <label htmlFor="test3">1.5 Year</label>
                    </div>
                    <div>
                      <input type="radio" id="test4" name="radio-group" />
                      <label htmlFor="test4">2 Years</label>
                    </div>
                    <div>
                      <input type="radio" id="test5" name="radio-group" defaultChecked />
                      <label htmlFor="test5">2.5 Years</label>
                    </div>
                    <div>
                      <input type="radio" id="test6" name="radio-group" />
                      <label htmlFor="test6">3 Years</label>
                    </div>
                    <div>
                      <input type="radio" id="test7" name="radio-group" />
                      <label htmlFor="test7">3.5 Years</label>
                    </div>
                    <div>
                      <input type="radio" id="test8" name="radio-group" defaultChecked />
                      <label htmlFor="test8">4 Years</label>
                    </div>
                    <div>
                      <input type="radio" id="test9" name="radio-group" />
                      <label htmlFor="test9">4.5 Years</label>
                    </div>
                    <div>
                      <input type="radio" id="test10" name="radio-group" />
                      <label htmlFor="test10">More</label>
                    </div>
                  </div>
                  {/* <input
                    type="number"
                    disabled
                    value={profileInfo.years_of_study_remaining}
                  />{" "}
                  Years */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="connect-me-area">
          <div className="container">
            <div className="connect-me">
              <div className="profile-hdng">
                <h2>Connect with me</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-lg-5">
                <div className="profile-details">
                  <div className="form-group">
                    <label>Phone no.</label>
                    <div className="phn-area">
                      <Select
                        styles={customStyles}
                        name="phone_number_code"
                        id=""
                        classNamePrefix="select"
                        options={countriesList.map((item) => {
                          return {
                            value: item.phone_code,
                            label: item.label,
                          };
                        })}
                        value={{
                          value: countriesList.find((item) => item.phone_code === profileInfo.phone_number_code)?.phone_code,
                          label: countriesList.find((item) => item.phone_code === profileInfo.phone_number_code)?.label,
                        }}
                        onChange={(val) => {
                          if (val?.value) {
                            setprofileInfo((prev) => {
                              let update = JSON.parse(JSON.stringify(prev));
                              update.phone_number_code = val?.value;
                              return update;
                            });

                            if (val?.value.length > 0) {
                              seterrors((prev) => {
                                prev.phone_number_code = "";
                                return prev;
                              });
                            }
                          }
                        }}
                      />
                      <input
                        type="text"
                        name="phone_number"
                        value={profileInfo.phone_number}
                        placeholder="Phone No"
                        onKeyDown={(e) => {
                          // Allow digits only if Shift key is not pressed
                          if (digits.includes(e.key) && !e.shiftKey) {
                            return;
                          }

                          // Allow specific control keys
                          if (allowedKeys.includes(e.key)) {
                            return;
                          }

                          // If none of the above conditions are met, prevent the default action
                          e.preventDefault();
                        }}
                        onChange={(e) => {
                          handleChange(e);

                          if (e.target.value.length > 0) {
                            seterrors((prev) => {
                              prev.phone_number = "";
                              return prev;
                            });
                          }
                        }}
                      />
                    </div>
                    <span className="text-danger text-errr">{errors.phone_number_code || errors.phone_number}</span>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-5">
                <div className="profile-details">
                  <div className="form-group">
                    <label>Alternate phone no.</label>
                    <div className="phn-area">
                      {/* <select
                        className="form-select"
                        aria-label="Default select example"
                        name="alternate_phone_code"
                        value={profileInfo.alternate_phone_code}
                        onChange={handleChange}
                      >
                        <option key={0} value={""}>
                          Select
                        </option>
                        {countriesList.map((item, index) => {
                          return (
                            <option key={index + 1} value={item.phone_code}>
                              {item.label}
                            </option>
                          );
                        })}
                      </select> */}
                      <Select
                        styles={customStyles}
                        name="alternate_phone_code"
                        id="alternate_phone_code"
                        classNamePrefix="select"
                        options={countriesList.map((item) => {
                          return { value: item.label, label: item.label };
                        })}
                        placeholder={!profileInfo?.alternate_phone_code && "Select"}
                        value={
                          profileInfo?.alternate_phone_code && {
                            value: profileInfo.alternate_phone_code,
                            label: profileInfo.alternate_phone_code,
                          }
                        }
                        // value={{
                        //   value: countriesList.find((item) => item.phone_code === profileInfo.alternate_phone_code)?.phone_code,
                        //   label: countriesList.find((item) => item.phone_code === profileInfo.phone_number_code)?.label,
                        // }}
                        onChange={(val) => {
                          setprofileInfo((prev) => {
                            let update = JSON.parse(JSON.stringify(prev));
                            if (val) {
                              update.alternate_phone_code = val.value;
                            }
                            console.log("val=====>", update);
                            return update;
                          });
                        }}
                      />
                      <input
                        type="text"
                        name="alternate_phone_number"
                        value={profileInfo.alternate_phone_number}
                        placeholder="Alternate Phone No"
                        onKeyDown={(e) => {
                          // Allow digits only if Shift key is not pressed
                          if (digits.includes(e.key) && !e.shiftKey) {
                            return;
                          }

                          // Allow specific control keys
                          if (allowedKeys.includes(e.key)) {
                            return;
                          }

                          // If none of the above conditions are met, prevent the default action
                          e.preventDefault();
                        }}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="btn-group" disabled={isLoading} onClick={handleSubmit}>
              <a className="custombtn btn-1 hover-filled-slide-down">
                <span>Update profile</span>
              </a>
            </div> */}
            <div className={`btn-group ${isLoading ? "disabled" : ""}`} onClick={!isLoading ? handleSubmit : undefined} style={{ pointerEvents: isLoading ? "none" : "auto", opacity: isLoading ? 0.6 : 1 }}>
              <a className="custombtn btn-1 hover-filled-slide-down">
                <span>{isLoading ? "Loading..." : "Update profile"}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* wrapper area end */}
    </>
  );
};

export default Profile;
