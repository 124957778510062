import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

// Helpers
import { ApiHelperFunction } from "../../helpers/api_helpers";
import { toast } from "react-hot-toast";

// Typeguards
import { isApiErrorResponse } from "../../helpers/typeguards";

// Models
import { ApiFuncArgProps } from "../../models/apiFuncHelpers";
import { SayNoToAiPageDataModel } from "../../models/views/pages";

interface SamplePageInitialState {
  noAiPageStatus: string;
  noAiPageData: SayNoToAiPageDataModel;
  noAiPageIsLoading: boolean;
  noAiPageIsSuccess: boolean;
  noAiPageIsError: boolean;
}

const initialState: SamplePageInitialState = {
  noAiPageStatus: "idle",
  noAiPageData: {
    sec_1_h1: "",
    sec_1_h2: "",
    sec_2_h1: "",
    sec_2_description: "",
    sec_3_h1: "",
    sec_3_description: "",
    sec_3_main_body_text: "",
    sec_4_h1: "",
    createdAt: "",
    updatedAt: "",
    publishedAt: "",
    sec_4_it_1: [],
  },
  noAiPageIsError: false,
  noAiPageIsSuccess: false,
  noAiPageIsLoading: false,
};

export const getNoAiPage = createAsyncThunk("get_no_ai_page", async () => {
  try {
    let response = await ApiHelperFunction({
      urlPath: "/say-no-to-ai?populate=deep",
      method: "GET",
    } as ApiFuncArgProps);

    if (isApiErrorResponse(response)) {
      toast.error(response.error.message);
    } else {
      return response.data;
    }
  } catch (error: any) {
    console.log("Failed to fetch no AI page due to ", error.message);
  }
});

export const noAiPageSlice = createSlice({
  name: "no_ai_page",
  initialState,
  reducers: {
    clearNoAiPageState: (state) => {
      state.noAiPageStatus = "idle";
      state.noAiPageData = {
        sec_1_h1: "",
        sec_1_h2: "",
        sec_2_h1: "",
        sec_2_description: "",
        sec_3_h1: "",
        sec_3_description: "",
        sec_3_main_body_text: "",
        sec_4_h1: "",
        createdAt: "",
        updatedAt: "",
        publishedAt: "",
        sec_4_it_1: [],
      };
      state.noAiPageIsLoading = false;
      state.noAiPageIsSuccess = false;
      state.noAiPageIsError = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNoAiPage.pending, (state) => {
        state.noAiPageStatus = "loading";
        state.noAiPageIsLoading = true;
      })
      .addCase(getNoAiPage.fulfilled, (state, { payload }) => {
        state.noAiPageStatus = "success";
        state.noAiPageIsSuccess = true;
        state.noAiPageData = payload?.data?.attributes;
      })
      .addCase(getNoAiPage.rejected, (state) => {
        state.noAiPageStatus = "failed";
        state.noAiPageIsError = true;
      });
  },
});

export const { clearNoAiPageState } = noAiPageSlice.actions;
