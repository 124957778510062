import React, { useEffect, useState, useRef, useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import parsePhoneNumberFromString, { isValidPhoneNumber } from "libphonenumber-js";
// Utils
import { toast } from "react-hot-toast";
import { commafy } from "../../../../utils";
import Swal from "sweetalert2";
import {
  getOnlyFullDate,
  // formatTimeToAMPM,
  getDateAndTimeFromNow,
} from "../../../../utils";

// Helper
import { isApiErrorResponse } from "../../../../helpers/typeguards";
import dayjs, { Dayjs } from "dayjs";

// Constants
import { digits, allowedKeys, countriesList } from "../../../../constants/data/data-constants";

// Models
import { ApiFuncArgProps, ApiFuncArgPropsPromo } from "../../../../models/apiFuncHelpers";
import { ServiceInfo } from "../../../../models/constants/data/dataModels";
// API
import { ApiHelperFunction, FetchPromoCode } from "../../../../helpers/api_helpers";

// Redux actions
import { getServiceCourseCategories } from "../../../../redux/slices/CategorySlices";

// Components
import PhoneInput from "react-phone-number-input";
import Select from "react-select";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import LoginModal from "../../modals/LoginModal";

import "react-phone-number-input/style.css";

interface SubmitAssigmentValues {
  email: string;
  phone_number_code: string;
  phone: string;
  type: string;
  subject: string;
  service: string;
  country: string;
  price_quote: string;
  no_of_pages: number;
  end_by_date: string;
  end_by_time: string;
  message: string;
  is_accpted: boolean;
  order_status: string;
}
interface Country {
  label: string;
  value: string;
  abbrev: string;
  currency_symbol: string;
  phone_code: string;
}

let submitAssignmentInitVal: SubmitAssigmentValues = {
  email: "",
  phone_number_code: "+44",
  phone: "",
  type: "Writing",
  subject: "",
  country: "",
  service: "0",
  no_of_pages: 0,
  end_by_date: new Date().toLocaleDateString("en-GB").replace(/\//g, "-"),
  end_by_time: new Date().toLocaleTimeString("en-GB", { hour: "2-digit", minute: "2-digit" }),
  message: "",
  price_quote: "",
  order_status: "pending",
  is_accpted: true,
};

let submitAssignmentCreateErrors = {
  email: "",
  phone: "",
  subject: "",
  service: "",
  country: "",
  no_of_pages: "",
  end_by_date: "",
  end_by_time: "",
  price_quote: "",
  message: "",
  attachment: "",
};

var emailReg = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

var relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

const MAX_FILE_SIZE_MB = 5;
const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024; // 5MB in bytes

const SubmitAssignmentForm = () => {
  const dispatch = useAppDispatch();
  const isLoggedIn = localStorage.getItem("logged_in");
  const token = localStorage.getItem("Authorization");
  const [submitData, setsubmitData] = useState(submitAssignmentInitVal);

  const [orderDeadline, setorderDeadline] = useState<string | undefined>("");
  const [endDate, setendDate] = React.useState<Dayjs | null>(dayjs(getOnlyFullDate(new Date().toDateString())));
  const [endByTime, setendByTime] = React.useState<Dayjs | null>(dayjs(new Date()));
  const [submitDataErrors, setsubmitDataErrors] = useState(submitAssignmentCreateErrors);
  const [showInput, setShowInput] = useState(false);
  const [selectedService, setselectedService] = useState({} as ServiceInfo | any);
  const [numberOfPages, setnumberOfPages] = useState<any>("");
  const [countryCurrency, setcountryCurrency] = useState<string | undefined>("");
  const [totalPrice, settotalPrice] = useState("");

  const handleToggleInput = () => {
    setShowInput(!showInput);
  };
  // const datetimeInputRef = useRef(null) as React.LegacyRef<HTMLInputElement>;
  const fileRef = useRef<HTMLInputElement>(null);
  const [attachmentFiles, setattachmentFiles] = useState<Array<File> | []>([]);
  const [agreedTerms, setagreedTerms] = useState(false);
  // const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const [promoCode, setPromoCode] = useState<string>("");
  // Redux stores
  const { serviceCourseList } = useAppSelector((state) => state.categorySlicesReducer);

  console.log("countriesList=====>", countriesList);
  const fetchProfile = async () => {
    try {
      let res = await ApiHelperFunction({
        urlPath: "users/me?populate=deep",
        method: "GET",
        role: "privileged",
      } as ApiFuncArgProps);

      if (isApiErrorResponse(res)) {
        toast.error("Failed to fetch profile information due to " + res.error.message, { duration: 10000 });
      } else if (res.data) {
        // console.log("profile info", res.data);
        setsubmitData((prev) => {
          let update = JSON.parse(JSON.stringify(prev));
          update.email = res.data?.email;
          update.phone_number_code = res.data?.phone_number_code?.slice(2);
          update.phone = res.data?.phone_number;
          return update;
        });
      } else {
        console.log("Unexpected response:", res);
      }
    } catch (error: any) {
      console.log(error);
      toast.error(error.message, { duration: 10000 });
    }
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    if (isLoggedIn === "yes" && token) {
      fetchProfile();
    } else {
      setsubmitData(submitAssignmentInitVal);
    }
    if (serviceCourseList.data.length === 0) {
      dispatch(getServiceCourseCategories());
    }
    // console.log("isLoggedIn=====> 123", token);
  }, [isLoggedIn, token]);

  const today = new Date();
  today.setHours(0, 0, 0, 0); // Set time to beginning of the day

  const handleSubmitDataChange: React.ChangeEventHandler<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement> = (event) => {
    let { name, value = "" } = event.target || {};

    if (name === "no_of_pages") {
      const numericValue = value.replace(/[^0-9]/g, "");
      value = numericValue === "" ? "" : (+numericValue || "").toString();
    }
    setsubmitData({ ...submitData, [name]: value });
  };

  const handleSubmitFileChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    if (!event?.target?.files?.length) return;

    const files = Array.from(event.target.files);
    const updatedFiles = [...attachmentFiles];
    let hasError = false;

    for (const file of files) {
      if (file.size > MAX_FILE_SIZE_BYTES) {
        setsubmitDataErrors({
          ...submitAssignmentCreateErrors,
          attachment: `Uploaded file can't be more than ${MAX_FILE_SIZE_MB}MB`,
        });
        hasError = true;
        break;
      }

      const isDuplicate = attachmentFiles.some((existingFile) => existingFile.name === file.name);
      if (!isDuplicate) {
        updatedFiles.push(file);
      }
    }

    if (!hasError) {
      setattachmentFiles(updatedFiles);
      setsubmitDataErrors({ ...submitAssignmentCreateErrors, attachment: "" });
    }
    event.target.value = "";
  };

  const handleRemoveFile = (index: number) => {
    let updated = [...attachmentFiles];
    updated.splice(index, 1);
    setattachmentFiles(updated);

    // If all files are removed, reset the file input value
    if (updated.length === 0 && fileRef.current) {
      fileRef.current.value = "";
    }
  };

  // console.log("submitData", submitData);
  function validateTime(selectedDate: string, selectedTime: string): boolean {
    const now = new Date();
    const currentHours = now.getHours();
    const currentMinutes = now.getMinutes();
    const [selectedHours, selectedMinutes] = selectedTime.split(":").map(Number);

    // Get today's date in "DD-MM-YYYY" format
    const todayDate = dayjs(new Date()).format("DD-MM-YYYY");

    // Validate the time against the current time
    const net_currentMinutes = 60 * currentHours + currentMinutes;
    const net_selectedMinutes = 60 * selectedHours + selectedMinutes;

    return todayDate === selectedDate && net_selectedMinutes <= net_currentMinutes + 60 ? false : true;
  }

  const handleSubmitValidation = () => {
    const { email, phone, subject, service, no_of_pages, end_by_date, end_by_time, message, price_quote } = submitData;

    if (!validateTime(end_by_date, end_by_time)) {
      setsubmitDataErrors({
        ...submitAssignmentCreateErrors,
        end_by_date: "Selected time is too early. Please select a time at least one hour from now.",
      });
      return false;
    }
    if (email === "") {
      setsubmitDataErrors({
        ...submitAssignmentCreateErrors,
        email: "Email cannot be empty.",
      });
      return false;
    }
    if (price_quote === "0") {
      setsubmitDataErrors({
        ...submitAssignmentCreateErrors,
        price_quote: "Please select a country and service to get the price quote to place the order.",
      });
      return false;
    }
    if (!emailReg.test(email)) {
      setsubmitDataErrors({
        ...submitAssignmentCreateErrors,
        email: "Please put email in correct format. E.g., abc@xxx.com",
      });
      return false;
    }
    if (phone === "") {
      setsubmitDataErrors({
        ...submitAssignmentCreateErrors,
        phone: "Phone number cannot be empty.",
      });
      return false;
    }

    if (subject === "") {
      setsubmitDataErrors({
        ...submitAssignmentCreateErrors,
        subject: "Subject cannot be empty.",
      });
      return false;
    }
    if (service === "0") {
      setsubmitDataErrors({
        ...submitAssignmentCreateErrors,
        service: "Service cannot be empty.",
      });
      return false;
    }
    if (no_of_pages === 0) {
      setsubmitDataErrors({
        ...submitAssignmentCreateErrors,
        no_of_pages: "Atleast 1 page is required.",
      });
      return false;
    }
    if (end_by_date === "") {
      setsubmitDataErrors({
        ...submitAssignmentCreateErrors,
        end_by_date: "End by date is required.",
      });
      return false;
    }
    if (new Date(end_by_date).getDate() < new Date().getDate()) {
      setsubmitDataErrors({
        ...submitAssignmentCreateErrors,
        end_by_date: "End by date cannot be before the current date.",
      });
      return false;
    }
    if (end_by_time === "") {
      setsubmitDataErrors({
        ...submitAssignmentCreateErrors,
        end_by_time: "End by time is required.",
      });
      return false;
    }

    if (message.length < 5) {
      setsubmitDataErrors({
        ...submitAssignmentCreateErrors,
        message: "Atleast 5 characters of message is required.",
      });
      return false;
    }
    if (submitDataErrors.attachment !== "") {
      return false;
    }
    if (attachmentFiles.length === 0) {
      setsubmitDataErrors({
        ...submitAssignmentCreateErrors,
        attachment: "Please add an attachment",
      });
      return false;
    }

    return true;
  };

  const handleAssigmentSubmit: React.MouseEventHandler<HTMLAnchorElement> = async (event) => {
    event.preventDefault();

    // if (isLoggedIn === "yes" && token) {
    if (handleSubmitValidation()) {
      if (agreedTerms) {
        try {
          const DATA = new FormData();

          for (let i: number = 0; i < attachmentFiles.length; i++) {
            DATA.append("files", attachmentFiles[i]);
          }

          const imgUpRes = await ApiHelperFunction({
            urlPath: "/upload",
            method: "POST",
            data: DATA,
            role: "privileged",
            contentType: "form-data",
          } as ApiFuncArgProps);

          if (isApiErrorResponse(imgUpRes)) {
            // toast.error(imgUpRes.error.message);
            Swal.fire("Error", imgUpRes.error.message, "error");
          } else if (imgUpRes.data) {
            submitData.end_by_date = dayjs(submitData.end_by_date, "DD-MM-YYYY").format("YYYY-MM-DD");
            let bodyData = {
              ...submitData,
              no_of_pages: Number(submitData.no_of_pages),
              attachment: imgUpRes.data,
              promo_code: promoCode,
            };
            // console.log("submitData======>", submitData);
            let response = await ApiHelperFunction({
              urlPath: "/new-assignment-form-submissions",
              method: "POST",
              data: {
                data: bodyData,
              },
              role: "privileged",
            } as ApiFuncArgProps);

            if (isApiErrorResponse(response)) {
              // toast.error(response.error.message);
              Swal.fire("Error", response.error.message, "error");
            } else if (response.data) {
              if (token) {
                const { email, phone, phone_number_code } = submitData;
                submitAssignmentInitVal.email = email;
                submitAssignmentInitVal.phone = phone;
                submitAssignmentInitVal.phone_number_code = phone_number_code;
              } else {
                // const { email, phone } = submitData;
                submitAssignmentInitVal.email = "";
                submitAssignmentInitVal.phone = "";
                submitAssignmentInitVal.phone_number_code = "";
                submitAssignmentInitVal.no_of_pages = 0;
              }
              // console.log("submitAssignmentInitVal=====>", token);

              setendDate((prevCurrency: any) => {
                return dayjs(getOnlyFullDate(new Date().toDateString()));
              });
              setorderDeadline((prevCurrency: any) => {
                return "";
              });

              setendByTime((prevCurrency: any) => {
                return dayjs(new Date());
                // return dayjs(getOnlyFullDate(new Date().toDateString()));
              });

              setcountryCurrency((prevCurrency: any) => {
                return "";
              });
              setagreedTerms((prevCurrency: any) => {
                return false;
              });

              setselectedService((prevSelectedService: any) => {
                return {};
              });
              settotalPrice((prevTotalPrice: any) => {
                return "0";
              });
              setnumberOfPages((prevTotalPrice: any) => {
                return "";
              });
              //               settotalPrice
              // submitData.no_of_pages

              setsubmitData((prevSubmitData: any) => {
                return submitAssignmentInitVal;
              });

              setsubmitDataErrors(submitAssignmentCreateErrors);
              setattachmentFiles([]);

              Swal.fire({
                icon: "success",
                title: "Success",
                html: `
                  Your Order has been placed successfully.
                  <p style="margin-bottom: 10px; color: #545454;">
                     A 50% deposit of ${countryCurrency}${(Number(submitData.price_quote || 0) / 2).toFixed(2)} is required to proceed with your order. <br /> Would you like to complete the payment now?
                  </p>
                `,
                confirmButtonText: "Pay Now!",
                confirmButtonColor: "#3085d6",
                showCancelButton: false,

                cancelButtonColor: "#d33",
                background: "#f4f7fc",
                padding: "20px",
                customClass: {
                  popup: "custom-popup",
                  title: "custom-title",
                },
                backdrop: true,
                showClass: {
                  popup: "animate__animated animate__fadeInDown",
                },
                hideClass: {
                  popup: "animate__animated animate__fadeOutUp",
                },
                didOpen: () => {
                  console.log("SweetAlert opened!");
                },
              }).then((result) => {
                if (result.isConfirmed) {
                  window.location.href = "/payment";
                }
              });
            } else {
              console.log("Unexpected response:", response);
            }
          } else {
            console.log("Unexpected response:", imgUpRes);
          }
        } catch (error: any) {
          // toast.error(error.message);
          Swal.fire("Error", error.message, "error");
        }
      } else {
        Swal.fire("Error", "You have not agreed to the terms and conditions.", "error");
      }
    }
  };

  const handleCountryChange = (selectedOption: Country | null) => {
    if (selectedOption) {
      // Set the currency symbol of the selected country
      setcountryCurrency(selectedOption.currency_symbol);
      console.log("isLoggedIn=====>  totalPrice", totalPrice);
      setsubmitData({ ...submitData, country: selectedOption.label, price_quote: totalPrice || "0" });
      // setsubmitData((prev) => {
      //   let update = JSON.parse(JSON.stringify(prev));
      //   update.country = selectedOption.label;
      //   update.price_quote = update.price_quote || "0";
      //   console.log("value=====>", update);
      //   return update;
      // });
    }
  };

  const handleApplyPromoCode = async () => {
    // setIsSubmitting(true);
    try {
      // console.log("promoCode----->", promoCode);
      let response = await FetchPromoCode({
        urlPath: `promo-codes?filters[promo_code][$eq]=${promoCode}`,
        method: "GET",
        role: "privileged",
      } as ApiFuncArgPropsPromo);
      if (response?.data?.length === 0) {
        Swal.fire("Error", "Promocode Not Found", "error");
        setPromoCode("");
      } else {
        Swal.fire("Success", "Promocode Applied", "success");
      }
      // setPromoCode("");
    } catch (error) {
      console.error("Error applying promo code:", error);
    } finally {
      // setIsSubmitting(false);
    }
  };

  const handlePromoCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPromoCode(e.target.value);
  };

  const calculateTotalPrice: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      let pages: number = Number(event);
      let costPerWord: number;
      const country = countriesList.find((c) => c.currency_symbol === countryCurrency);
      switch (country?.abbrev) {
        case "CAN":
          costPerWord = Number(selectedService?.attributes?.CAD_value);
          break;
        case "AUS":
          costPerWord = Number(selectedService?.attributes?.AUD_value);
          break;
        case "UK":
          costPerWord = Number(selectedService?.attributes?.GBP_value);
          break;
        case "NZ":
          costPerWord = Number(selectedService?.attributes?.NZD_value);
          break;
        case "EUR":
          costPerWord = Number(selectedService?.attributes?.EUR_value);
          break;
        case "IND":
          costPerWord = Number(selectedService?.attributes?.INR_value);
          break;
        default:
          costPerWord = Number(selectedService?.attributes?.CAD_value);
      }
      if (countryCurrency !== "" && String(numberOfPages) !== "" && !isNaN(costPerWord)) {
        // let costPerWord: number = Number(selectedService?.attributes?.CAD_value);
        let totalCost = 250 * pages * costPerWord;
        console.log("costPerWord=====>", costPerWord, pages, totalCost, commafy(Math.round(totalCost)));
        settotalPrice(commafy(Math.round(totalCost)));
        setsubmitData((prev) => {
          let update = JSON.parse(JSON.stringify(prev));
          update.price_quote = totalCost || "0";
          return update;
        });
      } else {
        settotalPrice("0");
      }
    },
    [numberOfPages, countryCurrency, selectedService]
  );
  useEffect(() => {
    calculateTotalPrice(numberOfPages);
  }, [numberOfPages, calculateTotalPrice, countryCurrency]);

  const handleServiceChange = (value: any) => {
    // console.log("value=====>", value);

    if (value !== undefined) {
      let service = serviceCourseList.data.find((item) => item.attributes.name === value?.value);
      // console.log("value=====> service", service, value?.value, serviceCourseList);

      if (service) {
        setselectedService({
          ...service,
          label: service?.attributes?.name,
          name: service?.attributes?.name,
        });
        let totalCost: number;
        if (countryCurrency !== "" && numberOfPages !== "") {
          let costPerWord: number = Number(service?.attributes?.CAD_value);
          totalCost = 250 * Number(numberOfPages) * costPerWord;
          settotalPrice(commafy(Math.round(totalCost)));
        }

        setsubmitData((prev) => {
          let update = JSON.parse(JSON.stringify(prev));
          update.service = value?.value;
          update.price_quote = totalCost || "0";
          console.log("value=====>", update);
          return update;
        });
      } else {
        console.error("Service not found");
      }
      setsubmitDataErrors((prev) => {
        prev.price_quote = "";
        return prev;
      });
    }
  };

  const CustomPickerIcon = () => (
    <img
      src="/assets/images/select-down-arrow.svg"
      alt="select-timepicker-dropdown-arrow"
      style={{ width: 12, height: 20 }} // Optional: Add styling if needed
    />
  );
  const validatePhoneNumber = (phone: string, countryCode: any) => {
    let phoneNumber = parsePhoneNumberFromString(`${countryCode}${phone}`);

    return isValidPhoneNumber(`${countryCode}${phone}`, phoneNumber?.country);
  };
  return (
    <>
      <form>
        <div className="row g-3">
          <div className="col-xl-12">
            <div className="form-group-radio">
              <input
                className="checkbox-tools"
                type="radio"
                name="tools"
                id="tool-1"
                value={"Writing"}
                checked={submitData.type === "Writing"}
                onChange={(e) =>
                  setsubmitData((prev) => {
                    let update = JSON.parse(JSON.stringify(prev));
                    update.type = e.target.value;
                    return update;
                  })
                }
              />
              <label className="for-checkbox-tools" htmlFor="tool-1">
                Writing
              </label>
              <input
                className="checkbox-tools"
                type="radio"
                name="type"
                id="tool-2"
                value={"Rewriting"}
                checked={submitData.type === "Rewriting"}
                onChange={(e) =>
                  setsubmitData((prev) => {
                    let update = JSON.parse(JSON.stringify(prev));
                    update.type = e.target.value;
                    return update;
                  })
                }
              />
              <label className="for-checkbox-tools" htmlFor="tool-2">
                Rewriting
              </label>
              <input
                className="checkbox-tools"
                type="radio"
                name="type"
                id="tool-3"
                value={"Editing"}
                checked={submitData.type === "Editing"}
                onChange={(e) =>
                  setsubmitData((prev) => {
                    let update = JSON.parse(JSON.stringify(prev));
                    update.type = e.target.value;
                    return update;
                  })
                }
              />
              <label className="for-checkbox-tools" htmlFor="tool-3">
                Editing
              </label>
              <div className="assignment-type-info-wrapper">
                <img src="/assets/images/info-ico.svg" alt="info-ico" className="question loading" title="Info" />
                <div className="assignment-type-info-tooltip">
                  <p>
                    <strong>Writing:</strong> Get your essay and assignment written from scratch by PhD expert{" "}
                  </p>
                  <p>
                    <strong>Rewriting:</strong> Paraphrase or rewrite your friend's essay with similar meaning at reduced cost
                  </p>
                  <p>
                    <strong>Editing:</strong> Proofread your work by experts and improve grade at Lowest cost
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-12 col-xl-6">
            <div className="form-group">
              <div className="form-group-icon">
                <img src="/assets/images/email-ico.svg" alt="email-ico" />
              </div>
              <div className="form-group-form">
                <input
                  disabled={isLoggedIn === "yes"}
                  type="text"
                  name="email"
                  value={submitData.email}
                  placeholder={isLoggedIn === "yes" ? undefined : "Email"}
                  onChange={(e) => {
                    if (isLoggedIn !== "yes") {
                      handleSubmitDataChange(e);

                      if (e.target.value.length > 0) {
                        setsubmitDataErrors((prev) => {
                          prev.email = "";
                          return prev;
                        });
                      }
                    }
                  }}
                />
              </div>
            </div>
            <span className="text-errr text-errors">{submitDataErrors.email}</span>
          </div>
          <div className="col-md-6 col-lg-12 col-xl-6">
            <div className="form-group">
              {/* <div className="form-group-icon">
                <img src="/assets/images/phone-ico.svg" alt="phone-ico" />
              </div> */}
              <div className="form-group-form">
                <PhoneInput
                  international
                  className="phninpt-sd"
                  defaultCountry="GB"
                  value={submitData.phone_number_code}
                  onChange={(val) =>
                    setsubmitData((prev) => {
                      const newPhoneCode = val || "";
                      // Only validate if there's a phone number
                      if (submitData.phone) {
                        if (validatePhoneNumber(submitData.phone, newPhoneCode)) {
                          setsubmitDataErrors((prev) => ({
                            ...prev,
                            phone: "",
                            phone_number_code: "",
                          }));
                        } else {
                          setsubmitDataErrors((prev) => ({
                            ...prev,
                            phone: "Please enter a valid phone number for the selected country",
                          }));
                        }
                      }
                      return { ...prev, phone_number_code: newPhoneCode };
                    })
                  }
                />
                <input
                  type="text"
                  name="phone"
                  value={submitData.phone}
                  placeholder="Phone"
                  onKeyDown={(e) => {
                    if (digits.includes(e.key) && !e.shiftKey) {
                      return;
                    }
                    if (allowedKeys.includes(e.key)) {
                      return;
                    }
                    e.preventDefault();
                  }}
                  onChange={(e) => {
                    const newPhone = e.target.value;
                    handleSubmitDataChange(e);

                    if (submitData.phone_number_code) {
                      if (validatePhoneNumber(newPhone, submitData.phone_number_code)) {
                        setsubmitDataErrors((prev) => ({
                          ...prev,
                          phone: "",
                          phone_number_code: "",
                        }));
                      } else {
                        setsubmitDataErrors((prev) => ({
                          ...prev,
                          phone: "Please enter a valid phone number for the selected country",
                        }));
                      }
                    }
                  }}
                />
              </div>
            </div>
            <span className="text-errr text-errors">{submitDataErrors.phone}</span>
          </div>
          <div className="col-md-12 col-lg-12 col-xl-12">
            <div className="form-group">
              <div className="form-group-icon">
                <img src="/assets/images/subject-ico.svg" alt="subject-ico" />
              </div>
              <div className="form-group-form">
                <input
                  type="text"
                  name="subject"
                  value={submitData.subject}
                  placeholder="Subject/Course Code"
                  onChange={(e) => {
                    handleSubmitDataChange(e);

                    if (e.target.value.length > 0) {
                      setsubmitDataErrors((prev) => {
                        prev.subject = "";
                        return prev;
                      });
                    }
                  }}
                />
              </div>
            </div>
            <span className="text-errr text-errors">{submitDataErrors.subject}</span>
          </div>

          {/* .................................................................. */}
          <div className="col-12 col-sm-6 col-md-6 col-lg-12 col-xl-6">
            <div className="form-group">
              <div className="form-group-icon">
                <img src="/assets/images/Vector.svg" alt="subject-ico" />
              </div>
              <div className="form-group-form">
                <Select
                  name="service"
                  id="service"
                  classNamePrefix="select"
                  options={serviceCourseList.data.map((item) => {
                    // console.log("serviceCourseList====>", serviceCourseList.data[0]["attributes"]["name"], selectedService);
                    return {
                      value: item.attributes.name,
                      label: item.attributes.name,
                    };
                  })}
                  value={Object.values(selectedService).length > 0 ? selectedService : null}
                  placeholder="Select A Service"
                  onChange={(e) => handleServiceChange(e)}
                />
              </div>
            </div>
            <span className="text-errr text-errors">{submitDataErrors.service}</span>
          </div>

          <div className="col-sm-6 col-md-6 col-lg-12 col-xl-6">
            <div className="form-group">
              <div className="form-group-icon">
                <img src="/assets/images/Vector.svg" alt="subject-ico" />
              </div>

              <div className="form-group-form">
                <Select
                  name="service"
                  id="service"
                  classNamePrefix="select"
                  options={countriesList} // Use the full country objects as options
                  value={countryCurrency ? countriesList.find((item) => item.currency_symbol === countryCurrency) : null} // Find the country based on currency_symbol
                  placeholder="Select A Country"
                  onChange={handleCountryChange} // Handle full country object change
                />
              </div>
            </div>
            <span className="text-errr text-errors">{submitDataErrors.service}</span>
          </div>

          <div className="col-12">
            {/* <label className="labelform">No of Pages</label> */}
            <div className="form-group form-group-number">
              <div className="form-group-icon">
                <img src="/assets/images/page-ico.svg" alt="page-ico" />
              </div>
              <div className="form-group-form">
                <span>Page&nbsp;</span>
                <input
                  className="numberstyle"
                  type="number"
                  min="1"
                  step="1"
                  placeholder="Pages"
                  name="no_of_pages"
                  value={submitData.no_of_pages}
                  onKeyDown={(e) => {
                    // Allow digits, allowedKeys, ArrowUp, and ArrowDown
                    if (digits.includes(e.key) || allowedKeys.includes(e.key) || e.key === "ArrowUp" || e.key === "ArrowDown") {
                      return;
                    }

                    // Prevent any other key input
                    e.preventDefault();

                    setnumberOfPages((e.target as HTMLInputElement).value);

                    if ((e.target as HTMLInputElement).value.length > 0) {
                      setsubmitDataErrors((prev) => {
                        prev.no_of_pages = "";
                        prev.price_quote = "";
                        return prev;
                      });
                    }
                  }}
                  onChange={(e) => {
                    handleSubmitDataChange(e);
                    setnumberOfPages(e.target.value);

                    if (e.target.value.length > 0) {
                      setsubmitDataErrors((prev) => {
                        prev.no_of_pages = "";
                        return prev;
                      });
                    }
                  }}
                />
                <div className="calculation">
                  <span>
                    {" "}
                    {countryCurrency}
                    {totalPrice}{" "}
                  </span>
                  <span>= {250 * Number(submitData.no_of_pages)} words</span>
                </div>
              </div>
            </div>
            <span className="text-errr text-errors">{submitDataErrors.no_of_pages || submitDataErrors.price_quote}</span>
          </div>

          {/* ..................................................................... */}

          <div className="col-12">
            <div className="card deadline-card">
              <div className="card-header">
                <label className="deadline-label">Deadline</label>
              </div>
              <div className="card-body" style={{ padding: "unset" }}>
                <div className="row">
                  {/* Date Picker */}
                  <div className="col-sm-6  minimal-padding">
                    <div className="form-group date-form-group-sd" style={{ border: "unset !important" }}>
                      <div className="form-group-icon">
                        <img src="/assets/images/date-ico.svg" alt="date-ico" />
                      </div>
                      <div className="form-group-form">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={["DatePicker"]}>
                            <DatePicker
                              minDate={dayjs(getOnlyFullDate(new Date().toDateString()))}
                              value={endDate}
                              onChange={(newValue: any) => {
                                if (newValue) {
                                  setendDate(newValue);
                                  setsubmitData((prev) => {
                                    let update = JSON.parse(JSON.stringify(prev));
                                    update.end_by_date = dayjs(newValue).format("DD-MM-YYYY");
                                    // console.log("newValue=====>", update);
                                    setorderDeadline(getDateAndTimeFromNow(dayjs(newValue).format("DD-MM-YYYY"), submitData.end_by_time));
                                    return update;
                                  });
                                } else {
                                  console.error("Selected date is null");
                                }
                                console.log("submitData.end_by_date====>", submitData.end_by_date, submitData.end_by_time);
                              }}
                              format="DD-MM-YYYY"
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                      </div>
                    </div>
                    {/* <span className="text-errr text-errors">{submitDataErrors.end_by_date}</span> */}
                  </div>

                  <div className="col-sm-6  minimal-padding">
                    <div className="form-group date-form-group-sd" style={{ border: "unset !important" }}>
                      <div className="form-group-icon">
                        <img src="/assets/images/time-ico.svg" alt="time-ico" />
                      </div>
                      <div className="form-group-form">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={["TimePicker"]}>
                            <TimePicker
                              disabled={submitData.end_by_date === "" ? true : false}
                              value={endByTime}
                              slots={{
                                openPickerIcon: CustomPickerIcon,
                              }}
                              onChange={(newValue) => {
                                if (newValue) {
                                  const formattedTime = dayjs(newValue).format("HH:mm");
                                  dayjs.extend(utc);
                                  dayjs.extend(timezone);
                                  // const isoString = dayjs(newValue).toISOString();
                                  console.log("validation=======> 123", formattedTime);
                                  setendByTime(newValue);
                                  // setorderDeadline(formattedTime);
                                  if (submitDataErrors.end_by_date !== "") {
                                    setsubmitDataErrors({
                                      ...submitAssignmentCreateErrors,
                                      end_by_date: "",
                                    });
                                  }
                                  setorderDeadline(getDateAndTimeFromNow(submitData.end_by_date, formattedTime));
                                  setsubmitData((prev) => ({
                                    ...prev,
                                    end_by_time: formattedTime,
                                  }));
                                }
                              }}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <span className="text-errr text-errors">{submitDataErrors.end_by_date}</span>
            <span style={{ color: "white" }}>{orderDeadline}</span>
          </div>

          <div className="col-xl-12">
            {/* <div style={{ border: "solid red" }}> */}
            <div className="form-group form-group-textarea">
              <div className="form-group-icon">
                <img src="/assets/images/subject-ico.svg" alt="subject-ico" />
              </div>
              <div className="form-group-form">
                <textarea
                  name="message"
                  value={submitData.message}
                  placeholder="Write your Description"
                  onChange={(e) => {
                    handleSubmitDataChange(e);

                    if (e.target.value.length > 0) {
                      setsubmitDataErrors((prev) => {
                        prev.message = "";
                        return prev;
                      });
                    }
                  }}
                ></textarea>
              </div>
              <div className="file-input-area file-input-area-attachment">
                <div className="file-input">
                  <input type="file" name="file-input" id="file-input" className="file-input__input" ref={fileRef} multiple onChange={handleSubmitFileChange} />
                  <label className="file-input__label" htmlFor="file-input">
                    <img src="/assets/images/attach-blue-ico.svg" alt="attach-ico" />
                    <span style={{ color: "#0c4660" }}>Attach</span>
                  </label>
                </div>
                {attachmentFiles.length === 1 ? (
                  <button type="button" data-bs-toggle="modal" data-bs-target="#ViewAttachmentModal" className="uplded-file-sd">
                    1 Attachment
                  </button>
                ) : attachmentFiles.length > 1 ? (
                  <button type="button" data-bs-toggle="modal" data-bs-target="#ViewAttachmentModal" className="uplded-file-sd">
                    {attachmentFiles.length} Attachments
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
            <span className="text-errr text-errors">{submitDataErrors.message}</span>

            <div className="ean-bx">178 live experts available now!</div>

            <span className="text-errr text-errorsr">{submitDataErrors.attachment}</span>
            {/* </div> */}
          </div>
          <a href="#!" className="white-blue" onClick={handleToggleInput}>
            Have a Promo Code ?{" "}
          </a>
          {showInput && (
            <div style={{ marginTop: "10px" }}>
              <div className="col-md-12">
                <div className="form-group pe-0">
                  <div className="form-group-form">
                    <input type="text" name="promocode" value={promoCode} onChange={handlePromoCodeChange} placeholder="Enter Your Promo Code" />
                  </div>
                  <div className="apply-btn-area">
                    <a href="#!" className="custombtn btn-1 hover-filled-slide-down" onClick={handleApplyPromoCode}>
                      <span>Apply</span>
                    </a>
                  </div>
                </div>
                <span className="text-errr text-errors">{submitDataErrors.subject}</span>
              </div>
              {/* <input
            type="text"
            placeholder="Enter Promo Code"
            style={{ padding: "5px", marginRight: "5px" }}
          /> */}
              {/* <button style={{ padding: "5px 10px" }}>Apply</button> */}
            </div>
          )}
          <div className="col-xl-12">
            <div className="form-group-last">
              <div className="form-check">
                <input checked={agreedTerms} type="checkbox" className="form-check-input" id="exampleCheck1" onChange={() => (agreedTerms ? setagreedTerms(false) : setagreedTerms(true))} />
                <label className="form-check-label" htmlFor="exampleCheck1">
                  I accept the T&C, agree to receive offers & updates
                </label>
              </div>
              <div className="btn-group">
                {isLoggedIn === "yes" && token ? (
                  <a
                    // href="#!"
                    className="custombtn btn-1 hover-filled-slide-down"
                    onClick={handleAssigmentSubmit}
                  >
                    <span>Submit</span>
                  </a>
                ) : (
                  <a
                    // href="#!"
                    className="custombtn btn-1 hover-filled-slide-down"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal-sa"
                  >
                    <span>Submit</span>
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </form>

      {/* Attached Files modal */}

      <div className="modal fade view-attachment-modal" id="ViewAttachmentModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h4>Attached Files</h4>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
            </div>
            <div className="modal-body">
              {attachmentFiles.length > 0 ? (
                <div className="attachment-bx-area">
                  {attachmentFiles.map((item, index) => (
                    <div key={index} className="attachment-bx">
                      <div className="attachment-bx-left">
                        <i className="fa-solid fa-file"></i>
                        <span>{item.name}</span>
                      </div>
                      <button type="button" className="attachment-bx-right" onClick={() => handleRemoveFile(index)}>
                        <i className="fa-solid fa-trash-can"></i>
                      </button>
                    </div>
                  ))}
                </div>
              ) : (
                "No Attached File Found"
              )}
            </div>
            <div className="modal-footer">
              <a className="custombtn btn-1 hover-filled-slide-down w-100" data-bs-dismiss="modal">
                <span>done</span>
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Not Logged In Modal start */}
      <div className="modal fade modal-sa" id="exampleModal-sa" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <button type="button" className="btn-close btn-close-sa" data-bs-dismiss="modal" aria-label="Close" />
            <div className="modal-body text-center">
              <div className="wrng-ico">
                <i className="fa-solid fa-exclamation"></i>
              </div>
              <h3>You Are Not Logged In</h3>
              <p>Please Login To Submit New Assignment</p>
              <button type="button" className="custombtn btn-1 hover-filled-slide-down" data-bs-toggle="modal" data-bs-target="#exampleModal">
                <span>Proceed To Login</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Not Logged In Modal end */}

      {/* -- Login Modal Start -- */}
      <LoginModal />
      {/* -- Login Modal End -- */}
    </>
  );
};

export default SubmitAssignmentForm;
