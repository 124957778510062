import React, { useEffect, useState } from "react";

// API functions
import { FetchPaymentDetails } from "../../helpers/api_helpers";

// Components
import PageBanner from "../components/layout/pages/PageBanner";

const Payment = () => {
  const [paymentDetails, setPaymentDetails] = useState([]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    fetchPaymentDetails();
  }, []);

  async function fetchPaymentDetails() {
    try {
      const response: any = await FetchPaymentDetails({
        urlPath: "banks",
        method: "GET",
      });
      if (response && Array.isArray(response.data)) {
        setPaymentDetails(response.data);
      } else {
        console.error("Invalid response:", response);
      }
    } catch (error) {
      console.error("Error fetching payment details:", error);
    }
  }

  return (
    <>
      {/* Page Banner Area Start */}
      <PageBanner title={"Payment Information"} description={""} reviewSites={[]} reviewInfo={false} />
      {/* Page Banner Area End */}

      <section>
        <div className="container">
          <div className="row gx-3 gy-4">
            {paymentDetails.map((payment: any, index: number) => {
              const { Account_Name, Account_Number, Bank_Name, Bank_Branch_Name, Bank_Branch_Address, IFSC_Code_For_India, BAN_For_European_Transactions, SWIFT_BIC_Code_For_International, Routing_Number_For_US_Transfers } = payment.attributes;

              return (
                <div key={payment.id || index} className="col-lg-6 col-xl-6 col-xxl-4">
                  <div className="payment-dtls-bx">
                    <table>
                      <tbody>
                        <tr>
                          <th>Account Name</th>
                          <td>{Account_Name || "N/A"}</td>
                        </tr>
                        <tr>
                          <th>Account Number</th>
                          <td>{Account_Number || "N/A"}</td>
                        </tr>
                        <tr>
                          <th>Bank Name</th>
                          <td>{Bank_Name || "N/A"}</td>
                        </tr>
                        <tr>
                          <th>Bank Branch Name</th>
                          <td>{Bank_Branch_Name || "N/A"}</td>
                        </tr>
                        <tr>
                          <th>Bank Branch Address</th>
                          <td>{Bank_Branch_Address || "N/A"}</td>
                        </tr>
                        <tr>
                          <th>IFSC Code for India</th>
                          <td>{IFSC_Code_For_India || "N/A"}</td>
                        </tr>
                        <tr>
                          <th>BAN for European Transactions</th>
                          <td>{BAN_For_European_Transactions || "N/A"}</td>
                        </tr>
                        <tr>
                          <th>SWIFT BIC Code for International</th>
                          <td>{SWIFT_BIC_Code_For_International || "N/A"}</td>
                        </tr>
                        <tr>
                          <th>Routing Number for US Transfers</th>
                          <td>{Routing_Number_For_US_Transfers || "N/A"}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default Payment;
