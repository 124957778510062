import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

// Helpers
import { ApiHelperFunction } from "../../helpers/api_helpers";
import { toast } from "react-hot-toast";

// Typeguards
import { isApiErrorResponse } from "../../helpers/typeguards";

// Models
import { ApiFuncArgProps } from "../../models/apiFuncHelpers";
import { ReferencingPageData } from "../../models/views/pages";

interface SamplePageInitialState {
  referencingPageStatus: string;
  referencingPageData: ReferencingPageData;
  referencingPageIsLoading: boolean;
  referencingPageIsSuccess: boolean;
  referencingPageIsError: boolean;
}

const initialState: SamplePageInitialState = {
  referencingPageStatus: "idle",
  referencingPageData: {
    createdAt: "",
    updatedAt: "",
    publishedAt: "",
    sec_3_h1: "",
    sec_1_i_banner: [
      {
        id: 0,
        heading: "",
        subheading: "",
        inner_banner_review: [],
      },
    ],
    sec_2_it_1: [],
    sec_3_it_1: [],
  },
  referencingPageIsError: false,
  referencingPageIsSuccess: false,
  referencingPageIsLoading: false,
};

export const getReferencingPage = createAsyncThunk(
  "get_referencing_page",
  async () => {
    try {
      let response = await ApiHelperFunction({
        urlPath: "/referencing-page?populate=deep",
        method: "GET",
      } as ApiFuncArgProps);

      if (isApiErrorResponse(response)) {
        toast.error(response.error.message);
      } else {
        return response.data;
      }
    } catch (error: any) {
      console.log("Failed to fetch referencing page due to ", error.message);
    }
  }
);

export const referencingPageSlice = createSlice({
  name: "no_referencing_page",
  initialState,
  reducers: {
    clearReferencingPageState: (state) => {
      state.referencingPageStatus = "idle";
      state.referencingPageData = {
        createdAt: "",
        updatedAt: "",
        publishedAt: "",
        sec_3_h1: "",
        sec_1_i_banner: [
          {
            id: 0,
            heading: "",
            subheading: "",
            inner_banner_review: [],
          },
        ],
        sec_2_it_1: [],
        sec_3_it_1: [],
      };
      state.referencingPageIsLoading = false;
      state.referencingPageIsSuccess = false;
      state.referencingPageIsError = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getReferencingPage.pending, (state) => {
        state.referencingPageStatus = "loading";
        state.referencingPageIsLoading = true;
      })
      .addCase(getReferencingPage.fulfilled, (state, { payload }) => {
        state.referencingPageStatus = "success";
        state.referencingPageData = payload?.data?.attributes;
        state.referencingPageIsSuccess = true;
      })
      .addCase(getReferencingPage.rejected, (state) => {
        state.referencingPageStatus = "failed";
        state.referencingPageIsError = true;
      });
  },
});

export const { clearReferencingPageState } = referencingPageSlice.actions;
