// Models
import { PageBannerProps } from "../../../../models/views/components/pages";

// Components
import TopReviewWebsites from "./TopReviewWebsites";

const PageBanner = ({ className = "", title, description, ratingDisplay = false, rating, reviewInfo = true, reviewSites, requireClassName = true }: PageBannerProps) => {
  return (
    <div className={`inner-banner ${className === "" ? (requireClassName ? "experts-inner-banner" : "") : className}`}>
      <div className="container">
        {ratingDisplay && (
          <div className="row">
            <div className="col-lg-12">
              <div className="stars">
                <img src="/assets/images/stars.svg" alt="stars" />
                <span>{rating ? rating : "4.9"}</span>
              </div>
            </div>
          </div>
        )}
        {requireClassName ? (
          <div className="row align-items-center">
            {reviewInfo ? (
              <>
                <div className="col-lg-6">
                  <div className="inner-banner-hdng">
                    <h1>{title}</h1>
                    <p>{description}</p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <TopReviewWebsites data={reviewSites} />
                </div>
              </>
            ) : (
              <div className="col-lg-12">
                <div className="inner-banner-hdng">
                  <h1>{title}</h1>
                  <p>{description}</p>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className="inner-banner-hdng">
            <h1>{title}</h1>
            <p>{description}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default PageBanner;
