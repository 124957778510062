import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";

// Utils
import { toast } from "react-hot-toast";

// Constants
import { digits, allowedKeys } from "../../constants/data/data-constants";

// API functions
import { ApiHelperFunction } from "../../helpers/api_helpers";

// Redux actions
import { getContactUsPage } from "../../redux/slices/ContactUsPageSlice";

// Models
import { ApiFuncArgProps } from "../../models/apiFuncHelpers";

// Components
import PageBanner from "../components/layout/pages/PageBanner";

let initialValues = {
  name: "",
  email: "",
  phone_number: "",
  message: "",
};

let createErrors = {
  name: "",
  email: "",
  phone_number: "",
  message: "",
};

var emailReg = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

const ContactUs = () => {
  const dispatch = useAppDispatch();
  const [userQuery, setuserQuery] = useState(initialValues);
  const [errors, seterrors] = useState(createErrors);

  // Redux stores
  const { contactUsPageStatus, contactUsPageData } = useAppSelector((state) => state.contactUs);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    if (contactUsPageStatus !== "success") {
      dispatch(getContactUsPage());
    }
  }, []);

  const handleChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (event) => {
    setuserQuery({ ...userQuery, [event.target.name]: event.target.value });
  };

  const handleValidation = () => {
    const { name, email, phone_number, message } = userQuery;

    if (name === "") {
      seterrors({ ...createErrors, name: "Name is required." });
      return false;
    }
    if (email === "") {
      seterrors({ ...createErrors, email: "Email cannot be empty." });
      return false;
    }
    if (!emailReg.test(email)) {
      seterrors({
        ...createErrors,
        email: "Please put email in correct format. E.g., abc@xxx.com",
      });
      return false;
    }
    if (phone_number !== "" && (phone_number.length < 10 || phone_number.length > 15)) {
      seterrors({
        ...createErrors,
        phone_number: "Phone number must be between 10 to 15 integers.",
      });
      return false;
    }
    if (message.length < 5) {
      seterrors({ ...createErrors, message: "Message cannot be less than 5 characters." });
      return false;
    }

    return true;
  };

  const handleSubmit: React.MouseEventHandler<HTMLDivElement> = async (event) => {
    event.preventDefault();

    if (handleValidation()) {
      try {
        let bodyData = { data: userQuery };
        let response = await ApiHelperFunction({
          urlPath: "/contact-us-form-submissions",
          method: "POST",
          data: bodyData,
        } as ApiFuncArgProps);

        if (response.data?.data) {
          setuserQuery(initialValues);
          seterrors(createErrors);
          toast.success("Successfully submitted your query.", { duration: 1000 });
        } else {
          toast.error("Failed to submit your query.", { duration: 1000 });
        }
      } catch (error: any) {
        toast.error("Something went wrong. Please try again.", {
          duration: 1000,
        });
      }
    }
  };

  return (
    <>
      {/* page banner area start */}
      <PageBanner title={contactUsPageData.sec_1_i_banner[0].heading} description={contactUsPageData.sec_1_i_banner[0].subheading} reviewSites={contactUsPageData.sec_1_i_banner[0].inner_banner_review} />
      {/* page banner area end */}

      {/* wrapper area start */}
      <div className="wrapper">
        <section className="cntcus-sec">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="cntcus-form home-banner-form">
                  <div className="cntcus-hdng">
                    <h4>Submit Your Query</h4>
                  </div>
                  <form>
                    <div className="row gy-4">
                      <div className="col-md-12">
                        <div className="form-group">
                          <div className="form-group-icon">
                            <img src="/assets/images/name-ico.svg" alt="name-ico" />
                          </div>
                          <div className="form-group-form">
                            <input
                              type="text"
                              name="name"
                              value={userQuery.name}
                              placeholder="Name"
                              onChange={(e) => {
                                handleChange(e);

                                if (e.target.value.length > 0) {
                                  seterrors((prev) => {
                                    prev.name = "";
                                    return prev;
                                  });
                                }
                              }}
                            />
                          </div>
                        </div>
                        <span className="text-danger text-errr">{errors.name}</span>
                      </div>
                      <div className="col-md-6 col-lg-12 col-xl-6">
                        <div className="form-group">
                          <div className="form-group-icon">
                            <img src="/assets/images/email-ico.svg" alt="email-ico" />
                          </div>
                          <div className="form-group-form">
                            <input
                              type="email"
                              name="email"
                              value={userQuery.email}
                              placeholder="Email"
                              onChange={(e) => {
                                handleChange(e);

                                if (e.target.value.length > 0) {
                                  seterrors((prev) => {
                                    prev.email = "";
                                    return prev;
                                  });
                                }
                              }}
                            />
                          </div>
                        </div>
                        <span className="text-danger text-errr">{errors.email}</span>
                      </div>
                      <div className="col-md-6 col-lg-12 col-xl-6">
                        <div className="form-group">
                          <div className="form-group-icon">
                            <img src="/assets/images/phone-ico.svg" alt="phone-ico" />
                          </div>
                          <div className="form-group-form">
                            <input
                              type="text"
                              name="phone_number"
                              value={userQuery.phone_number}
                              placeholder="Phone"
                              onKeyDown={(e) => {
                                // Allow digits only if Shift key is not pressed
                                if (digits.includes(e.key) && !e.shiftKey) {
                                  return;
                                }

                                // Allow specific control keys
                                if (allowedKeys.includes(e.key)) {
                                  return;
                                }

                                // If none of the above conditions are met, prevent the default action
                                e.preventDefault();
                              }}
                              onChange={(e) => {
                                handleChange(e);

                                if (e.target.value.length > 0) {
                                  seterrors((prev) => {
                                    prev.phone_number = "";
                                    return prev;
                                  });
                                }
                              }}
                            />
                          </div>
                        </div>
                        <span className="text-danger text-errr">{errors.phone_number}</span>
                      </div>
                      <div className="col-xl-12">
                        <div className="form-group form-group-textarea">
                          <div className="form-group-icon">
                            <img src="/assets/images/subject-ico.svg" alt="subject-ico" />
                          </div>
                          <div className="form-group-form">
                            <textarea
                              name="message"
                              value={userQuery.message}
                              placeholder="Write your Description"
                              onChange={(e) => {
                                handleChange(e);

                                if (e.target.value.length > 0) {
                                  seterrors((prev) => {
                                    prev.email = "";
                                    return prev;
                                  });
                                }
                              }}
                            />
                          </div>
                        </div>
                        <span className="text-danger text-errr">{errors.message}</span>
                      </div>
                      <div className="col-xl-12">
                        <div className="btn-group" onClick={handleSubmit}>
                          <a href="#!" className="custombtn btn-1 hover-filled-slide-down">
                            <span>Submit</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="cntcus-dtls-area">
                  <div className="cntcus-dtls-top">
                    <div className="row gy-4">
                      <div className="col-md-6 col-lg-12 col-xl-6">
                        <div className="cntcus-hdng">
                          <h4>{contactUsPageData.sec_1_1}</h4>
                        </div>
                        <div className="cntcus-dtls-txt">
                          <p>{contactUsPageData.sec_1_2}</p>
                          <a href={`mailto:${contactUsPageData.sec_1_3}`}>{contactUsPageData.sec_1_3}</a>
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-12 col-xl-6">
                        <div className="cntcus-hdng">
                          <h4>{contactUsPageData.sec_1_4}</h4>
                        </div>
                        <div className="cntcus-dtls-txt">
                          <p>{contactUsPageData.sec_1_5}</p>
                          <a href={`mailto:${contactUsPageData.sec_1_6}`}>{contactUsPageData.sec_1_6}</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="cntcus-dtls-bottom">
                    <div className="row gy-4">
                      <div className="col-lg-12">
                        <div className="cntcus-hdng-2">
                          <h5>{contactUsPageData.sec_1_7}</h5>
                        </div>
                        <div className="cntcus-dtls-txt">
                          <p>{contactUsPageData.sec_1_8}</p>
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-6">
                        <div className="cntcus-hdng-2">
                          <h5>{contactUsPageData.sec_1_9}</h5>
                        </div>
                        <div className="cntcus-dtls-txt">
                          {contactUsPageData.sec_1_10.split("\n").map((item, index) => (
                            <p key={index}>{item}</p>
                          ))}
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-6">
                        <div className="cntcus-hdng-2">
                          <h5>{contactUsPageData.sec_1_11}</h5>
                        </div>
                        <div className="cntcus-dtls-txt">
                          <p>{contactUsPageData.sec_1_12}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="cntcus-map">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d29461.757175281196!2d88.387961!3d22.626942!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f89deb4f928e75%3A0x9d01c2ef2c33ee35!2s6%2C%20KC%20Ghosh%20Rd%2C%20Sinthee%2C%20Kolkata%2C%20West%20Bengal%20700050%2C%20India!5e0!3m2!1sen!2sus!4v1727957400389!5m2!1sen!2sus"
                style={{ border: 0 }}
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              />
            </div> */}
            <div className="cntcus-map" dangerouslySetInnerHTML={{ __html: contactUsPageData.map }} />
          </div>
        </section>
      </div>
      {/* wrapper area end */}
    </>
  );
};

export default ContactUs;
