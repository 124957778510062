import React, { Key, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { Link } from "react-router-dom";

// Helpers
import { ApiHelperFunction } from "../../helpers/api_helpers";
import { isApiErrorResponse } from "../../helpers/typeguards";
import { toast } from "react-hot-toast";

// Utils
import _ from "lodash";
import qs from "qs";

// Models
import { ApiFuncArgProps } from "../../models/apiFuncHelpers";

// Redux actions
import { getSamplePage } from "../../redux/slices/SamplePageSlice";

// Components
import PageBanner from "../components/layout/pages/PageBanner";
import AppAdvantages from "../components/layout/pages/AppAdvantages";

const Sample = () => {
  const dispatch = useAppDispatch();
  const [categories, setcategories] = useState<any>(null);
  const [searchText, setsearchText] = useState<string>("");

  // Redux store
  const { samplePageStatus, samplePageData } = useAppSelector((state) => state.samplePage);

  const fetchSubCategories = async (query?: string) => {
    let response: any;
    if (query) {
      response = await ApiHelperFunction({
        urlPath: `/sample-sub-categories?populate=deep&${query}`,
        method: "GET",
      } as ApiFuncArgProps);
    } else {
      response = await ApiHelperFunction({
        urlPath: "/sample-sub-categories?populate=deep",
        method: "GET",
      } as ApiFuncArgProps);
    }

    if (isApiErrorResponse(response)) {
      toast.error(response.error.message);
    } else if (response.data) {
      let sampleCategories = _.groupBy(response.data?.data, (item) => item?.attributes?.sample_category?.data?.attributes?.category_name);
      setcategories({ ...sampleCategories } as any);
    } else {
      toast.error("Unexpele sample category response");
    }
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    if (samplePageStatus !== "success") {
      dispatch(getSamplePage());
    }
    fetchSubCategories();
  }, []);

  console.log("categories", categories);

  const handleSearch: React.MouseEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault();
    console.log(searchText);

    if (searchText !== "") {
      let categoryKeys = Object.keys(categories);
      let filteredKey = categoryKeys.find((item: any) => item?.toLowerCase()?.includes(searchText.toLowerCase()));
      let categoryItems: any = Object.values(categories);
      let subCategoryName: string = "";

      for (let i: number = 0; i < categoryItems.length; i++) {
        for (let j: number = 0; j < categoryItems[i].length; j++) {
          if (categoryItems[i][j]?.attributes?.sub_category_name?.toLowerCase()?.includes(searchText.toLowerCase())) {
            subCategoryName = categoryItems[i][j]?.attributes?.sub_category_name;
            break;
          }
        }
      }

      const query = qs.stringify(
        {
          filters: {
            $or: [
              {
                sub_category_name: {
                  $eq: subCategoryName,
                },
              },
              {
                sample_category: {
                  category_name: {
                    $eq: filteredKey,
                  },
                },
              },
            ],
          },
        },
        {
          encodeValuesOnly: true, // prettify URL
        }
      );
      fetchSubCategories(query);
    }
  };

  return (
    <>
      {/* page banner area start */}
      <PageBanner title="Sample" description="Essay Examples Crafted by Our Experts" className="experts-inner-banner" />
      {/* page banner area end */}

      {/* wrapper area start */}
      <div className="wrapper">
        <section className="assignment-writers-area assignment-writers-area-expertsPg">
          <div className="container">
            <div className="assignment-writers-bx-area" style={{ paddingTop: "40px" }}>
              <div className="assignment-writers-filters">
                <div className="assignment-writers-filters-left">
                  <div className="srch-frm">
                    <input
                      type="search"
                      placeholder="I am searching for"
                      value={searchText}
                      onChange={(e) => {
                        setsearchText(e.target.value);
                      }}
                    ></input>
                  </div>
                </div>
                <div className="btn-group" onClick={handleSearch}>
                  <a
                    // href="#!"
                    className="custombtn btn-1 hover-filled-slide-down"
                  >
                    <span>Search</span>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <AppAdvantages headerText={samplePageData.sec_3_h1} data={samplePageData.sec_3_it_1} />
        </section>

        {/* Leading Advantages */}
        <div className="container">
          <div className="assignment-writers-bx-area">
            <div className="main-hdng text-center">
              <h2>Essay Samples across ALL Disciplines</h2>
            </div>
            <div className="sample-bx-area">
              <div className="sample-bx">
                {categories !== null &&
                  Object.keys(categories)
                    .slice(0, 10)
                    .map((item, index) => (
                      <div key={index} className="sample-bx-single">
                        <h5>{item} (614)</h5>
                        <ul>
                          {categories[item].map((it: any, idx: Key | null | undefined) => (
                            <li key={idx}>
                              <Link to={`/sample-answer/${item}/${it?.id}`}>{it?.attributes?.sub_category_name && it?.attributes?.sub_category_name} (1)</Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ))}
              </div>

              {categories !== null && Object.keys(categories).length > 10 && (
                <div className="sample-bx">
                  {Object.keys(categories)
                    .slice(10, 20)
                    .map((item, index) => (
                      <div key={index} className="sample-bx-single">
                        <h5>{item} (614)</h5>
                        <ul>
                          {categories[item].map((it: any, idx: Key | null | undefined) => (
                            <li key={idx}>
                              <Link to={`/sample-answer/${item}/${it?.id}`}>{it?.attributes?.sub_category_name && it?.attributes?.sub_category_name} (24)</Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ))}
                </div>
              )}

              {categories !== null && Object.keys(categories).length > 20 && (
                <div className="sample-bx">
                  {Object.keys(categories)
                    .slice(20, 30)
                    .map((item, index) => (
                      <div key={index} className="sample-bx-single">
                        <h5>{item} (614)</h5>
                        <ul>
                          {categories[item].map((it: any, idx: Key | null | undefined) => (
                            <li key={idx}>
                              <Link to={`/sample-answer/${item}/${it?.id}`}>{it?.attributes?.sub_category_name && it?.attributes?.sub_category_name} (24)</Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* wrapper area end */}
    </>
  );
};

export default Sample;
