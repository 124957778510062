import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

// Components
import AppAdvantages from "../../../components/layout/pages/AppAdvantages";

const FactoringCalculator = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <section className="dashboard-service-top-area">
        <div className="container">
          <div className="main-hdng text-center">
            <h2>Factoring Calculator for Quadratic Equation</h2>
            <p>A Factoring Calculator to Solve Your Math Challenges</p>
          </div>
          <div className="dashboard-service-top-bx">
            <div className="row">
              <div className="col-lg-7">
                <div className="dashboard-form">
                  <div className="row gy-4">
                    <div className="col-xl-12">
                      <p>
                        <strong>Type In The Equation Or The Number That You Want To Factorize</strong>
                      </p>
                      <div className="input-with-btn">
                        <input type="text" placeholder="4x + 7 = 2x +1" />
                        <div className="btn-group">
                          <a href="#!" className="custombtn btn-1 hover-filled-slide-down">
                            <span>Factorize it</span>
                          </a>
                        </div>
                      </div>
                      <div className="note-txt">* Write Multiple Query separated by ';</div>
                    </div>
                    <div className="col-xl-12">
                      <h5>Answer:</h5>
                      <textarea></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="dashboard-service-top-bx-right">
                  <h5>Get Rid Of The Factoring Calculator</h5>
                  <p>Ensure flawlessness in your documents</p>
                  <h5>Spell Check Unlimited Length Of Texts</h5>
                  <p>Our Improved algorithm ensures spell check of bulk amount of words</p>
                  <h5>Can Check Any Documents For Factoring Calculator</h5>
                  <p>Can check various write-ups and provide flawless results</p>
                  <h5>Compatible With Multiple Browsers</h5>
                  <p>Works on almost any browser and have an inbuilt thesaurus to help</p>
                  <div className="dashboard-service-top-bx-right-bottom">
                    <div className="dstbrb-single">
                      <h4>01</h4>
                      <span>Enter query</span>
                    </div>
                    <div className="dstbrb-single">
                      <h4>02</h4>
                      <span>Click on Factorize</span>
                    </div>
                    <div className="dstbrb-single">
                      <h4>03</h4>
                      <span>Get Answer</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="avail-expert-help-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-xl-8">
              <div className="row g-4">
                <div className="col-md-6 col-lg-6">
                  <div className="assignment-writers-bx">
                    <div className="writers-rating">
                      <div className="writers-rating-bx">
                        <img src="/assets/images/star-ico.svg" alt="star-ico" />
                        <span>4.8</span>
                      </div>
                    </div>
                    <div className="assignment-writers-bx-top">
                      <div className="subject-icon">
                        <img src="/assets/images/english-ico.svg" alt="subject-icon" />
                      </div>
                      <div className="subject-name">English</div>
                    </div>
                    <div className="assignment-writers-bx-middle">
                      <div className="writer-details">
                        <p>I deliver online assignment help in all accounting topics and even clarifications on complex concepts.</p>
                      </div>
                      <div className="rv-cp">
                        <div className="rv">
                          <img src="/assets/images/reviews-ico.svg" alt="reviews-ico" />
                          <span>
                            Reviews <strong>432</strong>
                          </span>
                        </div>
                        <div className="cp">
                          <img src="/assets/images/complete-projects-ico.svg" alt="complete-projects-ico" />
                          <span>
                            Completed Project <strong>461</strong>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="assignment-writers-bx-bottom">
                      <div className="assignment-writers-bx-bottom-left">
                        <div className="writer-img">
                          <img src="/assets/images/writer-img1.png" alt="writer-img" />
                        </div>
                        <div className="writer-designation">
                          <h6>Desmond Eagle</h6>
                          <p>MSc in English</p>
                        </div>
                      </div>
                      <a href="#!" className="custom-border-btn btn-3 hover-filled-slide-down">
                        <span onClick={() => navigate("/submit-new-assignment")}>Hire Me</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-6">
                  <div className="assignment-writers-bx">
                    <div className="writers-rating">
                      <div className="writers-rating-bx">
                        <img src="/assets/images/star-ico.svg" alt="star-ico" />
                        <span>4.8</span>
                      </div>
                    </div>
                    <div className="assignment-writers-bx-top">
                      <div className="subject-icon">
                        <img src="/assets/images/maths-ico.svg" alt="subject-icon" />
                      </div>
                      <div className="subject-name">Mathematics</div>
                    </div>
                    <div className="assignment-writers-bx-middle">
                      <div className="writer-details">
                        <p>I deliver online assignment help in all accounting topics and even clarifications on complex concepts.</p>
                      </div>
                      <div className="rv-cp">
                        <div className="rv">
                          <img src="/assets/images/reviews-ico.svg" alt="reviews-ico" />
                          <span>
                            Reviews <strong>432</strong>
                          </span>
                        </div>
                        <div className="cp">
                          <img src="/assets/images/complete-projects-ico.svg" alt="complete-projects-ico" />
                          <span>
                            Completed Project <strong>461</strong>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="assignment-writers-bx-bottom">
                      <div className="assignment-writers-bx-bottom-left">
                        <div className="writer-img">
                          <img src="/assets/images/writer-img2.png" alt="writer-img" />
                        </div>
                        <div className="writer-designation">
                          <h6>Desmond Eagle</h6>
                          <p>MSc in Mathematics</p>
                        </div>
                      </div>
                      <a href="#!" className="custom-border-btn btn-3 hover-filled-slide-down">
                        <span onClick={() => navigate("/submit-new-assignment")}>Hire Me</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-6">
                  <div className="assignment-writers-bx">
                    <div className="writers-rating">
                      <div className="writers-rating-bx">
                        <img src="/assets/images/star-ico.svg" alt="star-ico" />
                        <span>4.8</span>
                      </div>
                    </div>
                    <div className="assignment-writers-bx-top">
                      <div className="subject-icon">
                        <img src="/assets/images/accounts-ico.svg" alt="subject-icon" />
                      </div>
                      <div className="subject-name">Accounts</div>
                    </div>
                    <div className="assignment-writers-bx-middle">
                      <div className="writer-details">
                        <p>I deliver online assignment help in all accounting topics and even clarifications on complex concepts.</p>
                      </div>
                      <div className="rv-cp">
                        <div className="rv">
                          <img src="/assets/images/reviews-ico.svg" alt="reviews-ico" />
                          <span>
                            Reviews <strong>432</strong>
                          </span>
                        </div>
                        <div className="cp">
                          <img src="/assets/images/complete-projects-ico.svg" alt="complete-projects-ico" />
                          <span>
                            Completed Project <strong>461</strong>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="assignment-writers-bx-bottom">
                      <div className="assignment-writers-bx-bottom-left">
                        <div className="writer-img">
                          <img src="/assets/images/writer-img3.png" alt="writer-img" />
                        </div>
                        <div className="writer-designation">
                          <h6>Desmond Eagle</h6>
                          <p>MSc in Accounts</p>
                        </div>
                      </div>
                      <a href="#!" className="custom-border-btn btn-3 hover-filled-slide-down">
                        <span onClick={() => navigate("/submit-new-assignment")}>Hire Me</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-6">
                  <div className="assignment-writers-bx">
                    <div className="writers-rating">
                      <div className="writers-rating-bx">
                        <img src="/assets/images/star-ico.svg" alt="star-ico" />
                        <span>4.8</span>
                      </div>
                    </div>
                    <div className="assignment-writers-bx-top">
                      <div className="subject-icon">
                        <img src="/assets/images/english-ico.svg" alt="subject-icon" />
                      </div>
                      <div className="subject-name">English</div>
                    </div>
                    <div className="assignment-writers-bx-middle">
                      <div className="writer-details">
                        <p>I deliver online assignment help in all accounting topics and even clarifications on complex concepts.</p>
                      </div>
                      <div className="rv-cp">
                        <div className="rv">
                          <img src="/assets/images/reviews-ico.svg" alt="reviews-ico" />
                          <span>
                            Reviews <strong>432</strong>
                          </span>
                        </div>
                        <div className="cp">
                          <img src="/assets/images/complete-projects-ico.svg" alt="complete-projects-ico" />
                          <span>
                            Completed Project <strong>461</strong>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="assignment-writers-bx-bottom">
                      <div className="assignment-writers-bx-bottom-left">
                        <div className="writer-img">
                          <img src="/assets/images/writer-img1.png" alt="writer-img" />
                        </div>
                        <div className="writer-designation">
                          <h6>Desmond Eagle</h6>
                          <p>MSc in English</p>
                        </div>
                      </div>
                      <a href="#!" className="custom-border-btn btn-3 hover-filled-slide-down">
                        <span onClick={() => navigate("/submit-new-assignment")}>Hire Me</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-xl-4">
              <div className="avail-expert-help-cnt-area">
                <div className="main-hdng">
                  <h2>Clear Every Semester with Expert Writers</h2>
                </div>
                <div className="avail-expert-help-cnt">
                  <p>
                    Our online factor calculator can help you get the complex math functions with minimal hassle.  From a quadratic equation to a binomial equation, we can help you solve all kinds of problems. You can even get a factor calculator with steps from us. However, sometimes, you may have
                    to factor in real people who can answer your queries. If you feel that you need something more than just the right answer, hire one of our experts who are present at all times to accept your orders and make the impossible possible - to help you score a 100/100. They consider more
                    than one factor before helping you. They understand your specific requirements and can deliver written solutions instead of simply calculating them for you. So, if you want to factorize online, fill out the form or use the calculator. 
                  </p>
                  <div className="btn-group">
                    <a href="#!" className="custombtn btn-1 hover-filled-slide-down">
                      <span>Avail expert help</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* trust service */}
      <section className="trust-service-area trust-service-area-expertsPg trust-service-area-dashboardPg pb-0">
        <div className="container">
          <div className="main-hdng text-center">
            <h2>Samples of How Our Factor Calculator Works</h2>
            <p>Get better ideas of how it works with old samples</p>
          </div>
          <div className="trust-service-bx-area">
            <div className="trust-service-bx">
              <div className="trust-service-bx-single">
                <div className="trust-service-bx-single-img">
                  <img src="/assets/images/trust-service-img9.svg" alt="trust-service-bx-single-img" />
                </div>
                <div className="trust-service-bx-single-cnt">
                  <h5>Mathematics And Numeracy</h5>
                  <p>Define and describe mathematics. Support your discussion with one everyday (maths) phenomenon (e.g. in nature or built environments) that can be explained using mathematics.</p>
                </div>
                <a href="#!" className="btn-link">
                  View sample
                </a>
              </div>
            </div>
            <div className="trust-service-bx">
              <div className="trust-service-bx-single">
                <div className="trust-service-bx-single-img">
                  <img src="/assets/images/trust-service-img9.svg" alt="trust-service-bx-single-img" />
                </div>
                <div className="trust-service-bx-single-cnt">
                  <h5>Philosophical Perspectives On</h5>
                  <p>Intuitionism is predicated on the notion that mathematics is a product of the human imagination. To comprehend the validity of a mathematical statement, one must first establish a mental model that shows that the statement is correct.</p>
                </div>
                <a href="#!" className="btn-link">
                  View sample
                </a>
              </div>
            </div>
            <div className="trust-service-bx">
              <div className="trust-service-bx-single">
                <div className="trust-service-bx-single-img">
                  <img src="/assets/images/trust-service-img9.svg" alt="trust-service-bx-single-img" />
                </div>
                <div className="trust-service-bx-single-cnt">
                  <h5>Assessing Mathematical</h5>
                  <p>The four major NAPLAN questions of style to assess and evaluate the mathematical content of knowledge of decimals and fractions by students have been drafted in this portfolio.</p>
                </div>
                <a href="#!" className="btn-link">
                  View sample
                </a>
              </div>
            </div>
            <div className="trust-service-bx">
              <div className="trust-service-bx-single">
                <div className="trust-service-bx-single-img">
                  <img src="/assets/images/trust-service-img9.svg" alt="trust-service-bx-single-img" />
                </div>
                <div className="trust-service-bx-single-cnt">
                  <h5>Constructivism In Mathematics</h5>
                  <p>Mathematics is one of the vital domains of education and development. Learning math is fun and is essential for human beings to have the knowledge of mathematics to develop in life.</p>
                </div>
                <a href="#!" className="btn-link">
                  View sample
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* faq area 2 */}
      <section className="faqarea-2 pb-0">
        <div className="container">
          <div className="accordion" id="accordionExample">
            <div className="accordion-item">
              <div className="accordion-header" id="headingOne">
                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                  Troublesome Math Questions? Solve by Factoring Calculator
                </button>
              </div>
              <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  <div className="faqarea-2-cnt-bottom faqarea-2-cnt-bottom-sd align-items-end">
                    <div className="faqarea-2-cnt-bottom-left">
                      <p>
                        <strong>Here’s how our quadratic factoring calculator solves factor expressions:</strong>
                      </p>
                      <p>Let us explain how to get a solution for the different types of polynomials with some examples.</p>
                      <p>
                        Quadratic Polynomials: Find a solution for P1(x)= 2 x2+3x+1 <br></br>
                        If x=4, then P1(4)=2(4) 2 +3(4)+1=41 <br></br>
                        Cubic Polynomial : P2(x)=−x3+2x2+2x−5 <br></br>
                        x=−1, then P2(−1)=−(−1)3+2(−1)2+2(−1)−5=−8 <br></br>
                        Quartic Polynomial: P3(x)=3x4−2 x 3+5 x 2+2 x+1 <br></br>
                        If x =3, then P3(3)=3(3)4−2(3)3+5(3)2+2(3)+1=709 <br></br>
                        To find the product of two binomials, you have to multiply each factor of the first binomial by each factor of the second. For example: <br></br>
                        (2 x - 1)(3x - 2) <br></br>6 x^2 -4x - 3x +2 <br></br>
                        Simplified: 6 x^2 - 7x + 2 <br></br>
                        Each of these examples has a different expression. If you cannot understand the difference between each method or formula, take guidance from a polynomial number expert from us. Our helpers know the tricky aspects like -
                      </p>
                      <ul className="normal-ul">
                        <li>Finding a common factor</li>
                        <li>Finding the difference of an expression in an equation</li>
                        <li>Calculating the sum of a formula using the number block method</li>
                      </ul>
                      <div className="btn-group">
                        <a href="#!" className="custombtn btn-1 hover-filled-slide-down">
                          <span>Try this software</span>
                        </a>
                      </div>
                    </div>
                    <div className="faqarea-2-cnt-bottom-right">
                      <div className="faqarea-2-img">
                        <img src="/assets/images/faqarea-2-img.png" alt="faqarea-2-img" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <div className="accordion-header" id="headingTwo">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                  Problems Our Factoring Calculator with Steps Can Solve
                </button>
              </div>
              <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  <div className="faqarea-2-cnt-bottom faqarea-2-cnt-bottom-sd align-items-end">
                    <div className="faqarea-2-cnt-bottom-left">
                      <p>
                        <strong>Here’s how our quadratic factoring calculator solves factor expressions:</strong>
                      </p>
                      <p>Let us explain how to get a solution for the different types of polynomials with some examples.</p>
                      <p>
                        Quadratic Polynomials: Find a solution for P1(x)= 2 x2+3x+1 <br></br>
                        If x=4, then P1(4)=2(4) 2 +3(4)+1=41 <br></br>
                        Cubic Polynomial : P2(x)=−x3+2x2+2x−5 <br></br>
                        x=−1, then P2(−1)=−(−1)3+2(−1)2+2(−1)−5=−8 <br></br>
                        Quartic Polynomial: P3(x)=3x4−2 x 3+5 x 2+2 x+1 <br></br>
                        If x =3, then P3(3)=3(3)4−2(3)3+5(3)2+2(3)+1=709 <br></br>
                        To find the product of two binomials, you have to multiply each factor of the first binomial by each factor of the second. For example: <br></br>
                        (2 x - 1)(3x - 2) <br></br>6 x^2 -4x - 3x +2 <br></br>
                        Simplified: 6 x^2 - 7x + 2 <br></br>
                        Each of these examples has a different expression. If you cannot understand the difference between each method or formula, take guidance from a polynomial number expert from us. Our helpers know the tricky aspects like -
                      </p>
                      <ul className="normal-ul">
                        <li>Finding a common factor</li>
                        <li>Finding the difference of an expression in an equation</li>
                        <li>Calculating the sum of a formula using the number block method</li>
                      </ul>
                      <div className="btn-group">
                        <a href="#!" className="custombtn btn-1 hover-filled-slide-down">
                          <span>Try this software</span>
                        </a>
                      </div>
                    </div>
                    <div className="faqarea-2-cnt-bottom-right">
                      <div className="faqarea-2-img">
                        <img src="/assets/images/faqarea-2-img.png" alt="faqarea-2-img" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Leading Advantages */}
      <AppAdvantages dataItems={false} header={false} />
    </>
  );
};

export default FactoringCalculator;
