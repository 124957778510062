import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";

// Redux actions
import { getReferencingPage } from "../../redux/slices/ReferencingSlice";

// Components
import PageBanner from "../components/layout/pages/PageBanner";

const imgBaseUrl = process.env.REACT_APP_API_IMG_URL;

const Referencing = () => {
  const dispatch = useAppDispatch();

  // Redux stores
  const { referencingPageStatus, referencingPageData } = useAppSelector((state) => state.referencingPage);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    if (referencingPageStatus !== "success") {
      dispatch(getReferencingPage());
    }
  }, []);

  return (
    <>
      {/* page banner area start */}
      <PageBanner title={referencingPageData.sec_1_i_banner[0].heading} description={referencingPageData.sec_1_i_banner[0].subheading} reviewSites={referencingPageData.sec_1_i_banner[0].inner_banner_review} />
      {/* page banner area end */}

      {/* wrapper area start */}
      <div className="wrapper">
        <section className="referencing-top-area">
          <div className="container">
            <div className="row g-5">
              {referencingPageData.sec_2_it_1.map((item, index) => (
                <div key={index} className="col-md-6 col-lg-4">
                  <div className="referencing-top-bx">
                    <div className="referencing-top-bx-img">
                      <img
                        // src="/assets/images/referencing-top-bx-img1.svg"
                        src={imgBaseUrl ? imgBaseUrl + item.icon?.data.attributes?.url : ""}
                        alt="referencing-top-bx-img"
                      />
                    </div>
                    <h5>{item.heading}</h5>
                    <div className="btn-group">
                      <a href="#!" className="custombtn btn-4 hover-filled-slide-down w-100">
                        <span>{item.subheading}</span>
                      </a>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* trust service */}
        <section className="trust-service-area gray-bg trust-service-area-expertsPg trust-service-area-referencingPg">
          <div className="container">
            <div className="main-hdng text-center">
              <h2>Reference every source</h2>
            </div>
            <div className="trust-service-bx-area">
              {referencingPageData.sec_3_it_1.map((item, index) => (
                <div key={index} className="trust-service-bx">
                  <div className="trust-service-bx-single">
                    <div className="trust-service-bx-single-img">
                      <img
                        // src="/assets/images/trust-service-img5.svg"
                        src={imgBaseUrl ? imgBaseUrl + item.icon?.data.attributes?.url : ""}
                        alt="trust-service-bx-single-img"
                      />
                    </div>
                    <div className="trust-service-bx-single-cnt">
                      <h5>{item.heading}</h5>
                      <p>{item.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
      {/* wrapper area end */}
    </>
  );
};

export default Referencing;
