import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

// Components
import AppAdvantages from "../../../components/layout/pages/AppAdvantages";

const GrammarChecker = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <section className="dashboard-service-top-area">
        <div className="container">
          <div className="main-hdng text-center">
            <h2>Free Grammar Checker to Polish Your Writing</h2>
            <p>Eliminate all errors in your writing with our free online grammar checker tool.</p>
          </div>
          <div className="dashboard-service-top-bx">
            <div className="row">
              <div className="col-lg-7">
                <div className="home-banner-form">
                  <div className="row">
                    <div className="col-xl-12">
                      <div className="form-group form-group-textarea">
                        <div className="form-group-icon">
                          <img src="/assets/images/subject-ico.svg" alt="subject-ico" />
                        </div>
                        <div className="form-group-form">
                          <textarea placeholder="Enter The Text You Want To Get Checked." defaultValue={""} />
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-12">
                      <div className="form-group-last">
                        <div className="file-input">
                          <input type="file" name="file-input" id="file-input" className="file-input__input" />
                          <label className="file-input__label" htmlFor="file-input">
                            <img src="/assets/images/attach-ico.svg" alt="attach-ico" />
                            <span>Attach</span>
                          </label>
                        </div>
                        <div className="btn-group">
                          <a href="#!" className="custombtn btn-1 hover-filled-slide-down">
                            <span>Check</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="dashboard-service-top-bx-right">
                  <h5>Checks Clarity Of Documents</h5>
                  <p>Analyse word choices, active passive misuse and other</p>
                  <h5>Spell And Punctuation Check</h5>
                  <p>Checks all spelling and punctuation errors</p>
                  <h5>Modifiers And Sentence Construction Checked</h5>
                  <p>Improper phrases and usages weeded out</p>
                  <h5>Checks Dialectical Errors And Others</h5>
                  <p>Checks to hit every parameter of English grammar</p>
                  <div className="dashboard-service-top-bx-right-bottom">
                    <div className="dstbrb-single">
                      <h4>01</h4>
                      <span>Enter the text</span>
                    </div>
                    <div className="dstbrb-single">
                      <h4>02</h4>
                      <span>Click check</span>
                    </div>
                    <div className="dstbrb-single">
                      <h4>03</h4>
                      <span>Correct text</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="avail-expert-help-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-xl-8">
              <div className="row g-4">
                <div className="col-md-6 col-lg-6">
                  <div className="assignment-writers-bx">
                    <div className="writers-rating">
                      <div className="writers-rating-bx">
                        <img src="/assets/images/star-ico.svg" alt="star-ico" />
                        <span>4.8</span>
                      </div>
                    </div>
                    <div className="assignment-writers-bx-top">
                      <div className="subject-icon">
                        <img src="/assets/images/english-ico.svg" alt="subject-icon" />
                      </div>
                      <div className="subject-name">English</div>
                    </div>
                    <div className="assignment-writers-bx-middle">
                      <div className="writer-details">
                        <p>I deliver online assignment help in all accounting topics and even clarifications on complex concepts.</p>
                      </div>
                      <div className="rv-cp">
                        <div className="rv">
                          <img src="/assets/images/reviews-ico.svg" alt="reviews-ico" />
                          <span>
                            Reviews <strong>432</strong>
                          </span>
                        </div>
                        <div className="cp">
                          <img src="/assets/images/complete-projects-ico.svg" alt="complete-projects-ico" />
                          <span>
                            Completed Project <strong>461</strong>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="assignment-writers-bx-bottom">
                      <div className="assignment-writers-bx-bottom-left">
                        <div className="writer-img">
                          <img src="/assets/images/writer-img1.png" alt="writer-img" />
                        </div>
                        <div className="writer-designation">
                          <h6>Desmond Eagle</h6>
                          <p>MSc in English</p>
                        </div>
                      </div>
                      <a href="#!" className="custom-border-btn btn-3 hover-filled-slide-down">
                        <span onClick={() => navigate("/submit-new-assignment")}>Hire Me</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-6">
                  <div className="assignment-writers-bx">
                    <div className="writers-rating">
                      <div className="writers-rating-bx">
                        <img src="/assets/images/star-ico.svg" alt="star-ico" />
                        <span>4.8</span>
                      </div>
                    </div>
                    <div className="assignment-writers-bx-top">
                      <div className="subject-icon">
                        <img src="/assets/images/maths-ico.svg" alt="subject-icon" />
                      </div>
                      <div className="subject-name">Mathematics</div>
                    </div>
                    <div className="assignment-writers-bx-middle">
                      <div className="writer-details">
                        <p>I deliver online assignment help in all accounting topics and even clarifications on complex concepts.</p>
                      </div>
                      <div className="rv-cp">
                        <div className="rv">
                          <img src="/assets/images/reviews-ico.svg" alt="reviews-ico" />
                          <span>
                            Reviews <strong>432</strong>
                          </span>
                        </div>
                        <div className="cp">
                          <img src="/assets/images/complete-projects-ico.svg" alt="complete-projects-ico" />
                          <span>
                            Completed Project <strong>461</strong>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="assignment-writers-bx-bottom">
                      <div className="assignment-writers-bx-bottom-left">
                        <div className="writer-img">
                          <img src="/assets/images/writer-img2.png" alt="writer-img" />
                        </div>
                        <div className="writer-designation">
                          <h6>Desmond Eagle</h6>
                          <p>MSc in Mathematics</p>
                        </div>
                      </div>
                      <a href="#!" className="custom-border-btn btn-3 hover-filled-slide-down">
                        <span onClick={() => navigate("/submit-new-assignment")}>Hire Me</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-6">
                  <div className="assignment-writers-bx">
                    <div className="writers-rating">
                      <div className="writers-rating-bx">
                        <img src="/assets/images/star-ico.svg" alt="star-ico" />
                        <span>4.8</span>
                      </div>
                    </div>
                    <div className="assignment-writers-bx-top">
                      <div className="subject-icon">
                        <img src="/assets/images/accounts-ico.svg" alt="subject-icon" />
                      </div>
                      <div className="subject-name">Accounts</div>
                    </div>
                    <div className="assignment-writers-bx-middle">
                      <div className="writer-details">
                        <p>I deliver online assignment help in all accounting topics and even clarifications on complex concepts.</p>
                      </div>
                      <div className="rv-cp">
                        <div className="rv">
                          <img src="/assets/images/reviews-ico.svg" alt="reviews-ico" />
                          <span>
                            Reviews <strong>432</strong>
                          </span>
                        </div>
                        <div className="cp">
                          <img src="/assets/images/complete-projects-ico.svg" alt="complete-projects-ico" />
                          <span>
                            Completed Project <strong>461</strong>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="assignment-writers-bx-bottom">
                      <div className="assignment-writers-bx-bottom-left">
                        <div className="writer-img">
                          <img src="/assets/images/writer-img3.png" alt="writer-img" />
                        </div>
                        <div className="writer-designation">
                          <h6>Desmond Eagle</h6>
                          <p>MSc in Accounts</p>
                        </div>
                      </div>
                      <a href="#!" className="custom-border-btn btn-3 hover-filled-slide-down">
                        <span onClick={() => navigate("/submit-new-assignment")}>Hire Me</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-6">
                  <div className="assignment-writers-bx">
                    <div className="writers-rating">
                      <div className="writers-rating-bx">
                        <img src="/assets/images/star-ico.svg" alt="star-ico" />
                        <span>4.8</span>
                      </div>
                    </div>
                    <div className="assignment-writers-bx-top">
                      <div className="subject-icon">
                        <img src="/assets/images/english-ico.svg" alt="subject-icon" />
                      </div>
                      <div className="subject-name">English</div>
                    </div>
                    <div className="assignment-writers-bx-middle">
                      <div className="writer-details">
                        <p>I deliver online assignment help in all accounting topics and even clarifications on complex concepts.</p>
                      </div>
                      <div className="rv-cp">
                        <div className="rv">
                          <img src="/assets/images/reviews-ico.svg" alt="reviews-ico" />
                          <span>
                            Reviews <strong>432</strong>
                          </span>
                        </div>
                        <div className="cp">
                          <img src="/assets/images/complete-projects-ico.svg" alt="complete-projects-ico" />
                          <span>
                            Completed Project <strong>461</strong>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="assignment-writers-bx-bottom">
                      <div className="assignment-writers-bx-bottom-left">
                        <div className="writer-img">
                          <img src="/assets/images/writer-img1.png" alt="writer-img" />
                        </div>
                        <div className="writer-designation">
                          <h6>Desmond Eagle</h6>
                          <p>MSc in English</p>
                        </div>
                      </div>
                      <a href="#!" className="custom-border-btn btn-3 hover-filled-slide-down">
                        <span onClick={() => navigate("/submit-new-assignment")}>Hire Me</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-xl-4">
              <div className="avail-expert-help-cnt-area">
                <div className="main-hdng">
                  <h2>Professional Proofreaders to Check and Correct Grammar</h2>
                </div>
                <div className="avail-expert-help-cnt">
                  <p>
                    Hire our grammar professionals to help you get an A+. Professional proofreading can boost the quality of your document! Do not be concerned if you cannot fully utilize the free app. Hire a professional writer and let him handle all of your paper's commonly confused words and
                    misspelled words. Our proofreading and editing experts are always accessible to correct grammar, spelling, and punctuation, along with word choice, to make your work faultless!
                  </p>
                  <p>Place your order now! Make use of proofreading services.</p>
                  <div className="btn-group">
                    <a href="#!" className="custombtn btn-1 hover-filled-slide-down">
                      <span>Avail expert help</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* trust service */}
      <section className="trust-service-area trust-service-area-expertsPg trust-service-area-dashboardPg pb-0">
        <div className="container">
          <div className="main-hdng text-center">
            <h2>Check Our Top-Quality Samples</h2>
            <p>Pore through our samples for free and write better!</p>
          </div>
          <div className="trust-service-bx-area">
            <div className="trust-service-bx">
              <div className="trust-service-bx-single">
                <div className="trust-service-bx-single-img">
                  <img src="/assets/images/trust-service-img9.svg" alt="trust-service-bx-single-img" />
                </div>
                <div className="trust-service-bx-single-cnt">
                  <h5>Factors To Consider In Representative Sample</h5>
                  <p>
                    The purpose of this research is to find the associations between quantitative and qualitative job insecurity and well-being. However, data on variables such as gender, age, education level, extra income were also collected. What is the purpose of collecting data on variables such
                    as gender, age, educational level etc.? What research design is used for current research? What are the positive and negative side of the current research design?
                  </p>
                </div>
                <a href="#!" className="btn-link">
                  View sample
                </a>
              </div>
            </div>
            <div className="trust-service-bx">
              <div className="trust-service-bx-single">
                <div className="trust-service-bx-single-img">
                  <img src="/assets/images/trust-service-img9.svg" alt="trust-service-bx-single-img" />
                </div>
                <div className="trust-service-bx-single-cnt">
                  <h5>Strength Test On Structural Timber - Lab</h5>
                  <p>
                    Since ancient times timber has been considered an essential material for construction of buildings and other structures. The structural stability of this timbers was determined by use of simple calculations and assumptions which were validated by the several practical which were
                    carried out (Andrews, 2011). The strength capability of timber is very complicated. The strength capability of wood is a matter of several parameters which
                  </p>
                </div>
                <a href="#!" className="btn-link">
                  View sample
                </a>
              </div>
            </div>
            <div className="trust-service-bx">
              <div className="trust-service-bx-single">
                <div className="trust-service-bx-single-img">
                  <img src="/assets/images/trust-service-img9.svg" alt="trust-service-bx-single-img" />
                </div>
                <div className="trust-service-bx-single-cnt">
                  <h5>Essay: Prior Knowledge Learning Experience</h5>
                  <p>
                    The description of students and classroom context takes into account student’s backgrounds and learning needs and how the classroom environment is designed and organised to accommodate for learning. Part 2: Provide an analysis of the effectiveness of the Prior Knowledge learning
                    experience. Discuss student’s understandings based on the data that you have collected, including referencing appropriate literature and
                  </p>
                </div>
                <a href="#!" className="btn-link">
                  View sample
                </a>
              </div>
            </div>
            <div className="trust-service-bx">
              <div className="trust-service-bx-single">
                <div className="trust-service-bx-single-img">
                  <img src="/assets/images/trust-service-img9.svg" alt="trust-service-bx-single-img" />
                </div>
                <div className="trust-service-bx-single-cnt">
                  <h5>NFT2150 Business Analysis Essay Is Crucial</h5>
                  <p>
                    Business analytics is very necessary. This has always enabled the firm to realize the progress after a specified period. It has always enabled businesses to understand the strength points and points of weaknesses. This is very key in planning as it enables investors and business
                    runners to improve on weakness and mature up on various areas. They are able to determine from statistics the quality of products needed and the kind of
                  </p>
                </div>
                <a href="#!" className="btn-link">
                  View sample
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* faq area 2 */}
      <section className="faqarea-2 pb-0">
        <div className="container">
          <div className="accordion" id="accordionExample">
            <div className="accordion-item">
              <div className="accordion-header" id="headingOne">
                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                  Free Grammar Check: Easily Fix Your Grammar Mistakes
                </button>
              </div>
              <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  <div className="faqarea-2-cnt-bottom faqarea-2-cnt-bottom-sd">
                    <div className="faqarea-2-cnt-bottom-left">
                      <p>
                        Any article or other written work must be proofread for grammatical mistakes before publication. If there are grammatical problems in your writing assessment, it makes a negative impression on the assessor. Even if you are a superb writer, researcher, or journalist, it does
                        not guarantee that you will always be able to spot grammatical errors. Therefore, avoid letting a single grammar error spoil your career. Due to this, we have the best grammar checker online with no payment required.
                      </p>
                      <div className="btn-group">
                        <a href="#!" className="custombtn btn-1 hover-filled-slide-down">
                          <span>give a shot</span>
                        </a>
                      </div>
                    </div>
                    <div className="faqarea-2-cnt-bottom-right">
                      <div className="faqarea-2-img">
                        <img src="/assets/images/faqarea-2-img.png" alt="faqarea-2-img" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <div className="accordion-header" id="headingTwo">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                  Why Is Our Online Grammar Check Beneficial for All?
                </button>
              </div>
              <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  <div className="faqarea-2-cnt-bottom faqarea-2-cnt-bottom-sd">
                    <div className="faqarea-2-cnt-bottom-left">
                      <p>
                        Any article or other written work must be proofread for grammatical mistakes before publication. If there are grammatical problems in your writing assessment, it makes a negative impression on the assessor. Even if you are a superb writer, researcher, or journalist, it does
                        not guarantee that you will always be able to spot grammatical errors. Therefore, avoid letting a single grammar error spoil your career. Due to this, we have the best grammar checker online with no payment required.
                      </p>
                      <div className="btn-group">
                        <a href="#!" className="custombtn btn-1 hover-filled-slide-down">
                          <span>give a shot</span>
                        </a>
                      </div>
                    </div>
                    <div className="faqarea-2-cnt-bottom-right">
                      <div className="faqarea-2-img">
                        <img src="/assets/images/faqarea-2-img.png" alt="faqarea-2-img" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <div className="accordion-header" id="headingThree">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                  Who Benefits From Our Grammar Checking App?
                </button>
              </div>
              <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  <div className="faqarea-2-cnt-bottom faqarea-2-cnt-bottom-sd">
                    <div className="faqarea-2-cnt-bottom-left">
                      <p>
                        Any article or other written work must be proofread for grammatical mistakes before publication. If there are grammatical problems in your writing assessment, it makes a negative impression on the assessor. Even if you are a superb writer, researcher, or journalist, it does
                        not guarantee that you will always be able to spot grammatical errors. Therefore, avoid letting a single grammar error spoil your career. Due to this, we have the best grammar checker online with no payment required.
                      </p>
                      <div className="btn-group">
                        <a href="#!" className="custombtn btn-1 hover-filled-slide-down">
                          <span>give a shot</span>
                        </a>
                      </div>
                    </div>
                    <div className="faqarea-2-cnt-bottom-right">
                      <div className="faqarea-2-img">
                        <img src="/assets/images/faqarea-2-img.png" alt="faqarea-2-img" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Leading Advantages */}
      <AppAdvantages dataItems={false} header={false} />
    </>
  );
};

export default GrammarChecker;
