import { useEffect } from "react";
import { useAppDispatch } from "../../../../redux/hooks";

// Helpers
import qs from "qs";
import { fromUrlDownFn } from "../../../../helpers/file_downloads";

// Redux actions
import { getUserOrdersList } from "../../../../redux/slices/OrdersSlice";

// Models
import { OrdersTableProps } from "../../../../models/views/components/pages";
import { AttachmentDataModel } from "../../../../models/constants/data/dataModels";
import { countriesList } from "../../../../constants/data/data-constants";
// Components
import ReactPaginate from "react-paginate";

let fileBaseUrl = process.env.REACT_APP_API_IMG_URL;

interface PaginationOnChangeEvent {
  selected: number;
}
let userEmail = localStorage.getItem("user_email");

const OrdersTable = ({ parentComponentName = "Dashboard", className, ordersData, setorderDetails }: OrdersTableProps) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (ordersData.data && ordersData.data.length > 0) {
      setorderDetails(ordersData.data[0]);
    }
  }, []);

  const downloadAttachments = (attachementsArr: Array<AttachmentDataModel>) => {
    for (let i: number = 0; i < attachementsArr.length; i++) {
      if (fileBaseUrl) {
        fromUrlDownFn(fileBaseUrl + attachementsArr[i].attributes.url, attachementsArr[i].attributes.hash);
      }
    }
  };

  const countryCurrency = (country: string) => {
    const countryIndex = countriesList.find((item) => item.label === country);
    return countryIndex?.currency_symbol;
  };
  console.log(`ordersData=====>`, countriesList);
  return (
    <>
      {parentComponentName === "Dashboard" ? (
        ordersData.data.length > 0 && (
          <>
            <div className={`table-responsive tablerounededCorner ${className ? className : ""}`}>
              <table className="table table-striped">
                <thead>
                  <tr>
                    {/* &amp; Status */}
                    <th>Order ID</th>
                    <th>Subject</th>
                    <th className="text-center">Deadline</th>
                    <th className="text-center">Price Quote</th>
                    <th className="text-center">Order Status</th>
                    <th className="text-center">Attachments</th>
                    <th className="text-center">Solution</th>
                  </tr>
                </thead>
                <tbody>
                  {ordersData.data.map((item, index) => (
                    <tr key={index} className="cursor-pointer" onClick={() => setorderDetails(item)}>
                      <td>{item.id}</td>
                      <td>{item.attributes.subject}</td>
                      <td className="text-center">{item.attributes.end_by_date}</td>
                      <td className="text-center">{item.attributes.price_quote !== null ? `${countryCurrency(item.attributes?.country)}${item.attributes.price_quote}` : "$ 00.00"}</td>
                      <td className="text-center">{item.attributes.order_status ? item.attributes.order_status.charAt(0).toUpperCase() + item.attributes.order_status.slice(1) : "Pending"}</td>
                      <td className="text-center">
                        <button type="button" className="btn btn-warning" onClick={() => downloadAttachments(item.attributes.attachment.data)}>
                          <img src="/assets/images/download-ico.svg" /> Download
                        </button>
                      </td>
                      {item.attributes.solution.data ? (
                        <td className="text-center">
                          <button type="button" className="btn btn-warning" onClick={() => fromUrlDownFn(fileBaseUrl + item.attributes.solution.data.attributes.url, item.attributes.solution.data.attributes.hash)}>
                            <img src="/assets/images/download-ico.svg" /> Download
                          </button>
                        </td>
                      ) : (
                        <td className="text-center">NA</td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <ReactPaginate
              breakLabel="..."
              nextLabel="Next"
              previousLabel="Previous"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakClassName="page-item"
              breakLinkClassName="page-link"
              containerClassName="pagination pagination-sdnew pagination-sdnew2"
              activeClassName="active"
              onPageChange={(event: PaginationOnChangeEvent) => {
                const query = qs.stringify(
                  {
                    filters: {
                      email: {
                        $eq: `${userEmail}`,
                      },
                    },
                    sort: ["id:desc"],
                    pagination: {
                      page: event.selected + 1,
                      pageSize: 5,
                    },
                  },
                  {
                    encodeValuesOnly: true, // prettify URL
                  }
                );

                dispatch(getUserOrdersList(query));
              }}
              pageRangeDisplayed={2}
              pageCount={ordersData.meta.pagination.pageCount}
              renderOnZeroPageCount={null}
            />
          </>
        )
      ) : (
        <>
          <div className={`table-responsive tablerounededCorner ${className ? className : ""}`}>
            <table className="table table-striped">
              <thead>
                <tr>
                  {/* &amp; Status */}
                  <th>Order ID</th>
                  <th>Subject</th>
                  <th className="text-center">Deadline</th>
                  <th className="text-center">Price Quote</th>
                  <th className="text-center">Order Status</th>
                  <th className="text-center">Attachments</th>
                  <th className="text-center">Solution</th>
                </tr>
              </thead>
              <tbody>
                {ordersData.data && ordersData.data.length > 0 ? (
                  ordersData.data.map((item, index) => (
                    <tr key={index} className="cursor-pointer" onClick={() => setorderDetails(item)}>
                      <td>{item.id}</td>
                      <td>{item.attributes.subject}</td>
                      <td className="text-center">{item.attributes.end_by_date}</td>
                      <td className="text-center">{item.attributes.price_quote !== null ? `${countryCurrency(item.attributes?.country)}${item.attributes.price_quote}` : "$ 00.00"}</td>
                      <td className="text-center">{item.attributes.order_status ? item.attributes.order_status.charAt(0).toUpperCase() + item.attributes.order_status.slice(1) : "Pending"}</td>
                      <td className="text-center">
                        <button type="button" className="btn btn-warning" onClick={() => downloadAttachments(item.attributes.attachment.data)}>
                          <img src="/assets/images/download-ico.svg" /> Download
                        </button>
                      </td>
                      {item.attributes.solution.data ? (
                        <td className="text-center">
                          <button type="button" className="btn btn-warning" onClick={() => fromUrlDownFn(fileBaseUrl + item.attributes.solution.data.attributes.url, item.attributes.solution.data.attributes.hash)}>
                            <img src="/assets/images/download-ico.svg" /> Download
                          </button>
                        </td>
                      ) : (
                        <td className="text-center">NA</td>
                      )}
                    </tr>
                  ))
                ) : (
                  <div className="no-orders-sdnew">No orders found</div>
                )}
              </tbody>
            </table>
          </div>
          <ReactPaginate
            breakLabel="..."
            nextLabel="Next"
            previousLabel="Previous"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination pagination-sdnew pagination-sdnew2"
            activeClassName="active"
            onPageChange={(event: PaginationOnChangeEvent) => {
              const query = qs.stringify(
                {
                  filters: {
                    email: {
                      $eq: `${userEmail}`,
                    },
                  },
                  sort: ["id:desc"],
                  pagination: {
                    page: event.selected + 1,
                    pageSize: 5,
                  },
                },
                {
                  encodeValuesOnly: true, // prettify URL
                }
              );

              dispatch(getUserOrdersList(query));
            }}
            pageRangeDisplayed={2}
            pageCount={ordersData.meta.pagination.pageCount}
            renderOnZeroPageCount={null}
          />
        </>
      )}
    </>
  );
};

export default OrdersTable;
