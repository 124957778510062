// Models
import { AppAdvantagesProps } from "../../../../models/views/components/pages";

let imgBaseUrl = process.env.REACT_APP_API_IMG_URL;

const AppAdvantages = ({ className, header = true, headerText, description = false, descriptionText, dataItems = true, data = [] }: AppAdvantagesProps) => {
  return (
    <section className={`leading-advantages-area ${className ? className : ""}`}>
      <div className="container">
        <div className="main-hdng text-center">
          <h2>{!header ? "Well-Known Services We Offer" : headerText}</h2>
          {description && <p>{descriptionText}</p>}
        </div>
        <div className="leading-advantages-bx-area">
          {dataItems && data.length > 0 ? (
            <>
              {data.map((item, index) => (
                <div key={index} className="leading-advantages-bx">
                  <div className="leading-advantages-bx-img">
                    <img
                      // src="/assets/images/leading-advantages-bx-img7.svg"
                      src={imgBaseUrl ? imgBaseUrl + item.icon?.data?.attributes?.url : ""}
                      alt="leading-advantages-bx-img"
                    />
                  </div>
                  <div className="leading-advantages-bx-cnt">
                    <h5>{item.heading}</h5>
                    <p>{item.description}</p>
                  </div>
                </div>
              ))}
            </>
          ) : (
            <>
              <div className="leading-advantages-bx">
                <div className="leading-advantages-bx-img">
                  <img src="/assets/images/leading-advantages-bx-img7.svg" alt="leading-advantages-bx-img" />
                </div>
                <div className="leading-advantages-bx-cnt">
                  <h5>Assignment Writing</h5>
                  <p>Our pool of experts comprises professors and scholars who offer assignment help for all academic levels.</p>
                </div>
              </div>
              <div className="leading-advantages-bx">
                <div className="leading-advantages-bx-img">
                  <img src="/assets/images/leading-advantages-bx-img8.svg" alt="leading-advantages-bx-img" />
                </div>
                <div className="leading-advantages-bx-cnt">
                  <h5>Essay Writing Help</h5>
                  <p>Use our essay help services at the best prices to avoid mistakes while preparing your academic essays.</p>
                </div>
              </div>
              <div className="leading-advantages-bx">
                <div className="leading-advantages-bx-img">
                  <img src="/assets/images/leading-advantages-bx-img9.svg" alt="leading-advantages-bx-img" />
                </div>
                <div className="leading-advantages-bx-cnt">
                  <h5>Dissertation Help</h5>
                  <p>Our scholarly writers will assist you in writing a well-structured dissertation with relevant citations.</p>
                </div>
              </div>
              <div className="leading-advantages-bx">
                <div className="leading-advantages-bx-img">
                  <img src="/assets/images/leading-advantages-bx-img10.svg" alt="leading-advantages-bx-img" />
                </div>
                <div className="leading-advantages-bx-cnt">
                  <h5>Case Study Help</h5>
                  <p>Our case study helpers will generate and offer high-quality case studies on all disciplines in real-time</p>
                </div>
              </div>
              <div className="leading-advantages-bx">
                <div className="leading-advantages-bx-img">
                  <img src="/assets/images/leading-advantages-bx-img11.svg" alt="leading-advantages-bx-img" />
                </div>
                <div className="leading-advantages-bx-cnt">
                  <h5>Homework Help</h5>
                  <p>Get homework help from the best experts and get to boost your marks and learn more about the subject.</p>
                </div>
              </div>
              <div className="leading-advantages-bx">
                <div className="leading-advantages-bx-img">
                  <img src="/assets/images/leading-advantages-bx-img12.svg" alt="leading-advantages-bx-img" />
                </div>
                <div className="leading-advantages-bx-cnt">
                  <h5>Coursework Writing</h5>
                  <p>Our online assignment experts will also provide plagiarism-free writing help for various academic papers</p>
                </div>
              </div>
              <div className="leading-advantages-bx">
                <div className="leading-advantages-bx-img">
                  <img src="/assets/images/leading-advantages-bx-img13.svg" alt="leading-advantages-bx-img" />
                </div>
                <div className="leading-advantages-bx-cnt">
                  <h5>Proofreading Services</h5>
                  <p>Say goodbye to writing issues because our proofreading services will provide you with flawless results.</p>
                </div>
              </div>
              <div className="leading-advantages-bx">
                <div className="leading-advantages-bx-img">
                  <img src="/assets/images/leading-advantages-bx-img14.svg" alt="leading-advantages-bx-img" />
                </div>
                <div className="leading-advantages-bx-cnt">
                  <h5>Research Paper Help</h5>
                  <p>Receive A+ research papers with the assistance of Ph.D. experts available to advise you at every step.</p>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default AppAdvantages;
