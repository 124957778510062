import { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";

// Redux actions
import { getAllBlogs } from "../../../../redux/slices/BlogSlice";

let imgBaseUrl = process.env.REACT_APP_API_IMG_URL;

interface BlogSectionProps {
  className?: string;
}

const BlogSection = ({ className }: BlogSectionProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [showModal, setShowModal] = useState(false);
  const [youtubeLink, setyoutubeLink] = useState("");
  // Redux stores
  const { blogs } = useAppSelector((state) => state.blogs);

  useEffect(() => {
    if (blogs.data.length === 0) {
      dispatch(getAllBlogs());
    }
  }, []);
  const handleVideoClick = (link: string | undefined) => {
    // e.preventDefault();
    const urlObj = new URL(link || "");
    const videoId = urlObj.searchParams.get("v");

    setyoutubeLink(`https://www.youtube.com/embed/${videoId}` || "");
    setShowModal(true);
  };

  return (
    <section className="blog-area">
      <div className="container">
        <div className={`blog-bx ${className ? (className !== "" ? className : "") : ""}`}>
          <div className="blog-bx-left">
            {blogs.data.length > 0 && (
              <>
                <img src={imgBaseUrl + (blogs.data?.[blogs.data.length - 1]?.attributes?.image?.data?.attributes?.url || "/assets/images/blog-bx-left-img.png")} onClick={() => navigate(`/blog-details/${blogs.data?.[blogs.data.length - 1]?.id}`)} alt="blog-bx-left-img" />
                {blogs.data?.[blogs.data.length - 1]?.attributes?.youtube_video_link !== "" && (
                  <a className="ripple-block" onClick={() => handleVideoClick(blogs.data?.[blogs.data.length - 1]?.attributes?.youtube_video_link)}>
                    <i className="fa fa-play-circle" aria-hidden="true"></i>
                    <div className="ripple ripple-2"></div>
                    <div className="ripple ripple-3"></div>
                  </a>
                )}

                <div className="blog-bx-left-txt">
                  <a href="/blog-details/1"> {blogs.data?.[blogs.data.length - 1]?.attributes?.title}</a>
                  <div className="blog-bx-calender">
                    <img src="/assets/images/calender-ico.svg" alt="calender-ico" /> <span>{new Date(blogs.data?.[blogs.data.length - 1]?.attributes?.createdAt).toDateString()}</span>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="blog-bx-right">
            {blogs.data.slice(0, 3).map((item, index) => (
              <div key={index} className="blog-bx-right-single">
                <div className="blog-bx-right-img" style={{ cursor: "pointer" }}>
                  <img
                    src={
                      item.attributes.image?.data?.attributes?.url ? `${imgBaseUrl}${item.attributes.image.data.attributes.url}` : "/assets/images/blog-bx-right-img1.png" // Fallback image
                    }
                    alt="blog-bx-right-img"
                  />
                  {item.attributes.youtube_video_link && (
                    <a className="ripple-block small-ripple-block" onClick={() => handleVideoClick(item.attributes.youtube_video_link)}>
                      <i className="fa fa-play-circle" aria-hidden="true"></i>
                      <div className="ripple ripple-2"></div>
                      <div className="ripple ripple-3"></div>
                    </a>
                  )}
                </div>
                <div className="blog-bx-right-cnt">
                  <Link to={`/blog-details/${item.id}`}>{item.attributes.title}</Link>
                  <div className="blog-bx-calender">
                    <img src="/assets/images/calender-ico.svg" alt="calender-ico" />
                    <span>{new Date(item.attributes.createdAt).toDateString()}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* <div className="blog-bx-right">
            {blogs.data.slice(0, 3).map((item, index) => (
              <div key={index} className="blog-bx-right-single">
                <div className="blog-bx-right-img" style={{ cursor: "pointer" }} onClick={() => navigate(`/blog-details/${item.id}`)}>
                  <img
                    // src="/assets/images/blog-bx-right-img1.png"
                    src={imgBaseUrl ? imgBaseUrl + item.attributes.image.data?.attributes?.url : ""}
                    alt="blog-bx-right-img"
                  />
                  <a className="ripple-block small-ripple-block">
                    <i className="fa fa-play-circle" aria-hidden="true"></i>
                    <div className="ripple ripple-2"></div>
                    <div className="ripple ripple-3"></div>
                  </a>
                </div>
                <div className="blog-bx-right-cnt">
                  <Link to={`/blog-details/${item.id}`}>{item.attributes.title}</Link>
                  <div className="blog-bx-calender">
                    <img src="/assets/images/calender-ico.svg" alt="calender-ico" />
                    <span>{new Date(item.attributes.createdAt).toDateString()}</span>
                  </div>
                </div>
              </div>
            ))}
          </div> */}
        </div>
      </div>
      {showModal && (
        <div className="video-modal">
          <div className="modal-content-video">
            <button className="close-button" onClick={() => setShowModal(false)}>
              ✕
            </button>
            <iframe width="100%" src={youtubeLink} title="video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
          </div>
        </div>
      )}
    </section>
  );
};

export default BlogSection;
