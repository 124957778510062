import { useNavigate, Link } from "react-router-dom";

import { FAQ2Props } from "../../../../models/views/components/pages";

const FAQ2 = ({ className, dataProvided = true, dataType, data }: FAQ2Props) => {
  const navigate = useNavigate();

  return (
    <>
      {dataType === "write_my_paper" ? (
        dataProvided ? (
          <section className={`faqarea-2 pb-0 ${className ? className : ""}`}>
            <div className="container">
              <div className="accordion" id="accordionExample">
                <div className="accordion-item">
                  <div className="accordion-header" id="heading1">
                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse1" aria-expanded="true" aria-controls="collapse1">
                      {data?.[0]?.text_h1}
                    </button>
                  </div>
                  <div id="collapse1" className="accordion-collapse collapse show" aria-labelledby="heading1" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      <div className="faqarea-2-cnt">
                        {data?.[0]?.text_description?.map((item, index) => {
                          if (item.type === "heading") {
                            if (item.level === 4) {
                              return item.children[0].bold ? (
                                <h4>
                                  <b>{item.children[0].text}</b>
                                </h4>
                              ) : (
                                <h4>{item.children[0].text}</h4>
                              );
                            }
                          } else if (item.type === "paragraph") {
                            if (item.children.length === 1) {
                              return item.children.map((ele, id) => <p key={index}>{ele.bold ? <b>{ele.text}</b> : ele.text}</p>);
                            } else {
                              return (
                                <div key={index} className="d-flex">
                                  <p>{item.children[0].bold ? <b>{item.children[0].text}</b> : item.children[0].text}</p>
                                  <p>{item.children[1].bold ? <b>{item.children[1].text}</b> : item.children[1].text}</p>
                                </div>
                              );
                            }
                          } else if (item.type === "list") {
                            if (item.format === "unordered") {
                              return (
                                <ul key={index} className="mb-3">
                                  {item.children.map((it, idx) => (
                                    <li key={idx}>
                                      <b>{it?.children?.[0]?.text}</b> {it?.children?.[1]?.text}
                                    </li>
                                  ))}
                                </ul>
                              );
                            }
                          }
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                {data?.slice(1).map((item, index) => (
                  <div key={index + 1} className="accordion-item">
                    <div className="accordion-header" id={`heading${index + 2}`}>
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${index + 2}`} aria-expanded="false" aria-controls={`collapse${index + 2}`}>
                        {item.text_h1}
                      </button>
                    </div>
                    <div id={`collapse${index + 2}`} className="accordion-collapse collapse" aria-labelledby={`heading${index + 2}`} data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <div className="faqarea-2-cnt">
                          {item.text_description.map((item, index) => {
                            if (item.type === "heading") {
                              if (item.level === 4) {
                                return item.children[0].bold ? (
                                  <h4>
                                    <b>{item.children[0].text}</b>
                                  </h4>
                                ) : (
                                  <h4>{item.children[0].text}</h4>
                                );
                              }
                            } else if (item.type === "paragraph") {
                              if (item.children.length === 1) {
                                return item.children.map((ele, id) => <p key={index}>{ele.bold ? <b>{ele.text}</b> : ele.text}</p>);
                              } else {
                                return (
                                  <div key={index} className="">
                                    <p className="pb-1">{item.children[0].bold ? <b>{item.children[0].text}</b> : item.children[0].text}</p>
                                    <p>{item.children[1].bold ? <b>{item.children[1].text}</b> : item.children[1].text}</p>
                                  </div>
                                );
                              }
                            } else if (item.type === "list") {
                              if (item.format === "unordered") {
                                return (
                                  <ul key={index} className="mb-3">
                                    {item.children.map((it, idx) => (
                                      <li key={idx}>
                                        <b>{it.children?.[0]?.text}</b> {it.children?.[1]?.text}
                                      </li>
                                    ))}
                                  </ul>
                                );
                              }
                            }
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>
        ) : (
          <section className={`faqarea-2 pb-0 ${className ? className : ""}`}>
            <div className="container">
              <div className="accordion" id="accordionExample">
                <div className="accordion-item">
                  <div className="accordion-header" id="headingOne">
                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                      "Write My Paper for Me” Service By Professionals
                    </button>
                  </div>
                  <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      <div className="faqarea-2-cnt-top">
                        <p>
                          Academic writing can become so hectic that you’ll often wonder, “Can someone please&nbsp;write my paper?” Hiring an essay writing service&nbsp;or something similar is the trend during these times. After all, when you hire&nbsp;academic writing services, you get to enjoy
                          many advantages.
                        </p>
                      </div>
                      <div className="faqarea-2-cnt-bottom">
                        <div className="faqarea-2-cnt-bottom-left">
                          <table className="table table-bordered">
                            <tbody>
                              <tr>
                                <td>Unique Solutions</td>
                                <td>Enjoy 100% plagiarism-free answers</td>
                              </tr>
                              <tr>
                                <td>Reputed Writers</td>
                                <td>Hire top scholars from the US</td>
                              </tr>
                              <tr>
                                <td>Wide Coverage</td>
                                <td>Get guidance on every topic</td>
                              </tr>
                              <tr>
                                <td>Timely Delivery</td>
                                <td>Meet every deadline with ease</td>
                              </tr>
                              <tr>
                                <td>Custom Papers</td>
                                <td>Customized solutions for A+ grades</td>
                              </tr>
                              <tr>
                                <td>Affordable Services</td>
                                <td>Look forward to jaw-dropping prices.</td>
                              </tr>
                            </tbody>
                          </table>
                          <p>You can enjoy all of these features and more when you hire MyAssignmentHelp. For example, we offer –</p>
                          <p>
                            Our primary objective is to provide you with high-quality services. We’re a one-stop destination for all your academic worries. If you need&nbsp;essay writing help&nbsp;or guidance with research papers, we've got you covered. Once you send us your requirements, we will
                            assign you the professional writer&nbsp;best suited for the task. You can also check the educational qualifications of our paper writers&nbsp;before booking them to write papers.
                          </p>
                          <div className="btn-group">
                            <Link to={"/submit-new-assignment"} className="custombtn btn-1 hover-filled-slide-down">
                              <span>Order Now</span>
                            </Link>
                          </div>
                        </div>
                        <div className="faqarea-2-cnt-bottom-right">
                          <div className="faqarea-2-img">
                            <img src="/assets/images/faqarea-2-img.png" alt="faqarea-2-img" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <div className="accordion-header" id="headingTwo">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                      Advantages Of Paper Written&nbsp;By Professional For You
                    </button>
                  </div>
                  <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      <div className="faqarea-2-cnt-top">
                        <p>
                          Academic writing can become so hectic that you’ll often wonder, “Can someone please&nbsp;write my paper?” Hiring an essay writing service&nbsp;or something similar is the trend during these times. After all, when you hire&nbsp;academic writing services, you get to enjoy
                          many advantages.
                        </p>
                      </div>
                      <div className="faqarea-2-cnt-bottom">
                        <div className="faqarea-2-cnt-bottom-left">
                          <table className="table table-bordered">
                            <tbody>
                              <tr>
                                <td>Unique Solutions</td>
                                <td>Enjoy 100% plagiarism-free answers</td>
                              </tr>
                              <tr>
                                <td>Reputed Writers</td>
                                <td>Hire top scholars from the US</td>
                              </tr>
                              <tr>
                                <td>Wide Coverage</td>
                                <td>Get guidance on every topic</td>
                              </tr>
                              <tr>
                                <td>Timely Delivery</td>
                                <td>Meet every deadline with ease</td>
                              </tr>
                              <tr>
                                <td>Custom Papers</td>
                                <td>Customized solutions for A+ grades</td>
                              </tr>
                              <tr>
                                <td>Affordable Services</td>
                                <td>Look forward to jaw-dropping prices.</td>
                              </tr>
                            </tbody>
                          </table>
                          <p>You can enjoy all of these features and more when you hire MyAssignmentHelp. For example, we offer –</p>
                          <p>
                            Our primary objective is to provide you with high-quality services. We’re a one-stop destination for all your academic worries. If you need&nbsp;essay writing help&nbsp;or guidance with research papers, we've got you covered. Once you send us your requirements, we will
                            assign you the professional writer&nbsp;best suited for the task. You can also check the educational qualifications of our paper writers&nbsp;before booking them to write papers.
                          </p>
                          <div className="btn-group">
                            <Link to={"/submit-new-assignment"} className="custombtn btn-1 hover-filled-slide-down">
                              <span>Order Now</span>
                            </Link>
                          </div>
                        </div>
                        <div className="faqarea-2-cnt-bottom-right">
                          <div className="faqarea-2-img">
                            <img src="/assets/images/faqarea-2-img.png" alt="faqarea-2-img" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <div className="accordion-header" id="headingThree">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                      Why Do Students Prefer “Write My Paper” Services From MyAssignmentHelp
                    </button>
                  </div>
                  <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      <div className="faqarea-2-cnt-top">
                        <p>
                          Academic writing can become so hectic that you’ll often wonder, “Can someone please&nbsp;write my paper?” Hiring an essay writing service&nbsp;or something similar is the trend during these times. After all, when you hire&nbsp;academic writing services, you get to enjoy
                          many advantages.
                        </p>
                      </div>
                      <div className="faqarea-2-cnt-bottom">
                        <div className="faqarea-2-cnt-bottom-left">
                          <table className="table table-bordered">
                            <tbody>
                              <tr>
                                <td>Unique Solutions</td>
                                <td>Enjoy 100% plagiarism-free answers</td>
                              </tr>
                              <tr>
                                <td>Reputed Writers</td>
                                <td>Hire top scholars from the US</td>
                              </tr>
                              <tr>
                                <td>Wide Coverage</td>
                                <td>Get guidance on every topic</td>
                              </tr>
                              <tr>
                                <td>Timely Delivery</td>
                                <td>Meet every deadline with ease</td>
                              </tr>
                              <tr>
                                <td>Custom Papers</td>
                                <td>Customized solutions for A+ grades</td>
                              </tr>
                              <tr>
                                <td>Affordable Services</td>
                                <td>Look forward to jaw-dropping prices.</td>
                              </tr>
                            </tbody>
                          </table>
                          <p>You can enjoy all of these features and more when you hire MyAssignmentHelp. For example, we offer –</p>
                          <p>
                            Our primary objective is to provide you with high-quality services. We’re a one-stop destination for all your academic worries. If you need&nbsp;essay writing help&nbsp;or guidance with research papers, we've got you covered. Once you send us your requirements, we will
                            assign you the professional writer&nbsp;best suited for the task. You can also check the educational qualifications of our paper writers&nbsp;before booking them to write papers.
                          </p>
                          <div className="btn-group">
                            <Link to={"/submit-new-assignment"} className="custombtn btn-1 hover-filled-slide-down">
                              <span>Order Now</span>
                            </Link>
                          </div>
                        </div>
                        <div className="faqarea-2-cnt-bottom-right">
                          <div className="faqarea-2-img">
                            <img src="/assets/images/faqarea-2-img.png" alt="faqarea-2-img" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )
      ) : dataType === "plagiarism_checker" ? (
        ""
      ) : (
        ""
      )}
    </>
  );
};

export default FAQ2;
