import { Link } from "react-router-dom";

// Models
import { SectionWithCardsProp } from "../../../../models/views/components/pages";

let imgBaseUrl = process.env.REACT_APP_API_IMG_URL;

const Help = ({ className, headingOne = true, headingOneText, headingTwo = true, headingTwoText, data, helpButton = true, helpButtonText, helpButtonUrl }: SectionWithCardsProp) => {
  return (
    <section className={`gethelp-area ${className ? className : ""}`}>
      <div className="container">
        <div className="main-hdng text-center">
          {headingOne && <h2>{headingOneText ? headingOneText : "How to Get Help with Writing an Assignment?"}</h2>}
          {headingTwo && <p>{headingTwoText ? headingTwoText : "Finding Reliable Resources for Assignment Writing<"}</p>}
        </div>
        {data && (
          <div className="gethelp-bx-area">
            <div className="gethelp-line">
              <img src="/assets/images/gethelp-line.svg" alt="gethelp-line" />
            </div>
            {data.map((item, idx) => (
              <div key={idx} className="gethelp-bx">
                <div className="gethelp-bx-number">{item.heading}</div>
                <div className="gethelp-bx-img">
                  <img src={imgBaseUrl ? imgBaseUrl + item.icon?.data?.attributes?.url : ""} alt="gethelp-bx-img" />
                </div>
                <div className="gethelp-bx-text">
                  <h5>{item.subheading}</h5>
                  <p>{item.description}</p>
                </div>
              </div>
            ))}
          </div>
        )}
        {helpButton && (
          <div className="btn-group">
            <Link to={helpButtonUrl ? helpButtonUrl : "/submit-new-assignment"} className="custombtn btn-1 hover-filled-slide-down">
              <span>{helpButtonText ? helpButtonText : "Book A Solution"}</span>
            </Link>
          </div>
        )}
      </div>
    </section>
  );
};

export default Help;
