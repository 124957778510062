import { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";

// Redux actions
import { clearHomepageState } from "../../../../redux/slices/HomePageSlice";
import { clearBlogState } from "../../../../redux/slices/BlogSlice";
import { clearAboutUsPageState } from "../../../../redux/slices/AboutUsPageSlice";
import { clearUserState } from "../../../../redux/slices/UserSlice";
import { clearOrdersListState } from "../../../../redux/slices/OrdersSlice";
import { clearWriteMyPaperPageState } from "../../../../redux/slices/ServicePagesSlice";
import { clearSamplePageState } from "../../../../redux/slices/SamplePageSlice";

// Components
import { Sidebar } from "primereact/sidebar";
import LoginModal from "../../modals/LoginModal";

const imgBaseUrl = process.env.REACT_APP_API_IMG_URL;

function NavBar() {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [openSidebarLeft, setopenSidebarLeft] = useState(false);
  const [scrolling, setscrolling] = useState(false);
  const [selectHeaderItem, setselectHeaderItem] = useState("");
  const [sidemenuItem, setsidemenuItem] = useState("");
  const isLoggedIn = localStorage.getItem("logged_in");
  const [isActive, setIsActive] = useState(false);
  // Redux stores
  const { profilePageData } = useAppSelector((state) => state.userData);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setscrolling(window.scrollY > 10);
    });

    let path = location.pathname.split("/")[1] as string;

    let tabItem;
    let sideItem;

    // switch (path) {
    //   case "experts":
    //     tabItem = document.getElementById("header_item_2") as HTMLElement;
    //     tabItem.classList.add("active");
    //     setselectHeaderItem("header_item_2");
    //     break;
    //   case "write-my-paper":
    //     tabItem = document.getElementById("header_item_1") as HTMLElement;
    //     tabItem.classList.add("active");
    //     setselectHeaderItem("header_item_1");
    //     break;
    //   case "reviews":
    //     tabItem = document.getElementById("header_item_3") as HTMLElement;
    //     tabItem.classList.add("active");
    //     setselectHeaderItem("header_item_3");
    //     break;
    //   case "about-us":
    //     tabItem = document.getElementById("header_item_4") as HTMLElement;
    //     tabItem.classList.add("active");
    //     setselectHeaderItem("header_item_4");
    //     break;
    //   case "blogs":
    //     tabItem = document.getElementById("header_item_5") as HTMLElement;
    //     tabItem.classList.add("active");
    //     setselectHeaderItem("header_item_5");
    //     break;
    //   case "sample":
    //     tabItem = document.getElementById("header_item_5") as HTMLElement;
    //     tabItem.classList.add("active");
    //     setselectHeaderItem("header_item_5");
    //     break;
    //   default:
    //     break;
    // }
    function activateHeaderItem(path: string) {
      // Remove active class from all header items
      const headerItems = document.querySelectorAll("[id^='header_item_']");
      headerItems.forEach((item) => item.classList.remove("active"));

      let tabItem;
      switch (path) {
        case "experts":
          tabItem = document.getElementById("header_item_2");
          break;
        case "write-my-paper":
        case "submit-new-assignment":
        case "mba-essay-writing":
        case "write-my-essay-for-me":
        case "cheap-essay-writing":
        case "get-essay-help":
        case "research-proposal-writing-service":
        case "research-paper-writing":
        case "ghost-writer":
        case "academic-writing":
        case "programming-assignment-help":
        case "assessment-help":
        case "do-my-assignment":
        case "college-homework-help":
        case "urgent-assignment-help":
        case "pay-someone-to-do-my-homework":
        case "do-my-coursework":
        case "do-my-homework":
        case "take-my-online-class":
        case "dissertation-help":
        case "term-paper-help":
        case "homework-help":
        case "case-study-help":
        case "coursework-help":
        case "thesis-help":
        case "powerpoint-presentation-service":
          tabItem = document.getElementById("header_item_1");
          break;
        case "reviews":
          tabItem = document.getElementById("header_item_3");
          break;
        case "about-us":
          tabItem = document.getElementById("header_item_4");
          break;
        case "blogs":
        case "sample":
          tabItem = document.getElementById("header_item_5");
          break;
        case "coming-soon":
          tabItem = document.getElementById("header_item_6");
          break;
        default:
          break;
      }

      // Add active class to the matched item
      if (tabItem) {
        tabItem.classList.add("active");
      }
    }

    activateHeaderItem(path);
    // Side menu active for mobile and tab view
    if (openSidebarLeft) {
      const pathMappings = [
        {
          paths: [
            "/write-my-paper",
            "/submit-new-assignment",
            "/mba-essay-writing",
            "/write-my-essay-for-me",
            "/cheap-essay-writing",
            "/get-essay-help",
            "/research-proposal-writing-service",
            "/research-paper-writing",
            "/ghost-writer",
            "/academic-writing",
            "/programming-assignment-help",
            "/assessment-help",
            "/do-my-assignment",
            "/college-homework-help",
            "/urgent-assignment-help",
            "/pay-someone-to-do-my-homework",
            "/do-my-coursework",
            "/do-my-homework",
            "/take-my-online-class",
            "/take-my-online-exam",
            "/dissertation-help",
            "/term-paper-help",
            "/homework-help",
            "/case-study-help",
            "/coursework-help",
            "/thesis-help",
            "/powerpoint-presentation-service",
          ],
          sidemenuItem: "sidemenu_item_1_services",
        },
        { paths: ["/blogs", "/sample"], sidemenuItem: "sidemenu_item_5" },
        { paths: ["/coming-soon"], sidemenuItem: "sidemenu_item_6" },
      ];

      let matched = false;

      for (const { paths, sidemenuItem } of pathMappings) {
        if (paths.includes(location.pathname)) {
          setIsActive(true);
          setsidemenuItem(sidemenuItem);
          matched = true;
          break;
        }
      }

      if (!matched) {
        setIsActive(false);
      }
      switch (path) {
        case "experts":
          setsidemenuItem("sidemenu_item_2");
          break;
        case "reviews":
          setsidemenuItem("sidemenu_item_3");
          break;
        case "about-us":
          setsidemenuItem("sidemenu_item_4");
          break;

        default:
          break;
      }
    }
  }, [location.pathname, openSidebarLeft]);

  const handleLogout = () => {
    localStorage.clear();
    // dispatch(clearHomepageState());
    // dispatch(clearBlogState());
    dispatch(clearAboutUsPageState());
    dispatch(clearUserState());
    dispatch(clearOrdersListState());
    dispatch(clearWriteMyPaperPageState());
    dispatch(clearSamplePageState());

    navigate("/");
  };

  return (
    <>
      <div className="navbar-area">
        {/* Menu For tablet & mobile Device */}
        <div className="mobile-nav">
          <Link
            to="/"
            className="logo"
            onClick={() => {
              let tabItem = document.getElementById(selectHeaderItem) as HTMLElement;
              if (tabItem) {
                tabItem.classList.remove("active");
              }
            }}
          >
            <img className="img-fluid" src="/assets/images/logo.svg" alt="logo" />
          </Link>
          <div className="other-option-responsive">
            <a
              // href="#!"
              className="meanmenu-reveal"
              onClick={() => (openSidebarLeft === false ? setopenSidebarLeft(true) : setopenSidebarLeft(false))}
            >
              <span></span>
              <span></span>
              <span></span>
            </a>
            <Link to="/submit-new-assignment" className="custombtn btn-1 hover-filled-slide-down">
              <span>
                <img src="/assets/images/order-now-ico.svg" alt="icon" />
              </span>
            </Link>
            {isLoggedIn !== "yes" ? (
              <a href="#!" className="login-icon" data-bs-toggle="modal" data-bs-target="#exampleModal">
                <img src="/assets/images/login-ico.svg" alt="login-ico" />
              </a>
            ) : (
              <div className="dropdown-btn-sd">
                <a href="#! dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false" className="dp-dashboard-img">
                  <img src={profilePageData?.profile_pic === null ? "/assets/images/no-user.png" : imgBaseUrl ? imgBaseUrl + profilePageData?.profile_pic?.url : "/assets/images/no-user.png"} alt="dp-dashboard" />
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <Link className="dropdown-item" to="/user-profile">
                      My Profile
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/dashboard">
                      Dashboard
                    </Link>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      // href="#"
                      onClick={() => handleLogout()}
                    >
                      Logout
                    </a>
                  </li>
                </ul>
              </div>
            )}
          </div>

          {/* -- Mobile Sidebar Menu Start -- */}
          <Sidebar visible={openSidebarLeft} position="left" onHide={() => setopenSidebarLeft(false)}>
            <a href="#!" className="logo">
              <img src="/assets/images/logo.svg" alt="logo" />
            </a>
            <div className="accordion accordion-flush" id="accordionFlushExample">
              <div className="accordion-item">
                <div className="accordion-header" id="flush-headingOne">
                  <button className={`accordion-button collapsed ${isActive && sidemenuItem === "sidemenu_item_1_services" ? "active" : ""}`} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                    Services
                  </button>
                </div>
                <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                  <div className="accordion-body">
                    <ul>
                      <li>
                        <strong>Writing</strong>
                      </li>
                      <li>
                        <Link
                          to="/write-my-paper"
                          onClick={() => {
                            setopenSidebarLeft(false);
                          }}
                        >
                          Write My Paper
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/submit-new-assignment"
                          onClick={() => {
                            setopenSidebarLeft(false);
                          }}
                        >
                          Submit New Assigment
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/mba-essay-writing"
                          onClick={() => {
                            setopenSidebarLeft(false);
                          }}
                        >
                          MBA Assignment Help
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/write-my-essay-for-me"
                          onClick={() => {
                            setopenSidebarLeft(false);
                          }}
                        >
                          Write My Essay For Me
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/cheap-essay-writing"
                          onClick={() => {
                            setopenSidebarLeft(false);
                          }}
                        >
                          Cheap Essay Writing
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/get-essay-help"
                          onClick={() => {
                            setopenSidebarLeft(false);
                          }}
                        >
                          Get Essay Help
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/research-proposal-writing-service"
                          onClick={() => {
                            setopenSidebarLeft(false);
                          }}
                        >
                          Research Proposal Writing
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/research-paper-writing"
                          onClick={() => {
                            setopenSidebarLeft(false);
                          }}
                        >
                          Research Paper Writing
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/ghost-writer"
                          onClick={() => {
                            setopenSidebarLeft(false);
                          }}
                        >
                          Ghost Writer
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/academic-writing"
                          onClick={() => {
                            setopenSidebarLeft(false);
                          }}
                        >
                          Academic Writing
                        </Link>
                      </li>
                      <li>
                        <strong>Problem Solving</strong>
                      </li>
                      <li>
                        <Link
                          to="/programming-assignment-help"
                          onClick={() => {
                            setopenSidebarLeft(false);
                          }}
                        >
                          Programming Assignment Help
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/assessment-help"
                          onClick={() => {
                            setopenSidebarLeft(false);
                          }}
                        >
                          Assessment Help
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/do-my-assignment"
                          onClick={() => {
                            setopenSidebarLeft(false);
                          }}
                        >
                          Do My Assignment
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/college-homework-help"
                          onClick={() => {
                            setopenSidebarLeft(false);
                          }}
                        >
                          College Homework Help
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/urgent-assignment-help"
                          onClick={() => {
                            setopenSidebarLeft(false);
                          }}
                        >
                          Urgent Assignment Help
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/pay-someone-to-do-my-homework"
                          onClick={() => {
                            setopenSidebarLeft(false);
                          }}
                        >
                          Pay Someone To Do My Homework
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/do-my-coursework"
                          onClick={() => {
                            setopenSidebarLeft(false);
                          }}
                        >
                          Do My Coursework
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/do-my-homework"
                          onClick={() => {
                            setopenSidebarLeft(false);
                          }}
                        >
                          Do My Homework
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/take-my-online-class"
                          onClick={() => {
                            setopenSidebarLeft(false);
                          }}
                        >
                          Take My Online Class
                        </Link>
                      </li>
                      <li>
                        <strong>More Services</strong>
                      </li>
                      <li>
                        <Link
                          to="/take-my-online-exam"
                          onClick={() => {
                            setopenSidebarLeft(false);
                          }}
                        >
                          Take My Online Exam
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/dissertation-help"
                          onClick={() => {
                            setopenSidebarLeft(false);
                          }}
                        >
                          Dissertation Help
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/term-paper-help"
                          onClick={() => {
                            setopenSidebarLeft(false);
                          }}
                        >
                          Term Paper Help
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/homework-help"
                          onClick={() => {
                            setopenSidebarLeft(false);
                          }}
                        >
                          Homework Help
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/case-study-help"
                          onClick={() => {
                            setopenSidebarLeft(false);
                          }}
                        >
                          Case Study Help
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/coursework-help"
                          onClick={() => {
                            setopenSidebarLeft(false);
                          }}
                        >
                          Coursework Help
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/thesis-help"
                          onClick={() => {
                            setopenSidebarLeft(false);
                          }}
                        >
                          Thesis Help
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/powerpoint-presentation-service"
                          onClick={() => {
                            setopenSidebarLeft(false);
                          }}
                        >
                          Powerpoint Presentation Service
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* <a href="" id=""></a> */}
              <Link
                id="sidemenu_item_2"
                to="/experts"
                onClick={() => {
                  setopenSidebarLeft(false);
                  setsidemenuItem("sidemenu_item_2");
                }}
                className={`${sidemenuItem === "sidemenu_item_2" ? "active" : ""}`}
              >
                Experts
              </Link>
              <Link
                id="sidemenu_item_3"
                to="/reviews"
                onClick={() => {
                  setopenSidebarLeft(false);
                  setsidemenuItem("sidemenu_item_3");
                }}
                className={`${sidemenuItem === "sidemenu_item_3" ? "active" : ""}`}
              >
                Reviews
              </Link>
              <Link
                id="sidemenu_item_4"
                to="/about-us"
                onClick={() => {
                  setopenSidebarLeft(false);
                  setsidemenuItem("sidemenu_item_4");
                }}
                className={`${sidemenuItem === "sidemenu_item_4" ? "active" : ""}`}
              >
                About Us
              </Link>
              <div className="accordion-item">
                <div className="accordion-header" id="flush-headingTwo">
                  <button className={`accordion-button collapsed ${sidemenuItem === "sidemenu_item_5" ? "active" : ""}`} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                    Resources
                  </button>
                </div>
                <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                  <div className="accordion-body">
                    <ul>
                      <li>
                        <Link
                          to="/blogs"
                          onClick={() => {
                            setopenSidebarLeft(false);
                          }}
                        >
                          Blogs
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/sample"
                          onClick={() => {
                            setopenSidebarLeft(false);
                          }}
                        >
                          Sample
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <div className="accordion-header" id="flush-headingThree">
                  <button className={`accordion-button collapsed ${sidemenuItem === "sidemenu_item_6" ? "active" : ""}`} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                    Academic Tools
                  </button>
                </div>
                <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                  <div className="accordion-body">
                    <ul>
                      <li>
                        <Link
                          to="/coming-soon"
                          onClick={() => {
                            setopenSidebarLeft(false);
                          }}
                        >
                          Grammar Checker
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/coming-soon"
                          onClick={() => {
                            setopenSidebarLeft(false);
                          }}
                        >
                          Factoring Calculator
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/coming-soon"
                          onClick={() => {
                            setopenSidebarLeft(false);
                          }}
                        >
                          Equation Solver
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/coming-soon"
                          onClick={() => {
                            setopenSidebarLeft(false);
                          }}
                        >
                          Paraphrasing Tool
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/coming-soon"
                          onClick={() => {
                            setopenSidebarLeft(false);
                          }}
                        >
                          Plagiarism Checker
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </Sidebar>
          {/* -- Mobile Sidebar Menu End -- */}
        </div>

        {/* Menu For Desktop Device */}
        <div className="main-nav">
          <div className={`header_bottom ${scrolling ? "sticky" : ""}`}>
            <div className="container">
              <nav className="navbar navbar-expand-md navbar-light pb-1">
                <Link
                  className="navbar-brand"
                  to="/"
                  onClick={() => {
                    let tabItem = document.getElementById(selectHeaderItem) as HTMLElement;
                    if (tabItem) {
                      tabItem.classList.remove("active");
                    }
                  }}
                >
                  <img className="img-fluid" src="/assets/images/logo.svg" alt="logo" />
                </Link>
                <div className="navbar-collapse mean-menu" id="navbarSupportedContent">
                  <ul className="navbar-nav ms-auto">
                    <li id="header_item_1" className="nav-item hasChild">
                      <a href="#!" className={`nav-link dropdown-toggle `}>
                        Services
                      </a>
                      <ul className="dropdown-menu dropdown-megamenu" style={{ padding: "15px 15px", borderRadius: "10px" }}>
                        <li className="dropdown-submegamenu">
                          <ul>
                            <li>
                              <strong style={{ fontSize: "17px" }}>Writing</strong>
                            </li>
                            <li>
                              <Link to="/write-my-paper">Write My Paper</Link>
                            </li>
                            {/* <li>
                              <Link to="/submit-new-assignment">
                                Submit New Assigment
                              </Link>
                            </li> */}
                            <li>
                              <Link to="/mba-essay-writing">MBA Assignment Help</Link>
                            </li>
                            <li>
                              <Link to="/write-my-essay-for-me">Write My Essay For Me</Link>
                            </li>
                            <li>
                              <Link to="/cheap-essay-writing">Cheap Essay Writing</Link>
                            </li>
                            <li>
                              <Link to="/get-essay-help">Essay Help</Link>
                            </li>
                            <li>
                              <Link to="/research-proposal-writing-service">Research Proposal Writing</Link>
                            </li>
                            <li>
                              <Link to="/research-paper-writing">Research Paper Writing</Link>
                            </li>
                            <li>
                              <Link to="/ghost-writer">Ghost Writer</Link>
                            </li>
                            <li>
                              <Link to="/academic-writing">Academic Writing</Link>
                            </li>
                          </ul>
                        </li>

                        <li className="dropdown-submegamenu">
                          <ul>
                            <li>
                              <strong>Problem Solving</strong>
                            </li>
                            <li>
                              <Link to="/programming-assignment-help">Programming Assignment Help</Link>
                            </li>
                            <li>
                              <Link to="/assessment-help">Assessment Help</Link>
                            </li>
                            <li>
                              <Link to="/do-my-assignment">Do My Assignment</Link>
                            </li>
                            <li>
                              <Link to="/college-homework-help">College Homework Help</Link>
                            </li>
                            <li>
                              <Link to="/urgent-assignment-help">Urgent Assignment Help</Link>
                            </li>
                            <li>
                              <Link to="/pay-someone-to-do-my-homework">Pay Someone To Do My Homework</Link>
                            </li>
                            <li>
                              <Link to="/do-my-coursework">Do My Coursework</Link>
                            </li>
                            <li>
                              <Link to="/do-my-homework">Do My Homework</Link>
                            </li>
                            <li>
                              <Link to="/take-my-online-class">Take My Online Class</Link>
                            </li>
                          </ul>
                        </li>

                        <li className="dropdown-submegamenu">
                          <ul>
                            <li>
                              <strong>More Services</strong>
                            </li>
                            <li>
                              <Link to="/take-my-online-exam">Take My Online Exam</Link>
                            </li>
                            <li>
                              <Link to="/dissertation-help">Dissertation Help</Link>
                            </li>
                            <li>
                              <Link to="/term-paper-help">Term Paper Help</Link>
                            </li>
                            <li>
                              <Link to="/homework-help">Homework Help</Link>
                            </li>
                            <li>
                              <Link to="/case-study-help">Case Study Help</Link>
                            </li>
                            <li>
                              <Link to="/coursework-help">Coursework Help</Link>
                            </li>
                            <li>
                              <Link to="/thesis-help">Thesis Help</Link>
                            </li>
                            <li>
                              <Link to="/powerpoint-presentation-service">Powerpoint Presentation Service</Link>
                            </li>
                          </ul>
                        </li>

                        {/* <li>
                          <Link to="/write-my-paper">Write My Paper</Link>
                        </li>
                        <li>
                          <Link to="/submit-new-assignment">
                            Submit New Assigment
                          </Link>
                        </li>
                        <li>
                          <Link to="/mba-essay-writing">
                            MBA Assignment Help
                          </Link>
                        </li>
                        <li>
                          <Link to="/write-my-essay-for-me">
                            Write My Essay For Me
                          </Link>
                        </li>
                        <li>
                          <Link to="/cheap-essay-writing">
                            Cheap Essay Writing
                          </Link>
                        </li>
                        <li>
                          <Link to="/get-essay-help">Get Essay Help</Link>
                        </li> */}
                      </ul>
                    </li>
                    <li id="header_item_2" className={`nav-item ${selectHeaderItem === "header_item_2" ? "active" : ""}`}>
                      <Link to="/experts" className="nav-link" onClick={() => setselectHeaderItem("header_item_2")}>
                        Experts
                      </Link>
                    </li>
                    <li id="header_item_3" className={`nav-item ${selectHeaderItem === "header_item_3" ? "active" : ""}`}>
                      <Link to="/reviews" className="nav-link" onClick={() => setselectHeaderItem("header_item_3")}>
                        Reviews
                      </Link>
                    </li>
                    <li id="header_item_4" className={`nav-item ${selectHeaderItem === "header_item_4" ? "active" : ""}`}>
                      <Link to="/about-us" className="nav-link" onClick={() => setselectHeaderItem("header_item_4")}>
                        About us
                      </Link>
                    </li>
                    {(() => {
                      console.log("navcheck=====>", selectHeaderItem, "<====");
                      return <></>;
                    })()}
                    <li id="header_item_5" className={`nav-item ${selectHeaderItem === "header_item_5" ? "active" : ""} hasChild`}>
                      <a href="#!" className={`nav-link dropdown-toggle`}>
                        Resources
                      </a>
                      <ul className="dropdown-menu">
                        <li>
                          <Link to="/blogs">Blogs</Link>
                        </li>
                        <li>
                          <Link to="/sample">Sample</Link>
                        </li>
                      </ul>
                    </li>
                    <li id="header_item_6" className="nav-item hasChild">
                      <a href="#!" className="nav-link dropdown-toggle">
                        Academic Tools
                      </a>
                      <ul className="dropdown-menu">
                        <li>
                          <Link to="/coming-soon">Grammar Checker</Link>
                        </li>
                        <li>
                          <Link to="/coming-soon">Factoring Calculator</Link>
                        </li>
                        <li>
                          <Link to="/coming-soon">Equation Solver</Link>
                        </li>
                        <li>
                          <Link to="/coming-soon">Paraphrasing Tool</Link>
                        </li>
                        <li>
                          <Link to="/coming-soon">Plagiarism Checker</Link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <div className="other-option">
                    <Link to="/submit-new-assignment" className="custombtn btn-1 hover-filled-slide-down">
                      <span className="d-none d-xxl-block">order Now</span>
                      <span className="d-block d-xxl-none">
                        <img src="/assets/images/order-now-ico.svg" alt="icon" />
                      </span>
                    </Link>
                    {isLoggedIn !== "yes" ? (
                      <a href="#!" className="login-icon" data-bs-toggle="modal" data-bs-target="#exampleModal">
                        <img src="/assets/images/login-ico.svg" alt="login-ico" />
                      </a>
                    ) : (
                      <div className="dropdown-btn-sd">
                        <a href="#! dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false" className="dp-dashboard-img">
                          <img src={profilePageData?.profile_pic === null ? "/assets/images/no-user.png" : imgBaseUrl ? imgBaseUrl + profilePageData?.profile_pic?.url : "/assets/images/no-user.png"} alt="dp-dashboard" />
                        </a>
                        <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                          <li>
                            <Link className="dropdown-item" to="/user-profile">
                              My Profile
                            </Link>
                          </li>
                          <li>
                            <Link className="dropdown-item" to="/dashboard">
                              Dashboard
                            </Link>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              //href="#"
                              onClick={() => handleLogout()}
                            >
                              Logout
                            </a>
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>

      {/* -- Login Modal Start -- */}
      <LoginModal />
      {/* -- Login Modal End -- */}
    </>
  );
}

export default NavBar;
