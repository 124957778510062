import { useEffect, Suspense, lazy } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";

// Redux actions
import { getReviewsPage } from "../../../redux/slices/ReviewsSlice";

// Component
import LazyLoader from "../../components/layout/common/LazyLoader";

const ReviewPage = lazy(() => delayForDemo(import("../Reviews")));

export default function LazyReviewPage() {
  const dispatch = useAppDispatch();

  // Redux action
  const { reviewPageStatus, reviewPageData } = useAppSelector(
    (state) => state.reviewsPage
  );

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    if (reviewPageStatus !== "success") {
      dispatch(getReviewsPage());
    }
  }, []);

  return (
    <>
      <Suspense
        fallback={
          <LazyLoader
            pageBannerTitle={reviewPageData.sec_1_i_banner[0].heading}
            pageBannerDescription={reviewPageData.sec_1_i_banner[0].subheading}
            pageBannerReviewSites={
              reviewPageData.sec_1_i_banner[0].inner_banner_review
            }
          />
        }
      >
        <ReviewPage pageData={reviewPageData}/>
      </Suspense>
    </>
  );
}

// Add a fixed delay so you can see the loading state
async function delayForDemo(promise: Promise<typeof import("../Reviews")>) {
  await new Promise((resolve) => {
    setTimeout(resolve, 2000);
  });
  return await promise;
}
