import { Link } from "react-router-dom";

// Models
import { AssignmentWritersProps } from "../../../../models/views/components/pages";

// Constants
import { writers } from "../../../../constants/data/writers";

// Components
import Carousel from "react-multi-carousel";

import "react-multi-carousel/lib/styles.css";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1200 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1199, min: 768 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 767, min: 0 },
    items: 1,
  },
};

let imgBaseUrl = process.env.REACT_APP_API_IMG_URL;

const AssignmentWriters = ({ title1, title2, searchAndFilter = false, data }: AssignmentWritersProps) => {
  return (
    <>
      {searchAndFilter && (
        <div className="assignment-writers-filters">
          <div className="assignment-writers-filters-left">
            <select className="form-select" aria-label="Default select example">
              <option selected disabled>
                Select your subject
              </option>
              <option value={1}>All Subjects</option>
              <option value={2}>Mathematics</option>
              <option value={3}>English</option>
              <option value={4}>Accounts</option>
            </select>
          </div>
          <div className="btn-group">
            <a href="#!" className="custombtn btn-1 hover-filled-slide-down">
              <span>find expert</span>
            </a>
          </div>
        </div>
      )}
      <div className="main-hdng text-center">
        <h2>{title1}</h2>
        <p>{title2}</p>
      </div>
      {data ? (
        <Carousel
          swipeable={false}
          draggable={false}
          responsive={responsive}
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={4000}
          keyBoardControl={true}
          showDots={false}
          slidesToSlide={1}
          containerClass="carousel-container"
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
        >
          {data.map((item, idx) => (
            <div key={idx} className="assignment-writers-bx">
              <div className="writers-rating">
                <div className="writers-rating-bx">
                  <img src="/assets/images/star-ico.svg" alt="star-ico" />
                  <span>{item.rating}</span>
                </div>
              </div>
              <div className="assignment-writers-bx-top">
                <div className="subject-icon">
                  <img src={imgBaseUrl + item?.icon?.data?.attributes?.url} alt="subject-icon" />
                </div>
                <div className="subject-name">{item.heading}</div>
              </div>
              <div className="assignment-writers-bx-middle">
                <div className="writer-details">
                  <p>{item.description}</p>
                </div>
                <div className="rv-cp">
                  <div className="rv">
                    <img src="/assets/images/reviews-ico.svg" alt="reviews-ico" />
                    <span>
                      Reviews <strong>{item?.review_count?.split(" ")[item?.review_count?.split(" ").length - 1]}</strong>
                    </span>
                  </div>
                  <div className="cp">
                    <img src="/assets/images/complete-projects-ico.svg" alt="complete-projects-ico" />
                    <span>
                      Completed Project <strong>{item?.complete_project_count?.split(" ")[item.complete_project_count.split(" ").length - 1]}</strong>
                    </span>
                  </div>
                </div>
              </div>
              <div className="assignment-writers-bx-bottom">
                <div className="assignment-writers-bx-bottom-left">
                  <div className="writer-img" style={{ overflow: "unset" }}>
                    <img src={imgBaseUrl + item?.assignment_writer_imge?.data?.attributes?.url} alt="writer-img" />

                    <div className="online-icon"></div>
                  </div>
                  <div className="writer-designation">
                    <h6>{item.assignment_writer_name}</h6>
                    <p>{item.assignment_writer_education}</p>
                  </div>
                </div>
                <Link to="/submit-new-assignment" className="custom-border-btn btn-3 hover-filled-slide-down">
                  <span>Hire Me</span>
                </Link>
              </div>
            </div>
          ))}
        </Carousel>
      ) : (
        <Carousel
          swipeable={false}
          draggable={false}
          responsive={responsive}
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={4000}
          keyBoardControl={true}
          showDots={false}
          slidesToSlide={1}
          containerClass="carousel-container"
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
        >
          {writers.info.map((item, idx) => (
            <div key={idx} className="assignment-writers-bx">
              <div className="writers-rating">
                <div className="writers-rating-bx">
                  <img src="/assets/images/star-ico.svg" alt="star-ico" />
                  <span>{item.rating}</span>
                </div>
              </div>
              <div className="assignment-writers-bx-top">
                <div className="subject-icon">
                  <img src={item?.icon?.data?.attributes?.url} alt="subject-icon" />
                </div>
                <div className="subject-name">{item.heading}</div>
              </div>
              <div className="assignment-writers-bx-middle">
                <div className="writer-details">
                  <p>{item.description}</p>
                </div>
                <div className="rv-cp">
                  <div className="rv">
                    <img src="/assets/images/reviews-ico.svg" alt="reviews-ico" />
                    <span>
                      Reviews <strong>{item?.review_count?.split(" ")[item?.review_count?.split(" ").length - 1]}</strong>
                    </span>
                  </div>
                  <div className="cp">
                    <img src="/assets/images/complete-projects-ico.svg" alt="complete-projects-ico" />
                    <span>
                      Completed Project <strong>{item?.complete_project_count?.split(" ")[item.complete_project_count.split(" ").length - 1]}</strong>
                    </span>
                  </div>
                </div>
              </div>
              <div className="assignment-writers-bx-bottom">
                <div className="assignment-writers-bx-bottom-left">
                  <div className="writer-img">
                    <img src={item?.assignment_writer_imge?.data?.attributes?.url} alt="writer-img" />
                  </div>
                  <div className="writer-designation">
                    <h6>{item.assignment_writer_name}</h6>
                    <p>{item.assignment_writer_education}</p>
                  </div>
                </div>
                <Link to="/submit-new-assignment" className="custom-border-btn btn-3 hover-filled-slide-down">
                  <span>Hire Me</span>
                </Link>
              </div>
            </div>
          ))}
        </Carousel>
      )}
    </>
  );
};

export default AssignmentWriters;
