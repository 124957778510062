import {
  useEffect,
  // useState
} from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";

// Redux actions
import { getExpertsPageData } from "../../redux/slices/ExpertsPageSlice";

// Components
import PageBanner from "../components/layout/pages/PageBanner";
import AssignmentWriters from "../components/layout/pages/AssignmentWriters";
import Help from "../components/layout/pages/Help";

let imgBaseUrl = process.env.REACT_APP_API_IMG_URL;

const Experts = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // Redux stores
  const { expertsPageStatus, expertsPageData } = useAppSelector((state) => state.experts);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    if (expertsPageStatus !== "success") {
      dispatch(getExpertsPageData());
    }
  }, []);

  return (
    <>
      {/* page banner area start */}
      <PageBanner title={expertsPageData.sec_1_i_banner[0].heading} description={expertsPageData.sec_1_i_banner[0].subheading} reviewSites={expertsPageData.sec_1_i_banner[0].inner_banner_review} />
      {/* page banner area end */}

      {/* wrapper area start */}
      <div className="wrapper">
        {/* Assignment Writers */}
        <section className="assignment-writers-area white-bg assignment-writers-area-expertsPg">
          <div className="container">
            <div className="assignment-writers-bx-area">
              <AssignmentWriters
                title1={expertsPageData.sec_2_h1}
                title2={expertsPageData.sec_2_h2}
                // searchAndFilter={true}
                data={expertsPageData.sec_2_assignment_writer}
              />
            </div>
          </div>
        </section>

        {/* trust service */}
        <section className="trust-service-area gray-bg trust-service-area-expertsPg">
          <div className="container">
            <div className="main-hdng text-center">
              <h2>{expertsPageData.sec_3_h1}</h2>
            </div>
            <div className="trust-service-bx-area">
              {expertsPageData.sec_3_it_1.map((item, idx) => (
                <div key={idx} className="trust-service-bx">
                  <div className="trust-service-bx-single">
                    <div className="trust-service-bx-single-img">
                      <img src={imgBaseUrl ? imgBaseUrl + item.icon?.data?.attributes?.url : ""} alt="trust-service-bx-single-img" />
                    </div>
                    <div className="trust-service-bx-single-cnt">
                      <h5>{item.heading}</h5>
                      <p>{item.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* Get Help */}
        <Help headingOneText={expertsPageData.sec_4_h1} headingTwoText={expertsPageData.sec_4_h2} data={expertsPageData.sec_4_it_1} helpButton={false} />

        {/* Best Assignments Expert */}
        <section className="best-assignments-expert-area">
          <div className="container">
            <div className="main-hdng text-center">
              <h2>Choose The Best Assignments Expert Near Your Location</h2>
            </div>
            <div className="best-assignments-expert-filter">
              {/* <div className="best-assignments-expert-filter-bx">
                <select
                  className="form-select"
                  aria-label="Default select example"
                >
                  <option value={1} selected>
                    All Subjects
                  </option>
                  <option value={2}>Mathematics</option>
                  <option value={3}>English</option>
                  <option value={4}>Accounts</option>
                </select>
              </div>
              <div className="best-assignments-expert-filter-bx">
                <select
                  className="form-select"
                  aria-label="Default select example"
                >
                  <option value={1} selected>
                    All Ratings
                  </option>
                  <option value={2}>5 Star</option>
                  <option value={3}>4 Star</option>
                  <option value={4}>3 Star</option>
                  <option value={5}>2 Star</option>
                  <option value={6}>1 Star</option>
                </select>
              </div>
              <div className="best-assignments-expert-filter-bx">
                <select
                  className="form-select"
                  aria-label="Default select example"
                >
                  <option value={1} selected>
                    Orders Completed
                  </option>
                  <option value={2}>50 - 100</option>
                  <option value={3}>101 - 500</option>
                  <option value={4}>501 - 1000</option>
                  <option value={5}>1001 - 3000</option>
                  <option value={6}>3000+</option>
                </select>
              </div> */}
            </div>
            {expertsPageData.sec_2_assignment_writer.map((item, idx) => (
              <div key={idx} className="best-assignments-expert-details">
                <div className="best-assignments-expert-details-top">
                  <div className="baedt-left">
                    <div className="baedt-left-img">
                      <img src={imgBaseUrl + item?.assignment_writer_imge?.data?.attributes?.url} alt="baedt-left-img" />
                    </div>
                    <div className="baedt-left-cnt">
                      <h6>{item.assignment_writer_name}</h6>
                      <p>{item.assignment_writer_education}</p>
                      <div className="baedt-left-rating">
                        <img src="/assets/images/star-ico.svg" alt="star-ico" />
                        <img src="/assets/images/star-ico.svg" alt="star-ico" />
                        <img src="/assets/images/star-ico.svg" alt="star-ico" />
                        <img src="/assets/images/star-ico.svg" alt="star-ico" />
                        <img src="/assets/images/star-ico.svg" alt="star-ico" />
                        <span>{item.rating}</span>
                      </div>
                      <div className="assignment-writers-bx-top">
                        <div className="subject-icon">
                          <img src={imgBaseUrl + item?.icon?.data?.attributes?.url} alt="subject-icon" />
                        </div>
                        <div className="subject-name">{item.heading}</div>
                      </div>
                    </div>
                  </div>
                  <div className="baedt-middle">
                    <p>{item.description}</p>
                  </div>
                  <div className="baedt-right">
                    <div className="owl-carousel owl-carousel-2 owl-theme">
                      <div className="baedt-right-bx">
                        <h5>Assignment</h5>
                        <div className="baedt-left-rating">
                          <img src="/assets/images/star-ico.svg" alt="star-ico" />
                          <img src="/assets/images/star-ico.svg" alt="star-ico" />
                          <img src="/assets/images/star-ico.svg" alt="star-ico" />
                          <img src="/assets/images/star-ico.svg" alt="star-ico" />
                          <img src="/assets/images/star-ico.svg" alt="star-ico" />
                        </div>
                        <p>I am very happy with how fast the service is. The solution is also very accurate.</p>
                      </div>
                      <div className="baedt-right-bx">
                        <h5>Assignment</h5>
                        <div className="baedt-left-rating">
                          <img src="/assets/images/star-ico.svg" alt="star-ico" />
                          <img src="/assets/images/star-ico.svg" alt="star-ico" />
                          <img src="/assets/images/star-ico.svg" alt="star-ico" />
                          <img src="/assets/images/star-ico.svg" alt="star-ico" />
                          <img src="/assets/images/star-ico.svg" alt="star-ico" />
                        </div>
                        <p>Work completed on time. Very friendly assistance. Work completed with high quality. Affordable price.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="best-assignments-expert-details-bottom">
                  <div className="btn-group">
                    <Link to="/submit-new-assignment" className="custombtn btn-1 hover-filled-slide-down">
                      <span>Hire Me</span>
                    </Link>
                  </div>

                  <div className="rv-cp">
                    <div className="rv">
                      <img src="/assets/images/reviews-ico.svg" alt="reviews-ico" />
                      <span>
                        Reviews <strong>{item?.review_count?.split(" ")[item.review_count.split(" ").length - 1]}</strong>
                      </span>
                    </div>
                    <div className="cp">
                      <img src="/assets/images/complete-projects-ico.svg" alt="complete-projects-ico" />
                      <span>
                        Completed Project <strong>{item?.complete_project_count?.split(" ")[item.complete_project_count.split(" ").length - 1]}</strong>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </section>
      </div>
      {/* wrapper area end */}
    </>
  );
};

export default Experts;
