import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

// Helpers
import { ApiHelperFunction } from "../../helpers/api_helpers";

// Typeguards
import { isApiErrorResponse } from "../../helpers/typeguards";

// Models
import { ApiFuncArgProps } from "../../models/apiFuncHelpers";
import {
  ServicePagesDataModel,
  ServicePagesDataModel2,
  ServicePagesDataModel3,
} from "../../models/views/pages";

interface ServicePagesInitialState {
  writeMyPaperPageStatus: string;
  writeMyPaperPageData: ServicePagesDataModel;
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  mbaPageStatus: string;
  mbaPageData: ServicePagesDataModel2;
  mbaPageIsLoading: boolean;
  mbaPageIsSuccess: boolean;
  mbaPageIsError: boolean;
  essayPageStatus: string;
  essayPageData: ServicePagesDataModel3;
  essayPageIsLoading: boolean;
  essayPageIsSuccess: boolean;
  essayPageIsError: boolean;
  cheapEssayStatus: string;
  cheapEssayData: ServicePagesDataModel3;
  cheapEssayIsLoading: boolean;
  cheapEssayIsSuccess: boolean;
  cheapEssayIsError: boolean;
  essayHelpPageStatus: string;
  essayHelpPageData: ServicePagesDataModel3;
  essayHelpPageIsLoading: boolean;
  essayHelpPageIsSuccess: boolean;
  essayHelpPageIsError: boolean;
  researchPageStatus: string;
  researchPageData: ServicePagesDataModel3;
  researchPageIsLoading: boolean;
  researchPageIsSuccess: boolean;
  researchPageIsError: boolean;
  researchWritingPageStatus: string;
  researchWritingPageData: ServicePagesDataModel3;
  researchWritingPageIsLoading: boolean;
  researchWritingPageIsSuccess: boolean;
  researchWritingPageIsError: boolean;
  ghostWriterPageStatus: string;
  ghostWriterPageData: ServicePagesDataModel3;
  ghostWriterPageIsLoading: boolean;
  ghostWriterIsSuccess: boolean;
  ghostWriterPageIsError: boolean;
  academicWritingPageStatus: string;
  academicWritingPageData: ServicePagesDataModel3;
  academicWritingPageIsLoading: boolean;
  academicWritingPageIsSuccess: boolean;
  academicWritingPageIsError: boolean;
  programmingHelpPageStatus: string;
  programmingHelpPageData: ServicePagesDataModel3;
  programmingHelpPageIsLoading: boolean;
  programmingHelpPageIsSuccess: boolean;
  programmingHelpPageIsError: boolean;
  assessmentHelpPageStatus: string;
  assessmentHelpPageData: ServicePagesDataModel3;
  assessmentHelpPageIsLoading: boolean;
  assessmentHelpPageIsSuccess: boolean;
  assessmentHelpPageIsError: boolean;
  doMyAssignmentPageStatus: string;
  doMyAssignmentPageData: ServicePagesDataModel3;
  doMyAssignmentPageIsLoading: boolean;
  doMyAssignmentPageIsSuccess: boolean;
  doMyAssignmentPageIsError: boolean;
  collegeHomeworkPageStatus: string;
  collegeHomeworkPageData: ServicePagesDataModel3;
  collegeHomeworkPageIsLoading: boolean;
  collegeHomeworkPageIsSuccess: boolean;
  collegeHomeworkPageIsError: boolean;
  urgentHelpPageStatus: string;
  urgentHelpPageData: ServicePagesDataModel3;
  urgentHelpPageIsLoading: boolean;
  urgentHelpPageIsSuccess: boolean;
  urgentHelpPageIsError: boolean;
  paySomeonePageStatus: string;
  paySomeonePageData: ServicePagesDataModel3;
  paySomeonePageIsLoading: boolean;
  paySomeonePageIsSuccess: boolean;
  paySomeonePageIsError: boolean;
  doMyCourseworkPageStatus: string;
  doMyCourseworkPageData: ServicePagesDataModel3;
  doMyCourseworkIsLoading: boolean;
  doMyCourseworkIsSuccess: boolean;
  doMyCourseworkIsError: boolean;
  doMyHomeworkPageStatus: string;
  doMyHomeworkPageData: ServicePagesDataModel3;
  doMyHomeworkIsLoading: boolean;
  doMyHomeworkIsSuccess: boolean;
  doMyHomeworkIsError: boolean;
  onlineClassPageStatus: string;
  onlineClassPageData: ServicePagesDataModel3;
  onlineClassPageIsLoading: boolean;
  onlineClassPageIsSuccess: boolean;
  onlineClassPageIsError: boolean;
  onlineExamPageStatus: string;
  onlineExamPageData: ServicePagesDataModel3;
  onlineExamPageIsLoading: boolean;
  onlineExamPageIsSuccess: boolean;
  onlineExamPageIsError: boolean;
  dissertationHelpPageStatus: string;
  dissertationHelpPageData: ServicePagesDataModel3;
  dissertationHelpPageIsLoading: boolean;
  dissertationHelpPageIsSuccess: boolean;
  dissertationHelpPageIsError: boolean;
  termPaperHelpPageStatus: string;
  termPaperHelpPageData: ServicePagesDataModel3;
  termPaperHelpPageIsLoading: boolean;
  termPaperHelpPageIsSuccess: boolean;
  termPaperHelpPageIsError: boolean;
  homeworkHelpPageStatus: string;
  homeworkHelpPageData: ServicePagesDataModel3;
  homeworkHelpPageIsLoading: boolean;
  homeworkHelpPageIsSuccess: boolean;
  homeworkHelpPageIsError: boolean;
  caseStudyHelpPageStatus: string;
  caseStudyHelpPageData: ServicePagesDataModel3;
  caseStudyHelpPageIsLoading: boolean;
  caseStudyHelpPageIsSuccess: boolean;
  caseStudyHelpPageIsError: boolean;
  courseworkHelpPageStatus: string;
  courseworkHelpPageData: ServicePagesDataModel3;
  courseworkHelpPageIsLoading: boolean;
  courseworkHelpPageIsSuccess: boolean;
  courseworkHelpPageIsError: boolean;
  thesisHelpPageStatus: string;
  thesisHelpPageData: ServicePagesDataModel3;
  thesisHelpPageIsLoading: boolean;
  thesisHelpPageIsSuccess: boolean;
  thesisHelpPageIsError: boolean;
  powerpointPageStatus: string;
  powerpointPageData: ServicePagesDataModel3;
  powerpointPageIsLoading: boolean;
  powerpointPageIsSuccess: boolean;
  powerpointPageIsError: boolean;
}

let commonDataFormat: ServicePagesDataModel = {
  sec_2_h1: "",
  sec_2_h2: "",
  sec_3_h1: "",
  sec_3_h2: "",
  createdAt: "",
  updatedAt: "",
  publishedAt: "",
  sec_4_h1: "",
  sec_4_h2: "",
  sec_1_i_banner: [
    {
      id: 0,
      heading: "",
      subheading: "",
      inner_banner_review: [],
    },
  ],
  sec_2_assignment_writer: [],
  sec_3_it_1: [],
  sec_4_it_1: [],
};

let commonDataFormat2: ServicePagesDataModel2 = {
  createdAt: "",
  updatedAt: "",
  publishedAt: "",
  sec_1_i_banner: [
    {
      id: 0,
      heading: "",
      subheading: "",
      inner_banner_review: [],
    },
  ],
  sec_main: [
    {
      id: 0,
      sec_2_h1: "",
      sec_2_h2: "",
      sec_3_h1: "",
      sec_3_h2: "",
      sec_4_h1: "",
      sec_4_h2: "",
      sec_2_assignment_writer: [],
      sec_3_it_1: [],
      sec_4_it_1: [],
      sec_5_at: [],
    },
  ],
};

let commonDataFormat3: ServicePagesDataModel3 = {
  createdAt: "",
  updatedAt: "",
  publishedAt: "",
  sec_1_i_banner: [
    {
      id: 0,
      heading: "",
      subheading: "",
      inner_banner_review: [],
    },
  ],
  sec_main: {
    id: 0,
    sec_2_h1: "",
    sec_2_h2: "",
    sec_3_h1: "",
    sec_3_h2: "",
    sec_4_h1: "",
    sec_4_h2: "",
    sec_2_assignment_writer: [],
    sec_3_it_1: [],
    sec_4_it_1: [],
    sec_5_at: [],
  },
};

const initialState: ServicePagesInitialState = {
  writeMyPaperPageStatus: "idle",
  writeMyPaperPageData: commonDataFormat,
  isLoading: false,
  isSuccess: false,
  isError: false,
  mbaPageStatus: "idle",
  mbaPageData: commonDataFormat2,
  mbaPageIsLoading: false,
  mbaPageIsSuccess: false,
  mbaPageIsError: false,
  essayPageStatus: "idle",
  essayPageData: commonDataFormat3,
  essayPageIsLoading: false,
  essayPageIsSuccess: false,
  essayPageIsError: false,
  cheapEssayStatus: "idle",
  cheapEssayData: commonDataFormat3,
  cheapEssayIsLoading: false,
  cheapEssayIsSuccess: false,
  cheapEssayIsError: false,
  essayHelpPageStatus: "idle",
  essayHelpPageData: commonDataFormat3,
  essayHelpPageIsLoading: false,
  essayHelpPageIsSuccess: false,
  essayHelpPageIsError: false,
  researchPageStatus: "idle",
  researchPageData: commonDataFormat3,
  researchPageIsLoading: false,
  researchPageIsSuccess: false,
  researchPageIsError: false,
  researchWritingPageStatus: "idle",
  researchWritingPageData: commonDataFormat3,
  researchWritingPageIsLoading: false,
  researchWritingPageIsSuccess: false,
  researchWritingPageIsError: false,
  ghostWriterPageStatus: "idle",
  ghostWriterPageData: commonDataFormat3,
  ghostWriterPageIsLoading: false,
  ghostWriterIsSuccess: false,
  ghostWriterPageIsError: false,
  academicWritingPageStatus: "idle",
  academicWritingPageData: commonDataFormat3,
  academicWritingPageIsLoading: false,
  academicWritingPageIsSuccess: false,
  academicWritingPageIsError: false,
  programmingHelpPageStatus: "idle",
  programmingHelpPageData: commonDataFormat3,
  programmingHelpPageIsLoading: false,
  programmingHelpPageIsSuccess: false,
  programmingHelpPageIsError: false,
  assessmentHelpPageStatus: "idle",
  assessmentHelpPageData: commonDataFormat3,
  assessmentHelpPageIsLoading: false,
  assessmentHelpPageIsSuccess: false,
  assessmentHelpPageIsError: false,
  doMyAssignmentPageStatus: "idle",
  doMyAssignmentPageData: commonDataFormat3,
  doMyAssignmentPageIsLoading: false,
  doMyAssignmentPageIsSuccess: false,
  doMyAssignmentPageIsError: false,
  collegeHomeworkPageStatus: "idle",
  collegeHomeworkPageData: commonDataFormat3,
  collegeHomeworkPageIsLoading: false,
  collegeHomeworkPageIsSuccess: false,
  collegeHomeworkPageIsError: false,
  urgentHelpPageStatus: "idle",
  urgentHelpPageData: commonDataFormat3,
  urgentHelpPageIsLoading: false,
  urgentHelpPageIsSuccess: false,
  urgentHelpPageIsError: false,
  paySomeonePageStatus: "idle",
  paySomeonePageData: commonDataFormat3,
  paySomeonePageIsLoading: false,
  paySomeonePageIsSuccess: false,
  paySomeonePageIsError: false,
  doMyCourseworkPageStatus: "idle",
  doMyCourseworkPageData: commonDataFormat3,
  doMyCourseworkIsLoading: false,
  doMyCourseworkIsSuccess: false,
  doMyCourseworkIsError: false,
  doMyHomeworkPageStatus: "idle",
  doMyHomeworkPageData: commonDataFormat3,
  doMyHomeworkIsLoading: false,
  doMyHomeworkIsSuccess: false,
  doMyHomeworkIsError: false,
  onlineClassPageStatus: "idle",
  onlineClassPageData: commonDataFormat3,
  onlineClassPageIsLoading: false,
  onlineClassPageIsSuccess: false,
  onlineClassPageIsError: false,
  onlineExamPageStatus: "idle",
  onlineExamPageData: commonDataFormat3,
  onlineExamPageIsLoading: false,
  onlineExamPageIsSuccess: false,
  onlineExamPageIsError: false,
  dissertationHelpPageStatus: "idle",
  dissertationHelpPageData: commonDataFormat3,
  dissertationHelpPageIsLoading: false,
  dissertationHelpPageIsSuccess: false,
  dissertationHelpPageIsError: false,
  termPaperHelpPageStatus: "idle",
  termPaperHelpPageData: commonDataFormat3,
  termPaperHelpPageIsLoading: false,
  termPaperHelpPageIsSuccess: false,
  termPaperHelpPageIsError: false,
  homeworkHelpPageStatus: "idle",
  homeworkHelpPageData: commonDataFormat3,
  homeworkHelpPageIsLoading: false,
  homeworkHelpPageIsSuccess: false,
  homeworkHelpPageIsError: false,
  caseStudyHelpPageStatus: "idle",
  caseStudyHelpPageData: commonDataFormat3,
  caseStudyHelpPageIsLoading: false,
  caseStudyHelpPageIsSuccess: false,
  caseStudyHelpPageIsError: false,
  courseworkHelpPageStatus: "idle",
  courseworkHelpPageData: commonDataFormat3,
  courseworkHelpPageIsLoading: false,
  courseworkHelpPageIsSuccess: false,
  courseworkHelpPageIsError: false,
  thesisHelpPageStatus: "idle",
  thesisHelpPageData: commonDataFormat3,
  thesisHelpPageIsLoading: false,
  thesisHelpPageIsSuccess: false,
  thesisHelpPageIsError: false,
  powerpointPageStatus: "idle",
  powerpointPageData: commonDataFormat3,
  powerpointPageIsLoading: false,
  powerpointPageIsSuccess: false,
  powerpointPageIsError: false,
};

export const getWriteMyPaperPage = createAsyncThunk(
  "get_write_my_paper_page",
  async () => {
    try {
      let response = await ApiHelperFunction({
        urlPath: "/write-my-paper-page?populate=deep",
        method: "GET",
      } as ApiFuncArgProps);

      if (isApiErrorResponse(response)) {
        console.log("Error: ", response.error.message);
      } else {
        return response.data;
      }
    } catch (error: any) {
      console.log("Failed to fetch write my paper page due to ", error.message);
    }
  }
);

export const getMbaAssignmentPage = createAsyncThunk(
  "get_mba_assignment_page",
  async () => {
    try {
      let response = await ApiHelperFunction({
        urlPath: "/mba-essay-writing-service?populate=deep",
        method: "GET",
      } as ApiFuncArgProps);

      if (isApiErrorResponse(response)) {
        console.log("Error: ", response.error.message);
      } else {
        return response.data;
      }
    } catch (error: any) {
      console.log("Failed to fetch write my paper page due to ", error.message);
    }
  }
);

export const getWriteMyEssayPage = createAsyncThunk(
  "get_write_my_essay_page",
  async () => {
    try {
      let response = await ApiHelperFunction({
        urlPath: "/write-my-essay-for-me?populate=deep",
        method: "GET",
      } as ApiFuncArgProps);

      if (isApiErrorResponse(response)) {
        console.log("Error: ", response.error.message);
      } else {
        return response.data;
      }
    } catch (error: any) {
      console.log("Failed to fetch write my essay page due to ", error.message);
    }
  }
);

export const getCheapEssayPage = createAsyncThunk(
  "get_cheap_essay_page",
  async () => {
    try {
      let response = await ApiHelperFunction({
        urlPath: "/cheap-essay-writing-service?populate=deep",
        method: "GET",
      } as ApiFuncArgProps);

      if (isApiErrorResponse(response)) {
        console.log("Error: ", response.error.message);
      } else {
        return response.data;
      }
    } catch (error: any) {
      console.log("Failed to fetch write my essay page due to ", error.message);
    }
  }
);

export const getEssayHelpPage = createAsyncThunk(
  "get_essay_help_page",
  async () => {
    try {
      let response = await ApiHelperFunction({
        urlPath: "/essay-help?populate=deep",
        method: "GET",
      } as ApiFuncArgProps);

      if (isApiErrorResponse(response)) {
        console.log("Error: ", response.error.message);
      } else {
        return response.data;
      }
    } catch (error: any) {
      console.log("Failed to fetch essay help page due to ", error.message);
    }
  }
);

export const getResearchPage = createAsyncThunk(
  "get_research_proposal_page",
  async () => {
    try {
      let response = await ApiHelperFunction({
        urlPath: "/research-proposal-writing?populate=deep",
        method: "GET",
      } as ApiFuncArgProps);

      if (isApiErrorResponse(response)) {
        console.log("Error: ", response.error.message);
      } else {
        return response.data;
      }
    } catch (error: any) {
      console.log(
        "Failed to fetch research proposal page due to ",
        error.message
      );
    }
  }
);

export const getResearchWritingPage = createAsyncThunk(
  "get_research_writing_page",
  async () => {
    try {
      let response = await ApiHelperFunction({
        urlPath: "/research-paper-writing?populate=deep",
        method: "GET",
      } as ApiFuncArgProps);

      if (isApiErrorResponse(response)) {
        console.log("Error: ", response.error.message);
      } else {
        return response.data;
      }
    } catch (error: any) {
      console.log(
        "Failed to fetch research writing page due to ",
        error.message
      );
    }
  }
);

export const getGhostWriterPage = createAsyncThunk(
  "get_ghost_writer_page",
  async () => {
    try {
      let response = await ApiHelperFunction({
        urlPath: "/ghost-writer-online?populate=deep",
        method: "GET",
      } as ApiFuncArgProps);

      if (isApiErrorResponse(response)) {
        console.log("Error: ", response.error.message);
      } else {
        return response.data;
      }
    } catch (error: any) {
      console.log("Failed to fetch ghost writer page due to ", error.message);
    }
  }
);

export const getAcademicWritingPage = createAsyncThunk(
  "get_academic_writing_page",
  async () => {
    try {
      let response = await ApiHelperFunction({
        urlPath: "/academic-writing-service?populate=deep",
        method: "GET",
      } as ApiFuncArgProps);

      if (isApiErrorResponse(response)) {
        console.log("Error: ", response.error.message);
      } else {
        return response.data;
      }
    } catch (error: any) {
      console.log(
        "Failed to fetch academic writing page due to ",
        error.message
      );
    }
  }
);

export const getProgrammingHelpPage = createAsyncThunk(
  "get_programming_help_page",
  async () => {
    try {
      let response = await ApiHelperFunction({
        urlPath: "/programming-assignment-help?populate=deep",
        method: "GET",
      } as ApiFuncArgProps);

      if (isApiErrorResponse(response)) {
        console.log("Error: ", response.error.message);
      } else {
        return response.data;
      }
    } catch (error: any) {
      console.log(
        "Failed to fetch programming help page due to ",
        error.message
      );
    }
  }
);

export const getAssessmentHelpPage = createAsyncThunk(
  "get_assessment_help_page",
  async () => {
    try {
      let response = await ApiHelperFunction({
        urlPath: "/online-assessment-help?populate=deep",
        method: "GET",
      } as ApiFuncArgProps);

      if (isApiErrorResponse(response)) {
        console.log("Error: ", response.error.message);
      } else {
        return response.data;
      }
    } catch (error: any) {
      console.log(
        "Failed to fetch assessment help page due to ",
        error.message
      );
    }
  }
);

export const getDoMyAssignmentPage = createAsyncThunk(
  "get_do_my_assignment_page",
  async () => {
    try {
      let response = await ApiHelperFunction({
        urlPath: "/do-my-assignment?populate=deep",
        method: "GET",
      } as ApiFuncArgProps);

      if (isApiErrorResponse(response)) {
        console.log("Error: ", response.error.message);
      } else {
        return response.data;
      }
    } catch (error: any) {
      console.log(
        "Failed to fetch do my assignment page due to ",
        error.message
      );
    }
  }
);

export const getCollegeHomeworkPage = createAsyncThunk(
  "get_college_homework_page",
  async () => {
    try {
      let response = await ApiHelperFunction({
        urlPath: "/college-homework?populate=deep",
        method: "GET",
      } as ApiFuncArgProps);

      if (isApiErrorResponse(response)) {
        console.log("Error: ", response.error.message);
      } else {
        return response.data;
      }
    } catch (error: any) {
      console.log(
        "Failed to fetch college homework help page due to ",
        error.message
      );
    }
  }
);

export const getUrgentHelpPage = createAsyncThunk(
  "get_urgent_help_page",
  async () => {
    try {
      let response = await ApiHelperFunction({
        urlPath: "/urgent-assignment?populate=deep",
        method: "GET",
      } as ApiFuncArgProps);

      if (isApiErrorResponse(response)) {
        console.log("Error: ", response.error.message);
      } else {
        return response.data;
      }
    } catch (error: any) {
      console.log("Failed to fetch urgent help page due to ", error.message);
    }
  }
);

export const getPaySomeonePage = createAsyncThunk(
  "get_pay_someone_page",
  async () => {
    try {
      let response = await ApiHelperFunction({
        urlPath: "/pay-someone-to-do?populate=deep",
        method: "GET",
      } as ApiFuncArgProps);

      if (isApiErrorResponse(response)) {
        console.log("Error: ", response.error.message);
      } else {
        return response.data;
      }
    } catch (error: any) {
      console.log(
        "Failed to fetch pay someone for homework page due to ",
        error.message
      );
    }
  }
);

export const getDoMyCourseworkPage = createAsyncThunk(
  "get_do_my_coursework_page",
  async () => {
    try {
      let response = await ApiHelperFunction({
        urlPath: "/my-coursework-online?populate=deep",
        method: "GET",
      } as ApiFuncArgProps);

      if (isApiErrorResponse(response)) {
        console.log("Error: ", response.error.message);
      } else {
        return response.data;
      }
    } catch (error: any) {
      console.log(
        "Failed to fetch do my coursework page due to ",
        error.message
      );
    }
  }
);

export const getDoMyHomeworkPage = createAsyncThunk(
  "get_do_my_homework_page",
  async () => {
    try {
      let response = await ApiHelperFunction({
        urlPath: "/do-my-homework?populate=deep",
        method: "GET",
      } as ApiFuncArgProps);

      if (isApiErrorResponse(response)) {
        console.log("Error: ", response.error.message);
      } else {
        return response.data;
      }
    } catch (error: any) {
      console.log("Failed to fetch do my homework page due to ", error.message);
    }
  }
);

export const getOnlineClassPage = createAsyncThunk(
  "get_online_class_page",
  async () => {
    try {
      let response = await ApiHelperFunction({
        urlPath: "/take-online-class?populate=deep",
        method: "GET",
      } as ApiFuncArgProps);

      if (isApiErrorResponse(response)) {
        console.log("Error: ", response.error.message);
      } else {
        return response.data;
      }
    } catch (error: any) {
      console.log("Failed to fetch online class page due to ", error.message);
    }
  }
);

export const getOnlineExamPage = createAsyncThunk(
  "get_online_exam_page",
  async () => {
    try {
      let response = await ApiHelperFunction({
        urlPath: "/take-online-exam?populate=deep",
        method: "GET",
      } as ApiFuncArgProps);

      if (isApiErrorResponse(response)) {
        console.log("Error: ", response.error.message);
      } else {
        return response.data;
      }
    } catch (error: any) {
      console.log("Failed to fetch online exam page due to ", error.message);
    }
  }
);

export const getDissertationHelpPage = createAsyncThunk(
  "get_dissertation_help_page",
  async () => {
    try {
      let response = await ApiHelperFunction({
        urlPath: "/dissertation-help?populate=deep",
        method: "GET",
      } as ApiFuncArgProps);

      if (isApiErrorResponse(response)) {
        console.log("Error: ", response.error.message);
      } else {
        return response.data;
      }
    } catch (error: any) {
      console.log(
        "Failed to fetch dissertation help page due to ",
        error.message
      );
    }
  }
);

export const getTermPaperHelpPage = createAsyncThunk(
  "get_term_paper_help_page",
  async () => {
    try {
      let response = await ApiHelperFunction({
        urlPath: "/team-paper-help?populate=deep",
        method: "GET",
      } as ApiFuncArgProps);

      if (isApiErrorResponse(response)) {
        console.log("Error: ", response.error.message);
      } else {
        return response.data;
      }
    } catch (error: any) {
      console.log(
        "Failed to fetch term paper help page due to ",
        error.message
      );
    }
  }
);

export const getHomeworkHelpPage = createAsyncThunk(
  "get_homework_help_page",
  async () => {
    try {
      let response = await ApiHelperFunction({
        urlPath: "/homework-help?populate=deep",
        method: "GET",
      } as ApiFuncArgProps);

      if (isApiErrorResponse(response)) {
        console.log("Error: ", response.error.message);
      } else {
        return response.data;
      }
    } catch (error: any) {
      console.log("Failed to fetch homework help page due to ", error.message);
    }
  }
);

export const getCaseStudyHelpPage = createAsyncThunk(
  "get_case_study_help_page",
  async () => {
    try {
      let response = await ApiHelperFunction({
        urlPath: "/case-study-help?populate=deep",
        method: "GET",
      } as ApiFuncArgProps);

      if (isApiErrorResponse(response)) {
        console.log("Error: ", response.error.message);
      } else {
        return response.data;
      }
    } catch (error: any) {
      console.log(
        "Failed to fetch case study help page due to ",
        error.message
      );
    }
  }
);

export const getCourseworkHelpPage = createAsyncThunk(
  "get_course_work_help_page",
  async () => {
    try {
      let response = await ApiHelperFunction({
        urlPath: "/coursework-help?populate=deep",
        method: "GET",
      } as ApiFuncArgProps);

      if (isApiErrorResponse(response)) {
        console.log("Error: ", response.error.message);
      } else {
        return response.data;
      }
    } catch (error: any) {
      console.log(
        "Failed to fetch course work help page due to ",
        error.message
      );
    }
  }
);

export const getThesisHelpPage = createAsyncThunk(
  "get_thesis_help_page",
  async () => {
    try {
      let response = await ApiHelperFunction({
        urlPath: "/thesis-help?populate=deep",
        method: "GET",
      } as ApiFuncArgProps);

      if (isApiErrorResponse(response)) {
        console.log("Error: ", response.error.message);
      } else {
        return response.data;
      }
    } catch (error: any) {
      console.log(
        "Failed to fetch thesis work help page due to ",
        error.message
      );
    }
  }
);

export const getPowerpointPage = createAsyncThunk(
  "get_powerpoint_page",
  async () => {
    try {
      let response = await ApiHelperFunction({
        urlPath: "/power-point-presentation-writing?populate=deep",
        method: "GET",
      } as ApiFuncArgProps);

      if (isApiErrorResponse(response)) {
        console.log("Error: ", response.error.message);
      } else {
        return response.data;
      }
    } catch (error: any) {
      console.log(
        "Failed to fetch thesis powerpoint page due to ",
        error.message
      );
    }
  }
);

export const servicePagesSlice = createSlice({
  name: "service_pages",
  initialState,
  reducers: {
    clearWriteMyPaperPageState: (state) => {
      state.writeMyPaperPageStatus = "idle";
      state.writeMyPaperPageData = commonDataFormat;
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.mbaPageStatus = "idle";
      state.mbaPageData = commonDataFormat2;
      state.mbaPageIsLoading = false;
      state.mbaPageIsSuccess = false;
      state.mbaPageIsError = false;
      state.essayPageStatus = "idle";
      state.essayPageData = commonDataFormat3;
      state.essayPageIsLoading = false;
      state.essayPageIsSuccess = false;
      state.essayPageIsError = false;
      state.cheapEssayStatus = "idle";
      state.cheapEssayData = commonDataFormat3;
      state.cheapEssayIsLoading = false;
      state.cheapEssayIsSuccess = false;
      state.cheapEssayIsError = false;
      state.essayHelpPageStatus = "idle";
      state.essayHelpPageData = commonDataFormat3;
      state.essayHelpPageIsLoading = false;
      state.essayHelpPageIsSuccess = false;
      state.essayHelpPageIsError = false;
      state.researchPageStatus = "idle";
      state.researchPageData = commonDataFormat3;
      state.researchPageIsLoading = false;
      state.researchPageIsSuccess = false;
      state.researchPageIsError = false;
      state.researchWritingPageStatus = "idle";
      state.researchWritingPageData = commonDataFormat3;
      state.researchWritingPageIsLoading = false;
      state.researchWritingPageIsSuccess = false;
      state.researchWritingPageIsError = false;
      state.ghostWriterPageStatus = "idle";
      state.ghostWriterPageData = commonDataFormat3;
      state.ghostWriterPageIsLoading = false;
      state.ghostWriterIsSuccess = false;
      state.ghostWriterPageIsError = false;
      state.academicWritingPageStatus = "idle";
      state.academicWritingPageData = commonDataFormat3;
      state.academicWritingPageIsLoading = false;
      state.academicWritingPageIsSuccess = false;
      state.academicWritingPageIsError = false;
      state.programmingHelpPageStatus = "idle";
      state.programmingHelpPageData = commonDataFormat3;
      state.programmingHelpPageIsLoading = false;
      state.programmingHelpPageIsSuccess = false;
      state.programmingHelpPageIsError = false;
      state.assessmentHelpPageStatus = "idle";
      state.assessmentHelpPageData = commonDataFormat3;
      state.assessmentHelpPageIsLoading = false;
      state.assessmentHelpPageIsSuccess = false;
      state.assessmentHelpPageIsError = false;
      state.doMyAssignmentPageStatus = "idle";
      state.doMyAssignmentPageData = commonDataFormat3;
      state.doMyAssignmentPageIsLoading = false;
      state.doMyAssignmentPageIsSuccess = false;
      state.doMyAssignmentPageIsError = false;
      state.collegeHomeworkPageStatus = "idle";
      state.collegeHomeworkPageData = commonDataFormat3;
      state.collegeHomeworkPageIsLoading = false;
      state.collegeHomeworkPageIsSuccess = false;
      state.collegeHomeworkPageIsError = false;
      state.urgentHelpPageStatus = "idle";
      state.urgentHelpPageData = commonDataFormat3;
      state.urgentHelpPageIsLoading = false;
      state.urgentHelpPageIsSuccess = false;
      state.urgentHelpPageIsError = false;
      state.paySomeonePageStatus = "idle";
      state.paySomeonePageData = commonDataFormat3;
      state.paySomeonePageIsLoading = false;
      state.paySomeonePageIsSuccess = false;
      state.paySomeonePageIsError = false;
      state.doMyCourseworkPageStatus = "idle";
      state.doMyCourseworkPageData = commonDataFormat3;
      state.doMyCourseworkIsLoading = false;
      state.doMyCourseworkIsSuccess = false;
      state.doMyCourseworkIsError = false;
      state.doMyHomeworkPageStatus = "idle";
      state.doMyHomeworkPageData = commonDataFormat3;
      state.doMyHomeworkIsLoading = false;
      state.doMyHomeworkIsSuccess = false;
      state.doMyHomeworkIsError = false;
      state.onlineClassPageStatus = "idle";
      state.onlineClassPageData = commonDataFormat3;
      state.onlineClassPageIsLoading = false;
      state.onlineClassPageIsSuccess = false;
      state.onlineClassPageIsError = false;
      state.onlineExamPageStatus = "idle";
      state.onlineExamPageData = commonDataFormat3;
      state.onlineExamPageIsLoading = false;
      state.onlineExamPageIsSuccess = false;
      state.onlineExamPageIsError = false;
      state.dissertationHelpPageStatus = "idle";
      state.dissertationHelpPageData = commonDataFormat3;
      state.dissertationHelpPageIsLoading = false;
      state.dissertationHelpPageIsSuccess = false;
      state.dissertationHelpPageIsError = false;
      state.termPaperHelpPageStatus = "idle";
      state.termPaperHelpPageData = commonDataFormat3;
      state.termPaperHelpPageIsLoading = false;
      state.termPaperHelpPageIsSuccess = false;
      state.termPaperHelpPageIsError = false;
      state.homeworkHelpPageStatus = "idle";
      state.homeworkHelpPageData = commonDataFormat3;
      state.homeworkHelpPageIsLoading = false;
      state.homeworkHelpPageIsSuccess = false;
      state.homeworkHelpPageIsError = false;
      state.caseStudyHelpPageStatus = "idle";
      state.caseStudyHelpPageData = commonDataFormat3;
      state.caseStudyHelpPageIsLoading = false;
      state.caseStudyHelpPageIsSuccess = false;
      state.caseStudyHelpPageIsError = false;
      state.courseworkHelpPageStatus = "idle";
      state.courseworkHelpPageData = commonDataFormat3;
      state.courseworkHelpPageIsLoading = false;
      state.courseworkHelpPageIsSuccess = false;
      state.courseworkHelpPageIsError = false;
      state.thesisHelpPageStatus = "idle";
      state.thesisHelpPageData = commonDataFormat3;
      state.thesisHelpPageIsLoading = false;
      state.thesisHelpPageIsSuccess = false;
      state.thesisHelpPageIsError = false;
      state.powerpointPageStatus = "idle";
      state.powerpointPageData = commonDataFormat3;
      state.powerpointPageIsLoading = false;
      state.powerpointPageIsSuccess = false;
      state.powerpointPageIsError = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getWriteMyPaperPage.pending, (state) => {
        state.writeMyPaperPageStatus = "loading";
        state.isLoading = true;
      })
      .addCase(getWriteMyPaperPage.fulfilled, (state, { payload }) => {
        state.writeMyPaperPageStatus = "success";
        state.isSuccess = true;
        state.writeMyPaperPageData = payload?.data?.attributes;
      })
      .addCase(getWriteMyPaperPage.rejected, (state) => {
        state.writeMyPaperPageStatus = "failed";
        state.isError = true;
      })
      .addCase(getMbaAssignmentPage.pending, (state) => {
        state.mbaPageStatus = "loading";
        state.mbaPageIsLoading = true;
      })
      .addCase(getMbaAssignmentPage.fulfilled, (state, { payload }) => {
        state.mbaPageStatus = "success";
        state.mbaPageIsSuccess = true;
        state.mbaPageData = payload?.data?.attributes;
      })
      .addCase(getMbaAssignmentPage.rejected, (state) => {
        state.mbaPageStatus = "failed";
        state.mbaPageIsError = true;
      })
      .addCase(getWriteMyEssayPage.pending, (state) => {
        state.essayPageStatus = "loading";
        state.essayPageIsLoading = true;
      })
      .addCase(getWriteMyEssayPage.fulfilled, (state, { payload }) => {
        state.essayPageStatus = "success";
        state.essayPageIsSuccess = true;
        state.essayPageData = payload?.data?.attributes;
      })
      .addCase(getWriteMyEssayPage.rejected, (state) => {
        state.essayPageStatus = "failed";
        state.essayPageIsError = true;
      })
      .addCase(getCheapEssayPage.pending, (state) => {
        state.cheapEssayStatus = "loading";
        state.cheapEssayIsLoading = true;
      })
      .addCase(getCheapEssayPage.fulfilled, (state, { payload }) => {
        state.cheapEssayStatus = "success";
        state.cheapEssayIsSuccess = true;
        state.cheapEssayData = payload?.data?.attributes;
      })
      .addCase(getCheapEssayPage.rejected, (state) => {
        state.cheapEssayStatus = "failed";
        state.cheapEssayIsError = true;
      })
      .addCase(getEssayHelpPage.pending, (state) => {
        state.essayHelpPageStatus = "loading";
        state.essayHelpPageIsLoading = true;
      })
      .addCase(getEssayHelpPage.fulfilled, (state, { payload }) => {
        state.essayHelpPageStatus = "success";
        state.essayHelpPageIsSuccess = true;
        state.essayHelpPageData = payload?.data?.attributes;
      })
      .addCase(getEssayHelpPage.rejected, (state) => {
        state.essayHelpPageStatus = "failed";
        state.essayHelpPageIsError = true;
      })
      .addCase(getResearchPage.pending, (state) => {
        state.researchPageStatus = "loading";
        state.researchPageIsLoading = true;
      })
      .addCase(getResearchPage.fulfilled, (state, { payload }) => {
        state.researchPageStatus = "success";
        state.researchPageIsSuccess = true;
        state.researchPageData = payload?.data?.attributes;
      })
      .addCase(getResearchPage.rejected, (state) => {
        state.researchPageStatus = "failed";
        state.researchPageIsError = true;
      })
      .addCase(getResearchWritingPage.pending, (state) => {
        state.researchWritingPageStatus = "loading";
        state.researchWritingPageIsLoading = true;
      })
      .addCase(getResearchWritingPage.fulfilled, (state, { payload }) => {
        state.researchWritingPageStatus = "success";
        state.researchWritingPageIsSuccess = true;
        state.researchWritingPageData = payload?.data?.attributes;
      })
      .addCase(getResearchWritingPage.rejected, (state) => {
        state.researchWritingPageStatus = "failed";
        state.researchWritingPageIsError = true;
      })
      .addCase(getGhostWriterPage.pending, (state) => {
        state.ghostWriterPageStatus = "loading";
        state.ghostWriterPageIsLoading = true;
      })
      .addCase(getGhostWriterPage.fulfilled, (state, { payload }) => {
        state.ghostWriterPageStatus = "success";
        state.ghostWriterIsSuccess = true;
        state.ghostWriterPageData = payload?.data?.attributes;
      })
      .addCase(getGhostWriterPage.rejected, (state) => {
        state.ghostWriterPageStatus = "failed";
        state.ghostWriterPageIsError = true;
      })
      .addCase(getAcademicWritingPage.pending, (state) => {
        state.academicWritingPageStatus = "loading";
        state.academicWritingPageIsLoading = true;
      })
      .addCase(getAcademicWritingPage.fulfilled, (state, { payload }) => {
        state.academicWritingPageStatus = "success";
        state.academicWritingPageIsSuccess = true;
        state.academicWritingPageData = payload?.data?.attributes;
      })
      .addCase(getAcademicWritingPage.rejected, (state) => {
        state.academicWritingPageStatus = "failed";
        state.academicWritingPageIsError = true;
      })
      .addCase(getProgrammingHelpPage.pending, (state) => {
        state.programmingHelpPageStatus = "loading";
        state.programmingHelpPageIsLoading = true;
      })
      .addCase(getProgrammingHelpPage.fulfilled, (state, { payload }) => {
        state.programmingHelpPageStatus = "success";
        state.programmingHelpPageData = payload?.data?.attributes;
        state.programmingHelpPageIsSuccess = true;
      })
      .addCase(getProgrammingHelpPage.rejected, (state) => {
        state.programmingHelpPageStatus = "failed";
        state.programmingHelpPageIsError = true;
      })
      .addCase(getAssessmentHelpPage.pending, (state) => {
        state.assessmentHelpPageStatus = "loading";
        state.assessmentHelpPageIsLoading = true;
      })
      .addCase(getAssessmentHelpPage.fulfilled, (state, { payload }) => {
        state.assessmentHelpPageStatus = "success";
        state.assessmentHelpPageData = payload?.data?.attributes;
        state.assessmentHelpPageIsSuccess = true;
      })
      .addCase(getAssessmentHelpPage.rejected, (state) => {
        state.assessmentHelpPageStatus = "failed";
        state.assessmentHelpPageIsError = true;
      })
      .addCase(getDoMyAssignmentPage.pending, (state) => {
        state.doMyAssignmentPageStatus = "loading";
        state.doMyAssignmentPageIsLoading = true;
      })
      .addCase(getDoMyAssignmentPage.fulfilled, (state, { payload }) => {
        state.doMyAssignmentPageStatus = "success";
        state.doMyAssignmentPageData = payload?.data?.attributes;
        state.doMyAssignmentPageIsSuccess = true;
      })
      .addCase(getDoMyAssignmentPage.rejected, (state) => {
        state.doMyAssignmentPageStatus = "failed";
        state.doMyAssignmentPageIsError = true;
      })
      .addCase(getCollegeHomeworkPage.pending, (state) => {
        state.collegeHomeworkPageStatus = "loading";
        state.collegeHomeworkPageIsLoading = true;
      })
      .addCase(getCollegeHomeworkPage.fulfilled, (state, { payload }) => {
        state.collegeHomeworkPageStatus = "success";
        state.collegeHomeworkPageData = payload?.data?.attributes;
        state.collegeHomeworkPageIsSuccess = true;
      })
      .addCase(getCollegeHomeworkPage.rejected, (state) => {
        state.collegeHomeworkPageStatus = "failed";
        state.collegeHomeworkPageIsError = true;
      })
      .addCase(getUrgentHelpPage.pending, (state) => {
        state.urgentHelpPageStatus = "loading";
        state.urgentHelpPageIsLoading = true;
      })
      .addCase(getUrgentHelpPage.fulfilled, (state, { payload }) => {
        state.urgentHelpPageStatus = "success";
        state.urgentHelpPageData = payload?.data?.attributes;
        state.urgentHelpPageIsSuccess = true;
      })
      .addCase(getUrgentHelpPage.rejected, (state) => {
        state.urgentHelpPageStatus = "failed";
        state.urgentHelpPageIsError = true;
      })
      .addCase(getPaySomeonePage.pending, (state) => {
        state.paySomeonePageStatus = "loading";
        state.paySomeonePageIsLoading = true;
      })
      .addCase(getPaySomeonePage.fulfilled, (state, { payload }) => {
        state.paySomeonePageStatus = "success";
        state.paySomeonePageData = payload?.data?.attributes;
        state.paySomeonePageIsSuccess = true;
      })
      .addCase(getPaySomeonePage.rejected, (state) => {
        state.paySomeonePageStatus = "failed";
        state.paySomeonePageIsError = true;
      })
      .addCase(getDoMyCourseworkPage.pending, (state) => {
        state.doMyCourseworkPageStatus = "loading";
        state.doMyCourseworkIsLoading = true;
      })
      .addCase(getDoMyCourseworkPage.fulfilled, (state, { payload }) => {
        state.doMyCourseworkPageStatus = "success";
        state.doMyCourseworkPageData = payload?.data?.attributes;
        state.doMyCourseworkIsSuccess = true;
      })
      .addCase(getDoMyCourseworkPage.rejected, (state) => {
        state.doMyCourseworkPageStatus = "failed";
        state.doMyCourseworkIsError = true;
      })
      .addCase(getDoMyHomeworkPage.pending, (state) => {
        state.doMyHomeworkPageStatus = "loading";
        state.doMyHomeworkIsLoading = true;
      })
      .addCase(getDoMyHomeworkPage.fulfilled, (state, { payload }) => {
        state.doMyHomeworkPageStatus = "success";
        state.doMyHomeworkPageData = payload?.data?.attributes;
        state.doMyHomeworkIsSuccess = true;
      })
      .addCase(getDoMyHomeworkPage.rejected, (state) => {
        state.doMyHomeworkPageStatus = "failed";
        state.doMyHomeworkIsError = true;
      })
      .addCase(getOnlineClassPage.pending, (state) => {
        state.onlineClassPageStatus = "loading";
        state.onlineClassPageIsLoading = true;
      })
      .addCase(getOnlineClassPage.fulfilled, (state, { payload }) => {
        state.onlineClassPageStatus = "success";
        state.onlineClassPageData = payload?.data?.attributes;
        state.onlineClassPageIsSuccess = true;
      })
      .addCase(getOnlineClassPage.rejected, (state) => {
        state.onlineClassPageStatus = "failed";
        state.onlineClassPageIsError = true;
      })
      .addCase(getOnlineExamPage.pending, (state) => {
        state.onlineExamPageStatus = "loading";
        state.onlineExamPageIsLoading = true;
      })
      .addCase(getOnlineExamPage.fulfilled, (state, { payload }) => {
        state.onlineExamPageStatus = "success";
        state.onlineExamPageData = payload?.data?.attributes;
        state.onlineExamPageIsSuccess = true;
      })
      .addCase(getOnlineExamPage.rejected, (state) => {
        state.onlineExamPageStatus = "failed";
        state.onlineExamPageIsError = true;
      })
      .addCase(getDissertationHelpPage.pending, (state) => {
        state.dissertationHelpPageStatus = "loading";
        state.dissertationHelpPageIsLoading = true;
      })
      .addCase(getDissertationHelpPage.fulfilled, (state, { payload }) => {
        state.dissertationHelpPageStatus = "success";
        state.dissertationHelpPageData = payload?.data?.attributes;
        state.dissertationHelpPageIsSuccess = true;
      })
      .addCase(getDissertationHelpPage.rejected, (state) => {
        state.dissertationHelpPageStatus = "failed";
        state.dissertationHelpPageIsError = true;
      })
      .addCase(getTermPaperHelpPage.pending, (state) => {
        state.termPaperHelpPageStatus = "loading";
        state.termPaperHelpPageIsLoading = true;
      })
      .addCase(getTermPaperHelpPage.fulfilled, (state, { payload }) => {
        state.termPaperHelpPageStatus = "success";
        state.termPaperHelpPageData = payload?.data?.attributes;
        state.termPaperHelpPageIsSuccess = true;
      })
      .addCase(getTermPaperHelpPage.rejected, (state) => {
        state.termPaperHelpPageStatus = "failed";
        state.termPaperHelpPageIsError = true;
      })
      .addCase(getHomeworkHelpPage.pending, (state) => {
        state.homeworkHelpPageStatus = "loading";
        state.homeworkHelpPageIsLoading = true;
      })
      .addCase(getHomeworkHelpPage.fulfilled, (state, { payload }) => {
        state.homeworkHelpPageStatus = "success";
        state.homeworkHelpPageData = payload?.data?.attributes;
        state.homeworkHelpPageIsSuccess = true;
      })
      .addCase(getHomeworkHelpPage.rejected, (state) => {
        state.homeworkHelpPageStatus = "failed";
        state.homeworkHelpPageIsError = true;
      })
      .addCase(getCaseStudyHelpPage.pending, (state) => {
        state.caseStudyHelpPageStatus = "loading";
        state.caseStudyHelpPageIsLoading = true;
      })
      .addCase(getCaseStudyHelpPage.fulfilled, (state, { payload }) => {
        state.caseStudyHelpPageStatus = "success";
        state.caseStudyHelpPageData = payload?.data?.attributes;
        state.caseStudyHelpPageIsSuccess = true;
      })
      .addCase(getCaseStudyHelpPage.rejected, (state) => {
        state.caseStudyHelpPageStatus = "failed";
        state.caseStudyHelpPageIsError = true;
      })
      .addCase(getCourseworkHelpPage.pending, (state) => {
        state.courseworkHelpPageStatus = "loading";
        state.courseworkHelpPageIsLoading = true;
      })
      .addCase(getCourseworkHelpPage.fulfilled, (state, { payload }) => {
        state.courseworkHelpPageStatus = "success";
        state.courseworkHelpPageData = payload?.data?.attributes;
        state.courseworkHelpPageIsSuccess = true;
      })
      .addCase(getCourseworkHelpPage.rejected, (state) => {
        state.courseworkHelpPageStatus = "failed";
        state.courseworkHelpPageIsError = true;
      })
      .addCase(getThesisHelpPage.pending, (state) => {
        state.thesisHelpPageStatus = "loading";
        state.thesisHelpPageIsLoading = true;
      })
      .addCase(getThesisHelpPage.fulfilled, (state, { payload }) => {
        state.thesisHelpPageStatus = "success";
        state.thesisHelpPageData = payload?.data?.attributes;
        state.thesisHelpPageIsSuccess = true;
      })
      .addCase(getThesisHelpPage.rejected, (state) => {
        state.thesisHelpPageStatus = "failed";
        state.thesisHelpPageIsError = true;
      })
      .addCase(getPowerpointPage.pending, (state) => {
        state.powerpointPageStatus = "loading";
        state.powerpointPageIsLoading = true;
      })
      .addCase(getPowerpointPage.fulfilled, (state, { payload }) => {
        state.powerpointPageStatus = "success";
        state.powerpointPageData = payload?.data?.attributes;
        state.powerpointPageIsSuccess = true;
      })
      .addCase(getPowerpointPage.rejected, (state) => {
        state.powerpointPageStatus = "failed";
        state.powerpointPageIsError = true;
      });
  },
});

export const { clearWriteMyPaperPageState } = servicePagesSlice.actions;
