import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

// Models
import { MentorProfileDataModel } from "../../../models/constants/data/dataModels";
import { ApiFuncArgProps } from "../../../models/apiFuncHelpers";

// Helpers
import { ApiHelperFunction } from "../../../helpers/api_helpers";
import { isApiErrorResponse } from "../../../helpers/typeguards";

// Utils
import { toast } from "react-hot-toast";

import PageBanner from "../../components/layout/pages/PageBanner";

const MentorProfile = () => {
  const params = useParams();
  const [mentorProfileInfo, setmentorProfileInfo] = useState<MentorProfileDataModel | null>(null);

  const fetchMentorProfile = async () => {
    let response = await ApiHelperFunction({
      urlPath: `/mentor-profiles/${params.id}`,
      method: "GET",
    } as ApiFuncArgProps);

    if (isApiErrorResponse(response)) {
      toast.error(response.error.message);
    } else if (response.data) {
      setmentorProfileInfo(response.data?.data);
    } else {
      toast.error("Unexpele sample category response");
    }
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    fetchMentorProfile();
  }, []);

  console.log("mentorProfileInfo", mentorProfileInfo);

  return (
    <>
      {/* page banner area start */}
      <PageBanner title={mentorProfileInfo?.attributes.name} description={mentorProfileInfo?.attributes.description} reviewInfo={false} className="mentor-inner-banner" ratingDisplay={true} rating={mentorProfileInfo?.attributes.rating} />
      {/* page banner area end */}

      {/* wrapper area start */}
      <div className="wrapper">
        <div className="who-i-am-area">
          <div className="container">
            <div className="who-i-am">
              <div className="row align-items-end">
                <div className="col-md-5 col-lg-4">
                  <div className="who-i-am-img">
                    <img src="/assets/images/who-i-am-img2.svg" alt="who-i-am-img" />
                    <a href="#!" className="instagram-ico">
                      <img src="/assets/images/instagram-ico.svg" alt="instagram-ico" />
                    </a>
                  </div>
                </div>
                <div className="col-md-7 col-lg-8">
                  <div className="profile-hdng">
                    <h2>
                      About <br></br> Occupation/Designation
                    </h2>
                    {mentorProfileInfo?.attributes.about.map((item, index) => {
                      if (item.type === "paragraph") {
                        return item.children.map((it, idx) => (
                          <>
                            <p key={`${index}_${idx}`}>{it.text}</p>
                            <br />
                          </>
                        ));
                      }
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mentor-dtls">
          <div className="container">
            <ul>
              <li>
                <img src="/assets/images/mentor-dtls-ico-1.svg" alt="mentor-dtls-ico" />
                <span>
                  {/* Completed Orders <strong>461</strong> */}
                  {mentorProfileInfo?.attributes.sec_text_1}
                </span>
              </li>
              <li>
                <img src="/assets/images/mentor-dtls-ico-2.svg" alt="mentor-dtls-ico" />
                <span>
                  {/* Member since <strong>3Years</strong> */}
                  {mentorProfileInfo?.attributes.sec_text_2}
                </span>
              </li>
              <li>
                <img src="/assets/images/mentor-dtls-ico-3.svg" alt="mentor-dtls-ico" />
                <span>
                  {/* Response time <strong>98%</strong> */}
                  {mentorProfileInfo?.attributes.sec_text_3}
                </span>
              </li>
              <li>
                <img src="/assets/images/mentor-dtls-ico-4.svg" alt="mentor-dtls-ico" />
                <span>
                  {/* Reviews <strong>43</strong> */}
                  {mentorProfileInfo?.attributes.sec_text_4}
                </span>
              </li>
              <li>
                <img src="/assets/images/mentor-dtls-ico-5.svg" alt="mentor-dtls-ico" />
                <span>
                  {/* Rating <strong>4.9</strong> */}
                  {mentorProfileInfo?.attributes.sec_text_5}
                </span>
              </li>
            </ul>
          </div>
        </div>

        <section className="real-experts-area real-experts-area-2">
          <div className="container">
            <div className="real-experts-bx">
              <div className="home-banner-form">
                <div className="main-hdng text-center">
                  <h2>Stuck on Any Question</h2>
                  <p>Our best expert will help you with the answer of your question with best explanation.</p>
                </div>
                <div className="row justify-content-center">
                  <div className="col-lg-7">
                    <form>
                      <div className="row gy-4">
                        <div className="col-xl-12">
                          <div className="form-group-radio">
                            <input className="checkbox-tools" type="radio" name="tools" id="tool-1" defaultChecked />
                            <label className="for-checkbox-tools" htmlFor="tool-1">
                              Writing
                            </label>
                            <input className="checkbox-tools" type="radio" name="tools" id="tool-2" />
                            <label className="for-checkbox-tools" htmlFor="tool-2">
                              Rewriting
                            </label>
                            <input className="checkbox-tools" type="radio" name="tools" id="tool-3" />
                            <label className="for-checkbox-tools" htmlFor="tool-3">
                              Editing
                            </label>
                            <div className="assignment-type-info-wrapper">
                              <img src="/assets/images/info-blue-ico.svg" alt="info-blue-ico" className="question loading" title="Info" />
                              <div className="assignment-type-info-tooltip">
                                <p>
                                  <strong>Writing:</strong> Get your essay and assignment written from scratch by PhD expert{" "}
                                </p>
                                <p>
                                  <strong>Rewriting:</strong> Paraphrase or rewrite your friend's essay with similar meaning at reduced cost
                                </p>
                                <p>
                                  <strong>Editing:</strong> Proofread your work by experts and improve grade at Lowest cost
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6 col-lg-12 col-xl-6">
                          <div className="form-group">
                            <div className="form-group-icon">
                              <img src="/assets/images/email-ico.svg" alt="email-ico" />
                            </div>
                            <div className="form-group-form">
                              <input type="email" placeholder="Email" />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-12 col-xl-6">
                          <div className="form-group">
                            <div className="form-group-icon">
                              <img src="/assets/images/phone-ico.svg" alt="phone-ico" />
                            </div>
                            <div className="form-group-form">
                              <input type="text" placeholder="Phone" />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-12 col-xl-12">
                          <div className="form-group">
                            <div className="form-group-icon">
                              <img src="/assets/images/subject-ico.svg" alt="subject-ico" />
                            </div>
                            <div className="form-group-form">
                              <input type="text" placeholder="Subject/Course Code" />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-12 col-xl-6">
                          <div className="form-group form-group-number">
                            <div className="form-group-icon">
                              <img src="/assets/images/page-ico.svg" alt="page-ico" />
                            </div>
                            <div className="form-group-form">
                              <span>Page</span>
                              <input className="numberstyle" type="number" min="1" step="1" value="1" />
                              <span>= 250 words</span>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-6 col-xl-3">
                          <div className="form-group form-group-datepicker">
                            <div className="form-group-icon">
                              <img src="/assets/images/date-ico.svg" alt="date-ico" />
                            </div>
                            <div className="form-group-form">
                              <input placeholder="Date" type="text" name="checkIn" id="datepicker" value="" className="calendar" />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-6 col-xl-3">
                          <div className="form-group">
                            <div className="form-group-icon">
                              <img src="/assets/images/time-ico.svg" alt="time-ico" />
                            </div>
                            <div className="form-group-form">
                              <select className="form-select" aria-label="Default select example">
                                <option selected disabled>
                                  Time
                                </option>
                                <option value="1">2:00 AM</option>
                                <option value="2">4:00 AM</option>
                                <option value="3">6:00 AM</option>
                                <option value="1">8:00 AM</option>
                                <option value="2">10:00 AM</option>
                                <option value="3">12:00 PM</option>
                                <option value="1">2:00 PM</option>
                                <option value="2">4:00 PM</option>
                                <option value="3">6:00 PM</option>
                                <option value="1">8:00 PM</option>
                                <option value="2">10:00 PM</option>
                                <option value="3">12:00 PM</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-12">
                          <div className="form-group form-group-textarea">
                            <div className="form-group-icon">
                              <img src="/assets/images/subject-ico.svg" alt="subject-ico" />
                            </div>
                            <div className="form-group-form">
                              <textarea placeholder="Write your Description"></textarea>
                            </div>
                          </div>
                          <div className="file-input">
                            <input type="file" name="file-input" id="file-input" className="file-input__input" />
                            <label className="file-input__label" htmlFor="file-input">
                              <img src="/assets/images/attach-blue-ico.svg" alt="attach-blue-ico" />
                              <span>Attach</span>
                            </label>
                          </div>
                        </div>
                        <div className="col-xl-12">
                          <div className="form-group-last">
                            <div className="form-check">
                              <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                              <label className="form-check-label" htmlFor="exampleCheck1">
                                I accept the T&C, agree to receive offers & updates
                              </label>
                            </div>
                            <div className="btn-group">
                              <a href="#!" className="custombtn btn-1 hover-filled-slide-down">
                                <span>Submit</span>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="mentor-tab-area">
          <div className="container">
            <nav>
              <div className="nav nav-tabs" id="nav-tab" role="tablist">
                <button className="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">
                  Completed Orders
                </button>
                <button className="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">
                  Reviews
                </button>
              </div>
            </nav>
            <div className="tab-content" id="nav-tabContent">
              <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                <div className="co-bx">
                  <div className="co-bx-top">
                    <h5>Contract Law Assignment: Offer, Acceptance, Revocation, And Postal Rules</h5>
                    <p>
                      Learning Outcomes for the item of assessmentLearning Outcomes for the item of assessmentThis item of assessment covers the following learning outcomes. For the full list of learning outcomes for the module, please refer to the Module Study Guide.â€¢ Demonstrate an understanding
                      and application of law governing businesses and assess its effectivenessâ€¢ Identify the main sources of law and essential elements of the legal systemâ€¢ Apply relevant ...
                    </p>
                  </div>
                  <div className="co-bx-bottom">
                    <div className="btn-group">
                      <a href="#!" className="custombtn btn-1 hover-filled-slide-down">
                        <span>Get Answer</span>
                      </a>
                    </div>
                    <div className="co-bx-bottom-right">
                      <div className="co-bx-bottom-right-single">
                        <img src="/assets/images/cbbrs-img-1.svg" alt="icon" />
                        <span>
                          Published <strong>17 Feb 2024</strong>{" "}
                        </span>
                      </div>
                      <div className="co-bx-bottom-right-single">
                        <img src="assets/images/cbbrs-img-2.svg" alt="icon" />
                        <span>
                           Pages <strong>35</strong> Words <strong>8860</strong>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="co-bx">
                  <div className="co-bx-top">
                    <h5>Contract Law Assignment: Offer, Acceptance, Revocation, And Postal Rules</h5>
                    <p>
                      Learning Outcomes for the item of assessmentLearning Outcomes for the item of assessmentThis item of assessment covers the following learning outcomes. For the full list of learning outcomes for the module, please refer to the Module Study Guide.â€¢ Demonstrate an understanding
                      and application of law governing businesses and assess its effectivenessâ€¢ Identify the main sources of law and essential elements of the legal systemâ€¢ Apply relevant ...
                    </p>
                  </div>
                  <div className="co-bx-bottom">
                    <div className="btn-group">
                      <a href="#!" className="custombtn btn-1 hover-filled-slide-down">
                        <span>Get Answer</span>
                      </a>
                    </div>
                    <div className="co-bx-bottom-right">
                      <div className="co-bx-bottom-right-single">
                        <img src="assets/images/cbbrs-img-1.svg" alt="icon" />
                        <span>
                          Published <strong>17 Feb 2024</strong>{" "}
                        </span>
                      </div>
                      <div className="co-bx-bottom-right-single">
                        <img src="assets/images/cbbrs-img-2.svg" alt="icon" />
                        <span>
                           Pages <strong>35</strong> Words <strong>8860</strong>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="co-bx">
                  <div className="co-bx-top">
                    <h5>Contract Law Assignment: Offer, Acceptance, Revocation, And Postal Rules</h5>
                    <p>
                      Learning Outcomes for the item of assessmentLearning Outcomes for the item of assessmentThis item of assessment covers the following learning outcomes. For the full list of learning outcomes for the module, please refer to the Module Study Guide.â€¢ Demonstrate an understanding
                      and application of law governing businesses and assess its effectivenessâ€¢ Identify the main sources of law and essential elements of the legal systemâ€¢ Apply relevant ...
                    </p>
                  </div>
                  <div className="co-bx-bottom">
                    <div className="btn-group">
                      <a href="#!" className="custombtn btn-1 hover-filled-slide-down">
                        <span>Get Answer</span>
                      </a>
                    </div>
                    <div className="co-bx-bottom-right">
                      <div className="co-bx-bottom-right-single">
                        <img src="assets/images/cbbrs-img-1.svg" alt="icon" />
                        <span>
                          Published <strong>17 Feb 2024</strong>{" "}
                        </span>
                      </div>
                      <div className="co-bx-bottom-right-single">
                        <img src="assets/images/cbbrs-img-2.svg" alt="icon" />
                        <span>
                           Pages <strong>35</strong> Words <strong>8860</strong>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                <div className="co-bx">
                  <div className="co-bx-rating">
                    <img src="assets/images/stars-2.svg" alt="stars" />
                    <span>Rating 5</span>
                  </div>
                  <div className="co-bx-top">
                    <h5>Thesis</h5>
                    <p>Excellent work been done by the expert, i got a geart mark ( 84%) on the assignment which will be an added value for me in the module overall mark.Tha ...</p>
                  </div>
                  <div className="co-bx-bottom-2">
                    <div className="usr-bx">
                      <div className="usr-bx-img">
                        <img src="assets/images/usr-bx-img.svg" alt="usr-bx-img" />
                      </div>
                      <span>User ID:7***10</span>
                    </div>
                    <div className="co-bx-bottom-right">
                      <div className="co-bx-bottom-right-single">
                        <img src="assets/images/cbbrs-img-1.svg" alt="icon" />
                        <span>
                          Published <strong>17 Feb 2024</strong>{" "}
                        </span>
                      </div>
                      <div className="co-bx-bottom-right-single">
                        <img src="assets/images/cbbrs-img-2.svg" alt="icon" />
                        <span>
                           Pages <strong>35</strong> Words <strong>8860</strong>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="co-bx">
                  <div className="co-bx-rating">
                    <img src="assets/images/stars-2.svg" alt="stars" />
                    <span>Rating 5</span>
                  </div>
                  <div className="co-bx-top">
                    <h5>Thesis</h5>
                    <p>Excellent work been done by the expert, i got a geart mark ( 84%) on the assignment which will be an added value for me in the module overall mark.Tha ...</p>
                  </div>
                  <div className="co-bx-bottom-2">
                    <div className="usr-bx">
                      <div className="usr-bx-img">
                        <img src="assets/images/usr-bx-img.svg" alt="usr-bx-img" />
                      </div>
                      <span>User ID:7***10</span>
                    </div>
                    <div className="co-bx-bottom-right">
                      <div className="co-bx-bottom-right-single">
                        <img src="assets/images/cbbrs-img-1.svg" alt="icon" />
                        <span>
                          Published <strong>17 Feb 2024</strong>{" "}
                        </span>
                      </div>
                      <div className="co-bx-bottom-right-single">
                        <img src="assets/images/cbbrs-img-2.svg" alt="icon" />
                        <span>
                           Pages <strong>35</strong> Words <strong>8860</strong>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="co-bx">
                  <div className="co-bx-rating">
                    <img src="assets/images/stars-2.svg" alt="stars" />
                    <span>Rating 5</span>
                  </div>
                  <div className="co-bx-top">
                    <h5>Thesis</h5>
                    <p>Excellent work been done by the expert, i got a geart mark ( 84%) on the assignment which will be an added value for me in the module overall mark.Tha ...</p>
                  </div>
                  <div className="co-bx-bottom-2">
                    <div className="usr-bx">
                      <div className="usr-bx-img">
                        <img src="assets/images/usr-bx-img.svg" alt="usr-bx-img" />
                      </div>
                      <span>User ID:7***10</span>
                    </div>
                    <div className="co-bx-bottom-right">
                      <div className="co-bx-bottom-right-single">
                        <img src="assets/images/cbbrs-img-1.svg" alt="icon" />
                        <span>
                          Published <strong>17 Feb 2024</strong>{" "}
                        </span>
                      </div>
                      <div className="co-bx-bottom-right-single">
                        <img src="assets/images/cbbrs-img-2.svg" alt="icon" />
                        <span>
                           Pages <strong>35</strong> Words <strong>8860</strong>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      {/* wrapper area end */}
    </>
  );
};

export default MentorProfile;
