import { ReviewSitesProps } from "../../../../models/views/components/pages";

let imgBaseUrl = process.env.REACT_APP_API_IMG_URL;

const TopReviewWebsites = ({ data = [] }: ReviewSitesProps) => {
  return (
    <div className="home-banner-review">
      {data.length > 0 ? (
        data.map((item) => {
          console.log("items------>", imgBaseUrl + item?.icon?.data?.attributes?.url);
          return (
            <div key={item.id} className="home-banner-review-bx">
              <div className="banner-review-stars">
                <img src="/assets/images/banner-review-stars.svg" alt="banner-review-stars" />
              </div>

              <div className="banner-review-platform-img">
                <img src={imgBaseUrl + item?.icon?.data?.attributes?.url} alt="banner-review-platform-imgsss" />
              </div>
              <h6>{item.rating}</h6>
              <p>
                {item.review_count.split(" ")[0]} <strong>{item.review_count.split(" ")[1]}</strong>
              </p>
              <div className="banner-review-platform-name">
                <span>{item.text}</span>
              </div>
            </div>
          );
        })
      ) : (
        <>
          <div className="home-banner-review-bx">
            <div className="banner-review-stars">
              <img src="/assets/images/banner-review-stars.svg" alt="banner-review-stars" />
            </div>
            <div className="banner-review-platform-img">
              <img src="/assets/images/banner-review-platform1.svg" alt="banner-review-platform-img" />
            </div>
            <h6>4.9</h6>
            <p>
              Reviews <strong>2374</strong>
            </p>
            <div className="banner-review-platform-name">
              <span>Your Assignment Help</span>
            </div>
          </div>
          <div className="home-banner-review-bx">
            <div className="banner-review-stars">
              <img src="/assets/images/banner-review-stars.svg" alt="banner-review-stars" />
            </div>
            <div className="banner-review-platform-img">
              <img src="/assets/images/banner-review-platform2.svg" alt="banner-review-platform-img" />
            </div>
            <h6>4.9</h6>
            <p>
              Reviews <strong>987</strong>
            </p>
            <div className="banner-review-platform-name">
              <span>Sitejabber</span>
            </div>
          </div>
          <div className="home-banner-review-bx">
            <div className="banner-review-stars">
              <img src="/assets/images/banner-review-stars.svg" alt="banner-review-stars" />
            </div>
            <div className="banner-review-platform-img">
              <img src="/assets/images/banner-review-platform3.svg" alt="banner-review-platform-img" />
            </div>
            <h6>4.9</h6>
            <p>
              Reviews <strong>1983</strong>
            </p>
            <div className="banner-review-platform-name">
              <span>Reviews.io</span>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default TopReviewWebsites;
