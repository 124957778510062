import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

// Helpers
import { ApiHelperFunction } from "../../helpers/api_helpers";
import { isApiErrorResponse } from "../../helpers/typeguards";

// Models
import { ApiFuncArgProps } from "../../models/apiFuncHelpers";
import { BlogListDataModel } from "../../models/constants/data/dataModels";

interface BlogInitialState {
  status: string;
  blogs: BlogListDataModel;
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
}

const initialState: BlogInitialState = {
  status: "idle",
  blogs: {
    data: [],
    meta: {
      pagination: {
        page: 0,
        pageCount: 0,
        pageSize: 0,
        total: 0,
      },
    },
  },
  isLoading: false,
  isSuccess: false,
  isError: false,
};

export const getAllBlogs = createAsyncThunk(
  "get_all_blogs",
  async (query?: string) => {
    try {
      let response;
      if (query) {
        response = await ApiHelperFunction({
          urlPath: `/blogs?populate=deep&${query}`,
          method: "GET",
        } as ApiFuncArgProps);
      } else {
        response = await ApiHelperFunction({
          urlPath: "/blogs?populate=deep",
          method: "GET",
        } as ApiFuncArgProps);
      }

      if (isApiErrorResponse(response)) {
        console.log("Error: ", response.error.message);
      } else {
        return response;
      }
    } catch (error: any) {
      console.log("Failed to fetch blogs due to ", error.message);
    }
  }
);

export const blogSlice = createSlice({
  name: "blogs",
  initialState,
  reducers: {
    clearBlogState: (state) => {
      state.status = "idle";
      state.blogs = {
        data: [],
        meta: {
          pagination: {
            page: 0,
            pageCount: 0,
            pageSize: 0,
            total: 0,
          },
        },
      };
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllBlogs.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(getAllBlogs.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.isSuccess = true;
        state.blogs = payload?.data;
      })
      .addCase(getAllBlogs.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
      });
  },
});

export const { clearBlogState } = blogSlice.actions;
