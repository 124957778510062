import { useState, useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { useAppSelector } from "../../redux/hooks";

// Models
import { ApiFuncArgProps } from "../../models/apiFuncHelpers";
import { BlogInfo } from "../../models/constants/data/dataModels";

// API
import { ApiHelperFunction } from "../../helpers/api_helpers";

// Components
import PageBanner from "../components/layout/pages/PageBanner";

let imgBaseUrl = process.env.REACT_APP_API_IMG_URL;

let initialValues: BlogInfo = {
  id: 0,
  attributes: {
    title: "",
    description: [],
    createdAt: "",
    updatedAt: "",
    status: false,
    category: {
      data: {
        id: 0,
        attributes: {
          createdAt: "",
          updatedAt: "",
          name: "",
          description: "",
          status: false,
          type: "",
        },
      },
    },
    image: {
      data: {
        attributes: {
          url: "",
        },
      },
    },
    youtube_video_link: "",
  },
};

const BlogDetails = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [blogData, setblogData] = useState(initialValues);
  const [showModal, setShowModal] = useState(false);
  const [youtubeLink, setyoutubeLink] = useState("");
  // Redux stores
  const { blogs } = useAppSelector((state) => state.blogs);

  async function fetchSingleBlogPageData() {
    let res = await ApiHelperFunction({
      urlPath: `/blogs/${params.id}?populate=deep`,
      method: "GET",
    } as ApiFuncArgProps);
    if (res.data) {
      setblogData((prev) => {
        let update = JSON.parse(JSON.stringify(prev));
        return { ...update, ...res.data?.data };
      });
    }
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    fetchSingleBlogPageData();
  }, [params.id]);
  const handleVideoClick = (link: string | undefined) => {
    // e.preventDefault();
    const urlObj = new URL(link || "");
    const videoId = urlObj.searchParams.get("v");

    setyoutubeLink(`https://www.youtube.com/embed/${videoId}` || "");
    setShowModal(true);
  };
  return (
    <>
      {/* page banner area start */}
      <PageBanner title={blogData.attributes.title} description={blogData.attributes.description?.[0]?.type === "paragraph" ? blogData.attributes.description?.[0]?.children?.[0]?.text.slice(0, 75) + "..." : ""} className="blogdetails-inner-banner" reviewInfo={false} ratingDisplay={false} />
      {/* page banner area end */}

      {/* wrapper area start */}
      <div className="wrapper">
        <div className="blogdetails-area">
          <div className="container">
            <div className="blog-details-banner">
              <img
                // src="/assets/images/blog-details-banner.png"
                src={imgBaseUrl ? imgBaseUrl + blogData.attributes.image.data?.attributes?.url : ""}
                alt="blog-details-banner"
              />
              {blogData?.attributes?.youtube_video_link !== "" && (
                <a className="ripple-block big-ripple-block" onClick={() => handleVideoClick(blogData?.attributes?.youtube_video_link)}>
                  <i className="fa fa-play-circle" aria-hidden="true"></i>
                  <div className="ripple ripple-2"></div>
                  <div className="ripple ripple-3"></div>
                </a>
              )}
            </div>

            <div className="main-hdng">
              <h2>{blogData.attributes.title}</h2>
            </div>
            <div className="blogdetails-content">
              {blogData.attributes.description?.map((item, index) => {
                if (item.type === "heading") {
                  if (item.level === 4) {
                    return item.children[0].bold ? (
                      <h4>
                        <b>{item.children[0].text}</b>
                      </h4>
                    ) : (
                      <h4>{item.children[0].text}</h4>
                    );
                  } else if (item.level === 5) {
                    return item.children[0].bold ? (
                      <h4>
                        <b>{item.children[0].text}</b>
                      </h4>
                    ) : (
                      <h4>{item.children[0].text}</h4>
                    );
                  }
                } else if (item.type === "paragraph") {
                  if (item.children.length === 1) {
                    return item.children.map((it, idx) => <p key={idx}>{it.bold ? <b>{it.text}</b> : it.text}</p>);
                  } else {
                    return (
                      <div key={index} className="d-flex">
                        <p>{item.children[0].bold ? <b>{item.children[0].text}</b> : item.children[0].text}</p>
                        <p>{item.children[1].bold ? <b>{item.children[1].text}</b> : item.children[1].text}</p>
                      </div>
                    );
                  }
                } else if (item.type === "list") {
                  if (item.format === "unordered") {
                    return (
                      <ul key={index} className="mb-3">
                        {item.children.map((it, idx) => (
                          <li key={idx}>
                            <b>{it?.children?.[0]?.text}</b> {it?.children?.[1]?.text}
                          </li>
                        ))}
                      </ul>
                    );
                  }
                }
              })}
            </div>
          </div>
        </div>

        {/* blog */}
        <section className="blogarea blogarea-blogdetailspg gray-bg">
          <div className="container">
            <div className="main-hdng text-center">
              <h2>Related Post</h2>
              <p>Essay writing &amp; more with MyAssignmentHelp</p>
            </div>
            <div className="row">
              {blogs.data.slice(0, 2).map((item, index) => (
                <div className="col-sm-6 col-md-6 col-lg-4">
                  <div className="blog-vertical-bx">
                    <div className="blog-vertical-bx-img" style={{ cursor: "pointer" }}>
                      <img src={imgBaseUrl ? imgBaseUrl + item.attributes.image.data?.attributes?.url : ""} alt="blog-bx-img" />
                      {item.attributes.youtube_video_link !== "" && (
                        <a className="ripple-block" onClick={() => handleVideoClick(item?.attributes?.youtube_video_link)}>
                          <i className="fa fa-play-circle" aria-hidden="true"></i>
                          <div className="ripple ripple-2"></div>
                          <div className="ripple ripple-3"></div>
                        </a>
                      )}
                    </div>
                    <div className="blog-vertical-bx-text">
                      <Link to={`/blog-details/${item.id}`}>{item.attributes.title}</Link>
                      <div className="blog-bx-calender">
                        <img src="/assets/images/calender-ico.svg" alt="calender-ico" />
                        <span>{new Date(item.attributes.createdAt).toDateString()}</span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              <div className="col-md-12 col-lg-4">
                <div className="blog-horizontal-bx-area">
                  {blogs.data.slice(2, 5).map((item, index) => (
                    <div className="blog-horizontal-bx">
                      <div className="blog-horizontal-bx-img" style={{ cursor: "pointer" }}>
                        <img src={imgBaseUrl ? imgBaseUrl + item.attributes.image.data?.attributes?.url : ""} alt="blog-bx-img" />
                        {item.attributes.youtube_video_link !== "" && (
                          <a className="ripple-block small-ripple-block" onClick={() => handleVideoClick(item?.attributes?.youtube_video_link)}>
                            <i className="fa fa-play-circle" aria-hidden="true"></i>
                            <div className="ripple ripple-2"></div>
                            <div className="ripple ripple-3"></div>
                          </a>
                        )}
                      </div>
                      <div className="blog-horizontal-bx-text">
                        <Link to={`/blog-details/${item.id}`}>{item.attributes.title}</Link>
                        <div className="blog-bx-calender">
                          <img src="/assets/images/calender-ico.svg" alt="calender-ico" />
                          <span>{new Date(item.attributes.createdAt).toDateString()}</span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          {showModal && (
            <div className="video-modal">
              <div className="modal-content-video">
                <button className="close-button" onClick={() => setShowModal(false)}>
                  ✕
                </button>
                <iframe width="100%" src={youtubeLink} title="video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
              </div>
            </div>
          )}
        </section>
      </div>
      {/* wrapper area end */}
    </>
  );
};

export default BlogDetails;
