import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

// Helpers
import { ApiHelperFunction } from "../../helpers/api_helpers";

// Typeguards
import { isApiErrorResponse } from "../../helpers/typeguards";

// Models
import { ApiFuncArgProps } from "../../models/apiFuncHelpers";
import {
  ServiceCourseCategoryDataModel,
  PaginationInfo,
} from "../../models/constants/data/dataModels";

interface ServiceCategoryInfo {
  id: number;
  attributes: {
    name: string;
    description: string;
    createdAt: string;
    updatedAt: string;
    publishedAt: string;
  };
}

export interface ServiceCategoriesList {
  data: Array<ServiceCategoryInfo>;
  meta: {
    pagination: PaginationInfo;
  };
}

interface CategoriesInitialState {
  categoriesStatus: string;
  serviceCourseList: ServiceCourseCategoryDataModel;
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  servCatDataFetchStatus: string;
  servCatDataList: ServiceCategoriesList;
  servCatDataFetchIsLoading: boolean;
  servCatDataFetchIsSuccess: boolean;
  servCatDataFetchIsError: boolean;
}

const initialState: CategoriesInitialState = {
  categoriesStatus: "idle",
  serviceCourseList: {
    data: [],
    meta: {
      pagination: {
        page: 0,
        pageSize: 0,
        pageCount: 0,
        total: 0,
      },
    },
  },
  isLoading: false,
  isSuccess: false,
  isError: false,
  servCatDataFetchStatus: "idle",
  servCatDataList: {
    data: [],
    meta: {
      pagination: {
        page: 0,
        pageSize: 0,
        pageCount: 0,
        total: 0,
      },
    },
  },
  servCatDataFetchIsLoading: false,
  servCatDataFetchIsSuccess: false,
  servCatDataFetchIsError: false,
};

export const getServiceCourseCategories = createAsyncThunk(
  "get_service_course_categories",
  async () => {
    try {
      let response = await ApiHelperFunction({
        urlPath: "/service-course-categories?populate=deep",
        method: "GET",
      } as ApiFuncArgProps);

      if (isApiErrorResponse(response)) {
        console.log("Error: ", response.error.message);
      } else {
        return response;
      }
    } catch (error: any) {
      console.log(
        "Failed to fetch service course categories due to ",
        error.message
      );
    }
  }
);

export const getServiceCategories = createAsyncThunk(
  "get_service_categories",
  async () => {
    try {
      let response = await ApiHelperFunction({
        urlPath: "/all-services?populate=deep",
        method: "GET",
      } as ApiFuncArgProps);

      if (isApiErrorResponse(response)) {
        console.log("Error: ", response.error.message);
      } else {
        return response.data;
      }
    } catch (error: any) {
      console.log("Failed to fetch service categories due to ", error.message);
    }
  }
);

export const categoriesSlices = createSlice({
  name: "categories",
  initialState,
  reducers: {
    clearCategories: (state) => {
      state.categoriesStatus = "idle";
      state.serviceCourseList = {
        data: [],
        meta: {
          pagination: {
            page: 0,
            pageSize: 0,
            pageCount: 0,
            total: 0,
          },
        },
      };
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.servCatDataFetchStatus = "idle";
      state.servCatDataList = {
        data: [],
        meta: {
          pagination: {
            page: 0,
            pageSize: 0,
            pageCount: 0,
            total: 0,
          },
        },
      };
      state.servCatDataFetchIsLoading = false;
      state.servCatDataFetchIsSuccess = false;
      state.servCatDataFetchIsError = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getServiceCourseCategories.pending, (state) => {
        state.categoriesStatus = "loading";
        state.isLoading = true;
      })
      .addCase(getServiceCourseCategories.fulfilled, (state, { payload }) => {
        state.categoriesStatus = "success";
        state.isSuccess = true;
        state.serviceCourseList = payload?.data;
      })
      .addCase(getServiceCourseCategories.rejected, (state) => {
        state.categoriesStatus = "failed";
        state.isError = true;
      })
      .addCase(getServiceCategories.pending, (state) => {
        state.servCatDataFetchStatus = "loading";
        state.servCatDataFetchIsLoading = true;
      })
      .addCase(getServiceCategories.fulfilled, (state, { payload }) => {
        state.servCatDataFetchStatus = "success";
        state.servCatDataFetchIsSuccess = true;
        state.servCatDataList = payload;
      })
      .addCase(getServiceCategories.rejected, (state) => {
        state.servCatDataFetchStatus = "failed";
        state.servCatDataFetchIsError = true;
      });
  },
});

export const { clearCategories } = categoriesSlices.actions;
