import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";

// Redux actions
import { getAboutUsPage } from "../../redux/slices/AboutUsPageSlice";

// Components
import PageBanner from "../components/layout/pages/PageBanner";
import HelpFeatures from "../components/layout/pages/HelpFeatures";
import AppAdvantages from "../components/layout/pages/AppAdvantages";

let imgBaseUrl = process.env.REACT_APP_API_IMG_URL;

const AboutUs = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // Redux stores
  const { aboutUsPageStatus, aboutUsPageData } = useAppSelector((state) => state.aboutUs);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    if (aboutUsPageStatus !== "success") {
      dispatch(getAboutUsPage());
    }
  }, []);

  return (
    <>
      {/* page banner area start */}
      <PageBanner title={aboutUsPageData.sec_1_i_banner[0].heading} description={aboutUsPageData.sec_1_i_banner[0].subheading} reviewSites={aboutUsPageData.sec_1_i_banner[0].inner_banner_review} />
      {/* page banner area end */}

      {/* wrapper area start */}
      <div className="wrapper">
        {/* help service */}
        <section className="help-service-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 responsive-order-2">
                <div className="help-service-cnt">
                  <h4>{aboutUsPageData.sec_2_h1}</h4>
                  <p>{aboutUsPageData.sec_2_description?.split("We cover")[0]}</p>
                  <p>
                    We cover {aboutUsPageData.sec_2_description?.split("We cover")[1]}
                    &nbsp;
                  </p>
                  <div className="btn-group">
                    <a href="#!" className="custombtn btn-1 hover-filled-slide-down">
                      <span onClick={() => navigate("/submit-new-assignment")}>Order Now</span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="help-service-img-area">
                  <div className="help-service-img">
                    <img src="/assets/images/benefits-assignment-img.png" alt="help-service-img" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* trust service */}
        <section className="trust-service-area">
          <div className="container">
            <div className="main-hdng text-center">
              <h2>{aboutUsPageData.sec_3_h1}</h2>
              <p>{aboutUsPageData.sec_3_description}</p>
            </div>
            <div className="trust-service-bx-area">
              {aboutUsPageData.sec_3_it_1.map((item, id) => (
                <div key={id} className="trust-service-bx">
                  <div className="trust-service-bx-single">
                    <div className="trust-service-bx-single-img">
                      <img
                        // src="/assets/images/trust-service-img1.svg"
                        src={imgBaseUrl ? imgBaseUrl + item.icon?.data?.attributes?.url : ""}
                        alt="trust-service-bx-single-img"
                      />
                    </div>
                    <div className="trust-service-bx-single-cnt">
                      <h5>{item.heading}</h5>
                      <p>{item.description}</p>
                    </div>
                  </div>
                  <div className="trust-service-bx-after-area">
                    <div className="trust-service-bx-after">
                      <img src="/assets/images/trust-service-bx-after.svg" alt="trust-service-bx-after" className="trust-service-bx-after-img-bg" />
                      <img src="/assets/images/trust-service-img.svg" alt="trust-service-img" className="trust-service-img" />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* help service 2 */}
        <section className="help-service-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-7 responsive-order-2">
                <div className="help-service-cnt">
                  <h4>{aboutUsPageData.sec_4_h1}</h4>
                  <p>{aboutUsPageData.sec_4_description?.split("We cover")[0]}</p>
                  <p>
                    We cover {aboutUsPageData.sec_4_description?.split("We cover")[1]}
                    &nbsp;
                  </p>
                  <div className="btn-group">
                    <a href="#!" className="custombtn btn-1 hover-filled-slide-down">
                      <span onClick={() => navigate("/submit-new-assignment")}>Order Now</span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="help-service-txt">
                  <ul>
                    {aboutUsPageData.sec_4_it_1.map((item, idx) => (
                      <li key={idx}>
                        <h5>{item.heading}</h5>
                        <p>{item.description}</p>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* help features */}
        <HelpFeatures headingOneText={aboutUsPageData.sec_5_h1} headingTwoText={aboutUsPageData.sec_5_description} data={aboutUsPageData.sec_5_it_1} helpButton={false} />

        {/* Leading Advantages */}
        <AppAdvantages headerText={aboutUsPageData.sec_6_h1} description={true} descriptionText={aboutUsPageData.sec_6_description} data={aboutUsPageData.sec_6_it_1} />
      </div>
      {/* wrapper area end */}
    </>
  );
};

export default AboutUs;
