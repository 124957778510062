// Models
import { ComingSoonProps } from "../../../../models/views/components/common";

// Components
import PageBanner from "../pages/PageBanner";

const ComingSoon = ({ pageBannerTitle, reviewInfo = false }: ComingSoonProps) => {
  return (
    <>
      {/* page banner area start */}
      <PageBanner title={pageBannerTitle ? pageBannerTitle : "This Feature Is Under Development"} reviewInfo={reviewInfo} />
      {/* page banner area end */}

      <div className="wrapper">
        <div className="coming-soon-dp">
          <div className="row">
            <div className="col-12 text-center coming-soon-image">
              <img src="/assets/images/coming-soon.jpg" alt="coming-soon-img" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ComingSoon;
