import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

// Components
import PageBanner from "../../components/layout/pages/PageBanner";
import AppAdvantages from "../../components/layout/pages/AppAdvantages";

const ParaphrasingTool = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      {/* page banner area start */}
      <PageBanner title="Paraphrasing Tool" description="An Online Tool To Help You Rephrase Your Text With Better Sentences." reviewInfo={false} />
      {/* page banner area end */}

      <div className="wrapper">
        <section className="dashboard-service-top-area">
          <div className="container">
            <div className="main-hdng text-center">
              <h2>Best Paraphrasing Tool for Accurate Rewriting</h2>
              <p>Rewrite the original text without altering its original meaning with our paraphraser</p>
            </div>
            <div className="dashboard-service-top-bx">
              <div className="row">
                <div className="col-lg-12">
                  <div className="modes-selection-area">
                    <span>Modes: </span>
                    <ul>
                      <li>
                        <a href="javascript:void(0)" className="selected">
                          Standard
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0)" className="selected">
                          Fluent
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0)" className="selected">
                          Creative
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0)" className="">
                          Creative +
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0)" className="">
                          Formal
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0)" className="">
                          Shorten
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0)" className="">
                          Expand
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="dashboard-form dashboard-form-2">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="dashboard-textarea">
                          <textarea></textarea>
                          <div className="dashboard-textarea-top-text">
                            Paste content here to paraphrase <br></br>
                            OR <br></br>
                            Drop file here
                          </div>
                          <div className="dashboard-textarea-bottom-text">(.tex, .txt, doc, .docx, .odt, .pdf, .rtf)</div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="dashboard-textarea">
                          <textarea></textarea>
                          <div className="dashboard-textarea-top-text">
                            You will get paraphrased text here once paraphrasing <br></br> tool finished rephrasing.
                          </div>
                        </div>
                      </div>
                      <div className="btn-group">
                        <a href="#!" className="custombtn btn-1 hover-filled-slide-down">
                          <span>Paraphrase the text</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="avail-expert-help-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-xl-8">
                <div className="row g-4">
                  <div className="col-md-6 col-lg-6">
                    <div className="assignment-writers-bx">
                      <div className="writers-rating">
                        <div className="writers-rating-bx">
                          <img src="/assets/images/star-ico.svg" alt="star-ico" />
                          <span>4.8</span>
                        </div>
                      </div>
                      <div className="assignment-writers-bx-top">
                        <div className="subject-icon">
                          <img src="/assets/images/english-ico.svg" alt="subject-icon" />
                        </div>
                        <div className="subject-name">English</div>
                      </div>
                      <div className="assignment-writers-bx-middle">
                        <div className="writer-details">
                          <p>I deliver online assignment help in all accounting topics and even clarifications on complex concepts.</p>
                        </div>
                        <div className="rv-cp">
                          <div className="rv">
                            <img src="/assets/images/reviews-ico.svg" alt="reviews-ico" />
                            <span>
                              Reviews <strong>432</strong>
                            </span>
                          </div>
                          <div className="cp">
                            <img src="/assets/images/complete-projects-ico.svg" alt="complete-projects-ico" />
                            <span>
                              Completed Project <strong>461</strong>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="assignment-writers-bx-bottom">
                        <div className="assignment-writers-bx-bottom-left">
                          <div className="writer-img">
                            <img src="/assets/images/writer-img1.png" alt="writer-img" />
                          </div>
                          <div className="writer-designation">
                            <h6>Desmond Eagle</h6>
                            <p>MSc in English</p>
                          </div>
                        </div>
                        <a href="#!" className="custom-border-btn btn-3 hover-filled-slide-down">
                          <span onClick={() => navigate("/submit-new-assignment")}>Hire Me</span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-6">
                    <div className="assignment-writers-bx">
                      <div className="writers-rating">
                        <div className="writers-rating-bx">
                          <img src="/assets/images/star-ico.svg" alt="star-ico" />
                          <span>4.8</span>
                        </div>
                      </div>
                      <div className="assignment-writers-bx-top">
                        <div className="subject-icon">
                          <img src="/assets/images/maths-ico.svg" alt="subject-icon" />
                        </div>
                        <div className="subject-name">Mathematics</div>
                      </div>
                      <div className="assignment-writers-bx-middle">
                        <div className="writer-details">
                          <p>I deliver online assignment help in all accounting topics and even clarifications on complex concepts.</p>
                        </div>
                        <div className="rv-cp">
                          <div className="rv">
                            <img src="/assets/images/reviews-ico.svg" alt="reviews-ico" />
                            <span>
                              Reviews <strong>432</strong>
                            </span>
                          </div>
                          <div className="cp">
                            <img src="/assets/images/complete-projects-ico.svg" alt="complete-projects-ico" />
                            <span>
                              Completed Project <strong>461</strong>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="assignment-writers-bx-bottom">
                        <div className="assignment-writers-bx-bottom-left">
                          <div className="writer-img">
                            <img src="/assets/images/writer-img2.png" alt="writer-img" />
                          </div>
                          <div className="writer-designation">
                            <h6>Desmond Eagle</h6>
                            <p>MSc in Mathematics</p>
                          </div>
                        </div>
                        <a href="#!" className="custom-border-btn btn-3 hover-filled-slide-down">
                          <span onClick={() => navigate("/submit-new-assignment")}>Hire Me</span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-6">
                    <div className="assignment-writers-bx">
                      <div className="writers-rating">
                        <div className="writers-rating-bx">
                          <img src="/assets/images/star-ico.svg" alt="star-ico" />
                          <span>4.8</span>
                        </div>
                      </div>
                      <div className="assignment-writers-bx-top">
                        <div className="subject-icon">
                          <img src="/assets/images/accounts-ico.svg" alt="subject-icon" />
                        </div>
                        <div className="subject-name">Accounts</div>
                      </div>
                      <div className="assignment-writers-bx-middle">
                        <div className="writer-details">
                          <p>I deliver online assignment help in all accounting topics and even clarifications on complex concepts.</p>
                        </div>
                        <div className="rv-cp">
                          <div className="rv">
                            <img src="/assets/images/reviews-ico.svg" alt="reviews-ico" />
                            <span>
                              Reviews <strong>432</strong>
                            </span>
                          </div>
                          <div className="cp">
                            <img src="/assets/images/complete-projects-ico.svg" alt="complete-projects-ico" />
                            <span>
                              Completed Project <strong>461</strong>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="assignment-writers-bx-bottom">
                        <div className="assignment-writers-bx-bottom-left">
                          <div className="writer-img">
                            <img src="/assets/images/writer-img3.png" alt="writer-img" />
                          </div>
                          <div className="writer-designation">
                            <h6>Desmond Eagle</h6>
                            <p>MSc in Accounts</p>
                          </div>
                        </div>
                        <a href="#!" className="custom-border-btn btn-3 hover-filled-slide-down">
                          <span onClick={() => navigate("/submit-new-assignment")}>Hire Me</span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-6">
                    <div className="assignment-writers-bx">
                      <div className="writers-rating">
                        <div className="writers-rating-bx">
                          <img src="/assets/images/star-ico.svg" alt="star-ico" />
                          <span>4.8</span>
                        </div>
                      </div>
                      <div className="assignment-writers-bx-top">
                        <div className="subject-icon">
                          <img src="/assets/images/english-ico.svg" alt="subject-icon" />
                        </div>
                        <div className="subject-name">English</div>
                      </div>
                      <div className="assignment-writers-bx-middle">
                        <div className="writer-details">
                          <p>I deliver online assignment help in all accounting topics and even clarifications on complex concepts.</p>
                        </div>
                        <div className="rv-cp">
                          <div className="rv">
                            <img src="/assets/images/reviews-ico.svg" alt="reviews-ico" />
                            <span>
                              Reviews <strong>432</strong>
                            </span>
                          </div>
                          <div className="cp">
                            <img src="/assets/images/complete-projects-ico.svg" alt="complete-projects-ico" />
                            <span>
                              Completed Project <strong>461</strong>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="assignment-writers-bx-bottom">
                        <div className="assignment-writers-bx-bottom-left">
                          <div className="writer-img">
                            <img src="/assets/images/writer-img1.png" alt="writer-img" />
                          </div>
                          <div className="writer-designation">
                            <h6>Desmond Eagle</h6>
                            <p>MSc in English</p>
                          </div>
                        </div>
                        <a href="#!" className="custom-border-btn btn-3 hover-filled-slide-down">
                          <span onClick={() => navigate("/submit-new-assignment")}>Hire Me</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 col-xl-4">
                <div className="avail-expert-help-cnt-area">
                  <div className="main-hdng">
                    <h2>Well-Versed Essay Specialists for Your Assignment Needs</h2>
                  </div>
                  <div className="avail-expert-help-cnt">
                    <p>
                      Compiling and paraphrasing academic essays is always a challenge. Unlike school-level writing, such tasks come with plenty of complex guidelines – many of which students don’t know about. In addition, students face many other assignment hurdles like difficulty finding
                      resources, aptly paraphrasing content, finishing tasks within deadlines, and many more. Understanding their plight, we present our AI-paraphraser tool to help pupils craft original text for their papers. We recommend opting for our 360-degree essay help services (with an online
                      paraphrase generator) to grasp instructions and improve academic writing.
                    </p>
                    <div className="btn-group">
                      <a href="#!" className="custombtn btn-1 hover-filled-slide-down">
                        <span>Avail expert help</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* trust service */}
        <section className="trust-service-area trust-service-area-expertsPg trust-service-area-dashboardPg pb-0">
          <div className="container">
            <div className="main-hdng text-center">
              <h2>Access Solved Equations for a Better Understanding</h2>
              <p>Refer to more examples to learn how to solve algebra problems.</p>
            </div>
            <div className="trust-service-bx-area">
              <div className="trust-service-bx">
                <div className="trust-service-bx-single">
                  <div className="trust-service-bx-single-img">
                    <img src="/assets/images/trust-service-img9.svg" alt="trust-service-bx-single-img" />
                  </div>
                  <div className="trust-service-bx-single-cnt">
                    <h5>Assignment On Sample Property Data Analysis</h5>
                    <p>Answers to the six assignment tasks in Part II must be based on the sample data file that you have created in Part I. All tasks in this assignment require you to obtain an Excel output prior to performing some analysis. Copy and Paste these outputs to your assignment MS- </p>
                  </div>
                  <a href="#!" className="btn-link">
                    View sample
                  </a>
                </div>
              </div>
              <div className="trust-service-bx">
                <div className="trust-service-bx-single">
                  <div className="trust-service-bx-single-img">
                    <img src="/assets/images/trust-service-img9.svg" alt="trust-service-bx-single-img" />
                  </div>
                  <div className="trust-service-bx-single-cnt">
                    <h5>Critical Appraisal Of Research Article Using</h5>
                    <p>Randomisation refers to the process of randomly allocating individuals or sample for a particular research activity or across particular intervention or treatment groups (Hróbjartsson et al. 2014). The study design was a pragmatic one that was based on a randomized</p>
                  </div>
                  <a href="#!" className="btn-link">
                    View sample
                  </a>
                </div>
              </div>
              <div className="trust-service-bx">
                <div className="trust-service-bx-single">
                  <div className="trust-service-bx-single-img">
                    <img src="/assets/images/trust-service-img9.svg" alt="trust-service-bx-single-img" />
                  </div>
                  <div className="trust-service-bx-single-cnt">
                    <h5>The Essay On Smart, Connected Products'</h5>
                    <p>1.Develop a decision support model using Visual DSS using the variables described above. Include comments within your Visual DSS model to explain the variables and your calculations. 2.Based on the result of your model, what is the net present value (NPV)? Explain whether</p>
                  </div>
                  <a href="#!" className="btn-link">
                    View sample
                  </a>
                </div>
              </div>
              <div className="trust-service-bx">
                <div className="trust-service-bx-single">
                  <div className="trust-service-bx-single-img">
                    <img src="/assets/images/trust-service-img9.svg" alt="trust-service-bx-single-img" />
                  </div>
                  <div className="trust-service-bx-single-cnt">
                    <h5>Essay: TQM Tool Used By Tesco To Enhance</h5>
                    <p>1. Understand the nature and purpose of total quality management and how it is influenced by the management culture of organisations. 2. Identify a range of total quality management techniques including benchmarking and process re-engineering. </p>
                  </div>
                  <a href="#!" className="btn-link">
                    View sample
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* faq area 2 */}
        <section className="faqarea-2 pb-0">
          <div className="container">
            <div className="accordion" id="accordionExample">
              <div className="accordion-item">
                <div className="accordion-header" id="headingOne">
                  <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                    Paraphrasing Tool to End Your Rephrasing Woes
                  </button>
                </div>
                <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                    <div className="faqarea-2-cnt-bottom faqarea-2-cnt-bottom-sd faqarea-3-cnt-bottom-sd align-items-end">
                      <div className="faqarea-2-cnt-bottom-left">
                        <h6>Save Time Whenever Rephrasing</h6>
                        <p>Our paraphrase generator halves your time and effort whenever you rewrite. The paraphrase tool can also alter content tone and style per your assignment needs.</p>

                        <h6>Best Way to Augment Grades</h6>
                        <p>Our online paraphrase tool helps you produce a new version of the original content worthy of an A or A+. Start using one of the best paraphrasing tools to produce and submit well-crafted, unique assignments.</p>

                        <h6>Tool That Checks Plagiarism</h6>
                        <p>With our paraphrasing tool, you can quickly compile 100% unique content for your assignments. Stop looking. Use one of the best paraphrasing tools to ‘paraphrase my essay.’</p>

                        <h6>Works for All English Dialects</h6>
                        <p>Our state-of-the-art paraphrasing tool offers support in 17+ languages. As one of the best paraphrasing tools, it also features a built-in free plagiarism checker, 3 vivid rewording AI versions, and helps reword content in all English dialects – AU, USA, CA, UK, etc. </p>

                        <div className="btn-group">
                          <a href="#!" className="custombtn btn-1 hover-filled-slide-down">
                            <span onClick={() => navigate("/submit-new-assignment")}>Order Now</span>
                          </a>
                        </div>
                      </div>
                      <div className="faqarea-2-cnt-bottom-right">
                        <div className="faqarea-2-img">
                          <img src="/assets/images/faqarea-2-img.png" alt="faqarea-2-img" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <div className="accordion-header" id="headingTwo">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                    What Are the Chief USPs of Our Paraphrasing Tool?
                  </button>
                </div>
                <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                    <div className="faqarea-2-cnt-bottom faqarea-2-cnt-bottom-sd faqarea-3-cnt-bottom-sd align-items-end">
                      <div className="faqarea-2-cnt-bottom-left">
                        <h6>Save Time Whenever Rephrasing</h6>
                        <p>Our paraphrase generator halves your time and effort whenever you rewrite. The paraphrase tool can also alter content tone and style per your assignment needs.</p>

                        <h6>Best Way to Augment Grades</h6>
                        <p>Our online paraphrase tool helps you produce a new version of the original content worthy of an A or A+. Start using one of the best paraphrasing tools to produce and submit well-crafted, unique assignments.</p>

                        <h6>Tool That Checks Plagiarism</h6>
                        <p>With our paraphrasing tool, you can quickly compile 100% unique content for your assignments. Stop looking. Use one of the best paraphrasing tools to ‘paraphrase my essay.’</p>

                        <h6>Works for All English Dialects</h6>
                        <p>Our state-of-the-art paraphrasing tool offers support in 17+ languages. As one of the best paraphrasing tools, it also features a built-in free plagiarism checker, 3 vivid rewording AI versions, and helps reword content in all English dialects – AU, USA, CA, UK, etc. </p>

                        <div className="btn-group">
                          <a href="#!" className="custombtn btn-1 hover-filled-slide-down">
                            <span onClick={() => navigate("/submit-new-assignment")}>Order Now</span>
                          </a>
                        </div>
                      </div>
                      <div className="faqarea-2-cnt-bottom-right">
                        <div className="faqarea-2-img">
                          <img src="/assets/images/faqarea-2-img.png" alt="faqarea-2-img" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <div className="accordion-header" id="headingThree">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                    How Does Our Paraphrasing Tool Enhance Your Vocabulary?
                  </button>
                </div>
                <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                    <div className="faqarea-2-cnt-bottom faqarea-2-cnt-bottom-sd faqarea-3-cnt-bottom-sd align-items-end">
                      <div className="faqarea-2-cnt-bottom-left">
                        <h6>Save Time Whenever Rephrasing</h6>
                        <p>Our paraphrase generator halves your time and effort whenever you rewrite. The paraphrase tool can also alter content tone and style per your assignment needs.</p>

                        <h6>Best Way to Augment Grades</h6>
                        <p>Our online paraphrase tool helps you produce a new version of the original content worthy of an A or A+. Start using one of the best paraphrasing tools to produce and submit well-crafted, unique assignments.</p>

                        <h6>Tool That Checks Plagiarism</h6>
                        <p>With our paraphrasing tool, you can quickly compile 100% unique content for your assignments. Stop looking. Use one of the best paraphrasing tools to ‘paraphrase my essay.’</p>

                        <h6>Works for All English Dialects</h6>
                        <p>Our state-of-the-art paraphrasing tool offers support in 17+ languages. As one of the best paraphrasing tools, it also features a built-in free plagiarism checker, 3 vivid rewording AI versions, and helps reword content in all English dialects – AU, USA, CA, UK, etc. </p>

                        <div className="btn-group">
                          <a href="#!" className="custombtn btn-1 hover-filled-slide-down">
                            <span onClick={() => navigate("/submit-new-assignment")}>Order Now</span>
                          </a>
                        </div>
                      </div>
                      <div className="faqarea-2-cnt-bottom-right">
                        <div className="faqarea-2-img">
                          <img src="/assets/images/faqarea-2-img.png" alt="faqarea-2-img" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Leading Advantages */}
        <AppAdvantages dataItems={false} header={false} />
      </div>
    </>
  );
};

export default ParaphrasingTool;
