import React, { useEffect, useState, useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { useLocation } from "react-router-dom";

// Utils
import { commafy } from "../../../../utils";

// Models
import { ReviewSectionProps } from "../../../../models/views/components/common";
import { ServiceInfo } from "../../../../models/constants/data/dataModels";

// Redux actions
import { getServiceCourseCategories } from "../../../../redux/slices/CategorySlices";

// Constants
import { digits, allowedKeys, countriesList } from "../../../../constants/data/data-constants";

// Components
import Select from "react-select";
import Review from "./Review";
const imgBaseUrl = process.env.REACT_APP_API_IMG_URL;

const ReviewSection = ({ homepageData }: ReviewSectionProps) => {
  const dispatch = useAppDispatch();
  const [selectedService, setselectedService] = useState({} as ServiceInfo | undefined);
  const [numberOfPages, setnumberOfPages] = useState<any>("");
  const [countryCurrency, setcountryCurrency] = useState<string | undefined>("");
  const [totalPrice, settotalPrice] = useState("");

  // Redux stores
  const { serviceCourseList } = useAppSelector((state) => state.categorySlicesReducer);

  const location = useLocation();

  useEffect(() => {
    if (serviceCourseList.data.length === 0) {
      dispatch(getServiceCourseCategories());
    }
  }, []);

  const handleServiceChange = (value: number | undefined) => {
    if (value !== undefined) {
      let service = serviceCourseList.data.find((item) => Number(item.id) == value);
      setselectedService(service);

      if (countryCurrency !== "" && numberOfPages !== "") {
        let costPerWord: number = Number(service?.attributes?.CAD_value);
        let totalCost = 250 * Number(numberOfPages) * costPerWord;
        settotalPrice(commafy(Math.round(totalCost)));
      }
    }
  };

  const handleCountryChange = (value: string | undefined) => {
    if (value !== undefined) {
      let currency = countriesList.find((item) => item.value === value)?.currency_symbol;
      setcountryCurrency(currency);
    }
  };

  const calculateTotalPrice = useCallback(
    (pages: number | string) => {
      const numPages = Number(pages);
      let costPerWord: number;
      const country = countriesList.find((c) => c.currency_symbol === countryCurrency);

      switch (country?.abbrev) {
        case "CAN":
          costPerWord = Number(selectedService?.attributes?.CAD_value);
          break;
        case "AUS":
          costPerWord = Number(selectedService?.attributes?.AUD_value);
          break;
        case "UK":
          costPerWord = Number(selectedService?.attributes?.GBP_value);
          break;
        case "NZ":
          costPerWord = Number(selectedService?.attributes?.NZD_value);
          break;
        case "EUR":
          costPerWord = Number(selectedService?.attributes?.EUR_value);
          break;
        case "IND":
          costPerWord = Number(selectedService?.attributes?.INR_value);
          break;
        default:
          costPerWord = Number(selectedService?.attributes?.CAD_value);
      }

      if (countryCurrency !== "" && String(pages) !== "") {
        let totalCost = 250 * numPages * costPerWord;
        settotalPrice(commafy(Math.round(totalCost)));
      } else {
        settotalPrice("0");
      }
    },
    [countryCurrency, selectedService]
  );

  useEffect(() => {
    calculateTotalPrice(numberOfPages);
  }, [numberOfPages, calculateTotalPrice]);

  return (
    <>
      <section className={`review-area review-area-aboutusPg ${location.pathname === "/blogs" ? "active" : ""}`}>
        <div className="circle-blur-img">
          <img src="/assets/images/circle-blur-img.svg" alt="circle-blur-img" />
        </div>

        <Review />
        <div className="pricing-calculator-area pricing-calculator-area-aboutusPg">
          <div className="container">
            <div className="pricing-calculator-bx">
              <div className="main-hdng">
                <h2>Pricing Calculator</h2>
              </div>
              <form>
                <div className="row g-5">
                  <div className="col-md-6 col-lg-4 col-xl-3">
                    <div className="form-group">
                      <label>Service</label>
                      {/* <select
                      className="form-select"
                      aria-label="Default select example"
                      defaultValue="0"
                      onChange={handleServiceChange}
                    >
                      <option key={0} disabled value="0">
                        Select Service
                      </option>
                      {serviceCourseList.data.map((item, index) => {
                        return (
                          <option key={index + 1} value={item.id}>
                            {item.attributes.name}
                          </option>
                        );
                      })}
                    </select> */}
                      <Select
                        name="service"
                        id=""
                        classNamePrefix="select"
                        options={serviceCourseList.data.map((item) => {
                          return {
                            value: item.id,
                            label: item.attributes.name,
                          };
                        })}
                        placeholder="Select A Service"
                        // value={{
                        //   value: submitData.service,
                        //   label: submitData.service,
                        // }}
                        onChange={(val) => handleServiceChange(val?.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-3 col-xl-3">
                    {/* <div className="form-group form-group-datepicker">
                    <label>Deadline</label>
                    <div className="form-group-icon">
                      <img
                        src="/assets/images/date-ico2.svg"
                        alt="date-ico"
                      />
                    </div>
                    <input
                      placeholder="Date"
                      type="text"
                      name="checkIn"
                      id="datepicker2"
                      value=""
                      className="calendar"
                    />
                  </div> */}
                    <div className="form-group">
                      <label htmlFor="">Country</label>
                      {/* <select
                      className="form-select"
                      aria-label="Default select example"
                      defaultValue=""
                      onChange={handleCountryChange}
                    >
                      <option key={0} disabled value="">
                        Select Country
                      </option>
                      {countriesList.map((item, index) => {
                        return (
                          <option key={index + 1} value={item.value}>
                            {item.label}
                          </option>
                        );
                      })}
                    </select> */}
                      <Select
                        name="country"
                        id=""
                        classNamePrefix="select"
                        options={countriesList.map((item) => {
                          return {
                            value: item.value,
                            label: item.label,
                          };
                        })}
                        placeholder="Select A Country"
                        // value={{
                        //   value: submitData.service,
                        //   label: submitData.service,
                        // }}
                        onChange={(val) => handleCountryChange(val?.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-2 col-xl-3">
                    <div className="form-group">
                      <label>Pages</label>
                      <input
                        className="numberstyle"
                        type="number"
                        min="1"
                        value={numberOfPages}
                        placeholder="No of page"
                        onKeyDown={(e) => {
                          // Allow digits only if Shift key is not pressed
                          if (digits.includes(e.key) && !e.shiftKey) {
                            return;
                          }

                          // Allow specific control keys
                          if (allowedKeys.includes(e.key)) {
                            return;
                          }

                          // If none of the above conditions are met, prevent the default action
                          e.preventDefault();
                        }}
                        onChange={(e) => {
                          setnumberOfPages(e.target.value);
                          calculateTotalPrice(e.target.value);
                        }}
                      />
                      <div className="form-text">1 page = 250 words approx.</div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-3 col-xl-3">
                    <div className="calculate-bx">
                      {/* <a
                      href="#!"
                      className="custombtn btn-4 hover-filled-slide-down"
                    >
                      <span>Calculate</span>
                    </a> */}
                      <div className="calculate-bx-value">
                        <h3>{totalPrice === "" ? `${countryCurrency}0.00` : `${countryCurrency}${totalPrice}`}</h3>
                        <h6>{numberOfPages * 250} words/page</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ReviewSection;
