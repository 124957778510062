import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";

// Redux actions
import { getFAQPage } from "../../redux/slices/FAQPageSlice";

// Components
import PageBanner from "../components/layout/pages/PageBanner";
import FrequentFAQs from "../components/layout/pages/FrequentFAQs";

const FAQ = () => {
  const dispatch = useAppDispatch();

  // Redux stores
  const { faqPageStatus, faqPageData } = useAppSelector((state) => state.faqs);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    if (faqPageStatus !== "success") {
      dispatch(getFAQPage());
    }
    console.log("faqPageData====>", faqPageData);
  }, []);

  return (
    <>
      {/* page banner area start */}
      <PageBanner className="about-inner-banner" title={faqPageData.sec_1_i_banner[0].heading} description={faqPageData.sec_1_i_banner[0].subheading} reviewSites={faqPageData.sec_1_i_banner[0].inner_banner_review} />
      {/* page banner area end */}

      {/* wrapper area start */}
      <div className="wrapper">
        {/* faq */}
        <FrequentFAQs className="pb-0" headingOneText={faqPageData.sec_1_i_banner[0].subheading} data={faqPageData.sec_2_faq} />
      </div>
      {/* wrapper area end */}
    </>
  );
};

export default FAQ;
