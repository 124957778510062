import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

// Helpers
import { ApiHelperFunction } from "../../helpers/api_helpers";

// Typeguards
import { isApiErrorResponse } from "../../helpers/typeguards";

// Models
import { ApiFuncArgProps } from "../../models/apiFuncHelpers";
import { ReviewPageDataModel } from "../../models/views/pages";
import { ReviewTypeListDataModel } from "../../models/constants/data/dataModels";
import { AxiosResponse } from "axios";
import { ApiErrorResponse } from "../../models/apiFuncHelpers";

interface ReviewPageInitialState {
  reviewPageStatus: string;
  reviewPageData: ReviewPageDataModel;
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  reviewTypeDataFetchStatus: string;
  reviewTypeDataList: ReviewTypeListDataModel;
  reviewTypeDataFetchIsLoading: boolean;
  reviewTypeDataFetchIsSuccess: boolean;
  reviewTypeDataFetchIsError: boolean;
}

const initialState: ReviewPageInitialState = {
  reviewPageStatus: "idle",
  reviewPageData: {
    createdAt: "",
    updatedAt: "",
    publishedAt: "",
    sec_1_i_banner: [
      {
        id: 0,
        heading: "",
        subheading: "",
        inner_banner_review: [],
      },
    ],
    sec_2_at_1: [],
  },
  isLoading: false,
  isSuccess: false,
  isError: false,
  reviewTypeDataFetchStatus: "idle",
  reviewTypeDataList: {
    data: [],
    meta: {
      pagination: {
        page: 0,
        pageCount: 0,
        pageSize: 0,
        total: 0,
      },
    },
  },
  reviewTypeDataFetchIsLoading: false,
  reviewTypeDataFetchIsSuccess: false,
  reviewTypeDataFetchIsError: false,
};

export const getReviewsPage = createAsyncThunk("get_reviews_page", async () => {
  try {
    let response = await ApiHelperFunction({
      urlPath: "/reviews-page?populate=deep",
      method: "GET",
    } as ApiFuncArgProps);

    if (isApiErrorResponse(response)) {
      console.log("Error: ", response.error.message);
    } else {
      return response.data;
    }
  } catch (error: any) {
    console.log("Failed to fetch review page due to ", error.message);
  }
});

export const getReviewTypeOne = createAsyncThunk(
  "get_review_type_one",
  async (query?:string) => {
    let response:AxiosResponse|ApiErrorResponse;
    try {
      if (query) {
        response = await ApiHelperFunction({
          urlPath: `/reviews-type-ones?populate=deep&${query}`,
          method: "GET",
        } as ApiFuncArgProps);
      } else {
        response = await ApiHelperFunction({
          urlPath: "/reviews-type-ones?populate=deep",
          method: "GET",
        } as ApiFuncArgProps);
      }

      if (isApiErrorResponse(response)) {
        console.log("Error: ", response.error.message);
      } else {
        return response.data;
      }
    } catch (error: any) {
      console.log(
        "Failed to fetch review type one data due to ",
        error.message
      );
    }
  }
);

export const reviewsPageSlice = createSlice({
  name: "reviews_page",
  initialState,
  reducers: {
    clearReviewsPageState: (state) => {
      state.reviewPageStatus = "idle";
      state.reviewPageData = {
        createdAt: "",
        updatedAt: "",
        publishedAt: "",
        sec_1_i_banner: [
          {
            id: 0,
            heading: "",
            subheading: "",
            inner_banner_review: [],
          },
        ],
        sec_2_at_1: [],
      };
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.reviewTypeDataFetchStatus = "idle";
      state.reviewTypeDataList = {
        data: [],
        meta: {
          pagination: {
            page: 0,
            pageCount: 0,
            pageSize: 0,
            total: 0,
          },
        },
      };
      state.reviewTypeDataFetchIsLoading = false;
      state.reviewTypeDataFetchIsSuccess = false;
      state.reviewTypeDataFetchIsError = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getReviewsPage.pending, (state) => {
        state.reviewPageStatus = "loading";
        state.isLoading = true;
      })
      .addCase(getReviewsPage.fulfilled, (state, { payload }) => {
        state.reviewPageStatus = "success";
        state.isSuccess = true;
        state.reviewPageData = payload?.data?.attributes;
      })
      .addCase(getReviewsPage.rejected, (state) => {
        state.reviewPageStatus = "failed";
        state.isError = true;
      })
      .addCase(getReviewTypeOne.pending, (state) => {
        state.reviewTypeDataFetchStatus = "loading";
        state.reviewTypeDataFetchIsLoading = true;
      })
      .addCase(getReviewTypeOne.fulfilled, (state, { payload }) => {
        state.reviewTypeDataFetchStatus = "success";
        state.reviewTypeDataList = payload;
        state.reviewTypeDataFetchIsSuccess = true;
      })
      .addCase(getReviewTypeOne.rejected, (state) => {
        state.reviewTypeDataFetchStatus = "failed";
        state.reviewTypeDataFetchIsError = true;
      });
  },
});

export const { clearReviewsPageState } = reviewsPageSlice.actions;
