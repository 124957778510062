import {
  // combineReducers,
  configureStore,
} from "@reduxjs/toolkit";
// import storage from "redux-persist/lib/storage";
import {
  // persistReducer,
  persistStore,
} from "redux-persist";

// Reducers
import { homePageSlice } from "./slices/HomePageSlice";
import { userSlice } from "./slices/UserSlice";
import { expertsPageSlice } from "./slices/ExpertsPageSlice";
import { authSlice } from "./slices/AuthSlice";
import { aboutUsPageSlice } from "./slices/AboutUsPageSlice";
import { blogSlice } from "./slices/BlogSlice";
import { servicePagesSlice } from "./slices/ServicePagesSlice";
import { faqPageSlice } from "./slices/FAQPageSlice";
import { contactUsPageSlice } from "./slices/ContactUsPageSlice";
import { submitAssignmentSlice } from "./slices/SubmitAssignment";
import { categoriesSlices } from "./slices/CategorySlices";
import { samplePageSlice } from "./slices/SamplePageSlice";
import { reviewsPageSlice } from "./slices/ReviewsSlice";
import { ordersSlice } from "./slices/OrdersSlice";
import { noAiPageSlice } from "./slices/SayNoToAiPageSlice";
import { referencingPageSlice } from "./slices/ReferencingSlice";

// const persistConfig = {
//   key: "root",
//   storage,
// };

// const rootReducer = combineReducers({
//   // Add your reducers here
//   homepage: homePageSlice.reducer,
//   auth: authSlice.reducer,
//   aboutUs: aboutUsPageSlice.reducer,
//   blogs: blogSlice.reducer,
// });

// const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: {
    // Add your reducers here
    homepage: homePageSlice.reducer,
    userData: userSlice.reducer,
    experts: expertsPageSlice.reducer,
    auth: authSlice.reducer,
    aboutUs: aboutUsPageSlice.reducer,
    blogs: blogSlice.reducer,
    faqs: faqPageSlice.reducer,
    servicePages: servicePagesSlice.reducer,
    contactUs: contactUsPageSlice.reducer,
    submitAssignment: submitAssignmentSlice.reducer,
    samplePage: samplePageSlice.reducer,
    categorySlicesReducer: categoriesSlices.reducer,
    reviewsPage: reviewsPageSlice.reducer,
    orders: ordersSlice.reducer,
    noAiPage: noAiPageSlice.reducer,
    referencingPage: referencingPageSlice.reducer,
  }, // persistedReducer
  devTools: process.env.NODE_ENV !== "production",
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
