// import useAuth from "../hooks/useAuth";

// Components
import DashboardHeader from "../views/components/layout/dashboard/DashboardHeader";
import { Outlet } from "react-router";

const DashboardLayout = () => {
  // useAuth();

  return (
    <>
      <div className="inner-banner dashboard-inner-banner">
        <div className="container">
          <div className="inner-banner-hdng">
            <h1>Dashboard</h1>
            <p>
              Get your hands on more such brilliant deals and discounts when you
              opt for our services.
            </p>
          </div>
        </div>
      </div>

      {/* -- wrapper area start -- */}
      <div className="wrapper">
        <DashboardHeader />

        <Outlet />
      </div>
      {/* -- wrapper area end -- */}
    </>
  );
};

export default DashboardLayout;
