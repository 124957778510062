import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

// Helpers
import { ApiHelperFunction } from "../../helpers/api_helpers";

// Models
import { ApiFuncArgProps } from "../../models/apiFuncHelpers";

interface authInitialState {
  status: string;
  userInfo:
    | {
        uid: string;
        name: string;
        email: string;
      }
    | undefined;
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
}

const initialState: authInitialState = {
  status: "idle",
  userInfo: undefined,
  isLoading: false,
  isSuccess: false,
  isError: false,
};

export const getUser = createAsyncThunk("get_user", async () => {
  let response: object = {};

  try {
    response = await ApiHelperFunction({
      urlPath: "",
      method: "Get",
    } as ApiFuncArgProps);

    if (response && "status" in response) {
      //
    }
  } catch (error) {
    //
  }
});

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    clearAuthState: (state) => {
      state.status = "idle";
      state.userInfo = undefined;
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
    },
    setUser: (state, action) => {
      state.userInfo = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getUser.pending, (state) => {
        state.status = 'loading';
        state.isLoading = true;
      })
      .addCase(getUser.fulfilled, (state, { payload }) => {
        state.status = 'success';
        state.isSuccess = true;
      })
      .addCase(getUser.rejected, (state) => {
        state.status = 'failed';
        state.isError = true;
      })
  }
});

export const { clearAuthState, setUser } = authSlice.actions;
