import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

// Helpers
import { ApiHelperFunction } from "../../helpers/api_helpers";

// Models
import { ApiFuncArgProps } from "../../models/apiFuncHelpers";
import { ContactUsDataModel } from "../../models/views/pages";

interface ContactUsPageInitialState {
  contactUsPageStatus: string;
  contactUsPageData: ContactUsDataModel;
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
}

const initialState: ContactUsPageInitialState = {
  contactUsPageStatus: "idle",
  contactUsPageData: {
    createdAt: "",
    updatedAt: "",
    publishedAt: "",
    sec_1_i_banner: [
      {
        id: 9,
        heading: "",
        subheading: "",
        inner_banner_review: [],
      },
    ],
    sec_1_1: "",
    sec_1_2: "",
    sec_1_3: "",
    sec_1_4: "",
    sec_1_5: "",
    sec_1_6: "",
    sec_1_7: "",
    sec_1_8: "",
    sec_1_9: "",
    sec_1_10: "",
    sec_1_11: "",
    sec_1_12: "",
    map: "",
  },
  isLoading: false,
  isSuccess: false,
  isError: false,
};

export const getContactUsPage = createAsyncThunk(
  "get_contact_us_page",
  async () => {
    try {
      let response = await ApiHelperFunction({
        urlPath: "/contact-us-page?populate=deep",
        method: "GET",
      } as ApiFuncArgProps);

      if (response.data) {
        return response.data?.data;
      }
    } catch (error: any) {
      console.log("Failed to fetch contact us page due to ", error.message);
    }
  }
);

export const contactUsPageSlice = createSlice({
  name: "contact_us_page",
  initialState,
  reducers: {
    clearContactUsPageState: (state) => {
      state.contactUsPageStatus = "idle";
      state.contactUsPageData = {
        createdAt: "",
        updatedAt: "",
        publishedAt: "",
        sec_1_i_banner: [
          {
            id: 9,
            heading: "",
            subheading: "",
            inner_banner_review: [],
          },
        ],
        sec_1_1: "",
        sec_1_2: "",
        sec_1_3: "",
        sec_1_4: "",
        sec_1_5: "",
        sec_1_6: "",
        sec_1_7: "",
        sec_1_8: "",
        sec_1_9: "",
        sec_1_10: "",
        sec_1_11: "",
        sec_1_12: "",
        map: "",
      };
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getContactUsPage.pending, (state) => {
        state.contactUsPageStatus = "loading";
        state.isLoading = true;
      })
      .addCase(getContactUsPage.fulfilled, (state, { payload }) => {
        state.contactUsPageStatus = "success";
        state.isSuccess = true;
        state.contactUsPageData = payload?.attributes;
      })
      .addCase(getContactUsPage.rejected, (state) => {
        state.contactUsPageStatus = "failed";
        state.isError = true;
      });
  },
});

export const { clearContactUsPageState } = contactUsPageSlice.actions;
