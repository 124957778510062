import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// import {useAppDispatch, useAppSelector } from "../../redux/hooks";

// Helpers
import qs from "qs";

// Models
import { ApiFuncArgProps } from "../../models/apiFuncHelpers";
import {
  BlogTopicCategoryDataModel,
  // BlogListDataModel
} from "../../models/views/pages";
import { BlogListDataModel } from "../../models/constants/data/dataModels";

// API
import { ApiHelperFunction } from "../../helpers/api_helpers";

// // Redux actions
// import { getAllBlogs } from "../../redux/slices/BlogSlice";

// Components
import PageBanner from "../components/layout/pages/PageBanner";
import ReactPaginate from "react-paginate";

let imgBaseUrl = process.env.REACT_APP_API_IMG_URL;

let categories: BlogTopicCategoryDataModel = {
  data: [],
};

let initialValues: BlogListDataModel = {
  data: [],
  meta: {
    pagination: {
      page: 0,
      pageSize: 0,
      pageCount: 0,
      total: 0,
    },
  },
};

interface PaginationOnChangeEvent {
  selected: number;
}

const Blogs = () => {
  const navigate = useNavigate();
  // const dispatch = useAppDispatch();
  const [topicCategories, settopicCategories] = useState(categories);
  const [blogs, setblogs] = useState(initialValues);

  // // Redux stores
  // const { blogs } = useAppSelector((state) => state.blogs);

  async function fetchBlogCategories() {
    let res1 = await ApiHelperFunction({
      urlPath: "/categories",
      method: "GET",
    } as ApiFuncArgProps);
    if (res1.data) {
      settopicCategories((prev) => {
        let update = JSON.parse(JSON.stringify(prev));
        update.data = res1.data?.data;
        return update;
      });

      /** ------ Fetch and set the blogs ------ */
      /** ----------------------------------- */
    }
  }

  async function fetchBlogs(query?: string) {
    let res;
    if (query) {
      res = await ApiHelperFunction({
        urlPath: `/blogs?populate=deep&${query}`,
        method: "GET",
      } as ApiFuncArgProps);
    } else {
      res = await ApiHelperFunction({
        urlPath: "/blogs?populate=deep&pagination[page]=1&pagination[pageSize]=9",
        method: "GET",
      } as ApiFuncArgProps);
    }

    if (res.data) {
      setblogs(res.data);
    }
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    fetchBlogCategories();
    fetchBlogs();
    // if (blogs.length === 0) {
    // dispatch(getAllBlogs());
    // }
  }, []);

  return (
    <>
      {/* page banner area start */}
      <PageBanner title="Blog" description="Essay writing &amp; more with Your Assignment Help." className="about-inner-banner" />
      {/* page banner area end */}
      {/* wrapper area start */}
      <div className="wrapper">
        {/* Blog & Articles */}
        <section className="blog-articles-area pb-0">
          <div className="container">
            <div className="main-hdng text-center">
              <h2>Blogs </h2>
            </div>
            <div className="blog-articles-bx">
              <div className="blog-articles-left-bx">
                <div className="blog-articles-left-bx-hdng">Related categories</div>
                <div className="related-categories-area">
                  <ul>
                    <li key={0}>
                      <a
                        // href="#!"
                        onClick={() => {
                          fetchBlogs();
                        }}
                      >
                        All
                      </a>
                    </li>
                    {topicCategories.data.map((item, idx) => (
                      <li key={idx + 1}>
                        <a
                          // href="#!"
                          onClick={() => {
                            const query = qs.stringify(
                              {
                                filters: {
                                  category: {
                                    id: {
                                      $eq: item.id,
                                    },
                                  },
                                },
                              },
                              {
                                encodeValuesOnly: true, // prettify URL
                              }
                            );
                            // dispatch(getAllBlogs(query as string));
                            fetchBlogs(query as string);
                          }}
                        >
                          {item.attributes.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="blog-articles-left-bx-hdng">Recent Blog</div>
                <div className="blog-horizontal-bx-area">
                  {blogs.data.slice(0, 3).map((item, index) => (
                    <div className="blog-horizontal-bx">
                      <div className="blog-horizontal-bx-img" style={{ cursor: "pointer" }} onClick={() => navigate(`/blog-details/${item?.id}`)}>
                        <img src={imgBaseUrl ? imgBaseUrl + item.attributes.image.data?.attributes?.url : ""} alt="blog-bx-img" />
                        <a className="ripple-block extra-small-ripple-block">
                          <i className="fa fa-play-circle" aria-hidden="true"></i>
                          <div className="ripple ripple-2"></div>
                          <div className="ripple ripple-3"></div>
                        </a>
                      </div>
                      <div className="blog-horizontal-bx-text">
                        <Link to={`/blog-details/${item.id}`}>{item.attributes.title}</Link>
                        <div className="blog-bx-calender">
                          <img src="/assets/images/calender-ico.svg" alt="calender-ico" />
                          <span>{new Date(item.attributes.createdAt).toDateString()}</span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="blog-articles-right-bx">
                <div className="row gy-5">
                  {blogs?.data?.map((item, idx) => (
                    <div key={idx} className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-4">
                      <div className="blog-vertical-bx">
                        <div className="blog-vertical-bx-img" style={{ cursor: "pointer" }} onClick={() => navigate(`/blog-details/${item.id}`)}>
                          <img
                            // src="/assets/images/blog-bx-img1.png"
                            src={imgBaseUrl ? imgBaseUrl + item.attributes.image.data?.attributes?.url : ""}
                            alt="blog-bx-img"
                          />
                          <a className="ripple-block">
                            <i className="fa fa-play-circle" aria-hidden="true"></i>
                            <div className="ripple ripple-2"></div>
                            <div className="ripple ripple-3"></div>
                          </a>
                        </div>
                        <div className="blog-vertical-bx-text">
                          <Link to={`/blog-details/${item.id}`}>{item.attributes.title}</Link>
                          <div className="blog-bx-calender">
                            <img src="/assets/images/calender-ico.svg" alt="calender-ico" />
                            <span>{new Date(item.attributes.createdAt).toDateString()}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel="Next"
                    previousLabel="Previous"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    containerClassName="pagination pagination-sdnew"
                    activeClassName="active"
                    onPageChange={(event: PaginationOnChangeEvent) => {
                      const query = qs.stringify(
                        {
                          pagination: {
                            page: event.selected + 1,
                            pageSize: 9,
                          },
                        },
                        {
                          encodeValuesOnly: true, // prettify URL
                        }
                      );
                      fetchBlogs(query);
                    }}
                    pageRangeDisplayed={2}
                    pageCount={blogs.meta.pagination.pageCount}
                    renderOnZeroPageCount={null}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      {/* wrapper area end */}
    </>
  );
};

export default Blogs;
