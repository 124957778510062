import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

// Helpers
import { ApiHelperFunction } from "../../helpers/api_helpers";

// Typeguards
import { isApiErrorResponse } from "../../helpers/typeguards";

// Models
import { ApiFuncArgProps } from "../../models/apiFuncHelpers";
import { SubmitAssignmentPageDataModel } from "../../models/views/pages";

interface SubmitAssignmentInitialState {
  submitAssignmentStatus: string;
  submitAssignmentData: SubmitAssignmentPageDataModel;
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
}

const initialState: SubmitAssignmentInitialState = {
  submitAssignmentStatus: "idle",
  submitAssignmentData: {
    sec_2_h1: "",
    sec_2_h2: "",
    sec_3_h1: "",
    createdAt: "",
    updatedAt: "",
    publishedAt: "",
    sec_1_i_banner: [
      {
        id: 0,
        heading: "",
        subheading: "",
        inner_banner_review: [],
      },
    ],
    sec_3_it_1: [],
  },
  isLoading: false,
  isSuccess: false,
  isError: false,
};

export const getSubmitAssignmentPage = createAsyncThunk(
  "get_submit_assignment_page",
  async () => {
    try {
      let response = await ApiHelperFunction({
        urlPath: "/submit-new-assignment?populate=deep",
        method: "GET",
      } as ApiFuncArgProps);

      if (isApiErrorResponse(response)) {
        console.log("Error: ", response.error.message);
      } else {
        return response.data?.data;
      }
    } catch (error: any) {
      console.log(
        "Failed to fetch submit new assignment page due to ",
        error.message
      );
    }
  }
);

export const submitAssignmentSlice = createSlice({
  name: "submit_assignment_page",
  initialState,
  reducers: {
    clearSubmitAssignmentState: (state) => {
      state.submitAssignmentStatus = "idle";
      state.submitAssignmentData = {
        sec_2_h1: "",
        sec_2_h2: "",
        sec_3_h1: "",
        createdAt: "",
        updatedAt: "",
        publishedAt: "",
        sec_1_i_banner: [
          {
            id: 0,
            heading: "",
            subheading: "",
            inner_banner_review: [],
          },
        ],
        sec_3_it_1: [],
      };
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSubmitAssignmentPage.pending, (state) => {
        state.submitAssignmentStatus = "loading";
        state.isLoading = true;
      })
      .addCase(getSubmitAssignmentPage.fulfilled, (state, { payload }) => {
        state.submitAssignmentStatus = "success";
        state.isSuccess = true;
        state.submitAssignmentData = payload?.attributes;
      })
      .addCase(getSubmitAssignmentPage.rejected, (state) => {
        state.submitAssignmentStatus = "failed";
        state.isError = true;
      });
  },
});

export const { clearSubmitAssignmentState } = submitAssignmentSlice.actions;
