import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

// Helpers
import { ApiHelperFunction } from "../../helpers/api_helpers";

// Typeguards
import { isApiErrorResponse } from "../../helpers/typeguards";

// Models
import { ApiFuncArgProps } from "../../models/apiFuncHelpers";
import { OrderListDataModel } from "../../models/constants/data/dataModels";

interface OrdersInitialState {
  ordersFetchStatus: string;
  ordersData: OrderListDataModel;
  ordersIsLoading: boolean;
  ordersIsSuccess: boolean;
  ordersIsError: boolean;
}

const initialState: OrdersInitialState = {
  ordersFetchStatus: "idle",
  ordersData: {
    data: [],
    meta: {
      pagination: {
        page: 0,
        pageSize: 0,
        pageCount: 0,
        total: 0,
      },
    },
  },
  ordersIsLoading: false,
  ordersIsSuccess: false,
  ordersIsError: false,
};

export const getUserOrdersList = createAsyncThunk("get_orders", async (query?: string) => {
  try {
    let response;
    if (query) {
      response = await ApiHelperFunction({
        urlPath: `/new-assignment-form-submissions?populate=deep&${query}`,
        method: "GET",
        role: "privileged",
      } as ApiFuncArgProps);
    } else {
      response = await ApiHelperFunction({
        urlPath: "/new-assignment-form-submissions?populate=deep",
        method: "GET",
      } as ApiFuncArgProps);
    }

    if (isApiErrorResponse(response)) {
      console.log("Error: ", response.error.message);
    } else {
      return response.data;
    }
  } catch (error: any) {
    console.log("Failed to fetch orders due to ", error.message);
  }
});

export const ordersSlice = createSlice({
  name: "orders_list",
  initialState,
  reducers: {
    clearOrdersListState: (state) => {
      state.ordersFetchStatus = "idle";
      state.ordersData = {
        data: [],
        meta: {
          pagination: {
            page: 0,
            pageSize: 0,
            pageCount: 0,
            total: 0,
          },
        },
      };
      state.ordersIsLoading = false;
      state.ordersIsSuccess = false;
      state.ordersIsError = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserOrdersList.pending, (state) => {
        state.ordersFetchStatus = "loading";
        state.ordersIsLoading = true;
      })
      .addCase(getUserOrdersList.fulfilled, (state, { payload }) => {
        state.ordersFetchStatus = "success";
        state.ordersIsSuccess = true;
        state.ordersData = payload;
      })
      .addCase(getUserOrdersList.rejected, (state) => {
        state.ordersFetchStatus = "failed";
        state.ordersIsError = true;
      });
  },
});

export const { clearOrdersListState } = ordersSlice.actions;
