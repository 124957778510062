import React, { useEffect, useState, useRef, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import Review from "../components/layout/common/Review";
// Utils
import { commafy } from "../../utils";

// Constants
import { digits, allowedKeys, countriesList } from "../../constants/data/data-constants";

// Models
// import { HomepageDataModel } from "../../models/views/pages";
import { ServiceInfo } from "../../models/constants/data/dataModels";

// Redux actions
import { getHomepage } from "../../redux/slices/HomePageSlice";
import { getAllBlogs } from "../../redux/slices/BlogSlice";
import { getServiceCourseCategories } from "../../redux/slices/CategorySlices";

// Components
import TopReviewWebsites from "../components/layout/pages/TopReviewWebsites";
import SubmitAssignmentForm from "../components/layout/pages/SubmitAssignmentForm";
import AssignmentWriters from "../components/layout/pages/AssignmentWriters";
import HelpFeatures from "../components/layout/pages/HelpFeatures";
import Help from "../components/layout/pages/Help";
import Select from "react-select";
import FrequentFAQs from "../components/layout/pages/FrequentFAQs";

// let initialValues: HomepageDataModel = {
//   createdAt: "",
//   updatedAt: "",
//   publishedAt: "",
//   sec_1_h1: "",
//   sec_1_h2: "",
//   sec_1_h3: "",
//   sec_1_h4: "",
//   sec_1_h5: "",
//   sec_1_h6: "",
//   sec_1_rit: [],
//   sec_2_h1: "",
//   sec_2_h2: "",
//   sec_2_assignment_writer: [],
//   sec_3_h1: "",
//   sec_3_h2: "",
//   sec_4_h1: "",
//   sec_4_h2: "",
//   sec_5_h1: "",
//   sec_5_h2: "",
//   sec_5_it_1: [],
//   sec_6_h1: "",
//   sec_h2_1: "",
//   sec_6_btn_1_text: "",
//   sec_6_it_1: [],
//   sec_7_h1: "",
//   sec_7_h2: "",
//   sec_8_h1: "",
//   sec_8_h2: "",
//   sec_8_faq: [],
// };

let imgBaseUrl = process.env.REACT_APP_API_IMG_URL;

const Homepage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  // const [attributes, setattributes] = useState(initialValues);
  const [selectedService, setselectedService] = useState({} as ServiceInfo | undefined);
  const [numberOfPages, setnumberOfPages] = useState<any>("");
  const [countryCurrency, setcountryCurrency] = useState<string | undefined>("");
  const [totalPrice, settotalPrice] = useState("");

  // Redux stores
  const { status, attributes } = useAppSelector((state) => state.homepage);
  const { blogs } = useAppSelector((state) => state.blogs);
  const { serviceCourseList } = useAppSelector((state) => state.categorySlicesReducer);

  // async function fetchHomepageData() {
  //   let res = await ApiHelperFunction({
  //     urlPath: "/home-page?populate=deep",
  //     method: "GET",
  //   } as ApiFuncArgProps);
  //   if (res.data) {
  //     setattributes((prev) => {
  //       let update = JSON.parse(JSON.stringify(prev));
  //       return { ...update, ...res.data?.data?.attributes };
  //     });
  //   }
  // }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    // fetchHomepageData();
    if (status !== "success") {
      dispatch(getHomepage());
    }
    console.log("blogs.data.length", blogs.data.length, status, attributes);
    if (blogs.data.length === 0) {
      dispatch(getAllBlogs());
    }
    if (serviceCourseList.data.length === 0) {
      dispatch(getServiceCourseCategories());
    }
  }, []);

  const handleServiceChange = (value: number | undefined) => {
    if (value !== undefined) {
      let service = serviceCourseList.data.find((item) => Number(item.id) == value);
      setselectedService(service);

      if (countryCurrency !== "" && numberOfPages !== "") {
        let costPerWord: number = Number(service?.attributes?.CAD_value);
        let totalCost = 250 * Number(numberOfPages) * costPerWord;
        settotalPrice(commafy(Math.round(totalCost)));
      }
    }
  };

  const handleCountryChange = (value: string | undefined) => {
    if (value !== undefined) {
      let currency = countriesList.find((item) => item.value === value)?.currency_symbol;
      setcountryCurrency(currency);
    }
  };
  const calculateTotalPrice: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      // let pages: number = Number(event.target.value);
      let pages: number = Number(event);
      let costPerWord: number;
      const country = countriesList.find((c) => c.currency_symbol === countryCurrency);
      switch (country?.abbrev) {
        case "CAN":
          costPerWord = Number(selectedService?.attributes?.CAD_value);
          break;
        case "AUS":
          costPerWord = Number(selectedService?.attributes?.AUD_value);
          break;
        case "UK":
          costPerWord = Number(selectedService?.attributes?.GBP_value);
          break;
        case "NZ":
          costPerWord = Number(selectedService?.attributes?.NZD_value);
          break;
        case "EUR":
          costPerWord = Number(selectedService?.attributes?.EUR_value);
          break;
        case "IND":
          costPerWord = Number(selectedService?.attributes?.INR_value);
          break;
        default:
          costPerWord = Number(selectedService?.attributes?.CAD_value);
      }
      if (countryCurrency !== "" && String(numberOfPages) !== "") {
        // let costPerWord: number = Number(selectedService?.attributes?.CAD_value);
        let totalCost = 250 * pages * costPerWord;
        settotalPrice(commafy(Math.round(totalCost)));
      } else {
        settotalPrice("0");
      }
    },
    [numberOfPages, countryCurrency, selectedService]
  );

  useEffect(() => {
    calculateTotalPrice(numberOfPages);
  }, [numberOfPages, calculateTotalPrice]);

  return (
    <>
      {/* -- Home Banner Start-- */}
      <div className="home-banner">
        <div className="circle-blur-img">
          <img src="/assets/images/circle-blur-img.svg" alt="circle-blur-img" />
        </div>
        <div className="footer-polka-dot">
          <img src="/assets/images/footer-polka-dot.svg" alt="footer-polka-dot" />
        </div>
        <div className="container">
          <div className="home-banner-cnt-area">
            <div className="row">
              <div className="col-lg-6">
                <div className="home-banner-cnt">
                  <div className="home-banner-hdng">
                    <span>{attributes.sec_1_h1}</span>
                    <h1>{attributes.sec_1_h2}</h1>
                    <h2>{attributes.sec_1_h3}</h2>
                    <p>{attributes.sec_1_h4}</p>
                    <p>
                      Trusted by <strong>1.5M+</strong> happy customers
                    </p>
                  </div>
                  <TopReviewWebsites data={attributes.sec_1_rit} />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="home-banner-form">
                  <h4>{attributes.sec_1_h6}</h4>
                  <SubmitAssignmentForm />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* -- Home Banner End -- */}

      {/* -- wrapper area start -- */}
      <div className="wrapper">
        {/* -- Assignment Writers -- */}
        <section className="assignment-writers-area">
          <div className="container">
            <AssignmentWriters title1={attributes.sec_2_h1} title2={attributes.sec_2_h2} data={attributes.sec_2_assignment_writer} />
          </div>
        </section>

        {/*  -- business success -- */}
        <section className="business-success-area">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-4">
                <div className="main-hdng">
                  <h2>{attributes.sec_3_h1}</h2>
                  <p>{attributes.sec_3_h2}</p>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="row ps-5 gy-4">
                  {attributes.sec_3_it_1?.map((item, idx) => (
                    <div key={idx} className="col-sm-6 col-md-3">
                      <div className="business-success-bx">
                        <div className="business-success-bx-img">
                          <img src={imgBaseUrl ? imgBaseUrl + item.icon?.data?.attributes?.url : ""} alt="business-success-bx-img" />
                        </div>
                        <div className="business-success-bx-text">
                          <h3>{item.heading}</h3>
                          <h6>{item.subheading}</h6>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>

        {/*  -- help features -- */}
        <HelpFeatures headingOneText={attributes.sec_4_h1} headingTwoText={attributes.sec_4_h2} data={attributes.sec_4_it_1} helpButton={false} />

        {/*  -- Benefits to your Assignment -- */}
        <section className="benefits-assignment-area">
          <div className="container">
            <div className="main-hdng text-center">
              <h2>{attributes.sec_5_h1}</h2>
              <p>{attributes.sec_5_h2}</p>
            </div>

            <div className="benefits-assignment-bx-area">
              <div className="benefits-assignment-img-area">
                <div className="benefits-assignment-img">
                  <img src="/assets/images/benefits-assignment-img.png" alt="benefits-assignment-img" />
                </div>
              </div>
              <div className="benefits-assignment-bx essay-help-bx">
                <div className="benefits-assignment-bx-img">
                  <img src={imgBaseUrl ? imgBaseUrl + attributes.sec_5_it_1?.[0]?.icon?.data?.attributes?.url : ""} alt="benefits-assignment-bx-img" />
                </div>
                <div className="benefits-assignment-bx-cnt">
                  <h5>{attributes.sec_5_it_1?.[0]?.heading}</h5>
                  <p>{attributes.sec_5_it_1?.[0]?.description}</p>
                </div>
              </div>
              <div className="benefits-assignment-bx coursework-help-bx">
                <div className="benefits-assignment-bx-img">
                  <img src={imgBaseUrl ? imgBaseUrl + attributes.sec_5_it_1?.[1]?.icon?.data?.attributes?.url : ""} alt="benefits-assignment-bx-img" />
                </div>
                <div className="benefits-assignment-bx-cnt">
                  <h5>{attributes.sec_5_it_1?.[1]?.heading}</h5>
                  <p>{attributes.sec_5_it_1?.[1]?.description}</p>
                </div>
              </div>
              <div className="benefits-assignment-bx homework-help-bx">
                <div className="benefits-assignment-bx-img">
                  <img src={imgBaseUrl ? imgBaseUrl + attributes.sec_5_it_1?.[2]?.icon?.data?.attributes?.url : ""} alt="benefits-assignment-bx-img" />
                </div>
                <div className="benefits-assignment-bx-cnt">
                  <h5>{attributes.sec_5_it_1?.[2]?.heading}</h5>
                  <p>{attributes.sec_5_it_1?.[2]?.description}</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/*  -- Get Help -- */}
        <Help headingOneText={attributes.sec_6_h1} headingTwoText={attributes.sec_h2_1} data={attributes.sec_6_it_1} helpButtonText={attributes.sec_6_btn_1_text} helpButtonUrl={attributes.sec_6_btn_1_link} />

        {/*  -- pricing calculator -- */}
        <section className="pricing-calculator-area">
          <div className="container">
            <div className="pricing-calculator-bx">
              <div className="main-hdng">
                <h2>Pricing Calculator</h2>
              </div>
              <form>
                <div className="row g-5">
                  <div className="col-md-6 col-lg-4 col-xl-3">
                    <div className="form-group">
                      <label>Service</label>
                      {/* <select
                        className="form-select"
                        aria-label="Default select example"
                        onChange={handleServiceChange}
                      >
                        <option key={0} selected disabled>
                          Select Service
                        </option>
                        {serviceCourseList.data.map((item, index) => {
                          return (
                            <option key={index + 1} value={item.id}>
                              {item.attributes.name}
                            </option>
                          );
                        })}
                      </select> */}
                      <Select
                        name="service"
                        id=""
                        classNamePrefix="select"
                        options={serviceCourseList.data.map((item) => {
                          return {
                            value: item.id,
                            label: item.attributes.name,
                          };
                        })}
                        placeholder="Select A Service"
                        onChange={(val) => handleServiceChange(val?.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4 col-xl-3">
                    <div className="form-group">
                      <label htmlFor="">Country</label>

                      <Select
                        name="country"
                        id=""
                        classNamePrefix="select"
                        options={countriesList.map((item) => {
                          return {
                            value: item.value,
                            label: item.label,
                          };
                        })}
                        placeholder="Select A Country"
                        onChange={(val) => handleCountryChange(val?.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-2 col-xl-3">
                    <div className="form-group">
                      <label>Pages</label>
                      <input
                        className="numberstyle"
                        type="number"
                        min="1"
                        value={numberOfPages}
                        placeholder="No of page"
                        onKeyDown={(e) => {
                          // Allow digits only if Shift key is not pressed
                          if (digits.includes(e.key) && !e.shiftKey) {
                            return;
                          }

                          // Allow specific control keys
                          if (allowedKeys.includes(e.key)) {
                            return;
                          }

                          // If none of the above conditions are met, prevent the default action
                          e.preventDefault();
                        }}
                        onChange={(e) => {
                          // console.log("pages=====>", e.target.value);
                          setnumberOfPages(e.target.value);
                          // calculateTotalPrice(e);
                        }}
                      />
                      <div className="form-text">1 page = 250 words approx.</div>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-2 col-xl-3">
                    <div className="calculate-bx">
                      {/* <a
                        href="#!"
                        className="custombtn btn-1 hover-filled-slide-down"
                      >
                        <span>Calculate</span>
                      </a> */}
                      <div className="calculate-bx-value">
                        <h3>{totalPrice === "" ? `${countryCurrency}0.00` : `${countryCurrency}${totalPrice}`}</h3>
                        <h6>{numberOfPages * 250} words approx</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>

        {/*  -- review -- */}
        <section className="review-area">
          <Review />
        </section>
        {/*  -- faq -- */}
        <FrequentFAQs headingOneText={attributes.sec_8_h1} headingTwo={true} headingTwoText={attributes.sec_8_h2} data={attributes.sec_8_faq} />

        {/*  -- blog -- */}
        <section className="blogarea pt-0">
          <div className="container">
            <div className="main-hdng text-center">
              <h2>
                <a style={{ color: "#023850" }} href="/blogs">
                  Blog & Articles
                </a>
              </h2>
              <p>Essay writing & more with MyAssignmentHelp</p>
            </div>

            <div className="row">
              {blogs.data.slice(0, 2).map((item, idx) => (
                <div key={idx} className="col-sm-6 col-md-6 col-lg-4">
                  <div className="blog-vertical-bx">
                    <div className="blog-vertical-bx-img" style={{ cursor: "pointer" }} onClick={() => navigate(`/blog-details/${item.id}`)}>
                      <img src={imgBaseUrl ? imgBaseUrl + item.attributes.image.data?.attributes?.url : ""} alt="blog-bx-img" />
                    </div>
                    <div className="blog-vertical-bx-text">
                      <Link to={`/blog-details/${item.id}`}>{item.attributes.title}</Link>
                      <div className="blog-bx-calender">
                        <img src="/assets/images/calender-ico.svg" alt="calender-ico" />
                        <span>{new Date(item.attributes.createdAt).toDateString()}</span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              <div className="col-md-12 col-lg-4">
                <div className="blog-horizontal-bx-area">
                  {blogs.data.slice(2, 5).map((item, idx) => (
                    <div key={idx} className="blog-horizontal-bx">
                      <div className="blog-horizontal-bx-img" style={{ cursor: "pointer" }} onClick={() => navigate(`/blog-details/${item.id}`)}>
                        <img src={imgBaseUrl ? imgBaseUrl + item.attributes.image.data?.attributes?.url : ""} alt="blog-bx-img" />
                      </div>
                      <div className="blog-horizontal-bx-text">
                        <Link to={`/blog-details/${item.id}`}>{item.attributes.title}</Link>
                        <div className="blog-bx-calender">
                          <img src="/assets/images/calender-ico.svg" alt="calender-ico" />
                          <span>{new Date(item.attributes.createdAt).toDateString()}</span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      {/* -- wrapper area end -- */}
    </>
  );
};

export default Homepage;
