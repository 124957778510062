import { FrequentFAQsProp } from "../../../../models/views/components/pages";

const FrequentFAQs = ({ className = "", headingOneText, headingTwo = false, headingTwoText, data }: FrequentFAQsProp) => {
  return (
    <section className={`faqarea ${className ? className : ""}`}>
      <div className="container">
        <div className="main-hdng text-center">
          <h2>{headingOneText}</h2>
          {headingTwo && <p>{headingTwoText}</p>}
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="accordion accordion-row" id="accordionExample-faq">
              {data?.map((item, id) => {
                return (
                  <div key={id} className="accordion-item">
                    <div className="accordion-header" id={`heading_${id}`}>
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse_${id}`} aria-expanded="false" aria-controls={`collapse_${id}`}>
                        <span>{item.question}</span>
                      </button>
                    </div>
                    <div id={`collapse_${id}`} className="accordion-collapse collapse" aria-labelledby={`heading_${id}`} data-bs-parent="#accordionExample-faq">
                      <div className="accordion-body">{item.answer}</div>
                    </div>
                  </div>
                );
                // if (id === 0) {
                //   return (
                //     <div key={id} className="accordion-item">
                //       <div className="accordion-header" id={`heading_${id}`}>
                //         <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse_${id}`} aria-expanded="true" aria-controls={`collapse_${id}`}>
                //           <span>{item.question}</span>
                //         </button>
                //       </div>
                //       <div id={`collapse_${id}`} className="accordion-collapse collapse show" aria-labelledby={`heading_${id}`} data-bs-parent="#accordionExample-faq">
                //         <div className="accordion-body">{item.answer}</div>
                //       </div>
                //     </div>
                //   );
                // } else {
                //   return (
                //     <div key={id} className="accordion-item">
                //       <div className="accordion-header" id={`heading_${id}`}>
                //         <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse_${id}`} aria-expanded="false" aria-controls={`collapse_${id}`}>
                //           <span>{item.question}</span>
                //         </button>
                //       </div>
                //       <div id={`collapse_${id}`} className="accordion-collapse collapse" aria-labelledby={`heading_${id}`} data-bs-parent="#accordionExample-faq">
                //         <div className="accordion-body">{item.answer}</div>
                //       </div>
                //     </div>
                //   );
                // }
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FrequentFAQs;
