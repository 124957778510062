import { WritersDataModel } from "../../models/constants/data/dataModels";

export const writers: WritersDataModel = {
  info: [
    {
      id: 1,
      rating: 4.8,
      heading: "English",
      icon: {
        data: {
          attributes: {
            url: "/assets/images/english-ico.svg",
          },
        },
      },
      description:
        "I deliver online assignment help in all accounting topics and even clarifications on complex concepts.",
      review_count: "432",
      complete_project_count: "461",
      assignment_writer_imge: {
        data: {
          attributes: {
            url: "/assets/images/writer-img1.png",
          },
        },
      },
      assignment_writer_name: "Desmond Eagle",
      assignment_writer_education: "MA in English",
    },
    {
      id: 2,
      rating: 4.8,
      heading: "Mathematics",
      icon: {
        data: {
          attributes: {
            url: "/assets/images/maths-ico.svg",
          },
        },
      },
      description:
        "I deliver online assignment help in all accounting topics and even clarifications on complex concepts.",
      review_count: "432",
      complete_project_count: "461",
      assignment_writer_imge: {
        data: {
          attributes: {
            url: "/assets/images/writer-img2.png",
          },
        },
      },
      assignment_writer_name: "Desmond Eagle",
      assignment_writer_education: "MSc in Mathematics",
    },
    {
      id: 3,
      rating: 4.8,
      heading: "Accounts",
      icon: {
        data: {
          attributes: {
            url: "/assets/images/accounts-ico.svg",
          },
        },
      },
      description:
        "I deliver online assignment help in all accounting topics and even clarifications on complex concepts.",
      review_count: "432",
      complete_project_count: "461",
      assignment_writer_imge: {
        data: {
          attributes: {
            url: "/assets/images/writer-img3.png",
          },
        },
      },
      assignment_writer_name: "Desmond Eagle",
      assignment_writer_education: "MCom in Accounts",
    },
  ],
};
