import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="acfooter">
      <div className="circle-blur-img">
        <img src="/assets/images/circle-blur-img.svg" alt="circle-blur-img" />
      </div>
      <div className="footer-polka-dot">
        <img src="/assets/images/footer-polka-dot.svg" alt="footer-polka-dot" />
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            {/* <div className="acfooter-top">
              <a href="index.html" className="acfooter-top-logo">
                <img src="/assets/images/logo.svg" alt="logo" />
              </a>
            </div> */}
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6 col-md-6 col-lg-2 responsive-order-2">
            <div className="footerlinks">
              <h3 className="footertitle">Get to know us</h3>
              <ul>
                <li>
                  <Link to="/blogs">Blogs</Link>
                </li>
                <li>
                  <Link to="/sample">Sample</Link>
                </li>
                <li>
                  <Link to="/faqs">Faqs</Link>
                </li>

                <li>
                  <Link to="/reviews">Reviews</Link>
                </li>
                <li>
                  <Link to="/contact-us">Contact Us</Link>
                </li>
                {/* <li>
                  <a href="#!">Our Offers</a>
                </li> */}
              </ul>
            </div>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-7">
            <div className="footerlinks special-menu">
              <h3 className="footertitle">Services</h3>
              <ul>
                <li>
                  <Link to="/write-my-paper">Write My Paper</Link>
                </li>
                {/* <li>
                              <Link to="/submit-new-assignment">
                                Submit New Assigment
                              </Link>
                            </li> */}
                <li>
                  <Link to="/mba-essay-writing">MBA Assignment Help</Link>
                </li>
                <li>
                  <Link to="/write-my-essay-for-me">Write My Essay For Me</Link>
                </li>
                <li>
                  <Link to="/cheap-essay-writing">Cheap Essay Writing</Link>
                </li>
                <li>
                  <Link to="/get-essay-help">Essay Help</Link>
                </li>
                <li>
                  <Link to="/research-proposal-writing-service">Research Proposal Writing</Link>
                </li>
                <li>
                  <Link to="/research-paper-writing">Research Paper Writing</Link>
                </li>
                <li>
                  <Link to="/ghost-writer">Ghost Writer</Link>
                </li>
                <li>
                  <Link to="/academic-writing">Academic Writing</Link>
                </li>
                <li>
                  <Link to="/programming-assignment-help">Programming Assignment Help</Link>
                </li>
                <li>
                  <Link to="/assessment-help">Assessment Help</Link>
                </li>
                <li>
                  <Link to="/do-my-assignment">Do My Assignment</Link>
                </li>
                <li>
                  <Link to="/college-homework-help">College Homework Help</Link>
                </li>
                <li>
                  <Link to="/urgent-assignment-help">Urgent Assignment Help</Link>
                </li>
                <li>
                  <Link to="/pay-someone-to-do-my-homework">Pay Someone To Do My Homework</Link>
                </li>
                <li>
                  <Link to="/do-my-coursework">Do My Coursework</Link>
                </li>
                <li>
                  <Link to="/do-my-homework">Do My Homework</Link>
                </li>
                <li>
                  <Link to="/take-my-online-class">Take My Online Class</Link>
                </li>
                <li>
                  <Link to="/take-my-online-exam">Take My Online Exam</Link>
                </li>
                <li>
                  <Link to="/dissertation-help">Dissertation Help</Link>
                </li>
                <li>
                  <Link to="/term-paper-help">Term Paper Help</Link>
                </li>
                <li>
                  <Link to="/homework-help">Homework Help</Link>
                </li>
                <li>
                  <Link to="/case-study-help">Case Study Help</Link>
                </li>
                <li>
                  <Link to="/coursework-help">Coursework Help</Link>
                </li>
                <li>
                  <Link to="/thesis-help">Thesis Help</Link>
                </li>
                <li>
                  <Link to="/powerpoint-presentation-service">Powerpoint Presentation Service</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-sm-6 col-md-6 col-lg-3">
            <div className="footerlinks">
              <h3 className="footertitle">Academic Tools</h3>
              <ul>
                <li>
                  <Link to="/coming-soon">Grammar Checker</Link>
                </li>
                <li>
                  <Link to="/coming-soon">Factoring Calculator</Link>
                </li>
                <li>
                  <Link to="/coming-soon">Equation Solver</Link>
                </li>
                <li>
                  <Link to="/coming-soon">Paraphrasing Tool</Link>
                </li>
                <li>
                  <Link to="/coming-soon">Plagiarism Checker</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-sm-6">
            <div className="footerlinks"></div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="row">
            <div className="col-md-12">
              <div className="copyrights text-center">
                <div className="d-flex flex-column flex-md-row justify-content-center align-items-center gap-3 flex-wrap-wrap">
                  <span>© 2024 YOUR ASSIGNMENT HELP. All Rights Reserved.</span>
                  <span className="social-link d-flex gap-3">
                    <a href="#!" aria-label="LinkedIn">
                      <i className="fa-brands fa-linkedin-in"></i>
                    </a>
                    <a href="#!" aria-label="Instagram">
                      <i className="fa-brands fa-instagram"></i>
                    </a>
                    <a href="#!" aria-label="Facebook">
                      <i className="fa-brands fa-facebook-f"></i>
                    </a>
                  </span>
                  <span className="policy-link d-flex gap-3">
                    <a href="/privacy-policy">Privacy Policy</a>
                    <a href="/refund-policy">Refund Policy</a>
                    <a href="/fair-use-policy">Fair Use Policy</a>
                  </span>
                  <span>
                    Designed by{" "}
                    <a href="https://www.theaquarious.com/" target="_blank" rel="noopener noreferrer">
                      Aquarious
                    </a>
                  </span>
                  <span style={{ fontSize: "12px" }}>
                    <strong>Disclaimer:</strong> The reference papers provided by https://yourassignmenthelp.uk/ serve as model papers for students and are not to be submitted as it is. These papers are intended to be used for research and reference purposes only.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
