import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";

// Redux actions
import { getNoAiPage } from "../../../redux/slices/SayNoToAiPageSlice";

const imgBaseUrl = process.env.REACT_APP_API_IMG_URL;

const SayNoToAi = () => {
  const dispatch = useAppDispatch();

  // Redux stores
  const { noAiPageStatus, noAiPageData } = useAppSelector((state) => state.noAiPage);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    if (noAiPageStatus !== "success") {
      dispatch(getNoAiPage());
    }
  }, []);
  console.log("noAiPageData", noAiPageData);

  return (
    <>
      <section className="dashboard-top-area">
        <div className="container">
          <div className="main-hdng text-center">
            <h2>
              <span>
                <img src="/assets/images/warning-ico.webp" alt="warning-ico" />
              </span>{" "}
              {noAiPageData.sec_1_h1.split(" ")[0]}{" "}
              <span>
                <img src="/assets/images/warning-ico.webp" alt="warning-ico" />
              </span>{" "}
              {noAiPageData.sec_1_h1.split(" ").slice(1).join(" ")}
            </h2>
            <p>{noAiPageData.sec_1_h2}</p>
          </div>
        </div>
      </section>

      <section className="dashboard-middle-area pb-0">
        <div className="container">
          <div className="what-ai-bx">
            <img src="/assets/images/ai-img.png" alt="ai-img" className="ai-img" />
            <div className="row">
              <div className="col-lg-8">
                <h3>{noAiPageData.sec_2_h1}</h3>
                <p>{noAiPageData.sec_2_description}</p>
              </div>
            </div>
          </div>
          <div className="what-ai-bx-bottom">
            <h5>{noAiPageData.sec_3_h1}</h5>
            <p>{noAiPageData.sec_3_description}</p>
          </div>
          {/* <div className="what-ai-bx-ul">
            <h6>Some examples include :</h6>
            <ul>
              <li>
                The Department of Education in New York City has blocked access
                to ChatGPT and similar tools across devices and networks
                accessed by public schools.
              </li>
              <li>
                Seattle Public Schools completely blocked the use of ChatGPT.
              </li>
              <li>
                Among the 24 Russell Group Universities in the UK, eight
                institutes, including Cambridge University, have banned the use
                of ChatGPT for producing academic content.
              </li>
              <li>
                The updated academic integrity policy at the University of
                Sydney states that content generated using artificial
                intelligence will be considered “cheating.”
              </li>
            </ul>
          </div> */}
          <div className="what-ai-bx-bottom">
            {noAiPageData.sec_3_main_body_text}
            {/* <h5>Tools to Detect AI Content</h5>
            <p>
              The fight against AI content generators has become intense as
              several academic institutes have declared resorting to AI content
              detectors to check student papers. For example, The Queen Mary
              University of London has already started using Turnitin to check
              assignments for plagiarism.
            </p>
            <p>
              The creators of Turnitin have already upgraded the tool to detect
              A.I.-generated content along with plagiarism detection. Other
              equally capable tools that incorporate AI to detect AI-generated
              content are –
            </p> */}
          </div>
          {/* <div className="what-ai-bx-ul">
            <ul>
              <li>Originality.AI</li>
              <li>Content at Scale</li>
              <li>Copyleaks</li>
              <li>GPT Zero</li>
              <li>Writer</li>
            </ul>
            <p>
              These are all more than capable of detecting assignments created
              by the latest AI content generators.
            </p>
          </div> */}
        </div>
      </section>

      <section className="amazing-features-area pb-0">
        <div className="container">
          <div className="main-hdng text-center">
            <h2>{noAiPageData.sec_4_h1}</h2>
          </div>
          <div className="row g-4">
            {noAiPageData.sec_4_it_1.map((item, index) => (
              <div key={index} className="col-lg-6">
                <div className="amazing-features-bx">
                  <div className="amazing-features-bx-img">
                    <img
                      // src="/assets/images/amazing-features-bx-img1.svg"
                      src={imgBaseUrl ? imgBaseUrl + item.icon?.data?.attributes?.url : ""}
                      alt="amazing-features-bx-img"
                    />
                  </div>
                  <div className="amazing-features-bx-txt">
                    <h5>{item.heading}</h5>
                    <p>{item.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default SayNoToAi;
