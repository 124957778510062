import PageBanner from "../../components/layout/pages/PageBanner";

const Orders = () => {
  return (
    <>
      {/* page banner area start */}
      <PageBanner
        title="Order"
        description="Order placed successfully!"
        reviewInfo={true}
        className="order-inner-banner"
        ratingDisplay={false}
      />
      {/* page banner area end */}

      {/* wrapper area start */}
      <div className="wrapper">

        <section className='order-placed-area'>
          <div className='container'>
            <div className='order-placed-bx'>
              <div className='ordr-id'>Order ID: <strong>57899400A</strong></div>
              <div className='order-placed-bx-middle'>
                <img src='assets/images/order-placed-bx-middle-ico.svg' alt='order-placed-bx-middle-ico' />
              </div>
              <div className='order-placed-bx-left'>
                <img src='assets/images/order-placed-img.svg' alt='order-placed-img' />
              </div>
              <div className='order-placed-bx-right'>
                <div className='order-placed-bx-right-area'>
                  <h4>Order placed successfully!</h4>
                  <p>Our experts are going through your Requirements. Please continue on chat to know the price!</p>
                  <div className="btn-group">
                    <a
                      href="#!"
                      className="custombtn btn-1 hover-filled-slide-down"
                    >
                      <span>Submit</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        
      </div>
      {/* wrapper area end */}
    </>
  )
}

export default Orders