export interface FileDownloadArgs {
  data: Blob;
  fileName: string;
  fileType: string;
}

/** For downloading any extension type file in local */
export const downloadFile = ({
  data,
  fileName,
  fileType,
}: FileDownloadArgs) => {
  const blob = new Blob([data], { type: fileType });
  const a = document.createElement("a");
  a.download = fileName;
  a.href = window.URL.createObjectURL(blob);
  const clickEvt = new MouseEvent("click", {
    view: window,
    bubbles: true,
    cancelable: true,
  });
  a.dispatchEvent(clickEvt);
  a.remove();
};

export function fromUrlDownFn(url: string, fileName: string) {
  fetch(url)
    .then((res) => {
      if (!res.ok) {
        throw new Error("Network Problem");
      }
      return res.blob();
    })
    .then((file) => {
      const ex = extFn(url);
      let tUrl = URL.createObjectURL(file);
      const tmp1 = document.createElement("a");
      tmp1.href = tUrl;
      tmp1.download = `${fileName}.${ex}`;
      document.body.appendChild(tmp1);
      tmp1.click();
      URL.revokeObjectURL(tUrl);
      tmp1.remove();
    })
    .catch((error: any) => {
      console.log("Failed to download file due to ", error.message);
    });
}
function extFn(url: string) {
  const match = url.match(/\.[0-9a-z]+$/i);
  return match ? match[0].slice(1) : "";
}
