import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

// Components
import AppAdvantages from "../../../components/layout/pages/AppAdvantages";

const PlagiarismChecker = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <section className="dashboard-service-top-area">
        <div className="container">
          <div className="main-hdng text-center">
            <h2>100% Authentic plagiarism checker is a click away</h2>
            <p>Check Plagiarism Online Within Seconds</p>
          </div>
          <div className="dashboard-service-top-bx">
            <div className="row">
              <div className="col-lg-7">
                <div className="home-banner-form">
                  <div className="row">
                    <div className="col-xl-12">
                      <div className="form-group form-group-textarea">
                        <div className="form-group-icon">
                          <img src="/assets/images/subject-ico.svg" alt="subject-ico" />
                        </div>
                        <div className="form-group-form">
                          <textarea placeholder="Drag and drop the document, attach the file, or copy-paste the text. The tool supports DOC, PPTX, PDF, and TXT files." defaultValue={""} />
                        </div>
                      </div>
                      <div className="small-text">Enter or copy paste atleast 15 words content</div>
                    </div>
                    <div className="col-xl-12">
                      <div className="form-group-last">
                        <div className="file-input">
                          <input type="file" name="file-input" id="file-input" className="file-input__input" />
                          <label className="file-input__label" htmlFor="file-input">
                            <img src="/assets/images/attach-ico.svg" alt="attach-ico" />
                            <span>Attach</span>
                          </label>
                        </div>
                        <div className="btn-group">
                          <a href="#!" className="custombtn btn-1 hover-filled-slide-down">
                            <span>Check Plagiarism</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="dashboard-service-top-bx-right2">
                  <div className="dstbr-single-2">
                    <div className="dstbr-single-2-img">
                      <img src="assets/images/dstbr-single-2-img1.svg" alt="dstbr-single-2-img" />
                    </div>
                    <div className="dstbr-single-2-txt">Scan your document against millions of web pages and over 16 billion databases.</div>
                  </div>
                  <div className="dstbr-single-2">
                    <div className="dstbr-single-2-img">
                      <img src="assets/images/dstbr-single-2-img2.svg" alt="dstbr-single-2-img" />
                    </div>
                    <div className="dstbr-single-2-txt">Download the plagiarism report for free keeping your information strictly confidential.</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Get Help */}
      <section className="gethelp-area gethelp-dashboard-area">
        <div className="container">
          <div className="main-hdng text-center">
            <h2>Online plagiarism checker: How to use it?</h2>
            <p>Using our online plagiarism checker is easy and quick. You don't have to be tech-savvy to try our plagiarism checker. Just follow these four simple steps and you’re good to go! Give our plagiarism detector a try and ensure the 100% originality of your document.</p>
          </div>
          <div className="gethelp-bx-area">
            <div className="gethelp-line">
              <img src="/assets/images/gethelp-line.svg" alt="gethelp-line" />
            </div>
            <div className="gethelp-bx">
              <div className="gethelp-bx-number">01</div>
              <div className="gethelp-bx-img">
                <img src="/assets/images/gethelp-bx-img4.svg" alt="gethelp-bx-img" />
              </div>
              <div className="gethelp-bx-text">
                <h5>Upload</h5>
                <p>Sign up with us and upload your document on our free plagiarism checker.</p>
              </div>
            </div>
            <div className="gethelp-bx">
              <div className="gethelp-bx-number">02</div>
              <div className="gethelp-bx-img">
                <img src="/assets/images/gethelp-bx-img5.svg" alt="gethelp-bx-img" />
              </div>
              <div className="gethelp-bx-text">
                <h5>Scan</h5>
                <p>Let the plagiarism detector check for even 1% of plagiarism in your document.</p>
              </div>
            </div>
            <div className="gethelp-bx">
              <div className="gethelp-bx-number">03</div>
              <div className="gethelp-bx-img">
                <img src="/assets/images/gethelp-bx-img6.svg" alt="gethelp-bx-img" />
              </div>
              <div className="gethelp-bx-text">
                <h5>Compare</h5>
                <p>Our plagiarism checker compares your files with millions of online databases and highlights the major duplications.</p>
              </div>
            </div>
            <div className="gethelp-bx">
              <div className="gethelp-bx-number">04</div>
              <div className="gethelp-bx-img">
                <img src="/assets/images/gethelp-bx-img7.svg" alt="gethelp-bx-img" />
              </div>
              <div className="gethelp-bx-text">
                <h5>Report</h5>
                <p>Finally, you will get the plagiarism checker report with plagiarism percentage mentioned in it.</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* trust service */}
      <section className="trust-service-area trust-service-area-expertsPg trust-service-area-dashboardPg pb-0">
        <div className="container">
          <div className="main-hdng text-center">
            <h2>Access Solved Equations for a Better Understanding</h2>
            <p>Refer to more examples to learn how to solve algebra problems.</p>
          </div>
          <div className="trust-service-bx-area">
            <div className="trust-service-bx">
              <div className="trust-service-bx-single">
                <div className="trust-service-bx-single-img">
                  <img src="/assets/images/trust-service-img9.svg" alt="trust-service-bx-single-img" />
                </div>
                <div className="trust-service-bx-single-cnt">
                  <h5>Assignment On Sample Property Data Analysis</h5>
                  <p>Answers to the six assignment tasks in Part II must be based on the sample data file that you have created in Part I. All tasks in this assignment require you to obtain an Excel output prior to performing some analysis. Copy and Paste these outputs to your assignment MS- </p>
                </div>
                <a href="#!" className="btn-link">
                  View sample
                </a>
              </div>
            </div>
            <div className="trust-service-bx">
              <div className="trust-service-bx-single">
                <div className="trust-service-bx-single-img">
                  <img src="/assets/images/trust-service-img9.svg" alt="trust-service-bx-single-img" />
                </div>
                <div className="trust-service-bx-single-cnt">
                  <h5>Critical Appraisal Of Research Article Using</h5>
                  <p>Randomisation refers to the process of randomly allocating individuals or sample for a particular research activity or across particular intervention or treatment groups (Hróbjartsson et al. 2014). The study design was a pragmatic one that was based on a randomized</p>
                </div>
                <a href="#!" className="btn-link">
                  View sample
                </a>
              </div>
            </div>
            <div className="trust-service-bx">
              <div className="trust-service-bx-single">
                <div className="trust-service-bx-single-img">
                  <img src="/assets/images/trust-service-img9.svg" alt="trust-service-bx-single-img" />
                </div>
                <div className="trust-service-bx-single-cnt">
                  <h5>The Essay On Smart, Connected Products'</h5>
                  <p>1.Develop a decision support model using Visual DSS using the variables described above. Include comments within your Visual DSS model to explain the variables and your calculations. 2.Based on the result of your model, what is the net present value (NPV)? Explain whether</p>
                </div>
                <a href="#!" className="btn-link">
                  View sample
                </a>
              </div>
            </div>
            <div className="trust-service-bx">
              <div className="trust-service-bx-single">
                <div className="trust-service-bx-single-img">
                  <img src="/assets/images/trust-service-img9.svg" alt="trust-service-bx-single-img" />
                </div>
                <div className="trust-service-bx-single-cnt">
                  <h5>Essay: TQM Tool Used By Tesco To Enhance</h5>
                  <p>1. Understand the nature and purpose of total quality management and how it is influenced by the management culture of organisations. 2. Identify a range of total quality management techniques including benchmarking and process re-engineering. </p>
                </div>
                <a href="#!" className="btn-link">
                  View sample
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* faq area 2 */}
      <section className="faqarea-2 pb-0">
        <div className="container">
          <div className="accordion" id="accordionExample">
            <div className="accordion-item">
              <div className="accordion-header" id="headingOne">
                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                  Paraphrasing Tool to End Your Rephrasing Woes
                </button>
              </div>
              <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  <div className="faqarea-2-cnt-bottom faqarea-2-cnt-bottom-sd faqarea-3-cnt-bottom-sd align-items-end">
                    <div className="faqarea-2-cnt-bottom-left">
                      <h6>Save Time Whenever Rephrasing</h6>
                      <p>Our paraphrase generator halves your time and effort whenever you rewrite. The paraphrase tool can also alter content tone and style per your assignment needs.</p>

                      <h6>Best Way to Augment Grades</h6>
                      <p>Our online paraphrase tool helps you produce a new version of the original content worthy of an A or A+. Start using one of the best paraphrasing tools to produce and submit well-crafted, unique assignments.</p>

                      <h6>Tool That Checks Plagiarism</h6>
                      <p>With our paraphrasing tool, you can quickly compile 100% unique content for your assignments. Stop looking. Use one of the best paraphrasing tools to ‘paraphrase my essay.’</p>

                      <h6>Works for All English Dialects</h6>
                      <p>Our state-of-the-art paraphrasing tool offers support in 17+ languages. As one of the best paraphrasing tools, it also features a built-in free plagiarism checker, 3 vivid rewording AI versions, and helps reword content in all English dialects – AU, USA, CA, UK, etc. </p>

                      <div className="btn-group">
                        <a href="#!" className="custombtn btn-1 hover-filled-slide-down">
                          <span onClick={() => navigate("/submit-new-assignment")}>Order Now</span>
                        </a>
                      </div>
                    </div>
                    <div className="faqarea-2-cnt-bottom-right">
                      <div className="faqarea-2-img">
                        <img src="/assets/images/faqarea-2-img.png" alt="faqarea-2-img" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <div className="accordion-header" id="headingTwo">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                  What Are the Chief USPs of Our Paraphrasing Tool?
                </button>
              </div>
              <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  <div className="faqarea-2-cnt-bottom faqarea-2-cnt-bottom-sd faqarea-3-cnt-bottom-sd align-items-end">
                    <div className="faqarea-2-cnt-bottom-left">
                      <h6>Save Time Whenever Rephrasing</h6>
                      <p>Our paraphrase generator halves your time and effort whenever you rewrite. The paraphrase tool can also alter content tone and style per your assignment needs.</p>

                      <h6>Best Way to Augment Grades</h6>
                      <p>Our online paraphrase tool helps you produce a new version of the original content worthy of an A or A+. Start using one of the best paraphrasing tools to produce and submit well-crafted, unique assignments.</p>

                      <h6>Tool That Checks Plagiarism</h6>
                      <p>With our paraphrasing tool, you can quickly compile 100% unique content for your assignments. Stop looking. Use one of the best paraphrasing tools to ‘paraphrase my essay.’</p>

                      <h6>Works for All English Dialects</h6>
                      <p>Our state-of-the-art paraphrasing tool offers support in 17+ languages. As one of the best paraphrasing tools, it also features a built-in free plagiarism checker, 3 vivid rewording AI versions, and helps reword content in all English dialects – AU, USA, CA, UK, etc. </p>

                      <div className="btn-group">
                        <a href="#!" className="custombtn btn-1 hover-filled-slide-down">
                          <span onClick={() => navigate("/submit-new-assignment")}>Order Now</span>
                        </a>
                      </div>
                    </div>
                    <div className="faqarea-2-cnt-bottom-right">
                      <div className="faqarea-2-img">
                        <img src="/assets/images/faqarea-2-img.png" alt="faqarea-2-img" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <div className="accordion-header" id="headingThree">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                  How Does Our Paraphrasing Tool Enhance Your Vocabulary?
                </button>
              </div>
              <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  <div className="faqarea-2-cnt-bottom faqarea-2-cnt-bottom-sd faqarea-3-cnt-bottom-sd align-items-end">
                    <div className="faqarea-2-cnt-bottom-left">
                      <h6>Save Time Whenever Rephrasing</h6>
                      <p>Our paraphrase generator halves your time and effort whenever you rewrite. The paraphrase tool can also alter content tone and style per your assignment needs.</p>

                      <h6>Best Way to Augment Grades</h6>
                      <p>Our online paraphrase tool helps you produce a new version of the original content worthy of an A or A+. Start using one of the best paraphrasing tools to produce and submit well-crafted, unique assignments.</p>

                      <h6>Tool That Checks Plagiarism</h6>
                      <p>With our paraphrasing tool, you can quickly compile 100% unique content for your assignments. Stop looking. Use one of the best paraphrasing tools to ‘paraphrase my essay.’</p>

                      <h6>Works for All English Dialects</h6>
                      <p>Our state-of-the-art paraphrasing tool offers support in 17+ languages. As one of the best paraphrasing tools, it also features a built-in free plagiarism checker, 3 vivid rewording AI versions, and helps reword content in all English dialects – AU, USA, CA, UK, etc. </p>

                      <div className="btn-group">
                        <a href="#!" className="custombtn btn-1 hover-filled-slide-down">
                          <span onClick={() => navigate("/submit-new-assignment")}>Order Now</span>
                        </a>
                      </div>
                    </div>
                    <div className="faqarea-2-cnt-bottom-right">
                      <div className="faqarea-2-img">
                        <img src="/assets/images/faqarea-2-img.png" alt="faqarea-2-img" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Leading Advantages */}
      <AppAdvantages dataItems={false} header={false} />
    </>
  );
};

export default PlagiarismChecker;
