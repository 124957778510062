import PageBanner from "../../components/layout/pages/PageBanner";

const MentorAnswer = () => {
  return (
    <>
      {/* page banner area start */}
      <PageBanner
        title="Answer"
        description="Contract Law Assignment: Offer, Acceptance, Revocation, and Postal Rules"
        reviewInfo={true}
        className="order-inner-banner"
        ratingDisplay={false}
      />
      {/* page banner area end */}

      {/* wrapper area start */}
      <div className="wrapper">

        <section className='mentor-answer-area pb-0'>
          <div className='container'>
            <h4>Contract Law Assignment: Offer, Acceptance, Revocation, and Postal Rules</h4>
            <h5>Learning Outcomes for the item of assessment</h5>
            <p>
              This item of assessment covers the following learning outcomes. For the full list of learning outcomes for the module, please refer to the Module Study Guide. <br></br>
              â€¢ Demonstrate an understanding and application of law governing businesses and assess its effectiveness <br></br>
              â€¢ Identify the main sources of law and essential elements of the legal system <br></br>
              â€¢ Apply relevant legal rules relating to the law of obligations <br></br>
              â€¢ Understand and be able to apply the law in a specified business situation/hypothetical scenario <br></br>
              â€¢ Understand corporate fraudulent and criminal behaviour <br></br>
              â€¢ Develop the key skills of self-management (C2) and problem solving (C4)
            </p>
            <h5>Assessment Grading</h5>
            <p>Your work will be marked in grades rather than percentages. This is considered to deliver the most accurate and fair outcomes for students. Each assignment that you undertake will be assessed using the common grading system. Information about the grading system can be found in your Student Handbook.</p>
            <p>The Grade Criteria can be found in Appendix C of your Student Handbook</p>
            <h5>Appendix 1: Submission Checklist</h5>
            <ol>
              <li>Have you checked Canvas messages/announcements for any additional/final details of the assessment?</li>
              <li>Are you submitting in the correct submission area?</li>
              <li>Make sure you are submitting the correct final version of your work.</li>
              <li>Have you kept to the word limit? Remember, anything greater than 10% above the word count will not be marked.</li>
              <li>Have you addressed the assessment requirements as outlined in the Assessment Brief?</li>
            </ol>
          </div>
        </section>
        
      </div>
      {/* wrapper area end */}
    </>
  )
}

export default MentorAnswer