export function formatTimeToAMPM(timeString: string) {
  const [hourString, minute] = timeString.split(":");
  const hour = +hourString % 24;
  return (hour % 12 || 12) + ":" + minute + (hour < 12 ? " AM" : " PM");
}

export function getTimeFromNow(endDate: Date): string {
  // Get the current date and time
  const now: Date = new Date();

  // Calculate the difference in milliseconds
  const diffMs: number = endDate.getTime() - now.getTime();

  // Calculate days, hours, and minutes from milliseconds
  const days: number = Math.floor(diffMs / (1000 * 60 * 60 * 24));
  const hours: number = Math.floor(
    (diffMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes: number = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60));

  console.log(`${days} days, ${hours} hours, ${minutes} minutes from now`);
  return `${days} days, ${hours} hours, ${minutes} minutes from now`;
}


export function getDateAndTimeFromNow(endDateStr: string, endTimeStr: string): string {
  // Parse the date string in "DD-MM-YYYY" format to "YYYY-MM-DD"
  const [day, month, year] = endDateStr.split("-").map(Number);
  const formattedDate = `${year}-${String(month).padStart(2, "0")}-${String(day).padStart(2, "0")}`;
  const endDate: Date = new Date(`${formattedDate}T${endTimeStr}`);
  const now: Date = new Date();

  // Calculate the difference in milliseconds
  const diffMs: number = endDate.getTime() - now.getTime();

  if (diffMs <= 0) {
      return "0 days, 0 hours, 0 minutes from Now"; // If the time has already passed
  }

  // Convert milliseconds to days, hours, and minutes
  const diffDays: number = Math.floor(diffMs / (1000 * 60 * 60 * 24));
  const diffHours: number = Math.floor((diffMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const diffMinutes: number = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60));

  return `${diffDays} days, ${diffHours} hours, ${diffMinutes} minutes from Now`;
}





