import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

// Components
import PageBanner from "../../components/layout/pages/PageBanner";
import AppAdvantages from "../../components/layout/pages/AppAdvantages";

const EquationSolver = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      {/* page banner area start */}
      <PageBanner title="Equation Solver" description="Our AI Enabled Equation Solver Will Help You Achieve Faster Solutions" reviewInfo={false} />
      {/* page banner area end */}

      <div className="wrapper">
        <section className="dashboard-service-top-area">
          <div className="container">
            <div className="main-hdng text-center">
              <h2>Equation Solver: AI-Enabled and Free</h2>
            </div>
            <div className="dashboard-service-top-bx">
              <div className="row">
                <div className="col-lg-7">
                  <div className="dashboard-form">
                    <div className="row gy-4">
                      <div className="col-xl-12">
                        <p>
                          <strong>Type In the Equation, You Want to Solve in The Text Box</strong>
                        </p>
                        <div className="input-with-btn">
                          <input type="text" placeholder="4x + 7 = 2x +1" />
                          <div className="btn-group">
                            <a href="#!" className="custombtn btn-1 hover-filled-slide-down">
                              <span>solve the equation</span>
                            </a>
                          </div>
                        </div>
                        <div className="note-txt">* Write Multiple Query separated by ';'</div>
                      </div>
                      <div className="col-xl-12">
                        <h5>Answer:</h5>
                        <textarea></textarea>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="dashboard-service-top-bx-right">
                    <h5>Write the Equation</h5>
                    <p>You will find a space to write the equation when you access our tool. Make sure the problem is shared correctly for the tool to perform properly.</p>
                    <h5>Verify the Signs</h5>
                    <p>For the equation calculator to perform correctly, the next step is to check if you have used the correct signs and roots. Verify it before you click on solve.</p>
                    <h5>Get Perfect Solutions</h5>
                    <p>Click on “Solve Equation” to get perfect solutions. The tool will get things done in an instant, thus helping you solve complex problems and score well.</p>
                    <div className="dashboard-service-top-bx-right-bottom">
                      <div className="dstbrb-single">
                        <h4>01</h4>
                        <span>Enter query</span>
                      </div>
                      <div className="dstbrb-single">
                        <h4>02</h4>
                        <span>Click Solve The Equation</span>
                      </div>
                      <div className="dstbrb-single">
                        <h4>03</h4>
                        <span>Get Answer</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="avail-expert-help-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-xl-8">
                <div className="row g-4">
                  <div className="col-md-6 col-lg-6">
                    <div className="assignment-writers-bx">
                      <div className="writers-rating">
                        <div className="writers-rating-bx">
                          <img src="/assets/images/star-ico.svg" alt="star-ico" />
                          <span>4.8</span>
                        </div>
                      </div>
                      <div className="assignment-writers-bx-top">
                        <div className="subject-icon">
                          <img src="/assets/images/english-ico.svg" alt="subject-icon" />
                        </div>
                        <div className="subject-name">English</div>
                      </div>
                      <div className="assignment-writers-bx-middle">
                        <div className="writer-details">
                          <p>I deliver online assignment help in all accounting topics and even clarifications on complex concepts.</p>
                        </div>
                        <div className="rv-cp">
                          <div className="rv">
                            <img src="/assets/images/reviews-ico.svg" alt="reviews-ico" />
                            <span>
                              Reviews <strong>432</strong>
                            </span>
                          </div>
                          <div className="cp">
                            <img src="/assets/images/complete-projects-ico.svg" alt="complete-projects-ico" />
                            <span>
                              Completed Project <strong>461</strong>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="assignment-writers-bx-bottom">
                        <div className="assignment-writers-bx-bottom-left">
                          <div className="writer-img">
                            <img src="/assets/images/writer-img1.png" alt="writer-img" />
                          </div>
                          <div className="writer-designation">
                            <h6>Desmond Eagle</h6>
                            <p>MSc in English</p>
                          </div>
                        </div>
                        <a href="#!" className="custom-border-btn btn-3 hover-filled-slide-down">
                          <span onClick={() => navigate("/submit-new-assignment")}>Hire Me</span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-6">
                    <div className="assignment-writers-bx">
                      <div className="writers-rating">
                        <div className="writers-rating-bx">
                          <img src="/assets/images/star-ico.svg" alt="star-ico" />
                          <span>4.8</span>
                        </div>
                      </div>
                      <div className="assignment-writers-bx-top">
                        <div className="subject-icon">
                          <img src="/assets/images/maths-ico.svg" alt="subject-icon" />
                        </div>
                        <div className="subject-name">Mathematics</div>
                      </div>
                      <div className="assignment-writers-bx-middle">
                        <div className="writer-details">
                          <p>I deliver online assignment help in all accounting topics and even clarifications on complex concepts.</p>
                        </div>
                        <div className="rv-cp">
                          <div className="rv">
                            <img src="/assets/images/reviews-ico.svg" alt="reviews-ico" />
                            <span>
                              Reviews <strong>432</strong>
                            </span>
                          </div>
                          <div className="cp">
                            <img src="/assets/images/complete-projects-ico.svg" alt="complete-projects-ico" />
                            <span>
                              Completed Project <strong>461</strong>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="assignment-writers-bx-bottom">
                        <div className="assignment-writers-bx-bottom-left">
                          <div className="writer-img">
                            <img src="/assets/images/writer-img2.png" alt="writer-img" />
                          </div>
                          <div className="writer-designation">
                            <h6>Desmond Eagle</h6>
                            <p>MSc in Mathematics</p>
                          </div>
                        </div>
                        <a href="#!" className="custom-border-btn btn-3 hover-filled-slide-down">
                          <span onClick={() => navigate("/submit-new-assignment")}>Hire Me</span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-6">
                    <div className="assignment-writers-bx">
                      <div className="writers-rating">
                        <div className="writers-rating-bx">
                          <img src="/assets/images/star-ico.svg" alt="star-ico" />
                          <span>4.8</span>
                        </div>
                      </div>
                      <div className="assignment-writers-bx-top">
                        <div className="subject-icon">
                          <img src="/assets/images/accounts-ico.svg" alt="subject-icon" />
                        </div>
                        <div className="subject-name">Accounts</div>
                      </div>
                      <div className="assignment-writers-bx-middle">
                        <div className="writer-details">
                          <p>I deliver online assignment help in all accounting topics and even clarifications on complex concepts.</p>
                        </div>
                        <div className="rv-cp">
                          <div className="rv">
                            <img src="/assets/images/reviews-ico.svg" alt="reviews-ico" />
                            <span>
                              Reviews <strong>432</strong>
                            </span>
                          </div>
                          <div className="cp">
                            <img src="/assets/images/complete-projects-ico.svg" alt="complete-projects-ico" />
                            <span>
                              Completed Project <strong>461</strong>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="assignment-writers-bx-bottom">
                        <div className="assignment-writers-bx-bottom-left">
                          <div className="writer-img">
                            <img src="/assets/images/writer-img3.png" alt="writer-img" />
                          </div>
                          <div className="writer-designation">
                            <h6>Desmond Eagle</h6>
                            <p>MSc in Accounts</p>
                          </div>
                        </div>
                        <a href="#!" className="custom-border-btn btn-3 hover-filled-slide-down">
                          <span onClick={() => navigate("/submit-new-assignment")}>Hire Me</span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-6">
                    <div className="assignment-writers-bx">
                      <div className="writers-rating">
                        <div className="writers-rating-bx">
                          <img src="/assets/images/star-ico.svg" alt="star-ico" />
                          <span>4.8</span>
                        </div>
                      </div>
                      <div className="assignment-writers-bx-top">
                        <div className="subject-icon">
                          <img src="/assets/images/english-ico.svg" alt="subject-icon" />
                        </div>
                        <div className="subject-name">English</div>
                      </div>
                      <div className="assignment-writers-bx-middle">
                        <div className="writer-details">
                          <p>I deliver online assignment help in all accounting topics and even clarifications on complex concepts.</p>
                        </div>
                        <div className="rv-cp">
                          <div className="rv">
                            <img src="/assets/images/reviews-ico.svg" alt="reviews-ico" />
                            <span>
                              Reviews <strong>432</strong>
                            </span>
                          </div>
                          <div className="cp">
                            <img src="/assets/images/complete-projects-ico.svg" alt="complete-projects-ico" />
                            <span>
                              Completed Project <strong>461</strong>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="assignment-writers-bx-bottom">
                        <div className="assignment-writers-bx-bottom-left">
                          <div className="writer-img">
                            <img src="/assets/images/writer-img1.png" alt="writer-img" />
                          </div>
                          <div className="writer-designation">
                            <h6>Desmond Eagle</h6>
                            <p>MSc in English</p>
                          </div>
                        </div>
                        <a href="#!" className="custom-border-btn btn-3 hover-filled-slide-down">
                          <span onClick={() => navigate("/submit-new-assignment")}>Hire Me</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 col-xl-4">
                <div className="avail-expert-help-cnt-area">
                  <div className="main-hdng">
                    <h2>A Peek at the Team of Extraordinary Math Experts</h2>
                  </div>
                  <div className="avail-expert-help-cnt">
                    <p>
                      Understanding the various math symbols and using addition, subtraction, multiplication, and division to calculate an exponent or quadratic equation correctly has been a struggle for students. MyAssignmentHelp is the best place to get the right assistance. Learn how to calculate
                      and solve equations or get step-by-step solutions from the academic stalwarts associated with us. We assign the best minds to help you solve complex problems like a pro. Ask an expert to understand the number system and all other math lessons, from fractions to trigonometry.
                      Solving equations will be easy with MyAssignmentHelp. The experts are well-equipped to handle all problems related to an equation.
                    </p>
                    <div className="btn-group">
                      <a href="#!" className="custombtn btn-1 hover-filled-slide-down">
                        <span>Avail expert help</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* trust service */}
        <section className="trust-service-area trust-service-area-expertsPg trust-service-area-dashboardPg pb-0">
          <div className="container">
            <div className="main-hdng text-center">
              <h2>Access Solved Equations for a Better Understanding</h2>
              <p>Refer to more examples to learn how to solve algebra problems.</p>
            </div>
            <div className="trust-service-bx-area">
              <div className="trust-service-bx">
                <div className="trust-service-bx-single">
                  <div className="trust-service-bx-single-img">
                    <img src="/assets/images/trust-service-img9.svg" alt="trust-service-bx-single-img" />
                  </div>
                  <div className="trust-service-bx-single-cnt">
                    <h5>Solving Cubic Polynomial Equation</h5>
                    <p>Every assignment must be typed and a sufficient amount of work shown. Use correct calculus notation. Graphs must be done electronically (I do not want hand drawn graphs.) Directions for graphing using excel are available in blackboard.</p>
                  </div>
                  <a href="#!" className="btn-link">
                    View sample
                  </a>
                </div>
              </div>
              <div className="trust-service-bx">
                <div className="trust-service-bx-single">
                  <div className="trust-service-bx-single-img">
                    <img src="/assets/images/trust-service-img9.svg" alt="trust-service-bx-single-img" />
                  </div>
                  <div className="trust-service-bx-single-cnt">
                    <h5>Calculation Of Local Maximum And</h5>
                    <p>
                      where x ∈ [−2, 3]. (a) What is f 0 (x)? [1] (b) What is f 00(x)? [1] (c) Find all the stationary points of f. Find the value of f(x) at each stationary point. [2] (d) For each stationary point of f, find whether it is a local minimum, a local maximum or neither. [2] (e) Find
                      all the global minima and
                    </p>
                  </div>
                  <a href="#!" className="btn-link">
                    View sample
                  </a>
                </div>
              </div>
              <div className="trust-service-bx">
                <div className="trust-service-bx-single">
                  <div className="trust-service-bx-single-img">
                    <img src="/assets/images/trust-service-img9.svg" alt="trust-service-bx-single-img" />
                  </div>
                  <div className="trust-service-bx-single-cnt">
                    <h5>Impact Of Calculator Usage On Mathematics</h5>
                    <p>We live in an age of modern technology. Hence, we are memorizing multiplication facts or working calculation out as pencil and paper has become out of date and waste of time. Students learning of mathematical processes, concepts, operations and</p>
                  </div>
                  <a href="#!" className="btn-link">
                    View sample
                  </a>
                </div>
              </div>
              <div className="trust-service-bx">
                <div className="trust-service-bx-single">
                  <div className="trust-service-bx-single-img">
                    <img src="/assets/images/trust-service-img9.svg" alt="trust-service-bx-single-img" />
                  </div>
                  <div className="trust-service-bx-single-cnt">
                    <h5>Financial Analysis And Tax Calculation Essay</h5>
                    <p>
                      CSR Enterprises is evaluating its financing requirements for the coming year. The firm has only been in business for 1 year, but its CFO predicts that the firm's operating expenses, current assets, net fixed assets, and current liabilities will remain at their current
                      proportion of{" "}
                    </p>
                  </div>
                  <a href="#!" className="btn-link">
                    View sample
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* faq area 2 */}
        <section className="faqarea-2 pb-0">
          <div className="container">
            <div className="accordion" id="accordionExample">
              <div className="accordion-item">
                <div className="accordion-header" id="headingOne">
                  <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                    Troublesome Math Questions? Solve by Factoring Calculator
                  </button>
                </div>
                <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                    <div className="faqarea-2-cnt-bottom faqarea-2-cnt-bottom-sd align-items-end">
                      <div className="faqarea-2-cnt-bottom-left">
                        <p>
                          <strong>Here’s how our quadratic factoring calculator solves factor expressions:</strong>
                        </p>
                        <p>Let us explain how to get a solution for the different types of polynomials with some examples.</p>
                        <p>
                          Quadratic Polynomials: Find a solution for P1(x)= 2 x2+3x+1 <br></br>
                          If x=4, then P1(4)=2(4) 2 +3(4)+1=41 <br></br>
                          Cubic Polynomial : P2(x)=−x3+2x2+2x−5 <br></br>
                          x=−1, then P2(−1)=−(−1)3+2(−1)2+2(−1)−5=−8 <br></br>
                          Quartic Polynomial: P3(x)=3x4−2 x 3+5 x 2+2 x+1 <br></br>
                          If x =3, then P3(3)=3(3)4−2(3)3+5(3)2+2(3)+1=709 <br></br>
                          To find the product of two binomials, you have to multiply each factor of the first binomial by each factor of the second. For example: <br></br>
                          (2 x - 1)(3x - 2) <br></br>6 x^2 -4x - 3x +2 <br></br>
                          Simplified: 6 x^2 - 7x + 2 <br></br>
                          Each of these examples has a different expression. If you cannot understand the difference between each method or formula, take guidance from a polynomial number expert from us. Our helpers know the tricky aspects like -
                        </p>
                        <ul className="normal-ul">
                          <li>Finding a common factor</li>
                          <li>Finding the difference of an expression in an equation</li>
                          <li>Calculating the sum of a formula using the number block method</li>
                        </ul>
                        <div className="btn-group">
                          <a href="#!" className="custombtn btn-1 hover-filled-slide-down">
                            <span>Try this software</span>
                          </a>
                        </div>
                      </div>
                      <div className="faqarea-2-cnt-bottom-right">
                        <div className="faqarea-2-img">
                          <img src="/assets/images/faqarea-2-img.png" alt="faqarea-2-img" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <div className="accordion-header" id="headingTwo">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                    Problems Our Factoring Calculator with Steps Can Solve
                  </button>
                </div>
                <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                    <div className="faqarea-2-cnt-bottom faqarea-2-cnt-bottom-sd align-items-end">
                      <div className="faqarea-2-cnt-bottom-left">
                        <p>
                          <strong>Here’s how our quadratic factoring calculator solves factor expressions:</strong>
                        </p>
                        <p>Let us explain how to get a solution for the different types of polynomials with some examples.</p>
                        <p>
                          Quadratic Polynomials: Find a solution for P1(x)= 2 x2+3x+1 <br></br>
                          If x=4, then P1(4)=2(4) 2 +3(4)+1=41 <br></br>
                          Cubic Polynomial : P2(x)=−x3+2x2+2x−5 <br></br>
                          x=−1, then P2(−1)=−(−1)3+2(−1)2+2(−1)−5=−8 <br></br>
                          Quartic Polynomial: P3(x)=3x4−2 x 3+5 x 2+2 x+1 <br></br>
                          If x =3, then P3(3)=3(3)4−2(3)3+5(3)2+2(3)+1=709 <br></br>
                          To find the product of two binomials, you have to multiply each factor of the first binomial by each factor of the second. For example: <br></br>
                          (2 x - 1)(3x - 2) <br></br>6 x^2 -4x - 3x +2 <br></br>
                          Simplified: 6 x^2 - 7x + 2 <br></br>
                          Each of these examples has a different expression. If you cannot understand the difference between each method or formula, take guidance from a polynomial number expert from us. Our helpers know the tricky aspects like -
                        </p>
                        <ul className="normal-ul">
                          <li>Finding a common factor</li>
                          <li>Finding the difference of an expression in an equation</li>
                          <li>Calculating the sum of a formula using the number block method</li>
                        </ul>
                        <div className="btn-group">
                          <a href="#!" className="custombtn btn-1 hover-filled-slide-down">
                            <span>Try this software</span>
                          </a>
                        </div>
                      </div>
                      <div className="faqarea-2-cnt-bottom-right">
                        <div className="faqarea-2-img">
                          <img src="/assets/images/faqarea-2-img.png" alt="faqarea-2-img" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Leading Advantages */}
        <AppAdvantages dataItems={false} header={false} />
      </div>
    </>
  );
};

export default EquationSolver;
