import { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../redux/hooks";

// Redux actions
import { getHomepage } from "../redux/slices/HomePageSlice";
import { getProfilePage } from "../redux/slices/UserSlice";

// Components
import Navbar from "../views/components/layout/common/Navbar";
// import Sidebar from '../views/components/layout/Sidebar';
import { Outlet } from "react-router";
import BlogSection from "../views/components/layout/common/BlogSection";
import ReviewSection from "../views/components/layout/common/ReviewSection";
import Footer from "../views/components/layout/common/Footer";
import FrequentFAQs from "../views/components/layout/pages/FrequentFAQs";
function Index() {
  const location = useLocation();
  const params = useParams();
  const dispatch = useAppDispatch();

  // Redux stores
  const { status, attributes } = useAppSelector((state) => state.homepage);
  const { profilePageStatus } = useAppSelector((state) => state.userData);

  useEffect(() => {
    if (status !== "success") {
      dispatch(getHomepage());
    }
    if (profilePageStatus !== "success") {
      dispatch(getProfilePage());
    }
  }, []);

  return (
    <>
      <Navbar />

      <Outlet />

      {/* -- blog -- */}
      {location.pathname !== "/" && location.pathname !== "/blogs" && location.pathname !== "/payment" && (
        <BlogSection
          className={location.pathname === "/mentor-profile" || location.pathname === "/referencing" || location.pathname === "/submit-new-assignment" || location.pathname === "/experts" || location.pathname === `/blog-details/${params.id}` || location.pathname === "/contact-us" ? "white-bg" : ""}
        />
      )}

      {/* -- review -- */}

      {location.pathname !== "/" && location.pathname !== "/payment" && <ReviewSection homepageData={attributes} />}
      {location.pathname !== "/" && location.pathname !== "/faqs" && location.pathname !== "/payment" && <FrequentFAQs headingOneText={attributes.sec_8_h1} headingTwo={true} headingTwoText={attributes.sec_8_h2} data={attributes.sec_8_faq} />}

      <Footer />
    </>
  );
}

export default Index;
