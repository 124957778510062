// Helpers
import moment from "moment";
import { fromUrlDownFn } from "../../../../helpers/file_downloads";
import qs from "qs";
// Models
import { ApiFuncArgProps } from "../../../../models/apiFuncHelpers";
import { OrderDetailsProps } from "../../../../models/views/components/pages";
import { useAppSelector } from "../../../../redux/hooks";
import { useEffect, useState } from "react";
import { ApiHelperFunction, UpdatePaymentDetails } from "../../../../helpers/api_helpers";
import { OrderDataModel } from "../../../../models/constants/data/dataModels";
import { isApiErrorResponse } from "../../../../helpers/typeguards";
import Swal from "sweetalert2";

let fileBaseUrl = process.env.REACT_APP_API_IMG_URL;

const OrderDetails = ({ colOneClassName, colTwoClassName, orderDetails, handleupdateorder }: OrderDetailsProps) => {
  //states
  const [file_name_upload_1, setFileNameUpload1] = useState<string | null>("");
  const [orderDetail, setorderDetails] = useState<OrderDataModel | undefined>(orderDetails);
  const [file_name_upload_2, setFileNameUpload2] = useState<string | null>("");

  const [url_path_2, setUrlPAth2] = useState<any>(null);
  const [url_path_1, setUrlPAth1] = useState<any>(null);

  const { ordersData } = useAppSelector((state) => state.orders);

  let userEmail = localStorage.getItem("user_email");

  //effects
  useEffect(() => {
    console.log(`ueffectorder=====>`, orderDetails, ordersData);
    setorderDetails(orderDetails);
    setFileNameUpload1(orderDetails?.attributes?.proof_of_payment_1st?.data?.attributes?.name || null);
    setFileNameUpload2(orderDetails?.attributes?.proof_of_payment_2nd?.data?.attributes?.name || null);
  }, [orderDetails, ordersData]);

  //functions
  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>, fileNumber: 1 | 2) => {
    event.preventDefault();
    if (!event?.target?.files?.length) return;

    const file = event.target.files[0];
    console.log(`file=====> ${fileNumber}`, file.name);
    if ((fileNumber === 1 && file.name === file_name_upload_2) || (fileNumber === 2 && file.name === file_name_upload_1)) {
      Swal.fire({
        icon: "warning",
        title: "Duplicate File",
        text: "Please upload a different file for each payment proof.",
      });
      event.target.value = "";
      return;
    }
    let data = {};
    const formData = new FormData();
    formData.append("files", file);
    if (file) {
      try {
        const imgUpRes = await ApiHelperFunction({
          urlPath: "upload",
          method: "POST",
          data: formData,
          role: "privileged",
          contentType: "form-data",
        } as ApiFuncArgProps);

        if (isApiErrorResponse(imgUpRes)) {
          console.error(`Upload failed for file ${fileNumber}: `, imgUpRes.error.message);
          Swal.fire({
            icon: "error",
            title: "Upload Failed",
            text: imgUpRes.error.message,
          });
        } else {
          console.log(`fileNumber=====>`, imgUpRes);
          if (fileNumber === 1) {
            setFileNameUpload1(file.name);
            data = {
              data: {
                proof_of_payment_1st: imgUpRes.data,
              },
            };
            setUrlPAth1(imgUpRes.data);
            // setFile1UrlPath1(imgUpRes.data.url);
          } else if (fileNumber === 2) {
            setFileNameUpload2(file.name);
            data = {
              data: {
                proof_of_payment_2nd: imgUpRes.data,
              },
            };
            setUrlPAth2(imgUpRes.data);
            // setFile1UrlPath2(imgUpRes.data.url);
          }
          console.log(`File ${fileNumber} upload response: `, imgUpRes.data);

          const response: any = await UpdatePaymentDetails({
            urlPath: `new-assignment-form-submissions/${orderDetail?.id}`,
            method: "PUT",
            data: data,
          } as ApiFuncArgProps);

          Swal.fire({
            icon: "success",
            title: "Upload Successful",
            text: `Payment proof  has been uploaded successfully. And order Updated successfully`,
          });
        }
      } catch (error) {
        console.error(`Error uploading file ${fileNumber}: `, error);
        Swal.fire({
          icon: "error",
          title: "Upload Error",
          text: "An error occurred while uploading file or updating the order.Please try again.",
        });
      }
    }

    event.target.value = "";
  };

  const orderStatus = orderDetail?.attributes?.order_status ? `${orderDetail?.attributes?.order_status.charAt(0)}${orderDetail.attributes.order_status.slice(1)}` : "Pending";

  return (
    <>
      <div className={`col-lg-5 ${colOneClassName ? colOneClassName : ""}`}>
        <div className="order-details-bx">
          <div className="order-details-bx-top">
            <div className="order-details-bx-top-left">Order Details</div>
            <div className="order-details-bx-top-right">
              Order Status <strong>{orderStatus}</strong>
            </div>
          </div>
          <div className="order-details-bx-bottom">
            <div className="odbb-hdng">Basic details</div>
            <table>
              <tbody>
                <tr>
                  <td>Email</td>
                  <td>{orderDetail?.attributes.email}</td>
                </tr>
                <tr>
                  <td>Phone</td>
                  <td>{orderDetail?.attributes.phone}</td>
                </tr>
                <tr>
                  <td>Service</td>
                  <td>{orderDetail?.attributes.service}</td>
                </tr>
                <tr>
                  <td>Subject</td>
                  <td>{orderDetail?.attributes.subject}</td>
                </tr>
                {/* <tr>
                  <td>Course Code</td>
                  <td>N/A</td>
                </tr> */}
                <tr>
                  <td>Pages/Words</td>
                  <td>
                    {orderDetail?.attributes.no_of_pages && orderDetail?.attributes.no_of_pages > 1 ? orderDetail?.attributes.no_of_pages + " Pages" : "1 Page"} {orderDetail?.attributes.no_of_pages && "/" + orderDetail?.attributes.no_of_pages * 250 + " words"}
                  </td>
                </tr>
                <tr>
                  <td>Deadline</td>
                  <td>{moment(orderDetail?.attributes.end_by_date).format("MMMM Do YYYY, h:mm:ss a")}</td>
                </tr>
                <tr>
                  <td>Description</td>
                  <td>{orderDetail?.attributes.message.length && orderDetail?.attributes.message.length > 40 ? orderDetail?.attributes.message.slice(0, 40) + "..." : orderDetail?.attributes.message.slice(0, 40)}</td>
                </tr>
              </tbody>
            </table>

            <div className="odbb-hdng">Assignment Details</div>
            {orderDetail?.attributes.attachment.data.map((item, index) => (
              <>
                <a
                  key={index}
                  href="#!"
                  className="attach"
                  onClick={() => {
                    if (fileBaseUrl) {
                      fromUrlDownFn(fileBaseUrl + item.attributes.url, item.attributes.hash);
                    }
                  }}
                >
                  <img src="/assets/images/download-ico.svg" alt="download-ico" />
                  {item.attributes.name}
                </a>
                <div className="row">
                  {!orderDetail?.attributes.proof_of_payment_1st?.data && (
                    <div className="col-md-12">
                      <div>
                        <div className="odbb-hdng">Upload payment proof for 1st payment</div>
                        <div className="file-input">
                          <input type="file" name="file-input-1" id="file-input-1" className="file-input__input" onChange={(e) => handleFileChange(e, 1)} />
                          <label className="attach" htmlFor="file-input-1">
                            <img src="/assets/images/upld-sd-ico.svg" alt="attach" />
                            <span>Attach File</span>
                          </label>
                        </div>
                      </div>
                    </div>
                  )}

                  {orderStatus === "processing" && (
                    <div className="col-md-12">
                      <div>
                        <div className="odbb-hdng">Upload payment proof for 2nd payment</div>
                        <div className="file-input">
                          <input type="file" name="file-input-2" id="file-input-2" className="file-input__input" onChange={(e) => handleFileChange(e, 2)} />
                          <label className="attach" htmlFor="file-input-2">
                            <img src="/assets/images/upld-sd-ico.svg" alt="attach" />
                            <span>Attach File</span>
                          </label>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="col-sm-6 col-md-6 col-lg-12 col-xxl-6 col-xxl-8">
                    {(file_name_upload_1 !== "" || orderDetail?.attributes.proof_of_payment_1st.data != null) && (
                      <>
                        <div className="odbb-hdng">1st 50% payment proof</div>
                        <a
                          className="attach"
                          download
                          // href="#!"
                          onClick={() => {
                            if (url_path_1) {
                              let path = url_path_1 ? url_path_1[0] : null;
                              fromUrlDownFn(fileBaseUrl + path?.url, path?.hash);
                            } else if (orderDetail?.attributes?.proof_of_payment_1st?.data) {
                              const paymentProof = orderDetail?.attributes?.proof_of_payment_1st?.data;

                              fromUrlDownFn(fileBaseUrl + paymentProof?.attributes?.url, paymentProof?.attributes?.hash);
                            }
                          }}
                        >
                          <img src="/assets/images/download-ico.svg" alt="download-ico" />
                          <span>{(orderDetail?.attributes?.proof_of_payment_1st?.data?.attributes?.name || file_name_upload_1 !== null) && "1st payment proof uploaded"}</span>
                        </a>
                      </>
                    )}

                    {(file_name_upload_2 !== null || orderDetail?.attributes.proof_of_payment_2nd.data != null) && (
                      <>
                        <div className="odbb-hdng">Another 50% payment proof</div>
                        <a
                          className="attach"
                          download
                          // href="#!"
                          onClick={() => {
                            if (url_path_2) {
                              let path = url_path_2 ? url_path_2[0] : null;
                              fromUrlDownFn(fileBaseUrl + path?.url, path?.hash);
                            } else if (orderDetail?.attributes?.proof_of_payment_2nd?.data) {
                              const paymentProof = orderDetail?.attributes?.proof_of_payment_2nd?.data;
                              fromUrlDownFn(fileBaseUrl + paymentProof?.attributes?.url, paymentProof?.attributes?.hash);
                            }
                          }}
                        >
                          <img src="/assets/images/download-ico.svg" alt="download-ico" />

                          <span>{(orderDetail?.attributes?.proof_of_payment_2nd?.data?.attributes?.name || file_name_upload_2 !== null) && "2nd payment proof uploaded"}</span>
                        </a>
                      </>
                    )}

                    {orderDetail?.attributes.solution.data != null && (
                      <div className="btn-group mt-5">
                        <button
                          className="custombtn btn-1 hover-filled-slide-down"
                          onClick={() => {
                            if (fileBaseUrl) {
                              fromUrlDownFn(fileBaseUrl + orderDetail?.attributes?.solution?.data?.attributes?.url, orderDetail?.attributes?.solution?.data?.attributes?.hash);
                            }
                          }}
                        >
                          <span>Solution</span>
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </>
            ))}
          </div>
        </div>
      </div>
      <div className={`col-lg-7 ${colTwoClassName ? colTwoClassName : ""}`}>
        <div className="preorder-bx">
          <div className="row">
            <div className="col-md-8 col-lg-7 mobile-order-2">
              <div className="preorder-bx-left">
                <h2>Save money with prebook order</h2>
                <p>Get flat 15 % off on your next assignment on prebooking it.</p>
                <a href="#!" className="custombtn btn-1 hover-filled-slide-down">
                  <span>Pre-book order</span>
                </a>
              </div>
            </div>
            <div className="col-md-4 col-lg-5">
              <div className="preorder-bx-right">
                <img src="/assets/images/prebook-img.svg" alt="prebook-img" className="prebook-img" />
                <div className="preorder-bx-right-circle"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderDetails;
